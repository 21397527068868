import React from 'react'
import './index.scss'
import { Button, Dialog } from '@mui/material'
import { getQuestions, GetCommentsResponse, Comment, commentLike, commentUnlike, postQuestion } from 'api/community'
import { InvestInfo, InvestDetail } from 'api/finance'
import Message from 'components/common/message'
import CommentDetail from 'components/comment-detail';
import { ProfileType } from 'api/user'
import useLoginInterceptor from 'hooks/useLoginInterceptor'
import { formatCommentTime } from 'utils/utils'
import { CircularProgress } from '@mui/material';
import Container from 'components/container'
import FormTextarea from 'components/common/form-textarea'
import { USER_ROLE } from 'constant'

interface Props {
    investInfo: InvestInfo
    refreshFlag: boolean
    profile: ProfileType|undefined
    investDetail: InvestDetail
}

export default function Questions(props:Props) {

    const { investInfo, refreshFlag, profile, investDetail } = props

    const [list, setList] = React.useState<Comment[]>([])
    const [openComment, setOpenComment] = React.useState(false)
    const [isQuestionAll, setIsQuestionAll] = React.useState(false)
    const [section, setSection] = React.useState<number>()
    const [hasMore, setHasMore] = React.useState(false)
    const [pageNo, setPageNo] = React.useState(1)
    const [sort, setSort] = React.useState(1)
    const [openPost, setOpenPost] = React.useState(false)
    const [postContent, setPostContent] = React.useState('')

    const loginInterceptor = useLoginInterceptor()

    async function getData(refresh:boolean) {
        const data:GetCommentsResponse = await getQuestions({
            project_id: investInfo.project_id,
            page_no: refresh ? 1 : pageNo,
            page_size: 10
        })
        const _list = data.comments || []
        if (!refresh) {
            setPageNo(pageNo + 1)
            setList([...list, ..._list])
        } else {
            setPageNo(2)
            setList(_list)
        }
        setHasMore(_list.length === 10)
    }

    async function handleClickLike(e:any, item:Comment) {
        e.stopPropagation()
        try {
            if (item.is_like) {
                await commentUnlike({
                    comment_id: item.id
                })
            } else {
                await commentLike({
                    comment_id: item.id
                })
            }
            getData(true)
        } catch(e:any) {
            console.log(e)
            Message(e.message, {severity:'error'})
        }
    }

    function handleClickQuestion(item:Comment) {
        setIsQuestionAll(false)
        setSection(item.section)
        setOpenComment(true)
        setTimeout(() => {
            window.location.href = window.location.origin + window.location.pathname + window.location.search + `#${item.index}_${item.length}`
        }, 500)
    }

    function handleClickViewAll() {
        setIsQuestionAll(true)
        setSection(undefined)
        setOpenComment(true)
    }

    async function handlePost() {
        try {
            if (!postContent) {
                Message('Please enter question text', {severity:'warning'})
                return
            }
            await postQuestion({
                project_id: investDetail.project_id,
                content: postContent
,            })
            Message('Question Posted')
            getData(true)
            setOpenPost(false)
        } catch (error:any) {
            Message(error.message || 'Error', {severity:'error'})
        }
    }

    function handleAsk() {
        loginInterceptor(() => {
            const isInvested = +investDetail.my_share > 0
            const isManager = profile?.user_info.role === USER_ROLE.OFFICIAL
            const isSelfProject = investInfo.user_id === profile?.id
            if (!isInvested && !profile?.has_profile_nft && !isSelfProject && !isManager) {
                Message('Only investors can make a comment', {severity:'warning'})
                return
            }
            setOpenPost(true)
        })
    }

    React.useEffect(() => {
        getData(true)
    }, [refreshFlag])

    React.useEffect(() => {
        const ob = new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio <= 0) return;
            getData(false)
        }, {
            root: document.querySelector('.box-wrap')
        })
        if (!hasMore) {
            ob.disconnect()
            return
        }
        const el = document.querySelector('.question-more')
        el && ob.observe(el)
    }, [hasMore])

    return (
        <div className='ques-container'>
            <div className="questions">
                <div className="title">Questions</div>
                <div className="sorts">
                    <div className={`item ${sort === 1 ? 'active' : ''}`} onClick={() => setSort(1)}>TOP</div>
                    <div className={`item ${sort === 2 ? 'active' : ''}`} onClick={() => setSort(2)}>LATEST</div>
                </div>
                <div className="box-wrap">
                    {list.map((item) => {
                        return (
                            <div className="box" key={`question_${item.id}`}>
                                <div className="question-arrow" onClick={() => handleClickQuestion(item)}>
                                    <img src={require('assets/img/invest-detail/question-arrow-right.png')} alt="" className="icon" />
                                </div>
                                <div className="box-top">
                                    <img src={item.user.profile_uri || require('assets/img/invest-detail/avatar-default.png')} alt="" className="avatar" />
                                    <div>
                                        <span className="name">{item.user.ens_name || item.user.username}</span>
                                        <div className="time">{formatCommentTime(item.time)}</div>
                                    </div>
                                </div>
                                <div className="box-content">{item.content}</div>
                                <div className="box-info">
                                    <div className="info-item" onClick={(e) => loginInterceptor(() => handleClickLike(e, item))}>
                                        <img src={require(`assets/img/invest-detail/like${item.is_like ? '-active' : ''}.png`)} alt="" className="icon" />
                                        <span className="count">{item.likes} likes</span>
                                    </div>
                                    <div className="info-item">
                                        <img src={require('assets/img/invest-detail/comment.png')} alt="" className="icon" />
                                        <span className="count">{item.reply_cnt} comments</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {hasMore && <CircularProgress className='question-more'></CircularProgress>}
                </div>
                
                {/* {list.length > 2 && <Button className="btn btn-1seed" variant='outlined' onClick={handleClickViewAll}>View All</Button>} */}
                
                <div className="btn-wrap">
                    <Button className='btn btn-1seed' variant='contained' onClick={handleAsk}>Ask a question</Button>
                </div>
    
                <CommentDetail
                profile={profile}
                open={openComment}
                onClose={() => setOpenComment(false)}
                investInfo={investInfo}
                isQuestionAll={isQuestionAll}
                section={section}
                investDetail={investDetail}
                ></CommentDetail>

                <Dialog open={openPost} onClose={() => setOpenPost(false)} className="post-question-dialog">
                    <div className="content">
                        <div className="content-title">Ask a question</div>
                        <img src={require('assets/img/close.png')} alt="" className="close" onClick={() => setOpenPost(false)} />
                        <FormTextarea placeholder='Tell about your question in a few words.' value={postContent} onChange={(d) => setPostContent(d)}></FormTextarea>
                        <Button className="btn btn-1seed" variant='contained' onClick={handlePost}>CONFIRM</Button>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}