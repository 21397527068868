import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import PrivateVisibleList from 'components/visible-list';
import { InvestInfo } from 'api/finance';

interface Props {
    onClose: () => void
    open: boolean
    investInfo: InvestInfo
}

export default function VisibleListDialog(props:Props) {
    const { open, onClose, investInfo } = props

    return (
        <Dialog open={open} onClose={onClose} className="visible-list-dialog">
            <div className="content">
                <div className="text1">{'Private Finacing List'}</div>
                <div className="text2">You can <span className="sub">Add</span> visible list under private financing mode .</div>
                <PrivateVisibleList projectId={investInfo.project_id}></PrivateVisibleList>
                <Button className="btn btn-1seed" variant='contained' onClick={() => {
                    onClose()
                }}>Confirm</Button>
            </div>
        </Dialog>
    )
}