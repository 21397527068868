import React from 'react';
import './index.scss'
import { Button } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles'
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as Down } from "assets/svg/sort-arrow-down.svg";
import { ReactComponent as Up } from "assets/svg/sort-arrow-up.svg";
import Message from 'components/common/message';
import { Aggrement } from '../types';
import { OTCDetail } from 'api/trade';
import { dealMetamaskAddress } from 'utils/utils';
import Empty from 'components/empty';
import { TAB_TYPE, SORT_TYPE, AGGREMENT_STATUS, statusMap, AGGREMENT_TYPE } from '../map';
import AggrementDialog from '../aggrement-dialog';
import useTradeWsMsg from 'hooks/useTradeWsMsg';
import useLoginInterceptor from 'hooks/useLoginInterceptor';
import CopyItem from '../copy-item';

interface Props {
    aggrements: Aggrement[],
    detail: OTCDetail,
    expiredTime: number,
    // symbol: string,
    userAddr: string
    onRefreshData: () => void
    // tokenDecimal: string
    onRefresh: () => void
}

export default function AggrementList(props: Props) {

    const { aggrements, detail, expiredTime, userAddr, onRefreshData, onRefresh } = props

    const [tab, setTab] = React.useState(TAB_TYPE.ALL)
    const [sortType, setSortType] = React.useState(SORT_TYPE.ALL)
    const [totalSortType, setTotalSortType] = React.useState(SORT_TYPE.ALL)
    const [statusList, setStatusList] = React.useState<string[]>([])
    const [filterAggrements, setFilterAggrements] = React.useState<Aggrement[]>([])
    const [type, setType] = React.useState('')
    const [aggrement, setAggrement] = React.useState<Aggrement>()
    const [open, setOpen] = React.useState(false)

    const {read, msgCount} = useTradeWsMsg(detail, false)
    const loginInterceptor = useLoginInterceptor()

    const StyledTabs = styled(Tabs)`
        & .MuiTabs-indicator {
        background: linear-gradient(85.25deg, #001AFF -12.81%, #22C0FF 74.7%);
        height: 3px;
        }
    `

    const StyledTab = styled(Tab)`
        &.MuiTab-textColorPrimary {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #808080;
        text-transform: none;
        margin-right: 27px;
        padding: 0;
        height: 64px;
        }
        &.Mui-selected {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        background: linear-gradient(85.25deg, #001AFF -12.81%, #22C0FF 74.7%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        }
    `;

    function handleChange(v:string) {
        setTab(v)
    }

    function handleClickSortType(type:number) {
        setTotalSortType(SORT_TYPE.ALL)
        if (sortType === type) {
            setSortType(SORT_TYPE.ALL)
        } else {
            setSortType(type)
        }
    }

    function handleClickTotalSortType(type:number) {
        setSortType(SORT_TYPE.ALL)
        if (totalSortType === type) {
            setTotalSortType(SORT_TYPE.ALL)
        } else {
            setTotalSortType(type)
        }
    }

    function generateStatusList() {
        if (!aggrements.length) return
        const list:string[] = []
        aggrements.forEach(item => {
            let status = ''
            if (item.active) {
                if (expiredTime == 0 || expiredTime * 1000 >= Date.now()) {
                    status = AGGREMENT_STATUS.PENDING
                } else {
                    status = AGGREMENT_STATUS.FAILED
                }
            } else {
                status = AGGREMENT_STATUS.COMPLETED
            }
            list.push(status)
        })
        setStatusList(list)
    }

    function getFilterAggrements() {
        return aggrements.filter(_aggrement => {
            if (tab === TAB_TYPE.ALL) {
                return true
            } else {
                return _aggrement.buyer === userAddr || _aggrement.seller === userAddr
            }
        }).sort((a:Aggrement, b:Aggrement) => {
            if (sortType === SORT_TYPE.DOWN) {
                return +a.costPerToken - +b.costPerToken
            }
            if (sortType === SORT_TYPE.UP) {
                return +b.costPerToken - +a.costPerToken
            }
            if (totalSortType === SORT_TYPE.DOWN) {
                return +a.costPerToken * +a.tokens - +b.tokens * +b.costPerToken
            }
            if (totalSortType === SORT_TYPE.UP) {
                return +b.tokens * +b.costPerToken - +a.tokens * +a.costPerToken
            }
            return 0
        })
    }

    function handleClickBtn(_type: string, item:Aggrement) {
        setType(_type)
        setAggrement(item)
        setOpen(true)
    }
    
    React.useEffect(() => {
        setFilterAggrements(getFilterAggrements())
    }, [aggrements, tab, sortType, totalSortType])

    React.useEffect(() => {
        generateStatusList()
    }, [aggrements])

    return (
        <div className="aggrement-list">
            <Box paddingLeft={'20px'} borderBottom={'1px solid rgba(179, 179, 179, 0.7);'} position={'relative'}>
                    <StyledTabs
                    value={tab}
                    >
                        <StyledTab value={TAB_TYPE.ALL} label="All aggrement" onClick={() => handleChange(TAB_TYPE.ALL)} />
                        <StyledTab value={TAB_TYPE.MY} label="My aggrement" onClick={() => {
                            handleChange(TAB_TYPE.MY)
                            msgCount > 0 && read()
                        }} />
                    </StyledTabs>
                    {msgCount > 0 && <div className="msg-count">{msgCount}</div>}
            </Box>
          
            <table className='data-table'>
                <thead>
                    <tr>
                        <th>Aggrement ID</th>
                        <th><div className="flex left">
                            <span>Price</span>
                            <div className="sort-wrap">
                                <Up className='icon' fill={sortType === SORT_TYPE.UP ? '#001AFF' : '#808080'} onClick={() => handleClickSortType(SORT_TYPE.UP)}></Up>
                                <Down className='icon' fill={sortType === SORT_TYPE.DOWN ? '#001AFF' : '#808080'} onClick={() => handleClickSortType(SORT_TYPE.DOWN)}></Down>
                            </div>
                            </div></th>
                        <th>Token</th>
                        <th>Quantity</th>
                        <th>
                            <div className="flex left">
                                <span>Totals</span>
                                <div className="sort-wrap">
                                    <Up className='icon' fill={totalSortType === SORT_TYPE.UP ? '#001AFF' : '#808080'} onClick={() => handleClickTotalSortType(SORT_TYPE.UP)}></Up>
                                    <Down className='icon' fill={totalSortType === SORT_TYPE.DOWN ? '#001AFF' : '#808080'} onClick={() => handleClickTotalSortType(SORT_TYPE.DOWN)}></Down>
                                </div>
                            </div>
                        </th>
                        <th style={{width: '180px'}}>From</th>
                        <th style={{width: '180px'}}>To</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {filterAggrements.length ? filterAggrements.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.tradeId}</td>
                                <td>{item.costPerToken} {item.symbol}</td>
                                <td>
                                    <div className="flex">
                                        <img src={detail?.token_logo_url} alt="" className="token-logo" />
                                        ${detail?.token_name}
                                    </div>
                                </td>
                                <td>{item.tokens}</td>
                                <td>{+(+item.costPerToken * +item.tokens).toFixed(6)} {item.symbol}</td>
                                <td width={180}>
                                    {/* <div className="flex left copy-wrap">
                                        {dealMetamaskAddress(item.seller)}
                                        <CopyToClipboard text={item.seller} onCopy={() => Message('copy success!')}>
                                            <img src={require('assets/img/trading/copy.png')} alt="" className="copy" />
                                        </CopyToClipboard>
                                    </div> */}
                                    <CopyItem showText={dealMetamaskAddress(item.seller)} copyText={item.seller}></CopyItem>
                                </td>
                                <td width={180}>
                                    {/* <div className="flex left copy-wrap">
                                        {dealMetamaskAddress(item.buyer)}
                                        <CopyToClipboard text={item.buyer} onCopy={() => Message('copy success!')}>
                                            <img src={require('assets/img/trading/copy.png')} alt="" className="copy" />
                                        </CopyToClipboard>
                                    </div> */}
                                    <CopyItem showText={dealMetamaskAddress(item.buyer)} copyText={item.buyer}></CopyItem>
                                </td>
                                <td>
                                    <span className={`status ${statusMap[statusList[index] || AGGREMENT_STATUS.PENDING]?.style}`}>{statusMap[statusList[index] || AGGREMENT_STATUS.PENDING]?.text}</span>
                                </td>
                                <td>
                                    <div className="flex center">
                                        
                                        {statusList[index] === AGGREMENT_STATUS.PENDING && item.seller === userAddr && <Button
                                        className="btn btn-1seed"
                                        variant='outlined'
                                        onClick={() => loginInterceptor(() => handleClickBtn(AGGREMENT_TYPE.FULFILL, item))}>
                                            <span className="text-color">Fulfill</span>
                                        </Button> }
                                        {statusList[index] === AGGREMENT_STATUS.FAILED && item.buyer === userAddr && <Button
                                        className="btn btn-1seed"
                                        variant='outlined'
                                        onClick={() => loginInterceptor(() => handleClickBtn(AGGREMENT_TYPE.CLAIM, item))}>
                                            <span className="text-color">Claim</span>
                                        </Button>}
                                    </div>
                                </td>
                            </tr>
                        )
                    }) : <tr><td colSpan={9}><Empty text2='Try Refresh' onRefresh={onRefreshData}></Empty></td></tr>}
                </tbody>
            </table>
            {(aggrement && open) &&
            <AggrementDialog
            open={open}
            onClose={() => setOpen(false)}
            detail={detail}
            aggrement={aggrement}
            // symbol={symbol}
            userAddr={userAddr}
            expiredTime={expiredTime}
            type={type}
            // tokenDecimal={tokenDecimal}
            onRefresh={onRefresh}
            ></AggrementDialog>}
        </div>
    );
}
