import React from 'react'
import './index.scss'
import HeaderProfile from 'components/header/header-profile'
import MintProfileNFT from 'components/mint-profile-nft'
import { useNavigate } from 'react-router'
// import ProfileNftCardDialog from 'components/profile-nft-card-dialog'

export default function CheckEligibility() {
    const navigate = useNavigate()

    const [showProfileNftCardDialog, setShowProfileNftCardDialog] = React.useState(false)

    return (
        <div className="check-eligibility">
            <div className="check-eligibility__top">
                <img src={require('assets/img/check-eligibility/logo.png')} alt="" className="logo" onClick={() => navigate('/')} />
                <HeaderProfile hideLogin></HeaderProfile>
            </div>
            <div className="check-eligibility__title">Welcome to the future Financing Style of 1Seeddao</div>
            <div className="check-eligibility__sub-title">Check your eligibility to claim Membership NFT.</div>
            <MintProfileNFT></MintProfileNFT>
            {/* <ProfileNftCardDialog
            open={showProfileNftCardDialog}
            onClose={() => setShowProfileNftCardDialog(false)}
            ></ProfileNftCardDialog> */}
        </div>
    )
}