import React from 'react';
import './index.scss';

interface Props {
    timestamp: number
}

let interval: NodeJS.Timer | undefined

export default function ProjectTimer(props: Props) {
    const { timestamp } = props

    const [dateList, setDateList] = React.useState<number[]>([0, 0, 0, 0])


    function getDate() {
        const leftTime = timestamp * 1000 - Date.now()
        // const leftTime = 1698357728519 - Date.now()
        if (leftTime <= 0) return
        setDateList([
            Math.floor(leftTime / (1000 * 60 * 60 * 24)),
            Math.floor(leftTime / (1000 * 60 * 60) % 24),
            Math.floor(leftTime / (1000 * 60) % 60),
            Math.floor(leftTime / (1000) % 60),
        ])
    }

    React.useEffect(() => {
        if (!timestamp) {
            return
        }
        getDate()
        if (!interval && timestamp * 1000 > Date.now()) {
            interval = setInterval(() => {
                getDate()
            }, 1000)
        }
    }, [timestamp])

    React.useEffect(() => {
        return () => {
            interval && clearInterval(interval)
            interval = undefined
        }
    }, [])

    return (
        <div className="project-timer">
            <div className="time">
                <div className="text1">{dateList[0]}</div>
                <div className="text2">DAYS</div>
            </div>
            <div className="time">
                <div className="text1">{dateList[1]}</div>
                <div className="text2">HRS</div>
            </div>
            <div className="time">
                <div className="text1">{dateList[2]}</div>
                <div className="text2">MINS</div>
            </div>
            <div className="time">
                <div className="text1">{dateList[3]}</div>
                <div className="text2">SECS</div>
            </div>
        </div>
    )
    
}