import React from 'react';
import './index.scss'
export default function Advantages() {

    return (
        <div className="advantages">
            <span className="advantages__title">Our Advantages</span>
            <div className="advantages__wrap">
                <div className="item">
                    <div className="num">01</div>
                    <div className="item-title">Easy Fundraising</div>
                    <div className="item-content">The 1Seed platform is designed to be easy to use for both builders and investors. Builders can create a project page in minutes, and investors can easily find and invest in projects that they are interested in.</div>
                </div>
                <div className="item">
                    <div className="num">02</div>
                    <div className="item-title">Community-driven</div>
                    <div className="item-content">1Seed is a community-driven platform. This means that builders can get feedback from potential investors and users early on in the development process. This feedback can help entrepreneurs to improve their projects and make them more successful.</div>
                </div>
                <div className="item">
                    <div className="num">03</div>
                    <div className="item-title">Affordable</div>
                    <div className="item-content">There are no fees charged to entrepreneurs by 1Seed before the project is successfully funded. After successful funding, fundraisers only need to pay a 5% fee of the financing amount to the platform. This makes it an affordable option for startups that are just starting out.</div>
                </div>
            </div>
        </div>
    )
}
