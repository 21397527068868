import React from 'react';
import './index.scss'
import FormInput from 'components/common/form-input';
import deleteIcon from 'assets/img/apply/visible/delete.png'
import deleteHoverIcon from 'assets/img/apply/visible/delete-hover.png'
import { CircularProgress } from '@mui/material';
import { PrivateUser, applyPriviteAddUsers, applyPriviteDelUsers } from 'api/apply';
import Tips from 'components/tips';
import { IsFirstLoginResponse, getIsRegistered } from 'api/user';
import { emailReg } from 'constant/form';
import Web3 from 'web3';
import Message from 'components/common/message';

type Props = {
    index: number,
    value: string,
    onChange?: (v:string) => void,
    projectId: string,
    onDel?: () => void,
    data?: PrivateUser
}


export default function VisibleItem(props: Props) {
    const { index, value, onChange, projectId, onDel, data } = props
    const STATUS = {
        CHECKED: 0,
        UNCHECKED: 1,
        LOADING: 2,
        INITIAL: 3
    }
    const TEXT_TYPE = {
        UNKNOWN: -1,
        WALLET: 0,
        EMAIL: 1,
    }
    const [status, setStatus] = React.useState(STATUS.INITIAL)
    const [v, setV] = React.useState(value)
    const [isError, setIsError] = React.useState(false)

    function handleChange(_v:string) {
        setV(_v)
    }

    async function onBlur() {
        if (!v) {
            return
        }
        const isWallet = Web3.utils.isAddress(v)
        const isEmail = emailReg.test(v)
        let type = TEXT_TYPE.UNKNOWN
        if (isWallet) {
            type = TEXT_TYPE.WALLET
        } else if (isEmail) {
            type = TEXT_TYPE.EMAIL
            // if (v.indexOf('@gmail.com') > -1) {
            //     type = TEXT_TYPE.EMAIL
            // } else {
            //     setTimeout(() => {
            //         Message('Only gmail support', {severity:"warning"})
            //     }, 100)
            // }
        }
        if (type === TEXT_TYPE.UNKNOWN) {
            // setStatus(STATUS.UNCHECKED)
            setIsError(true)
            console.log('Please enter correct google email or wallet address')
            setTimeout(() => {
                Message('Please enter correct google email or wallet address', {severity: 'error'})
            }, 200)
            // setV('')
            return
        }
        setIsError(false)
        addUser(type)
        try {
            setStatus(STATUS.LOADING)
            const data:IsFirstLoginResponse = await getIsRegistered({
                email: type === TEXT_TYPE.EMAIL ? v : '',
                wallet: type === TEXT_TYPE.WALLET ? v : ''
            })
            if (data.result) {
                setStatus(STATUS.CHECKED)
                return
            }
            setStatus(STATUS.UNCHECKED)
        } catch (error) {
            console.log(error)
            setStatus(STATUS.UNCHECKED)
        }
    }

    async function addUser(type:number) {
        try {
            await applyPriviteAddUsers({
                project_id: projectId,
                wallets: type === TEXT_TYPE.WALLET ? [v] : [],
                emails: type === TEXT_TYPE.EMAIL ? [v] : []
            })
            // setStatus(STATUS.CHECKED)
            onChange && onChange(v)
        } catch (error:any) {
            Message(error.message || 'Error', {severity: 'error'})
            console.log(error)
        }
    }

    async function handleDel() {
        if (!onDel) return
        if (STATUS.LOADING === status) {
            return
        }
        if (STATUS.CHECKED === status) {
            try {
                const isEmail = emailReg.test(value)
                await applyPriviteDelUsers({
                    project_id: projectId,
                    wallet: isEmail ? '' : value,
                    email: isEmail ? value : ''
                })
                onDel()
            } catch (error:any) {
                Message(error.message || 'Error', {severity: 'error'})
                console.log(error)
            }
            return
        }
        onDel()
    }

    React.useEffect(() => {
        setV(value)
        // if (value) {
        //     setStatus(STATUS.CHECKED)
        // }
        if (data?.id) {
            setStatus(STATUS.CHECKED)
        } else {
            setStatus(STATUS.UNCHECKED)
        }
    }, [value])

    return (
        <div className="visible-item">
            <div className="num">{index + 1}.</div>
            <FormInput
            placeholder='Please enter the wallet address or email'
            value={v}
            onChange={handleChange}
            disabled={[STATUS.CHECKED, STATUS.LOADING].includes(status) || !!data}
            onBlur={onBlur}
            error={isError}
            >
                <div className="subfix-wrap">
                    {STATUS.CHECKED === status && (
                        <>
                            <img src={require('assets/img/apply/visible/check.png')} alt="" className="subfix" />
                            <Tips text='Registered'></Tips>
                        </>
                    )}
                    {STATUS.UNCHECKED === status &&
                        <>
                            <img src={require('assets/img/apply/visible/uncheck.png')} alt="" className="subfix" />
                            <Tips text='Unregistered'></Tips>
                        </>
                    }
                    {STATUS.LOADING === status && <CircularProgress className='subfix' size={24}></CircularProgress>}
                </div>
            </FormInput>
            <div className={`icon-wrap ${onDel ? '' : 'hidden'}`} onClick={handleDel}>
                <img src={deleteIcon} alt="" className={`icon default `} />
                <img src={deleteHoverIcon} alt="" className={`icon hover`} />
            </div>
        </div>
    )
}
