import React from 'react';
import './index.scss'
import { ReactComponent as StepIcon1 } from "assets/img/apply-guide/step1.svg";
import { ReactComponent as StepIcon2 } from "assets/img/apply-guide/step2.svg";
import { ReactComponent as StepIcon3 } from "assets/img/apply-guide/step3.svg";
import { ReactComponent as StepIcon4 } from "assets/img/apply-guide/step4.svg";
import { ReactComponent as StepIcon5 } from "assets/img/apply-guide/step5.svg";
import img1 from 'assets/img/apply-guide/step1-img.png'
import img2 from 'assets/img/apply-guide/step2-img.png'
import img3 from 'assets/img/apply-guide/step3-img.png'
import img4 from 'assets/img/apply-guide/step4-img.png'
import img5 from 'assets/img/apply-guide/step5-img.png'
import { preloadImg } from 'utils/utils';

export default function Raises() {
    const [activeRaiseIndex, setActiveRaiseIndex] = React.useState(0)

    const raisesData = [
        {
            title: 'Upload project information and pitch.',
            text: `The fundraiser uploads the project's basic information and pitch.`,
            icon: <StepIcon1 className='icon'></StepIcon1>,
            activeIcon: <StepIcon1 className='icofn' fill='#fff'></StepIcon1>
        },
        {
            title: `Set fundraising goals and timeframe.`,
            text: `Set fundraising goal: 1Seed's fundraising model adopts the all-or-nothing approach. Startup projects can choose a funding goal and set the number of days to reach this target.`,
            icon: <StepIcon2 className='icon'></StepIcon2>,
            activeIcon: <StepIcon2 className='icon' fill='#fff'></StepIcon2>
        },
        {
            title: `Choose private or public fundraising mode.`,
            text: `Choose the fundraising method: Private fundraising (public fundraising after confirming lead investors) or direct public fundraising. When you start in private mode, your project page is already online but only visible to people with the direct link. When you switch to public mode, your company will be eligible to appear on the explore page.`,
            icon: <StepIcon3 className='icon'></StepIcon3>,
            activeIcon: <StepIcon3 className='icon' fill='#fff'></StepIcon3>
        },
        {
            title: `Initiate fundraising by creating a smart contract.`,
            text: `Forge the fundraising contract. Investor funds are managed through smart contracts, which guarantee full transparency and security. `,
            icon: <StepIcon4 className='icon'></StepIcon4>,
            activeIcon: <StepIcon4 className='icon' fill='#fff'></StepIcon4>
        },
        {
            title: `Receive funds if crowdfunding goals are met.`,
            text: `Funds are automatically transferred to fundraisers only after reaching the crowdfunding goals.`,
            icon: <StepIcon5 className='icon'></StepIcon5>,
            activeIcon: <StepIcon5 className='icon' fill='#fff'></StepIcon5>
        }
    ]

    React.useEffect(() => {
        preloadImg([img1, img2, img3, img4, img5])
    }, [])

    return (
        <div className="raises">
            <div className="raises__title">How to raise funds using 1Seed?</div>
            <div className="steps">
                {raisesData.map((item, index) => {
                    return (
                        <div
                        className={`step ${activeRaiseIndex >= index ? 'active' : ''}`}
                        onClick={() => setActiveRaiseIndex(index)}
                        key={`raise_${index}`}
                        >
                            <div className="icon-wrap">
                                {activeRaiseIndex >= index ? raisesData[index].activeIcon : raisesData[index].icon}
                            </div>
                            <div className="step-title">{item.title}</div>
                        </div>
                    )
                })}
            </div>
            <div className="raises__content">
                <div className="icon-wrap">
                    {raisesData[activeRaiseIndex].activeIcon}
                </div>
                <div className="text1">{raisesData[activeRaiseIndex].title}</div>
                <div className="text2">{raisesData[activeRaiseIndex].text}</div>
                <img src={require(`assets/img/apply-guide/step${activeRaiseIndex + 1}-img.png`)} alt="" className="img" />
            </div>
        </div>
    );
}
