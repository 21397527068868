import React from 'react'
import { InvestInfo } from 'api/finance'
import './index.scss'

interface Props {
    investInfo: InvestInfo
}

export default function ProjectTeammates(props:Props) {
    const { investInfo } = props
    return (
        <div className="project-teammates">
            <div className="project-teammates__title">Team member</div>
            <div className="project-teammates__wrap">
                {investInfo.teammates?.map((item, index) => {
                    return (
                        <div className="project-teammate" key={`teammate_${index}`}>
                            <div className="left">{item.name.slice(0, 1)}</div>
                            <div className="content">
                                <div className="text1">{item.name}<div className="tag">{item.title}</div></div>
                                <div className="text2" title={item.bio}>{item.bio.length > 120 ? item.bio.slice(0, 120) + '...' : item.bio}</div>
                                {/* <a href={`mailto:${item.email}`} className="text3" title={item.email}>{item.email}</a> */}
                                {/* <span className="text3">{item.email}</span> */}
                            </div>
                        </div>
                    )
                })}
            </div>
            
        </div>
    )
}