import { useEffect, useState } from "react";

export default function useFixedHeader() {
	const [isFixedHeader, setIsFixedHeader] = useState(false)

	useEffect(() => {
		const el = document.getElementById('header')
		if (!el) return
		window.addEventListener('scroll', () => {
			setIsFixedHeader(true)
			if (window.scrollY >= 88) {
				setIsFixedHeader(true)
			} else {
				setIsFixedHeader(false)
			}
		})
		return () => {
			window.removeEventListener('scroll', () => {
				setIsFixedHeader(false)
			})
		}
	}, ['mount'])

	return [isFixedHeader]
}