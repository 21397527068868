import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import FormInput from 'components/common/form-input';
import Button from '@mui/material/Button'
import Message from 'components/common/message';
import { urlReg } from 'constant/form';
import { postCal } from 'api/finance';

interface Props {
    onClose: () => void
    open: boolean
    projectId: string
    onSuccess: () => void
}

export default function CalendarInviteDialog(props:Props) {
    const { open, onClose, projectId, onSuccess } = props

    const [cal, setCal] = React.useState('')

    async function handleConfirm() {
        if (!urlReg.test(cal) || !cal.startsWith('https://cal.com/')) {
            Message('Invalid link', {severity: 'error'})
            return
        }
        try {
            await postCal({
                project_id: projectId,
                cal: cal
            })
            onSuccess()
        } catch(e:any) {
            Message(e.message || 'error', {severity: 'error'})
        }
    }

    return (
        <Dialog open={open} onClose={onClose} className="calendar-invite-dialog">
            <div className="content">
                <div className="title">Calendar Invitation
                    <img src={require('assets/img/close.png')} alt="" className="close" onClick={onClose} />
                </div>
                <div className="sub-title" onClick={() => window.open('https://1seeddaoprod.s3.ap-east-1.amazonaws.com/assets/guide.mp4 ')}>
                    <img src={require('assets/img/invest-detail/calendar-invite-notify.png')} alt="" className="icon" />
                    Detailed Description
                </div>
                <div className="text">
                    {`1Seeddao supports you to invite and communicate with investors through Cal.com. If you already have a Cal account, you need to upload your Cal.com invitation link for this project financing activity.If you don't have a `}
                    <span
                    className="sub"
                    onClick={() => window.open('https://cal.com/docs/introduction/quick-start/sign-up-for-a-cal.com-account#next-steps')}>Cal.com </span>
                    account yet, go to
                    <span className="sub" onClick={() => window.open('https://cal.com/signup')}> Quick Sign Up</span>.
                </div>
                <div className="text2">The invitation link of the project on Cal.com</div>
                <FormInput
                className='input'
                value={cal}
                onChange={(v) => setCal(v)}
                placeholder='eg: https://cal.com/XXXXXX/1seeddao'></FormInput>
                <Button className='btn-1seed btn' variant='contained' disabled={!cal} onClick={handleConfirm}>Confirm</Button>
            </div>
        </Dialog>
    )
}