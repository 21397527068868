import React, { useState, useEffect, ChangeEvent } from 'react';
import './index.scss'
import commaImg from '../../assets/img/login-guide/comma.png'
import logoImg from '../../assets/img/login-guide/logo.png'
// import defaultAvatar from '../../assets/img/login-guide/default-avatar.png'
import checkImg from '../../assets/img/login-guide/check.png'
import { TextField, Button, IconButton } from '@mui/material';
import { firstGuide, finishGuide, FirstGuideType, QasType, isDuplicate } from '../../api/user';
import Message from '../../components/common/message';
import GuideDoneDialog from './guide-done-dialog';
import ConfirmCloseDialog from './confirm-close-dialog';
import avatarCheckIcon from '../../assets/img/login-guide/avatar-check.png'
import Upload from '@mui/icons-material/Upload';
import UploadInput from '../../components/common/upload';
// import { useLocation } from 'react-router';
// import { useBeforeUnload } from 'react-router-dom';
import { unstable_useBlocker } from 'react-router';
import Loading from 'components/common/loading';
import { debounce } from 'lodash';

type Answer = {
  qid: number,
  aid: Array<string>
}
// type Form = {
//   profile?: File,
//   profile_url?: string,
//   username?: string,
//   answers?: string
// }

let noCloseConfirm = false

export default function LoginGuide() {

  // const location = useLocation()

  // 0 1 2 3 4 ...
  const [step, setStep] = useState(0)
  
  const isFirstStep = () => step === 0
  const [guideData, setGuideData] = useState<FirstGuideType>()
  // unused
  // const [profile, setProfile] = useState<File|null>(null)
  const [profile_url, setProfileUrl] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [answers, setAnswers] = useState<Array<Answer>>([])
  const [openGuideDoneDialog, setOpenGuideDoneDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [activeAvatarIndex, setActiveAvatarIndex] = useState(-1)
  const [usernameInvalid, setUsernameInvalid] = useState(false)

  async function getGuideContent() {
    const data:FirstGuideType = await firstGuide()
    setGuideData(data)
    
    const tmpAnswers = data?.qas.reduce((rs:Array<Answer>, item) => {
      rs.push({
        qid: item.id,
        aid: []
      })
      return rs
    }, [])
    setAnswers(tmpAnswers)
  }
  
  useEffect(() => {
    getGuideContent()
  }, [])

  // TODO: router change confirm
  // useBeforeUnload(React.useCallback(() => {
  //   console.log(123123123)
  // }, [location]));

  const blocker = unstable_useBlocker(() => {
    if (noCloseConfirm) {
      return false
    }
    setOpenConfirmDialog(true)
    return true
  })

  function confirmClose() {
    setOpenConfirmDialog(false)
    blocker.proceed && blocker.proceed()
  }

  function isMultiCheck() {
    return step === 2
  }

  function onUsernameChange(e:ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) {
    setUsername(e?.target.value || '')
    checkName(e?.target.value || '')
  }

  // function onUsernameBlur() {
  //   checkName(username)
  // }

  const checkName = debounce(async function(name:string) {
    if (!name) {
      setUsernameInvalid(false)
    }
    const { result } = await isDuplicate({
      type: 0,
      value: name
    })
    setUsernameInvalid(!!result)
  }, 300)

  // async function checkName(name:string) {
  //   if (!name) {
  //     setUsernameInvalid(false)
  //   }
  //   const { result } = await isDuplicate({
  //     type: 0,
  //     value: name
  //   })
  //   setUsernameInvalid(!!result)
  // }

  function onUpload(url:string) {
    // if (!e) return
    // setProfile(e.target.files && e?.target.files[0] || null)
    // e.target.value = ''
    // setProfileUrl('')
    // console.log(url)
    setProfileUrl(url)
    setActiveAvatarIndex(-1)
  }

  function getProfileImg() {
    return profile_url || ''
  }

  function getPreviewProfile():string {
    // if (profile_url) {
    //   return profile_url
    // }
    // if (profile) {
    //   const URL = window.URL || window.webkitURL
    //   return URL.createObjectURL(profile)
    // }
    return profile_url
  }

  function onChangeProfileUrl(url:string, index:number) {
    setProfileUrl(url)
    setActiveAvatarIndex(index)
  }

  function getCurrentQAData():QasType {
    if (step > 0 && guideData) {
      return guideData.qas[step - 1]
    }
    return {
      answers: [],
      question: {
        desc: '',
        content: ''
      },
      id: -1
    }
  }

  function checkAnswer(answer:string) {
    const qid = getCurrentQAData()?.id
    const findAnswer = answers.find(item => item.qid === qid)
    if (!findAnswer) return
    if (isMultiCheck()) {
      const findIndex = findAnswer.aid.findIndex(item => item === answer)
      if (findIndex > -1) {
        findAnswer.aid.splice(findIndex, 1)
      } else {
        findAnswer.aid.push(answer)
        if (findAnswer.aid.length > 3) {
          findAnswer.aid.splice(0, 1)
        }
      }
    } else {
      findAnswer.aid = [answer]
    }
    setAnswers([...answers])
  }

  function getCurrentAnswer():Answer|undefined {
    return answers.find(d => d.qid === getCurrentQAData()?.id)
  }

  function isAnswerActive(answer:string) {
    return getCurrentAnswer()?.aid.includes(answer)
  }

  function nextStep() {
    if (step === 0) {
      if (!username) {
        Message('Please enter username', { severity: 'warning' })
        return
      }
      if (username.length < 3 || username.length > 32) {
        Message('Username should be 3 - 32 characters', { severity: 'warning' })
        return
      }
      if (usernameInvalid) {
        Message('The username is already taken', { severity: 'warning' })
        return
      }
      if (!profile_url) {
        Message('Please choose or upload a profile picture', { severity: 'warning' })
        return
      }
    } else {
      if (getCurrentAnswer()?.aid.length === 0) {
        Message('Please choose an item at least', { severity: 'warning' })
        return
      }
    }
    setStep(step + 1)
  }

  async function submit() {
    if (getCurrentAnswer()?.aid.length === 0) {
      Message('Please choose an item at least', { severity: 'warning' })
      return
    }
    const formData = new FormData()
    // if (profile_url) {
    //   formData.append('profile_url', profile_url)
    // } else if (profile) {
    //   formData.append('profile', profile)
    // }
    formData.append('profile_url', profile_url)
    formData.append('username', username)
    answers.forEach((item, index) => {
      // formData.append('answers', JSON.stringify(item))
      if (index === 0) {
        const qa = guideData?.qas.find(qa => qa.id === item.qid)
        item.aid.forEach((answer) => {
          const idx = qa?.answers.findIndex(a => a.option === answer)
          formData.append('role', JSON.stringify(idx))
        })
      } else if (index === 1) {
        item.aid.forEach((answer) => {
          formData.append('tracks', JSON.stringify(answer))
        })
      } else if (index === 2) {
        item.aid.forEach(answer => {
          formData.append('invest_budget', JSON.stringify(answer))
        })
      } else if (index === 3) {
        item.aid.forEach(answer => {
          formData.append('hear_about', JSON.stringify(answer))
        })
      }
    })
    try {
      await finishGuide(formData)
      setOpenGuideDoneDialog(true)
      noCloseConfirm = true
    } catch (e:any) {
      Message(e.message || 'Unknown error', { severity: 'error' })
    }
  }

  return (
    <div className='login-guide'>
      <div className="left">
        <img className='logo' src={logoImg} alt="" />
        <img className='comma' src={commaImg} alt="" />
        <div className="intro">1Seed is the world{'\''}s first web3 investment and financing platform supported by its DAO and community. We are committed to building the world{'\''}s most powerful community investment and financing ecosystem based on community collaboration.</div>
        <div className="line"></div>
      </div>
      <div className="right"> 
        <div className="indicator-wrap">
          <div className={`indicator ${step >= 0 && 'active'}`}></div>
          <div className={`indicator ${step >= 1 && 'active'}`}></div>
          <div className={`indicator ${step >= 2 && 'active'}`}></div>
          <div className={`indicator ${step >= 3 && 'active'}`}></div>
          <div className={`indicator ${step === 4 && 'active'}`}></div>
        </div>
        {/* step 0 */}
        {isFirstStep() ? (
          <div>
            {/* <p className="title">Welcom!</p> */}
            {/* <p className="sub-title">Welcome to the 1seed community, please edit your avatar and username.</p> */}
            <p className="title">Welcome to the 1seed community, please edit your avatar and username.</p>
            <div className="label">Username</div>
            <TextField 
              className={`name-input ${usernameInvalid && 'name-input-error'}`}
              // label="Username should be 3 - 32 characters"
              variant="outlined"
              inputProps={{maxLength: 32, placeholder: 'Username should be 3 - 32 characters'}}
              value={username}
              onChange={onUsernameChange}
              helperText={usernameInvalid && "The username is already taken"}
              error={usernameInvalid}
              // onBlur={onUsernameBlur}
              />
            <div className="label">Choose or Upload a Profile Picture
              <p className="sub">We recommend an image of at least 400x400.</p>
            </div>
            <div className={`upload-wrap ${getProfileImg() && 'preview-wrap'}`}>
              {/* <Button variant="outlined" component="label" className={getProfileImg() && 'hidden' || ''}>Upload
                <input hidden accept="image/*" type="file" onChange={onUpload} />
              </Button> */}
              <IconButton color="primary" aria-label="upload picture" component="label" className={getProfileImg() && 'hidden' || ''}>
                <UploadInput hidden accept="image/*" id="upload" onChange={onUpload} uploadType={0} onProgress={Loading}></UploadInput>
                {/* <input hidden accept="image/*" type="file" id="upload" onChange={onUpload} /> */}
                <Upload className="upload-icon" />
                {/* <img src={avatarCheckIcon} alt="" className="upload-icon" /> */}
              </IconButton>
              {getProfileImg() && <label htmlFor="upload" className='preview'><img src={getPreviewProfile()} alt="" className="profile-img" /></label>}
              <div className="shadow-wrap"></div>
            </div>
            <div className="divider"></div>
            <div className="default-avatar-wrap">
              {guideData?.profile_urls?.map((item, index) => (
                <div className="avatar-wrap" key={`avatar_${index}`}>
                  <div className={`default-avatar ${activeAvatarIndex === index && 'active'}`}>
                    <img src={avatarCheckIcon} alt="" className="icon" />
                    <img src={item} alt="" className="avatar" onClick={() => onChangeProfileUrl(item, index)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            {getCurrentQAData()?.question.content && <p className="title">{getCurrentQAData()?.question.content}</p>}
            {/* {getCurrentQAData()?.question.desc && <p className="title">{getCurrentQAData()?.question.desc}</p>}
            {getCurrentQAData()?.question.content && <p className="sub-title">{getCurrentQAData()?.question.content}</p>} */}
            <div className={`check-wrap ${getCurrentQAData()?.answers.length <= 4 ? 'row' : ''}`}>
              {getCurrentQAData()?.answers.map((item, index) => {
                return (
                <div className={`box-wrap`} key={`answer-box-${index + 1}`}>
                  <div className={isAnswerActive(item.option) ? 'box active' : 'box'} onClick={() => checkAnswer(item.option)}>
                    <img src={checkImg} alt="" className="check-icon" />
                    <div className="icon"></div>
                    <div className="box-title">
                      {item.option}
                      {/* <div className="sub">{item.desc}</div> */}
                    </div>
                  </div>
                </div>
                
                )
              })}
              
            </div>
          </div>
        
        )     
      }
        

        <div className="button-wrap">
          {step > 0 && <Button variant="outlined" className='btn-1seed' onClick={() => setStep(step - 1)}>Back</Button>}
          {(step < (guideData?.qas.length || -1)) && <Button variant="contained" className='btn-1seed' disableElevation onClick={nextStep}>Next</Button>}
          {(step === (guideData?.qas.length || -1)) && <Button variant="contained" className='btn-1seed' disableElevation onClick={submit}>Finish</Button>}
        </div>
      </div>
      {openGuideDoneDialog && <GuideDoneDialog open={openGuideDoneDialog}></GuideDoneDialog>}
      <ConfirmCloseDialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)} onConfirm={confirmClose}></ConfirmCloseDialog>
    </div>
  );
}
