import React from 'react'
import './index.scss'

interface Props {
    onClose: () => void
}

export default function MintGuide(props:Props) {
    const { onClose } = props

    return (
        <div className="mint-guide">
            <div className="content">
                <div className="title">Mint Membership NFT</div>
                <div className="desc">{`You have obtained the first batch of Membership NFT airdropped by the 1Seeddao community. Please go to the platform to complete the mint.`}</div>
                <div className="btn-wrap">
                    <div className="left" onClick={onClose}>Get it!</div>
                    <div className="right" onClick={onClose}>Hide the tips</div>
                </div>
                <img src={require('assets/img/invest-detail/arrow-tips.png')} alt="" className="icon" />
            </div>
        </div>
    )
}