import React from 'react'
import Drawer from '@mui/material/Drawer';
import CommentBox from './comment-box';
import './index.scss'
import { ProfileType } from 'api/user';
import { getSectionComments, GetSectionCommentsResponse, getAllQuestions, GetAllQuestionsResponse, CommentsOfSection, Reply, SectionCommentWithReplies, getReplies, GetRepliesResponse } from 'api/community';
import { InvestInfo, InvestDetail } from 'api/finance';
import { generateSectionTextList } from 'utils/utils';
import { useLocation } from 'react-router';
import { isNumber } from 'lodash';
// import Tips from 'components/tips';

interface Props {
    profile: ProfileType|undefined
    open: boolean
    onClose: () => void
    section?: number
    investInfo: InvestInfo|undefined
    isQuestionAll?: boolean
    investDetail?: InvestDetail
}

export default function CommentDetail(props:Props) {
    const { profile, onClose, open, section, investInfo, isQuestionAll, investDetail } = props
    const [commentsOfSectionList, setCommentsOfSectionList] = React.useState<CommentsOfSection[]>([])
    const [sectionSliceList, setSectionSliceList] = React.useState<string[][][]>([])
    const [targetHash, setTargetHash] = React.useState('')
    const location = useLocation()

    async function getData() {
        if (isQuestionAll) {
            getAllQuestionsData()
            return
        }
        getSectionCommentsData()
    }

    async function getSectionCommentsData() {
        if (section === undefined) return
        try {
            const data:GetSectionCommentsResponse = await getSectionComments({
                project_id: investInfo?.project_id || '',
                section: section
            })
            setCommentsOfSectionList([{
                section: section,
                section_comments: data.section_comments
            }])
        } catch(e:any) {
            console.log(e)
            setCommentsOfSectionList([])
        }
    }

    async function getAllQuestionsData() {
        try {
            const data:GetAllQuestionsResponse = await getAllQuestions({
                project_id: investInfo?.project_id || ''
            })
            setCommentsOfSectionList(data.comments)
        } catch (e:any) {
            console.log(e)
            setCommentsOfSectionList([])
        }
    }

    // function getSectionSliceList() {
    //     if (!investInfo) return
    //     const slicesOfSections = investInfo.insights.map((insight) => {
    //         return insight.answer.map(answer => {
    //             const sliceList = generateSectionTextList(answer)
    //             return sliceList
    //         })
    //     })
    //     setSectionSliceList(slicesOfSections)
    // }

    function getSlicesText(_section:number, start:number, end:number) {
        // if (!sectionSliceList || !sectionSliceList.length) return ''
        // const [questionIndex, answerIndex] = _section.split('_').map(d => +d)
        // const targetSlices = sectionSliceList[questionIndex][answerIndex]
        // let str = ''
        // for(let i = start; i <= end; i++) {
        //     str += targetSlices[i]
        // }
        // return str
        return ''
    }

    function onReply(newReply:Reply, commentsOfSectionIndex:number, sectionCommentIndex:number, commentsIndex:number, replyIndex?:number) {
        const isReplyComment = replyIndex === undefined
        const targetComment = commentsOfSectionList[commentsOfSectionIndex].section_comments[sectionCommentIndex].comments[commentsIndex]
        targetComment.comment.reply_cnt += 1
        if (isReplyComment) {
            if (targetComment.replies && targetComment.replies.length > 0) {
                targetComment.replies.unshift(newReply)
            } else {
                targetComment.replies = [newReply]
            }
        } else {
            if (targetComment.replies && targetComment.replies.length > 0) {
                targetComment.replies = [...targetComment.replies.slice(0, replyIndex + 1), newReply, ...targetComment.replies.slice(replyIndex + 1, targetComment.replies.length)]
            } else {
                targetComment.replies = [newReply]
            }
        }
        setCommentsOfSectionList([...commentsOfSectionList])
    }

    async function getMoreReplies(comments:SectionCommentWithReplies) {
        if (!comments || !comments.replies || !comments.replies.length) return
        const data:GetRepliesResponse = await getReplies({
            comment_id: comments.comment.id,
            // from last index
            from: comments.replies.length,
            size: 5
        })
        comments.replies = [...comments.replies, ...data.replies].reduce((rs:Reply[], item:Reply) => {
            // drop repeat item
            if (!rs.find(d => d.id === item.id)) {
                rs.push(item)
            }
            return rs
        }, [])
        setCommentsOfSectionList([...commentsOfSectionList])
    }

    React.useEffect(() => {
        if (!open) return
        getData()
    }, [open])

    // React.useEffect(() => {
    //     getSectionSliceList()
    // }, [commentsOfSectionList])

    React.useEffect(() => {
        if (location.hash) {
            const hash = location.hash.slice(1)
            // console.log(hash)
            setTargetHash(hash)
        }
    }, [location])

    return (
        <Drawer
            className="comment-detail"
            anchor={'right'}
            open={open}
            onClose={onClose}
        >
            <div className="comment-detail__content">
                <div className="comment-detail__title">{isQuestionAll ? 'Questions' : 'Comments'}</div>
                <img src={require('assets/img/close.png')} alt="" className="close" onClick={onClose} />
                {commentsOfSectionList?.map((commentsOfSection, commentsOfSectionIndex) => {
                    return (
                        <div className="comment-detail__section" key={`comment-detail__section_${commentsOfSectionIndex}`}>
                            {commentsOfSection.section_comments?.map((sectionComment, sectionCommentIndex) => {
                                return (
                                    <div className="comment-detail__slices" key={`sectionComment_${sectionCommentIndex}`}>
                                        <div className={`${`${sectionComment.index}_${sectionComment.length}` === targetHash ? 'blink' : ''}`}>
                                            <div
                                            className={`top-text`}
                                            id={`${sectionComment.index}_${sectionComment.length}`}
                                            >{sectionComment.comments[0].comment.text}
                                            </div>
                                        </div>
                                        
                                        {sectionComment.comments?.map(((comment, commentsIndex) => {
                                            return (
                                                <div className="comment-content"
                                                    key={`sectionComment_comments_${sectionCommentIndex}_${commentsIndex}`}>
                                                    <CommentBox
                                                    profile={profile}
                                                    comment={comment.comment}
                                                    targetText={comment.comment.text}
                                                    onReply={(newReply) => onReply(newReply, commentsOfSectionIndex, sectionCommentIndex, commentsIndex)}
                                                    onRefresh={getData}
                                                    projectId={investInfo?.project_id}
                                                    projectUserId={investInfo?.user_id}
                                                    // isTargetHash={`${commentsOfSection.section}-${sectionComment.start}_${sectionComment.end}` === targetHash}
                                                    ></CommentBox>
                                                    {comment.replies?.length && <div className="replies">
                                                        {comment.replies.map((reply, replyIndex) => {
                                                            return (
                                                                <CommentBox
                                                                profile={profile}
                                                                isReply
                                                                reply={reply}
                                                                commentId={comment.comment.id}
                                                                key={`sectionComment_replies_${sectionCommentIndex}_${commentsIndex}_${replyIndex}`}
                                                                onReply={(newReply) => onReply(newReply, commentsOfSectionIndex, sectionCommentIndex, commentsIndex, replyIndex)}
                                                                onRefresh={getData}
                                                                projectId={investInfo?.project_id}
                                                                projectUserId={investInfo?.user_id}
                                                                ></CommentBox>
                                                            )
                                                        })}
                                                        {(comment.comment.reply_cnt > 2 && comment.comment.reply_cnt > comment.replies?.length) && <div className="more" onClick={() => getMoreReplies(comment)}>View {comment.comment.reply_cnt - comment.replies?.length} Comments</div>}
                                                    </div>}
                                                </div>
                                            )
                                        }))}
                                    </div>
                                )
                            })
                            }
                        </div>
                    )
                })}
                
            </div>
        </Drawer>
    )
}