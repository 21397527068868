import React from 'react';
import Dialog from '@mui/material/Dialog';
import './index.scss'
import close from 'assets/img/close-lg.png'
// import ethIcon from 'assets/img/poap/ETH.png'

type Props = {
    open: boolean,
    onClose: () => void,
    data: string
}

export default function PoapDialog(props: Props) {
    const { onClose, data } = props
    const [rotate, setRotate] = React.useState(false)

    return (
        <Dialog {...props} className="poap-dialog">
            <div className='poap-content'>
                <div className="token-img" dangerouslySetInnerHTML={{
                    __html: data
                }}></div>
                {/* <div className="title">{data.name}</div>
                <div className={`img-wrap ${rotate && 'rotate'}`} onClick={() => setRotate(true)}>
                    <img src={data.image} alt="" className={`img ${rotate && 'rotate'}`} />
                    <div className={`backend ${rotate && 'rotate'}`}>
                        <p className="name">{data.name}</p>
                        <img src={ethIcon} alt="" className="icon" />
                        <div className="number">#004</div>
                        <div className="items">
                            <div className="item">
                                <div className="text1">Token Standard</div>
                                <div className="text2">ERC-721</div>
                            </div>
                            <div className="item">
                                <div className="text1">contract Address</div>
                                <div className="text2">0x58EaB01ac58E5dE3d59254454d3d43254fe89c3</div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <img src={close} alt="" className="close" onClick={onClose}/>
            </div>
        </Dialog>
    )
}