const env = process.env.REACT_APP_ENV?.trim() || 'dev'
export const BASE_URL = {
    'dev': 'https://api-test.1seeddao.com',
    'alpha': 'https://api-alpha.1seeddao.com',
    'beta': 'https://api-beta.1seeddao.com',
    'production': 'https://api.1seeddao.com'
}[env]

export const WSS_URL = {
    'dev': 'wss://nr70rxnl11.execute-api.ap-east-1.amazonaws.com/production',
    'alpha': 'wss://7u16ky0kgb.execute-api.ap-east-1.amazonaws.com/production',
    'beta': 'wss://7u16ky0kgb.execute-api.ap-east-1.amazonaws.com/beta',
    'production': 'wss://bt9xip70hj.execute-api.ap-east-1.amazonaws.com/production'
}[env]


// telegram bot_id
export const BOT_ID = {
    'dev': '6290288931',
    'alpha': '6009527456',
    'beta': '6009527456',
    'production': '6314582821'
}[env]

export const SEED_ADDR = {
    'dev': '0x10D5929415B6A2C7DF537167e0c73a73ba3fB3b9',
    'alpha': '0x1E100b8570e4ad4e4F6307C97d695656100BA8D1',
    'beta': '0xfcD0E5c158C557ee7DdD6dB4Be07a654cD575dad',
    'production': '0x6F9675D556fc23ae2ecb47A125B987AcCdf39456'
}[env] || '0x10D5929415B6A2C7DF537167e0c73a73ba3fB3b9'

export const USDT_ADDR = {
    'dev': '0x310351A43c35CB2cF1dCAd649889184d123b942c',
    'alpha': '0x310351A43c35CB2cF1dCAd649889184d123b942c',
    'beta': '0x310351A43c35CB2cF1dCAd649889184d123b942c',
    'production': '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58'
}[env] || '0x310351A43c35CB2cF1dCAd649889184d123b942c'

export const USDC_ADDR = {
    'dev': '0x0c0b7A497405a599022e473F8c7BF73A5E442A1e',
    'alpha': '0x0c0b7A497405a599022e473F8c7BF73A5E442A1e',
    'beta': '0x0c0b7A497405a599022e473F8c7BF73A5E442A1e',
    'production': '0x7F5c764cBc14f9669B88837ca1490cCa17c31607'
}[env] || '0x0c0b7A497405a599022e473F8c7BF73A5E442A1e'

export const PROFILE_NFT_ADDR = {
    'dev': '0xB9eaD4722Aae43F3Af3fed6f14AF980335CCA8EB',
    'alpha': '0xB9eaD4722Aae43F3Af3fed6f14AF980335CCA8EB',
    'beta': '0xB9eaD4722Aae43F3Af3fed6f14AF980335CCA8EB',
    'production': '0xdE6774e4e78Ad37616a2301A65817430fFD0fFf8'
}[env] || '0xB9eaD4722Aae43F3Af3fed6f14AF980335CCA8EB'

export const CHAIN_ID = {
    'dev': 420,
    'alpha': 420,
    'beta': 420,
    'production': 10
}[env] || 420

export const CALL_RPC = {
    'dev': 'https://goerli.optimism.io',
    'alpha': 'https://goerli.optimism.io',
    'beta': 'https://goerli.optimism.io',
    'production': 'https://mainnet.optimism.io'
}[env] || 'https://goerli.optimism.io'


export const WALLET_CONNECT_PROJECT_ID = {
    'dev': 'cd6d371dc7a51595d1320a6bea1dd844',
    'alpha': 'cd6d371dc7a51595d1320a6bea1dd844',
    'beta': 'cd6d371dc7a51595d1320a6bea1dd844',
    'production': 'cd6d371dc7a51595d1320a6bea1dd844'
}[env] || 'cd6d371dc7a51595d1320a6bea1dd844'

export const WALLET_CONNECT_METADATA = {
    name: "1Seeddao-auth",
    description: "1Seeddao Dapp for Auth",
    url: window.location.host,
    icons: [],
}

export const CONTRACT_URL_PREFIX = {
    'dev': 'https://goerli-optimism.etherscan.io/address/',
    'alpha': 'https://goerli-optimism.etherscan.io/address/',
    'beta': 'https://goerli-optimism.etherscan.io/address/',
    'production': 'https://optimistic.etherscan.io/address/'
}[env] || 'https://goerli-optimism.etherscan.io/address/'

console.log(env)

// export default {
//     BASE_URL: BASE_URL[process.env.NODE_ENV],
//     WSS_URL: WSS_URL[process.env.NODE_ENV],
//     BOT_ID: BOT_ID[process.env.NODE_ENV]
// }