import React from 'react'
import './index.scss'
import { socialIconFullMapList } from 'pages/apply/maps'
import { InvestInfo, InvestDetail, watch, unwatch } from 'api/finance'
import Message from 'components/common/message'
import useLoginInterceptor from 'hooks/useLoginInterceptor'
import trackIcon from 'assets/svg/chain-logo.svg'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useNavigate } from 'react-router'
import { CONTRACT_URL_PREFIX } from 'config/config'
import Tips from 'components/tips'
import { wrap } from 'module'

interface Props {
    investInfo: InvestInfo,
    investDetail: InvestDetail,
    onWatch: (iswatch: boolean) => void,
    isSelfProject: boolean
}

export default function InfoCard(props: Props) {
    const { investInfo, investDetail, onWatch, isSelfProject } = props

    const [showMore, setShowMore] = React.useState(false)

    const loginInterceptor = useLoginInterceptor()
    const navigate = useNavigate()

    function handleClickWhitepaper() {
        if (!investInfo.whitepaper_url) return
        window.open(`/pdf-view?url=${encodeURIComponent(investInfo.whitepaper_url)}`)
    }

    async function watchProject() {
        try {
            await watch({
                project_id: investInfo.project_id
            })
            onWatch(true)
            Message('Add to watchlist success')
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Add to watchlist failed', {severity: 'error'})
        }
    }

    async function unwatchProject() {
        try {
            await unwatch({
                project_id: investInfo.project_id
            })
            onWatch(false)
            Message('Remove from watchlist success')
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Remove from watchlist failed', {severity: 'error'})
        }
    }

    function goSocial(social_type:string) {
        // const link = generateSocialLink(social_type, investInfo.social[social_type])
        const link = investInfo.social[social_type]
        if (!link) {
            return
        }
        window.open(link)
    }

    function handleClickContract() {
        if (!investDetail.invest_contract) return
        window.open(`${CONTRACT_URL_PREFIX}${investDetail.invest_contract}`)
    }

    return (
        <div className={`info-card`}>
            <div className="info-card__content">
                <div className="img-wrap">
                <img src={investInfo.logo_url} alt="" className="img" />
                </div>
                <div className="top-wrap">
                    <div className="title-wrap">
                        <div className="text item">{investInfo.name}</div>
                        {investInfo.is_ido === 1 && <div className="ido-tag item">IDO</div>}
                        <div className="track item">
                            {investInfo.tracks ? investInfo.tracks[0] || '' : ''}
                            <img src={trackIcon} alt="" className="icon" />
                        </div>
                    </div>
                    <div className="link-wrap">
                        {socialIconFullMapList.map((item, index) => {
                            return investInfo.social[item.key] && (
                                <div className="icon-wrap" key={`social_${index}`}>
                                    <img src={item.icon} alt="" className="icon" onClick={() => goSocial(item.key)} />
                                    <Tips text={item.tips}></Tips>
                                </div>
                            )
                        })}
                        {(Object.keys(investInfo.social).find(item => investInfo.social[item]) && (investInfo.whitepaper_url || investInfo.site)) && <img src={require('assets/img/invest-detail/social-divide.png')} alt="" className="icon default" />}
                        {investInfo.whitepaper_url &&
                            <div className='icon-wrap'>
                                <img src={require('assets/img/invest-detail/file.png')} alt="" className="icon" onClick={handleClickWhitepaper} />
                                <Tips text='Whitepaper'></Tips>
                            </div>
                        }
                        {investInfo.site && 
                            <div className='icon-wrap'>
                                <img src={require('assets/img/invest-detail/site.png')} alt="" className="icon" onClick={() => window.open(investInfo.site)} />
                                <Tips text='Website'></Tips>
                            </div>}
                        {isSelfProject && <div className='share'>
                            <img src={require('assets/img/invest-detail/share.png')} alt="" className="icon" />
                            <CopyToClipboard text={window.location.href} onCopy={() => Message('copy success!')}>
                                <div className="share-wrap">
                                    <img src={require('assets/img/copy.png')} alt="" className="share-icon" />
                                    <span className="text">Copy link</span>
                                </div>
                            </CopyToClipboard>
                        </div>}
                    </div>
                </div>
                <div className="info-wrap">
                    <div className="item">
                        <span className="text2">Proposed by：</span>
                        <span className="text2">{investInfo.user_ens_name || investInfo.user_name}</span>
                        {/* <img src={investInfo.user_avatar_url} alt="" className="avatar" onClick={() => navigate(`/profile?id=${investInfo.user_id}`)} /> */}
                    </div>
                    <div className="item">
                        <img src={require('assets/img/invest-detail/watch.png')} alt="" className="icon" />
                        <span className="text2">Watchlist：</span>
                        <div className="text2">{investDetail.watch_cnt}</div>
                    </div>
                    <div className="item">
                        <span className="text2">Contract address：</span>
                        <span
                        className={`text2 ${investDetail.invest_contract ? 'link' : ''}`}
                        onClick={handleClickContract}
                        >{investDetail.invest_contract || 'pending'}</span>
                        {investDetail.invest_contract && <CopyToClipboard text={investDetail.invest_contract} onCopy={() => Message('copy success!')}>
                            <img src={require('assets/img/copy.png')} alt="" className="copy-icon" />
                        </CopyToClipboard>}
                    </div>
                </div>
                <div className="desc-wrap">{investInfo.desc.length > 200 && !showMore ? investInfo.desc.slice(0, 200) + '...' : investInfo.desc}
                    {investInfo.desc.length > 200 ? <span className="sub" onClick={() => setShowMore(!showMore)}> {showMore ? 'read less' : 'read more'}</span> : ''}
                </div>
                {/* <div className="bottom">
                    <div className="item whitepaper" onClick={handleClickWhitepaper}>
                        <img src={require('assets/img/invest-detail/file.png')} alt="" className="icon" />
                        <div className="text">Whitepaper</div>
                    </div>
                    {investInfo.site && <div className="item whitepaper" onClick={() => window.open(investInfo.site)}>
                        <img src={require('assets/img/invest-detail/site.png')} alt="" className="icon" />
                        <div className="text">Website</div>
                    </div>}
                    <div className="item">
                        <img src={require('assets/img/invest-detail/watch.png')} alt="" className="icon" />
                        <div className="text">Watchlist {investDetail.watch_cnt}</div>
                    </div>
                </div> */}
            </div>
            {/* <div className="info-card__operate">
                {!isSelfProject && (!investDetail.watched ?
                    (<Button className="btn btn-1seed" variant='contained' onClick={() => loginInterceptor(watchProject)}>
                        <img src={require('assets/img/invest-detail/add.png')} alt="" className="icon" />
                        Add to watchlist
                    </Button>) :
                    (<Button className="btn btn-1seed at-watch" variant='outlined' onClick={() => loginInterceptor(unwatchProject)}>
                        <div className="text text-default">At watchlist</div>
                        <div className="text text-hover">Remove</div>
                    </Button>))
                }
                <div className="social-list">
                    {socialIconFullMapList.map((item, index) => {
                        return investInfo.social[item.key] && (
                            <img key={`social_${index}`} src={item.icon} alt="" className="icon" onClick={() => goSocial(item.key)} />
                        )
                    })}
                </div>
            </div> */}
        </div>
    )
}