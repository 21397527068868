import React, { useEffect } from 'react';
import './index.scss'
import { INVESTED_SELECTOR_TYPES, INVEST_STATUS } from '../../../../constant';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Button } from '@mui/material';
import refleshIcon from '../../../../assets/img/reflesh.png'
// import { useLocation } from 'react-router';

type Props = {
    onChange: (type: number, status: number) => void,
    filterPending?: boolean,
    filterSortType?: boolean,
    target: string
    showSuccessText?: boolean
}

export default function Filter(props:Props) {

  // const location = useLocation()

    const { onChange, filterPending, filterSortType, target, showSuccessText } = props

  // sort type
  const [type, setType] = React.useState<number>(INVESTED_SELECTOR_TYPES.RECENTLY_LIST)
  const [status, setStatus] = React.useState<number>(INVEST_STATUS.ALL)
  const [hideSelectorItems, setHideSelectorItems] = React.useState<boolean>(true)

  const ref = useDetectClickOutside({ onTriggered: () => setHideSelectorItems(true) });

  const labelMap = {
    [INVESTED_SELECTOR_TYPES.INVESTMENT_HIGH_TO_LOW]: 'Investment：high to low',
    [INVESTED_SELECTOR_TYPES.INVESTMENT_LOW_TO_HIGH]: 'Investment：low to high',
    [INVESTED_SELECTOR_TYPES.RECENTLY_LIST]: 'Recently List'
  }

  function handleChangeType(t:number) {
    setType(t)
    setHideSelectorItems(true)
    refresh({
        type: t,
        status
    })  
}

  async function handleChangeStatus(s:number) {
    setStatus(s)
    refresh({
        status: s,
        type
    })
  }

  type Param = {
    type: number,
    status: number
  }

  function refresh(param?:Param) {
    if (!onChange) return
    if (param) {
        onChange(param.status, param.type)
    } else {
        onChange(status, type)
    }
  }

  useEffect(() => {
    refresh()
  }, [target])

  return (
    <div className="profile-filter-wrap">
        <div className="btn-wrap">
        <div className={`btn ${status === INVEST_STATUS.ALL && 'active'}`} 
        onClick={() => handleChangeStatus(INVEST_STATUS.ALL)}>All</div>
        {filterPending ? (
          <>
          <div className={`btn ${status === INVEST_STATUS.INVESTOR && 'active'}`} 
          onClick={() => handleChangeStatus(INVEST_STATUS.INVESTOR)}>Success</div>
          <div className={`btn financing ${status === INVEST_STATUS.FINANCING && 'active'}`}
          onClick={() => handleChangeStatus(INVEST_STATUS.FINANCING)}>Financing</div>
          {!target && <div className={`btn failed ${status === INVEST_STATUS.FAILD && 'active'}`}
          onClick={() => handleChangeStatus(INVEST_STATUS.FAILD)}>Failed</div>}
          <div className={`btn pending ${status === INVEST_STATUS.PENDING && 'active'}`}
          onClick={() => handleChangeStatus(INVEST_STATUS.PENDING)}>Pending</div>
          </>
        ) : (
          <>
            <div className={`btn ${status === INVEST_STATUS.INVESTOR && 'active'}`} 
            onClick={() => handleChangeStatus(INVEST_STATUS.INVESTOR)}>{showSuccessText ? 'Success' : 'Investor'}</div>
            <div className={`btn financing ${status === INVEST_STATUS.FINANCING && 'active'}`}
            onClick={() => handleChangeStatus(INVEST_STATUS.FINANCING)}>Financing</div>
            {!target && <div className={`btn failed ${status === INVEST_STATUS.FAILD && 'active'}`}
            onClick={() => handleChangeStatus(INVEST_STATUS.FAILD)}>Failed</div>}
          </>
        )}
        {/* {filterPending && <div className={`btn pending ${status === INVEST_STATUS.PENDING && 'active'}`}
        onClick={() => handleChangeStatus(INVEST_STATUS.PENDING)}>Pending</div>} */}
        </div>
        <div className="type-wrap">
            {filterSortType && <div className='selector' ref={ref} onClick={() => setHideSelectorItems(!hideSelectorItems)}>
                <span className="text">{labelMap[type]}</span>
                <div className="selector-items" hidden={hideSelectorItems}>
                <div className={`item ${type === INVESTED_SELECTOR_TYPES.INVESTMENT_LOW_TO_HIGH && 'active'}`}
                    onClick={() => handleChangeType(INVESTED_SELECTOR_TYPES.INVESTMENT_LOW_TO_HIGH)}
                >Investment：low to high</div>
                <div className={`item ${type === INVESTED_SELECTOR_TYPES.INVESTMENT_HIGH_TO_LOW && 'active'}`}
                    onClick={() => handleChangeType(INVESTED_SELECTOR_TYPES.INVESTMENT_HIGH_TO_LOW)}
                >Investment：high to low</div>
                <div className={`item ${type === INVESTED_SELECTOR_TYPES.RECENTLY_LIST && 'active'}`}
                    onClick={() => handleChangeType(INVESTED_SELECTOR_TYPES.RECENTLY_LIST)}
                >Recently List</div>
                </div>
            </div>}
            <Button variant='contained' className='btn-1seed btn' onClick={() => refresh()}>
                <img className='icon' src={refleshIcon} alt="" />
                Reflesh</Button>
        </div>
    </div>
  );
}
