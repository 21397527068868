import React from 'react';
import {Snackbar} from '@mui/material';
import ReactDOM from "react-dom/client";
import error from '../../../assets/img/message/error.png'
import success from '../../../assets/img/message/success.png'
import warning from '../../../assets/img/message/warning.png'
import './index.scss'
// import { SnackbarProvider } from 'notistack'

type Config = {
    severity?: "success" | "warning" | "error",
    onClose?:(event:React.SyntheticEvent | Event, reason?: string) => void
}

let root:any

export default function Message(message:string,config?:Config) {

    const id = 'snake-bar'
    const { severity = 'success', onClose } = config || {}
    
    let snakeBarDom = document.getElementById(id)
    if (!snakeBarDom) {
        snakeBarDom = document.createElement('div')
        snakeBarDom.setAttribute('id', id)
        document.body.appendChild(snakeBarDom)
    }
    
    if (root && root._internalRoot) {
        root.unmount()
    }
    
    root = ReactDOM.createRoot(snakeBarDom)

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        onClose && onClose(event, reason)
        root && root.unmount()
    };

    const getIcon = () => {
        if (severity === 'success') {
            return success
        }
        if (severity === 'error') {
            return error
        }
        if (severity === 'warning') {
            return warning
        }
        return success
    }

    const snakeBar = <Snackbar
                        open={true}
                        autoHideDuration={3000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        // maxSnack={3}
                    >
                        <div className='message-wrap-1seed'> 
                            <img src={getIcon()} alt="" className="icon" />
                            <span className="text">{message}</span>
                        </div>
                    </Snackbar>

    root && root._internalRoot && root.render(snakeBar);
}
