import React from 'react';
import './index.scss'
import { INVEST_STATUS } from 'constant';
// import trackIcon from 'assets/img/apply/track-icon.png'
import trackIcon from 'assets/svg/chain-logo.svg'
import { ExploreProjectType } from 'api/explore';
import { formatFinTime, formatProjectTitle } from 'utils/utils';
import { useNavigate } from 'react-router';
import { formatMoney } from 'utils/utils';
// import { roundDetailTitle } from 'pages/apply/maps';

type PropsType = {
    project: ExploreProjectType
}

const styleMap = {
    [INVEST_STATUS.FINANCING]: {
        text: 'Financing',
        style: 'financing'
    },
    [INVEST_STATUS.INVESTOR]: {
        text: 'Success',
        style: 'success'
    },
}

export default function ExploreBox(props: PropsType) {

  const { project } = props

  const navigate = useNavigate()

  function goUserProfile(id:string, e:any) {
    e.stopPropagation()
    navigate(`/profile?id=${id}`)
  }

  return (
    <div className={`explore-box ${styleMap[project.status]?.style}`} onClick={() => navigate(`/invest-detail/${project.project_id}`)}>
        <div className="card-bg"></div>
        <div className="status">{styleMap[project.status]?.text}</div>
        <div className="content">
            <div className="card-content">
                <div className="card-left">
                    <div className="title" title={formatProjectTitle(project?.project_info.name, project?.project_info.rounds)}>{formatProjectTitle(project?.project_info.name, project?.project_info.rounds)}</div>
                    <div className="goal-wrap fin-wrap">
                        <div className="goal-content">
                            <div className="goal-title">Financing Goal：</div>
                            <div className="goal-text">{formatMoney(project?.project_info.fin_goal) || '-'} {project?.project_info.fin_token_symbol}</div>
                        </div>
                        <div className="fin-total">
                            <div className="fin-title">Total Financing</div>
                            <div className="fin-progress" title={`${project?.project_info.fin_amount} ${project?.project_info.fin_token_symbol}`}>
                                <div className="progress"
                                    style={{
                                        width: `${project.project_info.fin_amount / (project.project_info.fin_goal * 1.5) * 100}%`
                                    }}></div>
                                <div className="total"></div>
                            </div>
                            <div className="fin-time">{formatFinTime(project.project_info.fin_end)}</div>
                        </div>
                    </div>
                    <div className="investors">
                        {project.top_investors?.map((item, idx) => (
                            <div className="investor" key={`investor_${idx}`}>
                                <img src={item.profile_uri} alt="" className="avatar" onClick={(e) => goUserProfile(item.user_id, e)} />
                            </div>
                        ))
                        }
                        {!project.top_investors?.length && (
                            <>
                                <div className="investor">
                                    <img src={require('assets/img/avatar-empty.png')} alt="" className="avatar" />
                                </div>
                                <div className="empty-text">No investors yet</div>
                            </>
                        )}
                    </div>
                </div>
                <img src={project?.project_info.image_url} alt="" className="img" />
            </div>
            <div className="card-bottom">
                <img src={project?.project_info.slogan_url} alt="" className="logo" />
                <div className="text">{project?.project_info.name}</div>
                <div className="tag">
                    <div className="counts">
                        <div className="count">{project.investor_count || '0'} <span className="sub">Investors</span></div>
                        <div className="count">{project.comments_cnt || '0'} <span className="sub">Comments</span></div>
                    </div>
                    <div className="track">
                        {project?.project_info.tracks && project?.project_info.tracks[0]}
                        <img src={trackIcon} alt="" className="icon" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
