import React, { ChangeEvent } from 'react'
import './index.scss'

interface Props extends React.PropsWithChildren {
    max?: number,
    onChange?: (v:string) => void,
    value?: string,
    placeholder?: string,
    className?: string
    autoHeight?: boolean
    autoFocus?: boolean
}

export default function FormTextarea(props:Props) {
    const { max, value, placeholder, children, className = '', autoHeight = false, autoFocus = false } = props
    const getLength = () => value ? `${value}`.length : 0

    const handleChange = (e:ChangeEvent<HTMLTextAreaElement>) => {
        if (autoHeight) {
            e.target.rows = 1
            e.target.style.height = e.target.scrollHeight + 'px'
        }
        props.onChange && props.onChange(e.target.value || '')
    }

    return (
        <div className={`form-textarea-wrap ${className}`}>
            <textarea className='form-textarea'
                onChange={handleChange}
                value={value}
                maxLength={max}
                placeholder={placeholder}
                autoFocus={autoFocus}
            ></textarea>
            {max && <div className="text">{getLength()}/{max}</div>}
            {children}
        </div>
    )
}