import React from 'react';
import Header from 'components/header';
import './index.scss'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';

export default function TradingPage() {

    const navigate = useNavigate()

    return (
        <div className="trading-page">
            <Header></Header>
            <div className="trading-page__content">
                <div className="item">
                    <img src={require('assets/img/trading/shares.png')} alt="" className="img" />
                    <div className="text">airdrop shares trading</div>
                    <Button className="btn btn-1seed" variant='contained' onClick={() => navigate('/trading-list')}>VIEW</Button>
                </div>
                <div className="item disabled">
                    <img src={require('assets/img/trading/official.png')} alt="" className="img" />
                    <div className="text">Official Investment <br/> NFT Trading</div>
                    <Button className="btn btn-1seed" variant='contained' disableRipple disableElevation>VIEW</Button>
                </div>
            </div>
        </div>
    );
}
