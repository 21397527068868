import React from 'react';
import './index.scss';
import { Button } from '@mui/material';
import dayjs from 'dayjs'
import { Comment } from 'api/community';
import { InvestInfo } from 'api/finance';
import { commentLike, commentUnlike } from 'api/community';
import Message from 'components/common/message';
import { COMMENT_LABEL, COMMENT_REVIEW_TYPE, USER_ROLE } from 'constant';
import Tips from 'components/tips';
import { useNavigate } from 'react-router';
import useLoginInterceptor from 'hooks/useLoginInterceptor';

interface Props {
    data: Comment
    investInfo: InvestInfo
    onRefresh: () => void
    onOpenComment: (section:number) => void
}

export default function CommentBox(props: Props) {
    const { data, investInfo, onRefresh, onOpenComment } = props

    const navigate = useNavigate()
    const loginInterceptor = useLoginInterceptor()

    async function handleLike() {
        try {
            await commentLike({
                comment_id: data.id
            })
            onRefresh()
        } catch(e:any) {
            console.log(e)
            Message(e.message, {severity:'error'})
        }
    }

    async function handleUnLike() {
        try {
            await commentUnlike({
                comment_id: data.id
            })
            onRefresh()
        } catch(e:any) {
            console.log(e)
            Message(e.message, {severity:'error'})
        }
    }

    function handleClickLike() {
        if (data.is_like) {
            handleUnLike()
        } else {
            handleLike()
        }
    }

    function handleClickComment() {
        onOpenComment(data.section)
    }

    return (
        <div className={`invest-comment-box
        ${data.user.role === USER_ROLE.INSTITUTION ? 'institutional' : ''}
        ${data.user.role === USER_ROLE.OFFICIAL ? 'official' : ''}
        ${data.user.role === USER_ROLE.FAMER ? 'famer' : ''}
        ${data.label === COMMENT_LABEL.ELECT ? 'elect' : ''}`}>
            {/* <div className="review-icon">
                <img src={require('assets/img/community/institutional-review.png')} alt="" className="icon institutional-icon" />
                <img src={require('assets/img/community/official-review.png')} alt="" className="icon official-icon" />
                <Tips text={`${data.review?.includes(COMMENT_REVIEW_TYPE.INSTITUTIONAL) ? 'institutional' : 'official'} review`}></Tips>
            </div> */}
            {[COMMENT_LABEL.REJECTED, COMMENT_LABEL.ELECT].includes(data.label) && <div className="tag-icon-wrap">
                {data.label === COMMENT_LABEL.ELECT && <img src={require('assets/img/community/official-tag.png')} alt="" className="icon elect" />}
                {data.label === COMMENT_LABEL.REJECTED && <img src={require('assets/img/community/official-reject.png')} alt="" className="icon reject" />}
                <Tips text={`${data.label === COMMENT_LABEL.ELECT ? 'Essence recommended' : 'Fake info'}`}></Tips>
            </div>}
            <div className="content">
                <div className="avatar-wrap" onClick={() => navigate(`/profile?id=${data.user.id}`)}>
                    <img src={data.user.profile_uri} alt="" className="avatar" />
                </div>
                <div className="right">
                    <div className="name">{data.user.ens_name || data.user.username} 
                        <div className="review-icon">
                            <img src={require('assets/img/community/institutional-review.png')} alt="" className="icon institutional-icon" />
                            <img src={require('assets/img/community/official-review.png')} alt="" className="icon official-icon" />
                            <img src={require('assets/img/community/famer.png')} alt="" className="icon famer-icon" />
                            <Tips text={`${data.user.role === USER_ROLE.INSTITUTION ?  'Institutional review' : (data.user.role === USER_ROLE.FAMER ? 'famer review' : '1Seed review')}`}></Tips>
                        </div>
                        {/* <img src={require('assets/img/index/identified.png')} alt="" className="identified" /> */}
                    </div>
                    <div className="time">{dayjs(data.time * 1000).format('DD, MMMM')}</div>
                </div>
                <Button className='btn' onClick={handleClickComment}><img src={require('assets/img/index/right.png')} alt="" className="icon" /></Button>
            </div>
            
            <div className="desc">{data.content.length > 250 ? data.content.slice(0, 250) + '...' : data.content}</div>
            <div className="counts">
                <div className={`item hover`} onClick={() => loginInterceptor(handleClickLike)}>
                    <img src={require(`assets/img/invest-detail/like${data.is_like ? '-active' : ''}.png`)} alt="" className="icon" />
                    <div className="text">{data.likes} likes</div>
                </div>
                <div className="item">
                    <img src={require('assets/img/invest-detail/comment.png')} alt="" className="icon default" />
                    <div className="text">{data.reply_cnt} comments</div>
                </div>
            </div>
            
        </div>
    )
}