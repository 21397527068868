import React from 'react';
import './index.scss';
import { Recommend } from 'api';
import { useNavigate } from 'react-router';
import { dealAmountDecimal } from 'utils/utils';
import { INVEST_STATUS } from 'constant';
import Button from  '@mui/material/Button'
// import dayjs from 'dayjs';

interface Props {
    // data: Recommend,
    // currentIndex: number
    list: Recommend[]
}

let interval: NodeJS.Timer | undefined

export default function RecommendBox(props: Props) {
    const { list } = props

	const [currentIndex, setCurrentIndex] = React.useState(0)

    const [dateList, setDateList] = React.useState<number[]>([0, 0, 0, 0])

	function handleChange(idx:number) {
		if (idx < 0 || idx >= list.length) {
			return
		}
        setCurrentIndex(idx)
	}

    function getDate() {
        const leftTime = list[currentIndex].end_ts * 1000 - Date.now()
        // const leftTime = 1698357728519 - Date.now()
        console.log('leftTime:', leftTime)
        if (leftTime <= 0) return
        setDateList([
            Math.floor(leftTime / (1000 * 60 * 60 * 24)),
            Math.floor(leftTime / (1000 * 60 * 60) % 24),
            Math.floor(leftTime / (1000 * 60) % 60),
            Math.floor(leftTime / (1000) % 60),
        ])
    }

    React.useEffect(() => {
        if (!list[currentIndex]) {
            return
        }
        getDate()
        if (!interval && list[currentIndex].end_ts * 1000 > Date.now()) {
            interval = setInterval(() => {
                getDate()
            }, 1000)
        }
    }, [currentIndex])

    React.useEffect(() => {
        return () => {
            interval && clearInterval(interval)
        }
    }, [])

    const navigate = useNavigate()

    return list.length > 0 ? (
        <div className="recommand-box" onClick={() => navigate(`/invest-detail/${list[currentIndex].project_id}`)}>
            <div className="card">
                <img src={list[currentIndex].image_url} alt="" className="img" />
            </div>
            {(!list[currentIndex].kol && !list[currentIndex].institution) && <div className="item fin-item">
                <img src={require('assets/img/index/public-icon.png')} alt="" className="icon" />
                <span className="text">Public Financing</span>
            </div>}
            {list[currentIndex].teammates?.length && <div className="item teammate-item">
                <div className="name">{list[currentIndex].teammates[0].name.slice(0, 1)}</div>
                <div>
                    <div className="text1">{list[currentIndex].teammates[0].name}</div>
                    <div className="text2">{list[currentIndex].teammates[0].title}</div>
                </div>
            </div>}
            <div className="item status-item">
                <div className={`circle ${list[currentIndex].status === INVEST_STATUS.INVESTOR ? 'success' : ''}`}></div>
                <div className="text">{list[currentIndex].status === INVEST_STATUS.INVESTOR ? 'Financing Success' : 'Token Financing'}</div>
            </div>
            <div className="item amount-item">
                <div className="text1">{dealAmountDecimal(list[currentIndex].fin_amount, list[currentIndex].fin_token_decimal)}</div>
                <div className="text2">Total raised ({list[currentIndex].token_symbol})</div>
            </div>
            {(list[currentIndex].status === INVEST_STATUS.FINANCING &&
                 list[currentIndex].end_ts > Date.now()) && <div className="item time-item">
                <div className="time">
                    <div className="text1">{dateList[0]}</div>
                    <div className="text2">DAYS</div>
                </div>
                <div className="time">
                    <div className="text1">{dateList[1]}</div>
                    <div className="text2">HRS</div>
                </div>
                <div className="time">
                    <div className="text1">{dateList[2]}</div>
                    <div className="text2">MINS</div>
                </div>
                <div className="time">
                    <div className="text1">{dateList[3]}</div>
                    <div className="text2">SECS</div>
                </div>
            </div>}
            {list[currentIndex].is_ido === 1 && <div className="item ido-item">
                <span className="text">IDO</span>
            </div>}
            {list[currentIndex].kol && <div className="item kol-item">
                <img src={list[currentIndex].kol?.profile_uri} alt="" className="avatar" />
                <span className="name">{list[currentIndex].kol?.name}</span>
            </div>}
            {/* <div className="item kol-item">
                <img src={require('assets/img/avatar-empty.png')} alt="" className="avatar" />
                <span className="name">1sdsad</span>
            </div> */}
            {(list[currentIndex].institution && !list[currentIndex].kol) && <div className="institution-item item">
                <img src={require('assets/img/identified.png')} alt="" className="icon" />
                <div className="text">{list[currentIndex].institution?.name}</div>
            </div>}
            <div className="page page1"></div>
            <div className="page page2"></div>
            {list?.length > 1 && <div className="pager">
                <div className={`pager-item left ${currentIndex <= 0 ? 'disabled' : ''}`} onClick={(e) => {
                    e.stopPropagation()
                    handleChange(currentIndex - 1)
                }}>
                    <img src={require('assets/img/index/arrow-right.png')} alt="" className="icon" />
                </div>
                <div className={`pager-item ${currentIndex >= list.length - 1 ? 'disabled' : ''}`} onClick={(e) => {
                    e.stopPropagation()
                    handleChange(currentIndex + 1)
                }}>
                    <img src={require('assets/img/index/arrow-right.png')} alt="" className="icon" />
                </div>
            </div>}
        </div>
    ) : <></>
}