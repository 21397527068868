import React, { HTMLAttributes } from 'react';
import logoImg from 'assets/img/logo.png'
import './index.scss'
import Login from '../login';
import { isLogin, removeToken } from '../../utils/auth';
import SettingDialog from './setting-dialog';
import { Link } from 'react-router-dom';
import { getProfile, ProfileType } from '../../api/user';
import { Button } from '@mui/material';
import { isFirstLogin } from '../../api/user';
import { useNavigate } from 'react-router-dom';
import { useDetectClickOutside } from 'react-detect-click-outside';
import ApplyText from '../apply-text';
import useFixedHeader from 'hooks/useFixedHeader';
import HeaderProfile from './header-profile';

interface Props extends HTMLAttributes<HTMLDivElement>{
    hideShadow?: boolean
}

export default function Header(props:Props) {

    const notifySessionKey = 'header-notify-close'

    const { hideShadow = false } = props

    const [isFixedHeader] = useFixedHeader()

    const [open, setOpen] = React.useState(false)
    const [openSetting, setOpenSetting] = React.useState(false)
    const [profile, setProfile] = React.useState<ProfileType|undefined>(undefined)
    const [showNotify, setShowNotify] = React.useState(false)


    const ref = useDetectClickOutside({ onTriggered: () => setOpenSetting(false) });

    const navigate = useNavigate()

    // async function initProfile() {
    //     // getParam({project_id: 'b2840013ca3311edadc70603cf7ee07c'})
    //     if (isLogin()) {
    //         try {
    //             const data = await getProfile({})
    //             setProfile(data)
    //         } catch (e:any) {
    //             console.log(e)
    //             removeToken()
    //             window.location.href = '/'
    //         }
    //     }
    // }

    async function initFirstLoginStatus() {
        if (!isLogin()) {
            return
        }
        const {result} = await isFirstLogin()
        if (result) {
            navigate('/login-guide', {
                replace: false
            })
        }
    }

    function handleCloseNotify() {
        setShowNotify(false)
        sessionStorage.setItem(notifySessionKey, '1')
    }

    React.useEffect(() => {
        // initProfile()
        initFirstLoginStatus()
        console.log('notifySessionKey', sessionStorage.getItem(notifySessionKey))
        if (sessionStorage.getItem(notifySessionKey)) {
            return
        }
        setShowNotify(true)
        // if (sessionStorage.getItem(notifySessionKey)) {
        //     return
        // }
        // setShowNotify(true)
    }, [])

    function login() {
        setOpen(true)
    }

    function handleClose() {
        setOpen(false)
    }
    function handleCloseSeeting() {
        setOpenSetting(false)
    }

    function goFeedback() {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSfiDCEGBBn3IF9w9lfTmjb0TAsUIf5-mfXsn6IUoXZ2ZqBugg/viewform')
    }

	function goAbout() {
        window.open('https://jolly-pyjama-340.notion.site/1Seed-2dbd2bc2422f4ad7b5ea8098fa0c0d0a')
    }

    function goDocs() {
        window.open('https://docs.1seeddao.com/')
    }

    return (
        <div id="header" className={`header ${isFixedHeader ? 'fixed' : ''} ${!hideShadow ? 'shadow' : ''}`}>
            {showNotify && <div className="notify">
                <span className="text" onClick={() => navigate('/check-eligibility')}>🎉 Check your eligibility to claim Membership NFT !</span>
                <img src={require('assets/img/close-white.png')} alt="" className="close" onClick={handleCloseNotify} />
            </div>}
            <div className="header-wrap">
                <div className='header-left'>
                    <div className='logo-wrap'>
                        <Link to={'/'}><img className='logo' src={logoImg} alt="" /></Link>
                        {/* <span className='text'>1Seed</span> */}
                    </div>
                    <div className='btn' onClick={() => navigate('/explore')}>
                        <span>Explore</span>
                    </div>
                    <ApplyText className='btn' isGuideApply></ApplyText>
                    <div className='btn' onClick={() => navigate('/trading')}>
                        <span>Trading</span>
                    </div>
                    <div className='btn' onClick={goDocs}>
                        <span>Docs</span>
                    </div>
                    {/* <div className='btn' onClick={goAbout}>
                        <span>About</span>
                    </div>
                    <div className='btn' onClick={goFeedback}>
                        <span>Feedback</span>
                    </div> */}
                </div>
                {/* <div className='header-right' ref={ref}>
                    {
                        !isLogin() ? 
                        (<Button variant='contained' className="btn-right btn-1seed" onClick={login}>Connect</Button>)
                        : 
                        (<div className={`avatar-wrap ${profile?.user_info.profile_uri ? 'bg' : ''}`} 
                            onClick={() => setOpenSetting(!openSetting)}>
                            <img src={profile?.user_info.profile_uri} alt="" className='avatar'></img>
                        </div>)
                    }
                    {openSetting && <SettingDialog open={openSetting} onClose={handleCloseSeeting}></SettingDialog>}
                </div>
                <Login onClose={handleClose} open={open}></Login> */}
                <HeaderProfile className='header-right'></HeaderProfile>
            </div>
        </div>
  );
}
