import React from 'react';
import './index.scss'
import { OTC_STATUS } from 'constant';
import { OTCProject } from 'api/trade';
import { useNavigate } from 'react-router';

type Props = {
    data: OTCProject
}

export default function TradingCard(props:Props) {

    const { data } = props

    const navigate = useNavigate()

    const statusMap = {
        [OTC_STATUS.ALL]: {
            text: 'All',
            style: ''
        },
        [OTC_STATUS.LIVING]: {
            text: 'Living Now',
            style: 'living'
        },
        [OTC_STATUS.TRADING]: {
            text: 'Trading Now',
            style: 'trading'
        },
        [OTC_STATUS.COMPLETED]: {
            text: 'Completed',
            style: 'completed'
        },
    }

    return (
        <div className={`trading-card ${statusMap[data.status]?.style}`} onClick={() => navigate(`/trading-detail/${data.project_id}`)}>
            <div className="status-wrap">
                {data.status === OTC_STATUS.LIVING && <div className="status-content">
                    <div className="time-wrap">
                        <div className="time">
                            <div className="num">{'00'}</div>
                            <div className="desc">DAYS</div>
                        </div>
                        <div className="time">
                            <div className="num">{'00'}</div>
                            <div className="desc">HRS</div>
                        </div>
                        <div className="time">
                            <div className="num">{'00'}</div>
                            <div className="desc">MINS</div>
                        </div>
                        <div className="time">
                            <div className="num">{'00'}</div>
                            <div className="desc">SECS</div>
                        </div>
                    </div>
                </div>}
            </div>
            <div className="status">{statusMap[data.status]?.text}</div>
            <div className="trading-card__content">
                <div className="content">
                    <div className="left">
                        <div className="name">{data.project_name}’s Airdrop OTC Trading</div>
                        <div className="counts">
                            <div className="item">
                                <div className="text1">Offer：</div>
                                <div className="text2">{data.offer_count}</div>
                            </div>
                            <div className="item">
                                <div className="text1">Aggrement：</div>
                                <div className="text2">{data.agreement_count}</div>
                            </div>
                        </div>
                        <div className="users">
                            {data.top_members?.map((item, index) => {
                                return (
                                    <div className="avatar-wrap" key={index}>
                                        <img src={item.profile_uri || require('assets/img/invest-detail/avatar-empty.png')} alt="" className="avatar" onClick={() => {
                                            navigate(`/profile?id=${item.user_id}`)
                                        }} />
                                    </div>
                                )
                            })}
                            {!data.top_members?.length && <>
                                <div className="avatar-wrap">
                                    <img src={require('assets/img/avatar-empty.png')} alt="" className="avatar" />
                                </div>
                                <div className="empty-text">No members yet</div>
                            </>}
                        </div>
                    </div>
                    <div className="right">
                        <img src={data.image_url} alt="" className="img" />
                        <div className="track">
                            <span className="text">{data.tracks && data.tracks[0]}</span>
                            <img src={data.blockchain.logo_url} alt="" className="icon" />
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <img src={data.logo_url} alt="" className="logo" />
                    <div className="chain">{data.blockchain.name}</div>
                    {/* <img src={require('assets/img/identified.png')} alt="" className="identified" /> */}
                </div>
            </div>
        </div>
    );
}
