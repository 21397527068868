import React from 'react';
import './index.scss'
import TradingQABox from './box';

export type QA = {
    q: string,
    a: string
}

export default function TradingQA() {

    const list:QA[] = [
        {
            q: 'What is Airdrop OTC Trading ?',
            a: `The Token-OTC Solidity contract is a secure and reliable decentralised trading platform, designed with a focus on simplicity and robustness. The contract is engineered to prevent contract-to-contract calls, significantly reducing the potential for security exploits. In order to safeguard user funds, the contract includes an emergency withdrawal feature that, when activated, permanently disables all contract actions and allows users to withdraw their deposited assets. The platform's design emphasises a streamlined user experience by solely facilitating sell offers, simplifying the overall trading logic and reducing complexity. 
            
            The Token-OTC contract is entirely on-chain, requiring sellers to deposit real tokens as collateral, fostering trust and transparency in the trading process. Overall, the contract offers a secure and user-friendly environment for decentralised trading, while minimising the risk of potential vulnerabilities. Token-OTC is a smart contract built to support the native ecosystem airdrop. Token-OTC facilitates the safe exchange of tokens and to promote liquidity in the markets. As a permissionless protocol, Token-OTC bears no responsibility for any tokens purchased using its contracts. 
            
            All users are taking full responsibility that they are aware of the relevant risks involved and that they are participating with a token that is completely independent and not associated with Token-OTC in any way. Social media posts and visible information on the Token-OTC in no way counts as an endorsement of a protocol by the Token-OTC team, and nothing posted or shared in any Token-OTC media is a recommendation or financial advice. 
            
            Using Token-OTC involves risk. As a user of our protocol, you are in agreement that you are aware of these risks and that all liability resides with you, please read and follow the instructions carefully. Try to be as careful with your funds as we are with our code. Don’t trust me bro, trust the smart contract.`
        },
        {
            q: 'How it work?',
            a: `Token-OTC is a smart contract built to facilitate the safe exchange of tokens between $Token airdrop recipients and buyers looking to buy before the tokens become claimable. Token-OTC matches buyers and sellers of $Token and connects them through a smart contract that is simple yet secure to use. The Token-OTC contract is entirely on-chain.`
        },
        {
            q: 'How to Sell your Token Shares?',
            a: `1. Create SELL Offer
            
            2. Input # of tokens for sale, click set
            
            3. Input Price per token you want to sell it for, click set
            
            4. Click on Create New Offer and sign from wallet.
            
            5. Please note that as a seller creating an offer, you are sending 25% of the purchase value as a deposit to the smart contract. You will receive 100% of it back when you send your Airdrop Tokens and fulfil your agreement. Please note that should the Seller fail to fulfil the agreement by the deadline , you will lose 100% of your deposit.
            
            6. You may view all your outstanding offers under the Your Offers tab.
            
            Congratulations! You have now created a Sell Offer, now you wait for a buyer to pick up your offer and both seller and buyer will enter into an agreement. Once the agreement is locked in place, everything is set in motion. As the seller, you will have until the deadline to fulfil the Airdrop Tokens portion of the agreement. Failing to do so, you, the seller will forfeit 100% of your deposit. So please do not forget to fulfil your end of the agreement.`
        },
        {
            q: 'How to buy Airdrop Token Shares?',
            a: `1. Click on the All Offers tab

            2. Scroll to the Offer you want to take based on amount of tokens and price per token

            3. Once selected, click on the top right “Accept” button of the offer

            4. On the acceptance page, review offers terms and if agreeable, click “Confirm Accept” and sign.

            Note that the buyer has to put up 100% of offer value to enter into agreement.

            Congratulations! You have now entered into an agreement with the seller. Come back airdrop day to claim the $ARB tokens the seller will deposit.
            
            Should the Seller fail to fulfil the agreement by the deadline , the Seller will lose 100% of his deposit (25% of the Total Agreement Amount). Upon agreement failure, the buyer will receive 80% of that forfeited deposit amount upon agreement failure plus 100% of the original purchase amount back.`
        },
        {
            q: 'How much is the platform transaction fees？',
            a: `Our fees are 5% from buyer and 5% from seller on successful sale. Creating a sell offer is free, canceling a sell offer is free and you can do it immediately and at anytime provided the offer it not taken by a buyer.`
        }
    ]

    return (
        <div className="trading-qa">
            {list.map((item, index) => <TradingQABox key={index} index={index} data={item} expand={index === 0}></TradingQABox>)}
        </div>
    );
}
