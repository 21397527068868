import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import { OTCDetail } from 'api/trade';
import Tips from 'components/tips';
import { Offer } from '../types';
import { dealMetamaskAddress, dealAmountDecimal } from 'utils/utils';
import { useOTCContract, useErc20Contract } from 'utils/web3';
import Message from 'components/common/message';
import Loading from 'components/common/loading';
// import { OneSeedPoolArenaConnector } from 'config/connector';
import { ethers } from 'ethers';
import useChainInterceptor from 'hooks/useChainInterceptor';

interface Props {
    onClose: () => void,
    open: boolean,
    detail: OTCDetail | undefined
    offer: Offer
    // symbol: string
    userAddr: string
    // tokenDecimal: string
    onRefresh: () => void
}

export default function BuyDialog(props:Props) {
    const { open, onClose, detail, offer, userAddr, onRefresh } = props

    const [allowance, setAllowance] = React.useState('0')
    const [needApprove, setNeedApprove] = React.useState(true)

    const chainIntercepter = useChainInterceptor()

    function submit() {
        chainIntercepter(
        () => {
            if (needApprove) {
                approve()
            } else {
                buy()
            }
        },
        () => {
            console.log('err')
        },
        detail?.blockchain.chain_id)
    }

    async function approve() {
        if (!detail) return
        Loading(true)
        try {
            useErc20Contract(offer.collateralToken).methods.approve(detail.official_addr, ethers.MaxUint256)
            .send({
                from: userAddr
            }).on('receipt', function(receipt:any) {
                console.log(receipt)
                Message('Approve success')
                Loading(false)
                getAllowance()
            }).on('error', function(error:any, receipt:any) {
                console.log(error, receipt)
                Message(error.message || 'Approve failed', {severity: 'error'})
                Loading(false)
            })
        } catch(error:any) {
            console.log(error)
            Message(error.message || 'Approve failed', {severity: 'error'})
            Loading(false)
        }
    }

    async function buy() {
        Loading(true)
        try {
            useOTCContract(detail?.official_addr || '').methods
            .acceptOffer(offer.tradeId)
            .send({
                from: userAddr
            }).on('receipt', function(receipt:any) {
                console.log(receipt)
                onRefresh()
                Message('Buy Offer success')
                Loading(false)
                onClose()
            }).on('error', function(error:any, receipt:any) {
                console.log(error, receipt)
                Message(error.message || 'Buy Offer failed', {severity: 'error'})
                Loading(false)
            })
        } catch(error:any) {
            console.log(error)
            Message(error.message || 'Buy Offer failed', {severity: 'error'})
            Loading(false)
        }
    }

    async function getAllowance() {
        const _allowance = await useErc20Contract(offer.collateralToken, detail?.blockchain.chain_id).methods.allowance(userAddr, detail?.official_addr).call()
        console.log('allowance', _allowance)
        setAllowance(dealAmountDecimal(_allowance, +offer.decimal) + '')
        getNeedApprove(_allowance)
    }

    function getNeedApprove(_allowance:string) {
        setNeedApprove(!_allowance || _allowance === '0')
    }

    React.useEffect(() => {
        getAllowance()
    }, [])

    return (
        <Dialog open={open} onClose={onClose} className="buy-dialog">
            <div className="content">
                <img src={require(`assets/img/close.png`)} alt="" className="close" onClick={() => onClose()} />
                <div className="text1">Offer Trading</div>
                <div className="text2">5% of the total amount as trading Fee</div>
                <div className="info">
                    <img src={detail?.logo_url} alt="" className="logo" />
                    <span className="name">{detail?.project_name}</span>
                    <img src={require('assets/img/trading/identified.png')} alt="" className="icon" />
                </div>
                <div className="data-wrap">
                    <div className="data-title">#{offer.tradeId}</div>
                    <div className="data-item flex">
                        <div className="item-text1">Price:</div>
                        <div className="item-text2">{offer.costPerToken} $</div>
                    </div>
                    <div className="data-item flex">
                        <div className="item-text1">Amount:</div>
                        <div className="item-text2">{offer.tokens} {detail?.token_name}</div>
                    </div>
                    <div className="data-item flex">
                        <div className="item-text1">Total:</div>
                        <div className="item-text2">{+((+offer.costPerToken * +offer.tokens).toFixed(6))} {offer.symbol}</div>
                    </div>
                    <div className="data-item flex">
                        <div className="item-text1">Fee:</div>
                        <div className="item-text2 flex">{+((+offer.costPerToken * +offer.tokens * 0.05).toFixed(6))} {offer.symbol}
                            <div className='notify-wrap'>
                                <img src={require('assets/img/trading/notify.png')} alt="" className="notify" />
                                <Tips text={`${offer.costPerToken} $ * ${offer.tokens} * 5%`}></Tips>
                            </div>
                        </div>
                    </div>
                    <div className="data-item flex">
                        <div className="item-text1">From:</div>
                        <div className="item-text2"><span className="lighting">{dealMetamaskAddress(offer.creator)}</span></div>
                    </div>
                    <div className="data-item flex">
                        <div className="item-text1">You will get:</div>
                        <div className="item-text2">{+((+offer.tokens * 0.95).toFixed(6))} {detail?.token_name}</div>
                    </div>
                </div>
                <Button
                className="btn btn-1seed"
                variant='contained'
                onClick={submit}
                >{needApprove ? 'Approve' : 'Buy'}
                </Button>
            </div>
        </Dialog>
    )
}