import React from 'react';
import './index.scss'
import Header from '../../components/header';
import { applyNew, ApplyConfigResponse, applySave, Token, applySubmit, applyConfig, applyView } from '../../api/apply';
import { walletBind, walletBindPre } from 'api/user';
import FormItem from './components/form-item';
import FormInput from 'components/common/form-input';
import FormTextarea from '../../components/common/form-textarea';
// import ethIcon from '../../assets/img/apply/eth.png'
import chainLogo from 'assets/svg/chain-logo.svg'
import UploadInput from '../../components/common/upload';
import Upload from '@mui/icons-material/Upload';
import Teammates from './components/teammates';
import Button from '@mui/material/Button';
// import tipsIcon from '../../assets/img/apply/tips-arrow.png'
import checkImg from '../../assets/img/login-guide/check.png'
import arrowDownIcon from '../../assets/img/apply/arrow-down.png'
import metamaskIcon from '../../assets/img/login/metamask.png'
import PreviewDialog from './components/preview-dialog';
import { socialIconMapList } from './maps';
import SuccessDialog from './components/success-dialog';
import fileIcon from '../../assets/img/apply/whitepaper.png'
import Message from 'components/common/message';
import { useDetectClickOutside } from 'react-detect-click-outside';
import {
    stepTitleList,
    roundList,
    roundDetailTitle,
    TokenIconMap,
    durationList,
    formCheckList,
    IDODurationList
} from './maps';
import {
    FormParams,
} from './types';
import ApplyStepper from './components/stepper';
import { unstable_useBlocker, useLocation } from 'react-router';
import qs from 'qs'
import { useNavigate } from 'react-router';
import { getWeb3 } from 'utils/web3';
import { urlReg } from 'constant/form';
import ApplyConfirmDialog from './components/confirm-dialog';
import Loading from 'components/common/loading';
// import useAuthSocialAccount from 'hooks/useAuthSocialAccount';
import Tips from 'components/tips';
import { getProfile, ProfileType } from 'api/user';
import useChainInterceptor from 'hooks/useChainInterceptor';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import styled from '@emotion/styled';
import QuillEditor from 'components/quill-editor';
import ReactQuill, { Quill } from 'react-quill'
import { uploadFile } from 'utils/upload';
import { CHAIN_ID } from 'config/config';
import PrivateVisibleList from 'components/visible-list';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// let _formParams:FormParams

let noConfirmClose = true
export default function Apply() {
    const [step, setStep] = React.useState(1) // 1 | 2 | 3
    const [conf, setConf] = React.useState<ApplyConfigResponse>()
    const [openPreview, setOpenPreview] = React.useState(false)
    const [openSuccess, setOpenSuccess] = React.useState(false)
    const [formParams, setFormParams] = React.useState<FormParams>({
        project_id: '',
        tracks: [],
        name: '',
        short_name: '',
        chain_id: CHAIN_ID+'',
        desc: '',
        image_url: '',
        logo_url: '',
        step: '',
        social: {
            twitter: '',
            facebook: '',
            discord: '',
            github: '',
            // instagram: '',
            linkedin: '',
            // mirror: '',
            // telegram: ''
        },
        site: '',
        whitepaper_url: '',
        whitepaper_name: '',
        other_files_url: '',
        other_files_name: '',
        teammates: [],
        // insights: [],
        highlights: new Array(8).fill(''),
        pitch: '',
        rounds: '',
        details: [],
        type: '0',
        goal: '',
        wallet: '',
        duration: '30',
        token_symbol: '',
        token_addr: '',
        valuation: '',
        // overview: [],
        percent: '',
        is_ido: 0,
        token_issuance: '',
        stealth: 1
    })
    const [otherTrack, setOtherTrack] = React.useState('')
    const [checkOtherTrack, setcheckOtherTrack] = React.useState(false)
    const [checkOtherRound, setcheckOtherRound] = React.useState(false)
    const [tokenList, setTokenList] = React.useState<Token[]>([])
    const [isSelectToken, setIsSelectToken] = React.useState(false)
    const [openConfirmClose, setOpenConfirmClose] = React.useState(false)
    // const [profile, setProfile] = React.useState<ProfileType>()
    const [isErrorGoal, setIsErrorGoal] = React.useState(false)
    const [errorGoalText, setErrorGoalText] = React.useState('')
    const [showPercent, setShowPercent] = React.useState(false)
    // const [isFormChange, setIsFormChange] = React.useState(false)
    // const [noConfirmClose, setNoConfirmClose] = React.useState(true)
    const [step2Tab, setStep2Tab] = React.useState(0)
    const [showHeaderGuideLine, setShowHeaderGuideLine] = React.useState(false)

    const chainInterceptor = useChainInterceptor()

    const tokenSelectorRef = useDetectClickOutside({ onTriggered: () => setIsSelectToken(false) });

    const location = useLocation()

    const navigate = useNavigate()

    const blocker = unstable_useBlocker(() => {
        // console.log(noConfirmClose)
        if (noConfirmClose) {
            return false
        }
        setOpenConfirmClose(true)
        return true
        // return false
    })

    // const authSocialAccount = useAuthSocialAccount(function(social_name:string, social_user_name:string) {
    //     _formParams.social[social_name] = social_user_name
    //     setFormParams({..._formParams})
    //     // setNoConfirmClose(false)
    //     noConfirmClose = false
    // })

    async function init() {
        if (location.search) {
            const query = qs.parse(location.search.slice(1))
            if (query.projectId && query.step) {
                const _conf  = await initConfig(query.projectId as string)
                initFormData(query.projectId as string, query.step as string, _conf as ApplyConfigResponse)
                setStep(+query.step)
                return
            }
        }
        initConfig()
    }

    async function initFormData(projectId: string, _step:string, _conf:ApplyConfigResponse) {
        const data = await applyView({
            project_id: projectId,
            step: +_step
        })
        if (data.tracks && data.tracks.length) {
            const findOtherTrackIdx = data.tracks?.findIndex((d:string) => !_conf?.tracks.includes(d))
            if (findOtherTrackIdx > -1) {
                setcheckOtherTrack(true)
                setOtherTrack(data.tracks[findOtherTrackIdx])
                data.tracks.splice(findOtherTrackIdx, 1)
            }
        } else {
            data.tracks = []
        }
        if (data.percent) {
            setShowPercent(true)
        }
        setFormParams({...Object.assign(formParams, {
            ...data,
            details: data.details || [],
            // insights: data.insights || generateInitInsightsParam(_conf),
            // overview: data.overview || generateOverviewParam(_conf),
            teammates: data.teammates || [],
            rounds: data.rounds + '',
            valuation: data.valuation ? data.valuation + '' : '',
            percent: data.percent ? data.percent + '' : '',
            wallet: data.wallet || formParams.wallet,
            highlights: data.highlights || formParams.highlights,
            token_issuance: data.token_issuance ? data.token_issuance + '' : ''
        })})
    }

    async function initConfig(projectId?:string) {
        try {
            let data:ApplyConfigResponse
            if (projectId) {
                data = await applyConfig({
                    project_id: projectId
                })
            } else {
                data = await applyNew()
            }
            const _profile:ProfileType = await getProfile({})
            // console.log(projectId, 'conf', data)
            setConf(data)
            const blockChains = data.tokens.reduce((rs, item) => {
                if (!rs.find(d => d === item.chain_id)) {
                    rs.push(item.chain_id)
                }
                return rs
            }, [] as string[])
            const chain_id = blockChains[0] || ''
            setFormParams({...Object.assign(formParams, {
                chain_id: chain_id,
                project_id: data.project_id,
                // insights: generateInitInsightsParam(data),
                // overview: generateOverviewParam(data),
                wallet: _profile.user_info.wallet || ''
            })})
            initTokenList(data, chain_id)
            return data
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'init config failed', { severity: 'error' })
        }
    }

    // function generateInitInsightsParam(conf:ApplyConfigResponse) {
    //     return conf.insights.map(insight => {
    //         return {
    //             answer: insight.question.map(() => '')
    //         }
    //     })
    // }

    // function generateOverviewParam(conf:ApplyConfigResponse) {
    //     // console.log(conf.overview.map(() => '').slice(0, conf.overview.length - 1))
    //     return conf.overview.map(() => '').slice(0, conf.overview.length - 1)
    // }

    function initTokenList(conf:ApplyConfigResponse, chain_id: string) {
        setTokenList(conf.tokens.filter(item => item.chain_id === chain_id))
    }

    function preview() {
        setOpenPreview(true)
    }

    function refreshForm() {
        setFormParams({...formParams})
        noConfirmClose = false
        // setNoConfirmClose(false)
        // setIsFormChange(true)
    }
    
    function handleClickTrack(item:string) {
        setOtherTrack('')
        setcheckOtherTrack(false)
        // single check & array type
        const findIdx = formParams.tracks.findIndex(d => d === item)
        if (findIdx > -1) {
            formParams.tracks.splice(findIdx, 1)
        } else {
            if (formParams.tracks.length === 1) {
                formParams.tracks[0] = item
            } else {
                formParams.tracks.push(item)
            }
        }
        refreshForm()
    }

    function handleClickOtherTrack() {
        if (checkOtherTrack) {
            setOtherTrack('')
        }
        setcheckOtherTrack(!checkOtherTrack)
        if (formParams.tracks.length) {
            formParams.tracks = []
            refreshForm()
        }
    }

    function handleChangeOtherCheck(v:string) {
        setOtherTrack(v)
    }

    function handleChangeFormParams(k:string, v:any) {
        formParams[k] = v
        refreshForm()
    }

    function handleUploading(loading:boolean) {
        Loading(loading)
    }

    function handleInsightChange(insightIndex: number, questionIndex: number, v: string) {
        formParams.insights[insightIndex].answer[questionIndex] = v
        refreshForm()
    }

    function handleOverviewChange(e: any, overviewIndex: number) {
        console.log(e.target.innerText)
        formParams.overview[overviewIndex] = e.target.innerText
        // refreshForm()
    }

    function handleClickRound(rounds:string) {
        formParams.rounds = rounds
        setcheckOtherRound(false)
        changeRound(rounds)
    }

    // function handleClickOtherRound() {
    //     formParams.rounds = ''
    //     refreshForm()
    //     setcheckOtherRound(true)
    // }

    function handleChangeRound(v: string) {
        formParams.rounds = v
        changeRound(v)
    }

    function changeRound(round:string) {
        if (round && round.length > 0) {
            const details:string[] = []
            for(let i = 0; i < +round; i++) {
                details.push(formParams.details[i] || '')
            }
            formParams.details = details
        } else {
            formParams.details = []
        }
        refreshForm()
    }
    
    function handleChangeDetails(v: string, index: number) {
        formParams.details[index] = v
        refreshForm()
    }

    function handleClickToken(token:Token) {
        formParams.token_symbol = token.symbol
        formParams.token_addr = token.address
        setIsSelectToken(false)
        setIsErrorGoal(getIsErrorGoal(formParams.goal))
        refreshForm()
    }

    async function handleClickWallet() {
        if (!getWeb3().givenProvider) {
            Message('Please install Metamask First', {severity:'warning'})
            window.open('https://metamask.io/download/')
            return
        }
        chainInterceptor(async () => {
            try {
                const publicAddress = await getWeb3().eth.requestAccounts();
                const data = await walletBindPre({public_address: publicAddress[0]})
                handleSignMessage(publicAddress[0], data?.nonce)
                } catch (error:any) {
                    console.log(error)
                    Message(error.message || 'Unknown error', { severity: 'error' })
                }
        })
    }

    async function handleSignMessage(publicAddress:string, nonce:string) {
        try {
            const sign = await getWeb3().eth.personal.sign(
                getWeb3().utils.toHex(nonce),
                publicAddress,
                '' // password?
            )
            await walletBind({
                signature: sign,
                public_address: publicAddress,
                // project_id: formParams.project_id
            })
            handleChangeFormParams('wallet', publicAddress)
            Message('Connect wallet success')
        } catch (error:any) {
            console.log(error)
            Message(error.message || 'Unknown error', { severity: 'error' })
        }
    }

    function checkForm(rules:any) {
        for(let i = 0; i < rules.length; i++) {
            for (let j = 0; j < rules[i].rules.length; j++) {
                const result = rules[i].rules[j](formParams[rules[i].key], { otherTrack, formParams })
                if (result.length > 0) {
                    return result
                }
            }
        }
        return ''
    }

    function generateFormData() {
        const form = new FormData()
        formParams.step = step + ''
        Object.keys(formParams).forEach(k => {
            if (typeof formParams[k] === 'string') {
                form.append(k, formParams[k])
            } else {
                if (Array.isArray(formParams[k])) {
                    formParams[k].forEach((arr_item:any) => {
                        if (typeof arr_item === 'string') {
                            form.append(k, arr_item)
                        } else {
                            arr_item && form.append(k, JSON.stringify(arr_item))
                        }
                    })
                } else {
                    form.append(k, JSON.stringify(formParams[k]))
                }
            }
        })
        if (otherTrack) {
            form.append('tracks', otherTrack)
        }
        // form.append('step', step + '')
        return form
    }

    function checkValuation() {
        if (formParams.valuation && !(+formParams.valuation > 0)) {
            return 'Invalid valuation'
        }
        if (formParams.percent && !(+formParams.percent >= 1 && +formParams.percent < 100)) {
            return "Invalid valuation percent"
        }
        return ''
    }

    async function save(noJump?:boolean) {
        try {
            if (formParams.site && !urlReg.test(formParams.site)) {
                Message('Please enter correct website url', { severity: 'warning' })
                return
            }
            const valuationCheckStr = checkValuation()
            if (valuationCheckStr) {
                Message(valuationCheckStr, { severity: 'warning' })
                return
            }
            Loading(true)
            const form = generateFormData()
            await applySave(form)
            Message('Draft saved')
            noConfirmClose = true
            // setNoConfirmClose(true)
            // setNoConfirmClose(true)
            if (!noJump) {
                navigate(`/apply?projectId=${formParams.project_id}&step=${step}`, { replace: true })
                navigate('/apply/transfer')
            }
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Unknown error', { severity: 'error' })
        } finally {
            Loading(false)
        }
    }

    function back() {
        window.scrollTo(0, 420)
        if (step === 2 && step2Tab === 1) {
            setStep2Tab(0)
            return
        }
        setStep(step - 1)
    }

    function next() {
        if (step === 2 && step2Tab === 0) {
            setStep2Tab(1)
            return
        }
        const checkResult = checkForm(formCheckList[step - 1])
        if (checkResult) {
            Message(checkResult, { severity: 'error' })
            return
        }
        setStep(step + 1)
        window.scrollTo(0, 420)
    }

    async function submit() {
        const checkResult = checkForm(formCheckList[0]) || checkForm(formCheckList[1]) || checkForm(formCheckList[2])
        if (checkResult) {
            Message(checkResult, { severity: 'error' })
            return
        }
        if (!formParams.project_id) {
            Message('Project id is empty', { severity: 'error' })
            return
        }
        Loading(true)
        try {
            const form = generateFormData()
            await applySubmit(form)
            setOpenSuccess(true)
            noConfirmClose = true
            // setNoConfirmClose(true)
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Unknown error', { severity: 'error' })
        } finally {
            Loading(false)
        }
    }

    async function onConfirm() {
        await save(true)
        blocker.proceed && blocker.proceed()
        setOpenConfirmClose(false)
        return
    }

    function onConfirmCancel() {
        setOpenConfirmClose(false)
        blocker.proceed && blocker.proceed()
    }

    // function handleClickSocial(key:string) {
    //     if (formParams.social[key]) return
    //     authSocialAccount(key, '', formParams.project_id)
    // }

    function handleInputSocial(v:string, k:string) {
        formParams.social[k] = v
        refreshForm()
    }

    // async function initProfile() {
    //     const _profile:ProfileType = await getProfile({})
    //     setProfile(_profile)
    //     if (_profile?.user_info.wallet) {
    //         setFormParams({...formParams, wallet: _profile.user_info.wallet})
    //     }
    // }

    function handleChangeGoal(v:string) {
        setIsErrorGoal(getIsErrorGoal(v))
        handleChangeFormParams('goal', v)
    }

    function getIsErrorGoal(_goal: string) {
        if (!formParams.token_symbol) return false
        if (['ETH', 'WETH'].includes(formParams.token_symbol)) {
            setErrorGoalText(`no less than ${10} ${formParams.token_symbol}`)
            return +_goal < 10
        } else {
            setErrorGoalText(`no less than ${10000} ${formParams.token_symbol}`)
            return +_goal < 10000
        }
    }

    function onPitchUpload(el:ReactQuill) {
        if (!el?.editor) return
        const editor = el.editor
        editor.getModule('toolbar').addHandler('image', function() {
            const input = document.createElement('input')
            input.setAttribute('type', 'file' )
            input.setAttribute( 'accept','image/*')
            input.click()
            input.onchange = async (e:any) => {
                const file = e.target.files && e.target.files[0]
                e.target.value = ''
                const index = getPitchUploadIndex()
                if (index === -1) {
                    Message('upload image count no more than 50', { severity: 'warning' })
                    return
                }
                // console.log(index)
                const url = await uploadFile(file, {
                    type: 11,
                    index: index
                }, {
                    onProgress: Loading,
                    onError: (e) => {
                        Message(e.message, {severity:'error'})
                    }
                })
                // console.log(editor.getSelection())
                const selection = editor.getSelection()
                // el.unprivilegedEditor?.getHTML().length || 0
                editor.insertEmbed(selection?.index || 0, 'image', url)
            }
        })
    }

    function getPitchUploadIndex(max = 50) {
        const imgs = document.querySelectorAll('.quill img')
        if (!imgs || !imgs.length) return 0
        const indexList = new Array(max).fill('')
        imgs.forEach(img => {
            const node = Quill.find(img)
            const src = node.domNode.currentSrc as string
            if (!src.startsWith('http')) return
            // console.log(node, node.domNode.currentSrc)
            // console.log(/\S+-(?<index>\d+).\S+$/.exec(src))
            const match = /\S+-(?<index>\d+).\S+$/.exec(src)
            const index = match?.groups?.index || 0
            indexList[+index] = src
        })
        // console.log(indexList)
        return indexList.findIndex(item => !item)
    }

    function handleChangeIsIdo(checked:boolean) {
        formParams.is_ido = checked ? 1 : 0
        // handleChangeFormParams('is_ido', checked ? '1' : '0')
        formParams.duration = checked ? '3' : '30'
        refreshForm()
    }

    function goPitchTemplate() {
        window.open('https://docs.1seeddao.com/user-guide/if-you-are-a-funding-seeker/how-to-curate-your-pitch-guide')
    }

    function onscroll() {
        setShowHeaderGuideLine(window.scrollY > 320)
    }

    function handleShowPercent() {
        setShowPercent(!showPercent)
        if (!showPercent) {
            handleChangeIsIdo(false)
        }
    }

    function handleClickPrivateMode(v:number) {
        handleChangeFormParams('stealth', v)
        if (v) {
            setTimeout(() => {
                const el = document.getElementById('private-wrap')
                if (!el) return
                window.scrollTo({
                    top: el.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                })
            }, 100)
        }
    }

    React.useEffect(() => {
        noConfirmClose = true
        init()
        // initProfile()
        window.addEventListener('scroll', onscroll)
        return () => {
            window.removeEventListener('scroll', onscroll)
        }
    }, [])

    // React.useEffect(() => {
    //     _formParams = formParams
    // }, [formParams])

    // React.useEffect(() => {
    //     if (profile?.user_info.wallet) {
    //         setFormParams({...formParams, wallet: profile.user_info.wallet})
    //     }
    // }, [profile, formParams])

    // function TabPanel(props: TabPanelProps) {
    //     const { children, value, index, ...other } = props;
      
    //     return (
    //         <div
    //             role="tabpanel"
    //             hidden={value !== index}
    //             id={`vertical-tabpanel-${index}`}
    //             aria-labelledby={`vertical-tab-${index}`}
    //             {...other}
    //         >
    //             {value === index && (
    //             <Box sx={{ ml: '120px', width: '710px' }}>
    //                 {children}
    //             </Box>
    //             )}
    //         </div>
    //     );
    // }

    // const StyledTabs = styled(Tabs)`
    //     & .MuiTabs-indicator {
    //     }
    // `

    const StyledTab = styled(Tab)`
        &.MuiTab-textColorPrimary {
            text-transform: none;
            padding-left: 20px;
            width: 240px;
            font-size: 14px;
            font-weight: 400;
            font-family: Inter;
            letter-spacing: 0.005em;
            align-items: flex-start;
            height: 56px;
            margin-bottom: 20px;
        }
        &.Mui-selected {
            background: rgba(244, 249, 255, 1);
            border-radius: 10px;
            font-family: 'Poppins-Bold';
            font-weight: 600;
        }
    `;

    function handleHighlightsChange(v:string, index:number) {
        formParams.highlights[index] = v
        refreshForm()
    }

    return (
      <div className='apply'>
        <Header></Header>
        {showHeaderGuideLine &&
            <div className="header-guide-line">
                <div className="line" style={{width: step / 3 * window.innerWidth}}></div>
            </div>}
        <div className="apply-content">
            <ApplyStepper step={step}></ApplyStepper>
            <div className="content">
                {step !== 2 && <p className="step-title">{stepTitleList[step - 1].title}</p>}
                {/* {stepTitleList[step - 1].desc && <p className="step-desc">{stepTitleList[step - 1].desc}</p>} */}
                {conf && step === 1 && (
                    <div className="step1">
                        <FormItem title='Tracks of project'>
                            <div className="check-list">
                                {conf.tracks.map((item, index) => {
                                    return (
                                        <div
                                        className={`check-item ${formParams.tracks.includes(item) ? 'active' : '' }`}
                                        key={`track_${index}`}
                                        onClick={() => handleClickTrack(item)}
                                        >{item}</div>
                                    )
                                })}
                                <div
                                className={`check-item ${checkOtherTrack ? 'active' : '' }`}
                                onClick={handleClickOtherTrack}
                                >Others</div>
                            </div>
                            {checkOtherTrack && <FormInput
                            placeholder='Enter other track'
                            value={otherTrack}
                            onChange={handleChangeOtherCheck}></FormInput>}
                        </FormItem>
                        <FormItem title='Name'>
                            <FormInput
                            placeholder='Enter your project name'
                            onChange={(v) => handleChangeFormParams('name', v)}
                            value={formParams.name}
                            ></FormInput>
                        </FormItem>
                        <FormItem title='Abbreviation'>
                            <FormInput
                            placeholder='Enter Abbreviation'
                            onChange={(v) => handleChangeFormParams('short_name', v)}
                            value={formParams.short_name}
                            ></FormInput>
                        </FormItem>
                        <FormItem title='BlockChain'>
                            <FormInput
                            className='chain-input'
                            // value={formParams.chain_id}
                            value={'Optimism'}
                            disabled
                            >
                                <img src={chainLogo} alt="" className="chain-prefix" />
                            </FormInput>
                        </FormItem>
                        <FormItem title='Description'>
                            <FormTextarea
                            max={500}
                            placeholder='Give a brief introduction to your project'
                            value={formParams.desc}
                            onChange={(v) => handleChangeFormParams('desc', v)}></FormTextarea>
                        </FormItem>
                        <FormItem title='Image'>
                            <div className={`upload-wrap`}>
                                <div className={`upload-box`}>
                                    <div className="upload-title">Project image</div>
                                    <div className={`upload-content ${formParams.image_url ? 'preview' : ''}`}>
                                        <div className="text"> 600 x 400 <br/> is recommended.</div>
                                        <label htmlFor="upload-image" className='form-btn'>
                                            <span className="btn-text">Upload</span>
                                            <UploadInput
                                            hidden
                                            accept="image/*"
                                            id="upload-image"
                                            onChange={(v) => handleChangeFormParams('image_url', v)}
                                            onProgress={handleUploading}
                                            uploadType={2} />
                                        </label>
                                        {formParams.image_url && 
                                            <label htmlFor="upload-image" className="preview-img">
                                                <img src={formParams.image_url} alt="" />
                                            </label>
                                        }
                                    </div>
                                    <div className="upload-desc">This image will be used for featuring your Project on the Exploration or other promotional areas of 1seed. </div>
                                </div>
                                <div className="upload-box">
                                    <div className="upload-title">Project logo</div>
                                    <div className={`upload-content ${formParams.logo_url ? 'preview' : ''}`}>
                                        <div className="text"> 350 x 350 <br/> is recommended.</div>
                                        <label htmlFor="upload-logo" className='form-btn'>
                                            <span className="btn-text">Upload</span>
                                            <UploadInput
                                            hidden
                                            accept="image/*"
                                            id="upload-logo"
                                            onChange={(v) => handleChangeFormParams('logo_url', v)}
                                            onProgress={handleUploading}
                                            uploadType={1} />
                                        </label>
                                        {formParams.logo_url && 
                                            <label htmlFor="upload-logo" className="preview-img">
                                                <img src={formParams.logo_url} alt=""  />
                                            </label>
                                        }
                                    </div>
                                    <div className="upload-desc">This image will be used for project finance navigating logo.  </div>
                                </div>
                            </div>
                        </FormItem>
                        <FormItem title='Social Links（Optional）'>
                            <div className="social-imgs">
                                {
                                    socialIconMapList.map((item, i) => {
                                        return (
                                            // <div className="social-img-wrap" key={`social_${i}`} onClick={() => handleClickSocial(item.key)}>
                                            //     <img src={formParams.social[item.key] ? item.icon : item.iconDisabled} alt="" className='social-img' />
                                            //     <Tips text={item.tips}></Tips>
                                            //     <div className="form-btn">
                                            //         <div className="btn-text">{formParams.social[item.key] ? 'Connected' : 'Connect'}</div>
                                            //     </div>
                                            // </div>
                                            <div className="social-input-wrap" key={`social_${i}`}>
                                                <div className="social-text">{item.tips}</div>
                                                <FormInput className='social-input' value={formParams.social[item.key]} onChange={(v) => handleInputSocial(v, item.key)} placeholder='https://'></FormInput>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </FormItem>
                        <FormItem title='Your site（Optional）'>
                            <FormInput value={formParams.site} placeholder="https://" onChange={(v) => handleChangeFormParams('site', v)}>
                                {/* <div className='site-prefix'>https://</div> */}
                            </FormInput>
                        </FormItem>
                        <FormItem title='Upload files（Optional）' subTitle='You can choose to upload relevant introduction materials that can be made public by the project'>
                            <div className="upload-wrap upload-file">
                                <div className="upload-box">
                                    <div className="upload-title">White paper</div>
                                    <div className={`upload-content ${formParams.whitepaper_url ? 'preview' : ''}`}>
                                        {!formParams.whitepaper_name &&
                                        <><Upload className="upload-icon" />
                                        <div className="text">Word/PDF. Max 10mb</div>
                                        <label htmlFor="upload-whitepaper" className='form-btn'>
                                            <span className="btn-text">Upload</span>
                                            <UploadInput
                                            hidden
                                            accept=".doc,.docx,.pdf"
                                            id="upload-whitepaper"
                                            onChange={(v, n) => {
                                                handleChangeFormParams('whitepaper_url', v)
                                                handleChangeFormParams('whitepaper_name', n)
                                            }}
                                            onProgress={handleUploading}
                                            uploadType={3} />
                                        </label>
                                        </>
                                        }
                                        {formParams.whitepaper_name && (
                                            <div className="file-name"><img src={fileIcon} alt="" className="file-icon" />
                                                <span>{formParams.whitepaper_name}
                                                <img src={require('assets/img/apply/close.png')} alt="" className="close-icon" onClick={() => {
                                                handleChangeFormParams('whitepaper_url', '')
                                                handleChangeFormParams('whitepaper_name', '')
                                            }} /></span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="upload-box">
                                    <div className="upload-title">other files</div>
                                    <div className={`upload-content ${formParams.other_files_url ? 'preview' : ''}`}>
                                        {!formParams.other_files_name && <><Upload className="upload-icon" />
                                        <div className="text">Zip. Max 100mb</div>
                                        <label htmlFor="upload-other" className='form-btn'>
                                            <span className="btn-text">Upload</span>
                                            <UploadInput
                                            hidden
                                            accept=".zip"
                                            id="upload-other"
                                            onChange={(v, n) => {
                                                handleChangeFormParams('other_files_url', v)
                                                handleChangeFormParams('other_files_name', n)
                                            }}
                                            onProgress={handleUploading}
                                            uploadType={4} />
                                        </label></>}
                                        {formParams.other_files_name && (
                                            <div className="file-name"><img src={fileIcon} alt="" className="file-icon" />
                                                <span>{formParams.other_files_name}
                                                <img src={require('assets/img/apply/close.png')} alt="" className="close-icon" onClick={() => {
                                                handleChangeFormParams('other_files_url', '')
                                                handleChangeFormParams('other_files_name', '')
                                            }} /></span>
                                            </div>
                                        )}
                                    </div>
                                    {/* {formParams.other_files_name && (
                                            <div className="file-name"><img src={fileIcon} alt="" className="file-icon" />{formParams.other_files_name}</div>
                                        )} */}
                                </div>
                            </div>
                        </FormItem>
                        <FormItem title='Teammate'>
                            <Teammates teammates={formParams.teammates} handleChange={(v) => handleChangeFormParams('teammates', v)}></Teammates>
                        </FormItem>
                    </div>
                )}

                {conf && step === 2 && <div className='step2'>
                    <Box
                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={step2Tab}
                            onChange={(e, v) => setStep2Tab(v)}
                            // aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            <StyledTab label="Hightlights" aria-controls='step2-tab-1' value={0} />
                            <StyledTab label="Pitch" aria-controls='step2-tab-2' value={1} />
                        </Tabs>
                        {step2Tab === 0 && <div className='step2-content'>
                            <p className="step-title step2-title">Hightlights</p>
                            <div className="step-desc">The top reasons to invest in your company. Concrete facts about your traction, team credentials, and prior investors are best.</div>
                            <div className="highlights-wrap">
                                {formParams.highlights?.map((item, index) => {
                                    return (
                                        <FormTextarea
                                        className='highlights-box'
                                        key={`highlights-box_${index}`}
                                        // placeholder={index + 1 + ''}
                                        value={item}
                                        max={100}
                                        onChange={(v) => handleHighlightsChange(v, index)}
                                        >
                                            <div className="box-index">{index + 1 + ''}</div>
                                        </FormTextarea>
                                    )
                                })}
                            </div>
                        </div>}
                        {step2Tab === 1 && <div className='step2-content'>
                            <p className="step-title step2-title">Pitch
                                <div className="template-arrow" onClick={goPitchTemplate}>check template
                                    <img src={require('assets/img/apply/template-arrow.png')} alt="" className="icon default" />
                                    <img src={require('assets/img/apply/template-arrow.png')} alt="" className="icon active" />
                                </div>
                            </p>
                            <div className="step-desc">Get investors excited about your story, growth, team, and vision. Why should they invest?</div>
                            <QuillEditor
                            value={formParams.pitch}
                            onChange={(v) => handleChangeFormParams('pitch', v)}
                            onRef={onPitchUpload}
                            ></QuillEditor>
                        </div>}
                    </Box>
                    {/* <FormItem title='Your 10-second elevator pitch'>
                        <div className="insight-overview">
                            {conf.overview.map((overviewText, overviewIndex) => {
                                return (
                                    <span key={`overview_${overviewIndex}`}>
                                        <span className="text">{overviewText}</span>
                                        {overviewIndex < conf.overview.length - 1 &&
                                        <span
                                            className='overview-input'
                                            // contentEditable='plaintext-only'
                                            // suppressContentEditableWarning
                                            onInput={(e) => handleOverviewChange(e, overviewIndex)}
                                        >
                                            {formParams.overview[overviewIndex]}
                                        </span>
                                        }
                                        
                                    </span>
                                )
                            })}
                        </div>
                    </FormItem>
                    {
                        conf.insights.map((item, insightIndex) => {
                            return (
                                <div className="question-box" key={`question_${insightIndex}`}>
                                    <div className="question-title">{item.label}</div>
                                    {item.question.map((questionItem, questionIndex) => {
                                        return (
                                            <div className="item" key={`question_${insightIndex}_${questionIndex}`}>
                                                <div className="ques">{questionItem}</div>
                                                <FormTextarea
                                                value={formParams.insights[insightIndex]?.answer[questionIndex]}
                                                onChange={(v) => handleInsightChange(insightIndex, questionIndex, v)}
                                                placeholder='write down your answer'
                                                max={2000}
                                                ></FormTextarea>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })
                    } */}
                </div>}

                {conf && step === 3 && (
                    <div className='step3'>
                        <FormItem title='Valuation'>
                            <div className="valuation-wrap">
                                <FormInput
                                className='valuation-input'
                                placeholder='Enter valuation'
                                value={formParams.valuation}
                                rule={/^\d{0,12}$/}
                                onChange={v => handleChangeFormParams('valuation', v)}
                                disabled={!!formParams.percent}
                                >
                                    <div className='unit'>$</div>
                                </FormInput>
                                <Button className="btn-valuation btn-1seed" variant='contained' onClick={handleShowPercent}>Not Evaluated</Button>
                            </div>
                            <div className="form-desc">if the valuation has not vet been determined, contributors wll receive investment shares at the <span className="sub">x%</span> price of the latest round of investors</div>
                        </FormItem>
                        {showPercent && <FormItem title={'Set The Percentage'}>
                            <FormInput
                            className='valuation-input'
                            value={formParams.percent}
                            rule={/^([0-9]+\.?([0-9]{1,2})?)?$/}
                            onChange={(v) => handleChangeFormParams('percent', v)}
                            disabled={!!formParams.valuation}
                            >
                                <div className="unit">%</div>
                            </FormInput>
                        </FormItem>}
                        <FormItem title='Financing Rounds'>
                            <div className="check-list">
                                {roundList.map((round:string) => (
                                    <div
                                    key={`round_${round}`}
                                    className={formParams.rounds === round ? 'check-item active' : 'check-item'}
                                    onClick={() => handleClickRound(round)}>{roundDetailTitle[+round] || 'None'}</div>
                                ))}
                                
                                
                                {/* <div className={`check-item ${checkOtherRound ? 'active' : ''}`} onClick={handleClickOtherRound}>Other</div> */}
                            </div>
                            {checkOtherRound && <FormInput
                            rule={/^\d?$/}
                            value={formParams.rounds}
                            onChange={handleChangeRound}></FormInput>}
                        </FormItem>
                        {formParams.details?.map((item, index) => (
                            <FormItem title={`${roundDetailTitle[index + 1]}`} key={`round_${index}`}>
                            <FormTextarea max={300} value={item} onChange={v => handleChangeDetails(v, index)}></FormTextarea>
                        </FormItem>
                        ))}
                        <p className="step-title">Financing Plan</p>
                        <p className="step-desc">Welcome to the 1seed community, please edit your avatar and username.</p>
                        <FormItem title='Financing Type'>
                            <div className="financing-type-wrap">
                                <div className="box active">
                                    <img src={checkImg} alt="" className="check-icon" />
                                    <div className="icon"></div>
                                    <div className="box-title">
                                        Token Financing
                                        <div className="sub">The project party raises funds by issuing tokens .</div>
                                    </div>
                                </div>
                                <div className="box-wrap">
                                    {/* <div className="tips"> <img src={tipsIcon} alt="" className="icon" />coming soon!</div> */}
                                    <Tips text='coming soon!'></Tips>
                                    <div className="box disabled">
                                        <img src={checkImg} alt="" className="check-icon" />
                                        <div className="icon"></div>
                                        <div className="box-title">
                                            Equity Financing
                                            <div className="sub">This mode is coming soon !</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormItem>
                        {!showPercent && <FormItem title='IDO Mode'>
                            <Switch
                            checked={formParams.is_ido === 1}
                            onChange={(e) => handleChangeIsIdo(e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            ></Switch>
                        </FormItem>}
                        {formParams.is_ido === 1 && <FormItem title='Token Issuance'>
                            <FormInput
                            className='token-issuance-input'
                            placeholder='eg：100,000,000'
                            value={formParams.token_issuance}
                            onChange={(v) => handleChangeFormParams('token_issuance', v)}
                            rule={/^\d*$/}
                            >
                                <div className="issuance-price-wrap">
                                    <div className="issuance-price-title">Token Price：</div>
                                    {(formParams.valuation && formParams.token_issuance) ? <div className="issuance-price-content">
                                        {(+formParams.valuation / +formParams.token_issuance).toFixed(4)} USD
                                        </div> : <div className="issuance-price-content">-</div>}
                                </div>
                            </FormInput>
                        </FormItem>}
                        <FormItem title='Financing amount'>
                            <FormInput
                            placeholder='Amount'
                            className='token-input'
                            value={formParams.goal}
                            onChange={handleChangeGoal}
                            rule={/^\d{0,12}$/}
                            error={isErrorGoal}
                            errorText={errorGoalText}
                            >
                                <div className='token-selector' onClick={(e) => {e.stopPropagation();setIsSelectToken(!isSelectToken)}}>
                                    {formParams.token_symbol && <img src={TokenIconMap[formParams.token_symbol]} alt="" className="icon" />}
                                    <div className="text">{formParams.token_symbol || 'Select Token'}</div>
                                    <img src={arrowDownIcon} alt="" className="icon" />
                                </div>
                                {isSelectToken && <div className="token-list" ref={tokenSelectorRef}>
                                        {tokenList.map(item => (
                                            <div className="token-item" key={`token_${item.symbol}`} onClick={() => handleClickToken(item)}>
                                                <img src={TokenIconMap[item.symbol]} alt="" className="icon" />
                                                <div className="text">{item.symbol}</div>
                                                <div className="desc">{item.name}</div>
                                            </div>
                                        ))}
                                </div>}
                            </FormInput>
                            <div className={`form-desc ${isErrorGoal ? 'goal-desc' : ''}`}>
                            The maximum amount of platform financing can be increased by <span className="sub">{conf.fin_rule}%</span>, and it should not be lower than <span className="sub">{100 - conf.fin_rule}%</span> of the established financing amount within the financing time, otherwise the financing will be considered as a failure.
                            </div>
                        </FormItem>
                        <FormItem title='Financing Wallet'>
                            {!formParams.wallet && <div className="wallet" onClick={handleClickWallet}>
                                <img src={metamaskIcon} alt="" className="icon" />
                                <div className="text">Metamask</div>
                            </div>}
                            {formParams.wallet && <FormInput value={formParams.wallet} disabled></FormInput>}
                            <div className="form-desc">The financing receiving address of the project party will be automatically associated with your platform account</div>
                        </FormItem>
                        <FormItem title='Set duration'>
                            <div className="check-list">
                                {(formParams.is_ido === 1 ? IDODurationList : durationList).map(item => (
                                    <div
                                    className={`check-item ${formParams.duration + '' === item ? 'active' : ''}`}
                                    key={`duration_${item}`}
                                    onClick={() => handleChangeFormParams('duration', item)}
                                    >{item} Days</div>
                                ))}
                            </div>
                            {formParams.is_ido !== 1 && <div className="form-desc">
                            The financing duration recommended by the platform is not less than <span className="sub">15 days</span> and not more than <span className="sub">60 days</span>，the recommended time is <span className="sub">30 days</span>.
                            </div>}
                            {formParams.is_ido === 1 && <div className="form-desc">
                            IDO financing time is not less than 1 day, not more than 3 days
                            </div>}
                        </FormItem>
                        <p className="step-title">Financing Mode</p>
                        <FormItem title='Financing Type'>
                            <div className="financing-type-wrap">
                                <div
                                className={`box disabled ${formParams.stealth === 0 ? 'active' : ''}`}
                                // onClick={() => handleClickPrivateMode(0)}
                                >
                                    <img src={checkImg} alt="" className="check-icon" />
                                    <div className="icon"></div>
                                    <div className="box-title">
                                        Public Mode
                                        <div className="sub">Financing is visible to all users of the platform</div>
                                    </div>
                                </div>
                                <div className="box-wrap">
                                    {/* <div className="tips"> <img src={tipsIcon} alt="" className="icon" />coming soon!</div> */}
                                    <div
                                    className={`box ${formParams.stealth === 1 ? 'active' : ''}`}
                                    // onClick={() => handleClickPrivateMode(1)}
                                    >
                                        <img src={checkImg} alt="" className="check-icon" />
                                        <div className="icon"></div>
                                        <div className="box-title">
                                            Private Mode
                                            <div className="sub">The fundraising list can be designated in priority, which can be made public to the community later</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormItem>
                        {formParams.stealth === 1 && 
                        <FormItem title='Visible List'>
                            <div id="private-wrap">
                                <PrivateVisibleList
                                titleText='When the financing application is approved and complete the financing contract deployment, users on the visible list will receive your exclusive financing link'
                                projectId={formParams.project_id}
                                canDel
                                ></PrivateVisibleList>
                            </div>
                        </FormItem>
                        }
                    </div>
                )}
            
                <div className="bottom">
                    <div className="btn-wrap">
                        {step > 1 && <Button variant="outlined" className='btn-1seed btn' onClick={back}>Back</Button>}
                        <div className="right">
                            <Button variant="outlined" className='btn-1seed btn size-auto' onClick={() => save(false)}>Save as a draft</Button>
                            {step === 3 && <Button variant="outlined" className='btn-1seed btn' onClick={preview}>Preview</Button>}
                            {step < 3 && <Button variant="contained" className='btn-1seed btn' onClick={next}>Next</Button>}
                            {step === 3 && <Button variant="contained" className='btn-1seed btn' onClick={submit}>Submit</Button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {formParams && <PreviewDialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        previewData={formParams}
        previewConf={conf}
        otherTrack={otherTrack}
        onSubmit={submit}
        ></PreviewDialog>}
        <SuccessDialog open={openSuccess}></SuccessDialog>
        <ApplyConfirmDialog
        open={openConfirmClose}
        onClose={() => setOpenConfirmClose(false)}
        onConfirm={onConfirm}
        onCancel={onConfirmCancel}
        ></ApplyConfirmDialog>
      </div>
    );
  }
