import React from 'react'
import { InvestInfo } from 'api/finance'
import './index.scss'
import FormTextarea from 'components/common/form-textarea'
import { ProfileType } from 'api/user'
import { COMMENT_TYPE } from 'constant'
import { postComment } from 'api/community'
import Loading from 'components/common/loading'
import Message from 'components/common/message'
import { useDetectClickOutside } from 'react-detect-click-outside'

type Range = {
    index: number,
    length: number
}

interface Props {
    investInfo: InvestInfo
    profile: ProfileType|undefined
    commentType: number
    selectedText: string,
    // selectedTextPosition: number[],
    onSuccess: () => void
    onClose: () => void
    top: number
    left: number,
    range: Range,
    section: number
}

const tagTextMap = {
    [COMMENT_TYPE.ANNOTATE]: 'Annotate',
    [COMMENT_TYPE.QUERY]: 'Query',
    [COMMENT_TYPE.SUGGEST]: 'Suggest'
}

export default function CommentPoster(props:Props) {
    const { investInfo, commentType, selectedText, profile, onSuccess, onClose, top, left, range, section } = props

    const [content, setContent] = React.useState('')
    const [isShake, setIsShake] = React.useState(false)
    // const [realSelectedIndexList, setRealSelectedIndexList] = React.useState<number[]>(selectedIndexList)

    const ref = useDetectClickOutside({ onTriggered: () => handleClickOutside() });

    function handleClickOutside() {
        if (content) {
            setIsShake(true)
            return
        }
        onClose()
    }

    async function handleSubmit() {
        Loading(true)
        try {
            await postComment({
                project_id: investInfo.project_id,
                // start: selectedTextPosition[0],
                // end: selectedTextPosition[1],
                index: range.index,
                length: range.length,
                content: content,
                type: commentType,
                section: Math.floor(section),
                text: selectedText
            })
            Message('Comment success')
            onSuccess()
            onClose()
        } catch (error:any) {
            console.log(error)
            Message(error.message || 'Unknown error', {severity: 'error'})
        } finally {
            Loading(false)
        }
    }

    function handleContentChange(v:string) {
        setContent(v)
        setIsShake(false)
    }

    // React.useEffect(() => {
    //     setRealSelectedIndexList(selectedIndexList)
    // }, [])

    return (
        <div className={`comment-poster ${isShake ? 'shake' : ''}`} style={{top, left}} ref={ref}>
            <span className="tag">{tagTextMap[commentType]}</span>
            <img src={require('assets/img/community/close.png')} alt="" className="close" onClick={onClose} />
            <FormTextarea
            placeholder='Add a comment'
            value={content}
            onChange={handleContentChange}
            autoFocus
            ></FormTextarea>
            <div className="bottom">
                <div className="left">
                    <img src={profile?.user_info.profile_uri} alt="" className="avatar" />
                    <div className="name">{profile?.user_info.ens_name || profile?.user_info.username}</div>
                </div>
                <img src={require('assets/img/community/arrow-right.png')} alt="" className="right-icon" onClick={handleSubmit} />
            </div>
        </div>
    )
}