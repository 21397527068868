import React from 'react'
import './index.scss'

interface Props extends React.PropsWithChildren {
    title: string,
    subTitle?: string
}

export default function FormItem(props:Props) {
    return (
        <div className="form-item">
            <div className="form-title">
                {props.title}
                {props.subTitle && <div className="sub">{props.subTitle}</div>}
                </div>
            <div className="form-content">
                {props.children}
            </div>
        </div>
    )
}