import React from 'react'
import './index.scss'
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { QA } from '..';

interface Props {
    data:QA,
    index: number,
    expand: boolean
}

export default function TradingQABox(props:Props) {

    const {data, index, expand} = props

    const [open, setOpen] = React.useState(expand)

    return (
        <div className="project-insight-box">
            <div className="num">0{index + 1}</div>
            <div className="content">
                <div className="title">{data.q}</div>
                {open ? <>
                    <div className="qas">
                        {/* <div className="question">{'xxx'}</div> */}
                        <span className="answer">{data.a}</span>
                    </div>
                </> : <></>}
            </div>
            <SpeedDial
                className='expand-icon'
                open={open}
                onClick={() => setOpen(!open)}
                ariaLabel="SpeedDial openIcon example"
                sx={{ zIndex: 10 }}
                icon={<SpeedDialIcon openIcon={<HorizontalRuleIcon />} />}
            >
            </SpeedDial>
        </div>
    )
}