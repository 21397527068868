import React from 'react';
import Dialog from '@mui/material/Dialog';
import closeIcon from 'assets/img/close.png'
import './index.scss'
import { Button } from '@mui/material';

type Props = {
    reason: string,
    open: boolean,
    onClose: () => void
}

export default function AuditReasonDialog(props: Props) {
    const { onClose, reason, open } = props

    return (
        <Dialog open={open} onClose={onClose}>
            <div className="audit-reason-dialog">
                <img src={closeIcon} alt="" className="close" onClick={onClose} />
                <div className="title">Reason For Rejection</div>
                <div className="content">
                    <div className="content-title">Dear project leader:</div>
                    <div className="text text1">First of all, thank you very much for your trust in the 1seed platform. I am very excited to receive your financing application. After careful review by the platform, I regret to inform you that your application has not been approved. The reason is as follows:</div>
                    <div className="reason">{reason}</div>
                    <div className="text text2">All the best with your project.</div>
                    <div className="text text3">Thanks you!</div>
                </div>
                <Button variant='contained' className='btn btn-1seed' onClick={onClose}>I Got It</Button>
            </div>
        </Dialog>
    )
}