import React from 'react';
import './index.scss'
import { OTCDetail } from 'api/trade';
import { Aggrement, Offer } from '../types';
import { OTC_STATUS } from 'constant';
import { useNavigate } from 'react-router';

interface Props {
    detail: OTCDetail | undefined,
    aggrements: Aggrement[],
    offers: Offer[]
}

export default function InfoCard(props:Props) {

    const { detail, aggrements, offers } = props
    const navigate = useNavigate()

    const [costCount, setCostCount] = React.useState(0)
    const [percent, setPercent] = React.useState(0)
    const [offerCount, setOfferCount] = React.useState(0)

    
    const statusMap = {
        [OTC_STATUS.LIVING]: {
            text: 'Living Now',
            style: 'living'
        },
        [OTC_STATUS.TRADING]: {
            text: 'Trading Now',
            style: 'trading'
        },
        [OTC_STATUS.COMPLETED]: {
            text: 'Completed',
            style: 'completed'
        },
    }

    function initAggrementDatas() {
        if (!aggrements.length) return
        let notActiveCount = 0
        let cost = 0
        // console.log('000000000000000000', aggrements)
        aggrements.forEach(item => {
            // console.log(item.active)
            if (!item.active) {
                notActiveCount++
                cost += +item.costPerToken * +item.tokens
                console.log(notActiveCount, cost)
            }
        })
        setCostCount(+cost.toFixed(2))
        setPercent(+((notActiveCount / aggrements.length) * 100).toFixed(2))
    }

    function initOfferDatas() {
        // console.log('000000000000000000offers', offers)
        if (!offers.length) return
        const count = offers.reduce((rs, item) => {
            if (item.active) return rs
            return rs + +item.tokens
        }, 0)
        setOfferCount(+count.toFixed(2))
    }

    React.useEffect(() => {
        initAggrementDatas()
    }, [aggrements])

    React.useEffect(() => {
        initOfferDatas()
        // console.log('offersasdasdasdkjasdasd', offers)
    }, [offers])

    return (
        <div className={`trading-info-card ${statusMap[detail?.status || 0]?.style}`}>
            <div className="trading-info-card__bg"></div>
            <div className="trading-info-card__status">{statusMap[detail?.status || 0]?.text}</div>
            <div className="trading-info-card__content">
                <div className="info">
                    <img src={detail?.image_url} alt="" className='img' />
                    <div>
                        <div className="name">{detail?.project_name}</div>
                        <div className="counts">
                            <div className="count">
                                <div className="text1">All Offers</div>
                                <div className="text2">{offers.length}</div>
                            </div>
                            <div className="count">
                                <div className="text1">All Agreements</div>
                                <div className="text2">{aggrements.length}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="datas">
                    <div className="item">
                        <div className="text1">Total Value Locked (TVL)</div>
                        <div className="text2">{costCount} $USD<br/>{offerCount} ${detail?.token_name}</div>
                    </div>
                    <div className="item">
                        <div className="text1">Agreements Complete</div>
                        <div className="text2">{percent}%</div>
                    </div>
                </div>
                <div className="members">
                    {detail?.top_members?.length && <>
                        {detail.top_members.map((item, index) => {
                            return (
                                <div className="avatar-wrap" key={index} onClick={() => {
                                    item.user_id && navigate(`/profile?id=${item.user_id}`)
                                }}>
                                    <img src={item.profile_uri || require('assets/img/invest-detail/avatar-default.png')} alt="" className="avatar" />
                                </div>
                            )
                        })}
                        <span className='text'>+ {detail?.member_count} members</span>
                    </>}
                    {!detail?.top_members?.length && <>
                        <div className="avatar-wrap">
                            <img src={(require('assets/img/invest-detail/avatar-empty.png'))} alt="" className="avatar" />
                        </div>
                        <span className="text empty-text">No members yet</span>
                    </>}
                </div>
            </div>
        </div>
    );
}
