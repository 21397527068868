import React from 'react'
import './index.scss'

interface Props {
    text: string
    align?: 'left'|'right'|'center'
}

export default function Tips(props: Props) {
    const { text, align = 'center' } = props
	return (
			<div className={`tips ${align}`}>
                {text}
                <img src={require('assets/img/apply/tips-arrow.png')} alt="" className="tips-icon" />
            </div>
	)
}