import baseRequest from "../../utils/http"
const api = new baseRequest()

const PREFIX = '/api/v1/utility'

export type PresignUrlResponse = {
  result: boolean
}

type PresignUrlParam = {
  // 0 - user profile, 1 project logo, 2 project image, 3 project whitepaper, 4 project other file, 5-user cover, 6-proj recommand
  type: number,
  length: number,
  postfix: string,
  index?: number
  // filename: string
}
export function presignUrl(params:PresignUrlParam):Promise<PresignUrlResponse|any> {
  return api.get(`${PREFIX}/presign_url`, params)
}