import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { InvestInfo, InvestDetail } from 'api/finance';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import { getInvestors } from 'api/finance';
import { TopInvestor, ProfileType } from 'api/user';
import { useInvestmentContract } from 'utils/web3';
import Loading from 'components/common/loading';
import Message from 'components/common/message';
import { parseUnits } from 'ethers';
import useChainInterceptor from 'hooks/useChainInterceptor';

interface Props {
    investInfo: InvestInfo
    investDetail: InvestDetail
    onClose: () => void
    open: boolean
    onRefresh: () => void
    amount: number
    profile: ProfileType|undefined
}

export default function CreateNFTDialog(props:Props) {
    const { open, onClose, investInfo, investDetail, onRefresh, amount, profile } = props

    const [investorList, setInvestorList] = React.useState<TopInvestor[]>([])
    // wait mint count
    const [investCount, setInvestCount] = React.useState(-1)
    // minted count
    const [tokenCount, setTokenCount] = React.useState(0)

    const [sliderAmount, setSliderAmount] = React.useState(0)

    const chainInterceptor = useChainInterceptor()

    async function initInvestorList() {
        const { investors } = await getInvestors({
            project_id: investInfo.project_id,
            page_no: 1,
            page_size: 12
        })
        setInvestorList(investors || [])
    }

    async function initInvestCount() {
        const count = await useInvestmentContract(investDetail.invest_contract).methods.investorCount().call()
        console.log('investCount:', count)
        setInvestCount(+count || 0)
        setSliderAmount(+count > 30 ? 30 : +count)
    }

    async function initTokenCount() {
        const count = await useInvestmentContract(investDetail.invest_contract).methods.tokenCount().call()
        console.log('tokenCount:', count)
        setTokenCount(+count || 0)
    }

    function onSliderChange(e:any, v:any) {
        setSliderAmount(+v || 0)
    }

    async function createNFT() {
        if (!sliderAmount) return
        chainInterceptor(() => {
            try {
                Loading(true)
                useInvestmentContract(investDetail.invest_contract).methods.submitResult(parseUnits(sliderAmount + ''))
                .send({
                    from: investInfo.wallet
                }).on('receipt', function(receipt:any) {
                    console.log(receipt)
                    Message('Create financing NFT success')
                    Loading(false)
                    onRefresh()
                    onClose()
                }).on('error', function(error:any, receipt:any) {
                    console.log(error, receipt)
                    Message(error.message || 'Create financing NFT failed', {severity: 'error'})
                    Loading(false)
                })
            } catch(error:any) {
                console.log(error)
                Message(error.message || 'Create financing NFT failed', {severity: 'error'})
                Loading(false)
            }
        })
    }

    React.useEffect(() => {
        if (open) {
            initInvestorList()
            initInvestCount()
            initTokenCount()
        }
    }, [open])

    return (
        <Dialog open={open} onClose={onClose} className="create-NFT-dialog">
            <img className='create-NFT-dialog__icon' src={require('assets/img/close.png')} alt="" onClick={() => onClose()} />
            <div className="create-NFT-dialog__content">
                <div className="nft-wrap">
                    <img src={require('assets/img/invest-detail/nft-default.png')} alt="" className="nft-img" />
                    <div className="nft-name">{investInfo.name} <br/> investing</div>
                    <div className="amount">{amount} {investInfo.token_symbol}</div>
                </div>
                <div className="content-wrap">
                    <div>
                        <div className="title">Create Financing NFT</div>
                        <div className="sub-title">Congratulations on your successful completion of financing, please generate financing NFT certificates for investors</div>
                        <div className="block investors-wrap">
                            <div className="count">{investorList?.length || 0} Investors</div>
                            <div className="investor-list">
                                {investorList?.map((item => {
                                    return (
                                        <div className="avatar-wrap" key={`investor_${item.user_id}`}>
                                            <img src={item.profile_uri || require('assets/img/invest-detail/avatar-default.png')} alt="" className="avatar" />
                                        </div>
                                    )
                                }))}
                                {investorList?.length > 13 && <div className="avatar-wrap">
                                    <img src={require('assets/img/invest-detail/avatar-more.png')} alt="" className="avatar" />
                                </div>}
                            </div>
                        </div>
                        {(tokenCount > 0 || investCount >= 30) && <div className="block slider-wrap">
                            <div className="count">Mint {investCount} NFTs</div>
                            <div className="desc">Mint no more than <span className="sub">30</span> NFTs each time will save gas costs</div>
                            {/* default value effect before mounted */}
                            <div className={`slider ${tokenCount ? 'disabled-show' : ''}`}>
                                {tokenCount > 0 && <div className="disabled-content" style={{
                                    width: `${tokenCount / (investCount + tokenCount)}%`
                                }}></div>}
                                <Slider
                                className={`slider`}
                                aria-label="Default"
                                valueLabelDisplay="on"
                                min={0}
                                max={investCount}
                                onChange={onSliderChange}
                                defaultValue={investCount}
                                />
                            </div>
                        </div>}
                    </div>
                    <Button className="btn btn-1seed" variant='contained' onClick={createNFT}>MINT</Button>
                </div>
            </div>
        </Dialog>
    )
}