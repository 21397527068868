import React from 'react'
// import sanitize from 'sanitize-html'
import './index.scss'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { InvestInfo, InvestDetail, getDetail } from 'api/finance'
import { COMMENT_TYPE } from 'constant'
import CommentPoster from './comment-poster'
import { ProfileType } from 'api/user'
import { USER_ROLE } from 'constant'
import Message from 'components/common/message'
// import ReactQuill, {Quill} from "react-quill";
import Quill from 'quill'
import { Tag, SliceWeight } from 'api/community'
import CommentDetail from 'components/comment-detail';
import useLoginInterceptor from 'hooks/useLoginInterceptor'


interface Props extends React.PropsWithChildren {
    text: string
    investInfo: InvestInfo
    profile: ProfileType|undefined
    onRefresh: () => void
    tags: Tag[]
    investDetail: InvestDetail,
    sliceWeights: SliceWeight[]
}

let quill: Quill

type Range = {
    index: number,
    length: number
}

export default function CheckText(props: Props) {
    const { text, investInfo, profile, onRefresh, tags = [], sliceWeights = [], investDetail} = props

    const [commentType, setCommentType] = React.useState(COMMENT_TYPE.ANNOTATE)
    const [isShowOperate, setIsShowOperate] = React.useState(false)
    const [isShowCommentBox, setIsShowCommentBox] = React.useState(false)
    const [top, setTop] = React.useState(0)
    const [left, setLeft] = React.useState(0)
    const [selectedText, setSelectedText] = React.useState('')
    const [section, setSection] = React.useState(0)
    const [range, setRange] = React.useState<Range>({
        length: 0,
        index: 0
    })
    const [openCommentDetail, setOpenCommentDetail] = React.useState(false)

    // const quill = useRef()
    // const [selectedTextPosition, setSelectedTextPosition] = React.useState([0, 0])
    
    const operateRef = useDetectClickOutside({ onTriggered: () => setIsShowOperate(false) });

    const loginInterceptor = useLoginInterceptor()

    // function getTargetIndex(selectedText:string, selection:Selection) {
    //     if (!selectedText) {
    //         return [0, 0]
    //     }
    //     return [selection.anchorOffset, selection.focusOffset]
    // }

    function onMouseUp(e:any) {
        // console.log(e)
        e.stopPropagation()
        e.preventDefault()
        // const selection = document.getSelection()
        // console.log(ReactQuill.)
        // if (!selection || !selection.anchorNode || !selection.focusNode || selection.isCollapsed) return
        // const oRange = selection.getRangeAt(0)
        // setSelectedText(selection.toString())
        // setSelectedTextPosition(getTargetIndex(selectedText, selection))

        const _range = quill.getSelection()
        if (!_range || !_range.length) return
        // console.log(quill.getText(_range.index, _range.length))
        const _text = quill.getText(_range.index, _range.length)
        // console.log(quill.getBounds(_range.index, _range.length))
        const bounds = quill.getBounds(_range.index, _range.length)
        setRange(_range)
        setSelectedText(_text)
        setSection(bounds.top)
        console.log(_text, _range, bounds)
        // quill.formatText(0, 5, {
        //     background: '#ddd'
        // })
        // quill.formatText(3, 4, {
        //     background: '#666'
        // })

        setTop(e.clientY - 40)
        setLeft(e.clientX)
        setTimeout(() => {
            setIsShowOperate(true)
        })
    }

    async function handleClickOperate(type:number, e:any) {
        e.stopPropagation()
        // const _investDetail:InvestDetail = await getDetail({
        //     project_id: investInfo.project_id || ''
        // })
        loginInterceptor(() => {
            const isInvested = +investDetail.my_share > 0
            const isManager = profile?.user_info.role === USER_ROLE.OFFICIAL
            const isSelfProject = investInfo.user_id === profile?.id
            if (!isInvested && !profile?.has_profile_nft && !isSelfProject && !isManager) {
                Message('Only investors can make a comment', {severity:'warning'})
                return
            }
            setCommentType(type)
            setIsShowOperate(false)
            setIsShowCommentBox(true)
        })
    }


    function tagLine() {
        sliceWeights?.forEach(slice => {
            // self
            if (slice.commented) {
                drawLine(slice.index, slice.length, {
                    background: 'rgba(122, 216, 77, 0.2)'
                })
            }
            // top30percent
            if (slice.weight >= 10 && slice.weight < 30) {
                drawLine(slice.index, slice.length, {
                    background: 'rgba(102, 182, 255, 0.2)'
                })
            }
            // top10percent
            if (slice.weight >= 30) {
                drawLine(slice.index, slice.length, {
                    background: 'rgba(234, 197, 68, 0.2)'
                })
            }
        })
    }

    function drawLine(index: number, length: number, style: object) {
        quill.formatText(index, length, style)
    }

    function handleOpenCommentDetail(tag: Tag) {
        setSection(tag.section)
        setOpenCommentDetail(true)
    }

    React.useEffect(() => {
        quill = new Quill('#quill-text', {
            readOnly: true
        })
        quill.pasteHTML(text)
        quill.disable()
    }, [])

    React.useEffect(() => {
        tagLine()
    }, [tags])

    return (
        <div className={`check-text`} onMouseUp={onMouseUp}>
            {/* <div
            className={`check-text__wrap`}
            dangerouslySetInnerHTML={{
                __html: text
            }}
            onMouseUp={onMouseUp}
            ></div> */}
            {/* <ReactQuill
                theme='snow'
                value={text}
                // modules={[]}
                readOnly
                onKeyUp={onMouseUp}
                // ref={quill}
            ></ReactQuill> */}
            <div id="quill-text"></div>
            {tags?.map(tag => {
                return (
                    <div
                    className="contributors"
                    key={`contributor_${tag.section}`}
                    style={{
                        top: tag.section
                    }}
                    onClick={() => handleOpenCommentDetail(tag)}
                    >
                        {tag?.early_contributors?.map((item, index) => {
                            return (
                                <div className="avatar-wrap" key={`earlyContributor_${item.id}_${index}`}>
                                    <img src={item.profile_uri || require('assets/img/invest-detail/avatar-default.png')} alt="" className="avatar" />
                                </div>
                            )
                        })}
                        {tag?.comment_cnt && tag?.comment_cnt >= 3 &&
                            <div className="avatar-wrap count-wrap">
                                <div className="count"><div className="text">+{tag?.comment_cnt}</div></div>
                            </div>
                        }
                        <div className="avatar-wrap arrow-wrap">
                            <img src={require('assets/img/community/arrow-right.png')} alt="" className="avatar" />
                        </div>
                    </div>
                )
            })
            }
            {isShowOperate && <div className="operate" ref={operateRef} style={{
                top,
                left,
            }}>
                <div
                    className="item"
                    onClick={(e) => handleClickOperate(COMMENT_TYPE.ANNOTATE, e)}>
                    Annotate
                </div>
                <div
                    className="item"
                    onClick={(e) => handleClickOperate(COMMENT_TYPE.QUERY, e)}>
                    Query
                </div>
                <div
                    className="item"
                    onClick={(e) => handleClickOperate(COMMENT_TYPE.SUGGEST, e)}>
                    Suggest
                </div>
            </div>}
            {isShowCommentBox &&
            <CommentPoster
                investInfo={investInfo}
                profile={profile}
                commentType={commentType}
                selectedText={selectedText}
                onClose={() => setIsShowCommentBox(false)}
                onSuccess={onRefresh}
                top={top}
                left={left}
                range={range}
                section={section}
            />}
            <CommentDetail
            profile={profile}
            open={openCommentDetail}
            onClose={() => setOpenCommentDetail(false)}
            section={section}
            investInfo={investInfo}
            investDetail={investDetail}
            ></CommentDetail>
        </div>
    )
}