import React from 'react';
import Header from 'components/header';
import './index.scss'
import { useParams } from 'react-router';
import { getOTCDetail, OTCDetail } from 'api/trade';
import InfoCard from './info-card';
import Intro from './intro';
import OfferList from './offer-list';
import AggrementList from './aggrement-list';
import { useOTCContract } from 'utils/web3';
import { Aggrement, Offer } from './types';
import { useErc20Contract, getWeb3 } from 'utils/web3';
import Multicall from '@dopex-io/web3-multicall';
// import { Token } from './types';
import { Button } from '@mui/material';
import MakeOfferDialog from './make-offer-dialog';
import { dealAmountDecimal } from 'utils/utils';
import { getUrlProvider } from 'utils/providers';
import useChainInterceptor from 'hooks/useChainInterceptor';
import useLoginInterceptor from 'hooks/useLoginInterceptor';

export default function TradingDetailPage() {

    const projectId = useParams().id || ''

    const [detail, setDetail] = React.useState<OTCDetail>()
    const [aggrements, setAggrements] = React.useState<Aggrement[]>([])
    const [offers, setOffers] = React.useState<Offer[]>([])
    const [expiredTime, setExpiredTime] = React.useState(0)
    const [decimal, setDecimal] = React.useState('')
    // const [symbol, setSymbol] = React.useState('')
    // const [tokenDecimal, setTokenDecimal] = React.useState('')
    const [userAddr, setUserAddr] = React.useState('')
    // const [tokenList, setTokenList] = React.useState<Token[]>([])
    const [open, setOpen] = React.useState(false)
    const [max, setMax] = React.useState(0)
    const [min, setMin] = React.useState(0)

    const chainInterceptor = useChainInterceptor()
    const loginInterceptor = useLoginInterceptor()

    async function init() {
        const data:OTCDetail = await getOTCDetail({
            project_id: projectId
        })
        // data.desc += 'asddddddddddddddddddddddddddddddddddddddd'
        setDetail(data)
    }

    async function getAggrements(start = 0, _aggrements:Aggrement[] = [], symbolAggregates:Promise<any>[] = [], tokenDecimalAggregates:Promise<any>[] = []) {
        if (!detail) return
        // console.log('addr:', detail.official_addr)
        const list:Aggrement[] = await useOTCContract(detail.official_addr, detail.blockchain.chain_id).methods.getAgreements(start, start + 100).call()
        console.log('aggrements:', start, start + 100, list)
        if (list?.length) {
            list.forEach(item => {
                // symbolAggregates.push(getSymbol(item.collateralToken))
                symbolAggregates.push(useErc20Contract(item.collateralToken, detail.blockchain.chain_id).methods.symbol())
                // tokenDecimalAggregates.push(getTokenDecimal(item.collateralToken))
                tokenDecimalAggregates.push(useErc20Contract(item.collateralToken, detail.blockchain.chain_id).methods.decimals())
            })
        }
        if (list.length === 100) {
            getAggrements(start + 100, _aggrements.concat(list), symbolAggregates, tokenDecimalAggregates)
        } else {
            const multicall = new Multicall({
                multicallAddress: detail.blockchain.multicall,
                provider: getUrlProvider(detail.blockchain.chain_id),
            })
            const rs = await Promise.all([multicall.aggregate(symbolAggregates), multicall.aggregate(tokenDecimalAggregates)])
            const symbols = rs[0]
            const tokenDecimals = rs[1]
            setAggrements(_aggrements.concat(list).map((item, index) => {
                return {
                    ...item,
                    tokens: dealAmountDecimal(item.tokens, +decimal, 6) + '',
                    costPerToken: dealAmountDecimal(item.costPerToken, +tokenDecimals[index], 6) + '',
                    decimal: tokenDecimals[index],
                    symbol: symbols[index]
                }
            }))
        }
    }

    async function getOffers(start = 0, _offers:Offer[] = [], symbolAggregates:Promise<any>[] = [], tokenDecimalAggregates:Promise<any>[] = []) {
        if (!detail) return
        const list:Offer[] = await useOTCContract(detail.official_addr, detail.blockchain.chain_id).methods.getOffers(start, start + 100).call()
        console.log('offers:', start, start + 100, list)
        if (list?.length) {
            list.forEach(item => {
                // symbolAggregates.push(getSymbol(item.collateralToken))
                symbolAggregates.push(useErc20Contract(item.collateralToken, detail.blockchain.chain_id).methods.symbol())
                // tokenDecimalAggregates.push(getTokenDecimal(item.collateralToken))
                tokenDecimalAggregates.push(useErc20Contract(item.collateralToken, detail.blockchain.chain_id).methods.decimals())
            })
        }
        if (list.length === 100) {
            getOffers(start + 100, _offers.concat(list), symbolAggregates, tokenDecimalAggregates)
        } else {
            const multicall = new Multicall({
                multicallAddress: detail.blockchain.multicall,
                provider: getUrlProvider(detail.blockchain.chain_id),
            })
            const rs = await Promise.all([multicall.aggregate(symbolAggregates), multicall.aggregate(tokenDecimalAggregates)])
            const symbols = rs[0]
            const tokenDecimals = rs[1]
            setOffers(_offers.concat(list).map((item, index) => {
                return {
                    ...item,
                    tokens: dealAmountDecimal(item.tokens, +decimal, 6) + '',
                    costPerToken: dealAmountDecimal(item.costPerToken, +tokenDecimals[index], 6) + '',
                    decimal: tokenDecimals[index],
                    symbol: symbols[index]
                }
            }))
        }
    }

    // async function getSymbol(collateralToken: string) {
    //     // const data = await useErc20Contract(collateralToken, detail?.blockchain.chain_id || 0).methods.symbol().call()
    //     // console.log('symbol:', data)
    //     // setSymbol(data)
    //     return useErc20Contract(collateralToken, detail?.blockchain.chain_id).methods.symbol()
    // }

    // async function getTokenDecimal(collateralToken: string) {
    //     // const data = await useErc20Contract(collateralToken, detail?.blockchain.chain_id || 0).methods.decimals().call()
    //     // console.log('tokenDecimal:', data)
    //     // setTokenDecimal(data)
    //     // return data
    //     return useErc20Contract(collateralToken, detail?.blockchain.chain_id).methods.decimals()
    // }

    async function getExpiredTime() {
        if (!detail) return
        const time = await useOTCContract(detail.official_addr, detail.blockchain.chain_id).methods.OFFERS_EXPIRED().call()
        console.log('ExpiredTime' ,time)
        setExpiredTime(time)
    }

    function handleMakeOffer() {
        chainInterceptor(() => {
            setOpen(true)
        }, () => {
            return
        }, detail?.blockchain.chain_id)
    }

    // async function getSupports() {
    //     if (!detail) return
    //     const addrList = await useOTCContract(detail.official_addr, detail.blockchain.chain_id).methods.getSupports().call()
    //     console.log('supports' ,addrList)
    //     // const chainId = await web3.eth.getChainId()
    //     const multicall = new Multicall({
    //         // chainId: detail.blockchain.chain_id,
    //         multicallAddress: detail.blockchain.multicall,
    //         // multicallAddress: '0x9d94f4137a69390f0669fd6cb9be50ebeaf8d2d4',
    //         provider: getUrlProvider(detail.blockchain.chain_id),
    //     })
    //     const aggregates = addrList.map((addr:string) => {
    //         return useErc20Contract(addr, detail.blockchain.chain_id).methods.symbol()
    //     })
    //     const decimalsAggregates = addrList.map((addr:string) => {
    //         return useErc20Contract(addr, detail.blockchain.chain_id).methods.decimals()
    //     })
    //     // const symbolList = await multicall.aggregate(aggregates)
    //     // console.log('symbolList:', symbolList)
    //     // const decimalsList = await multicall.aggregate(decimalsAggregates)
    //     // console.log('decimalList:', decimalsList)
    //     const rs = await Promise.all([multicall.aggregate(aggregates), multicall.aggregate(decimalsAggregates)])
    //     const symbolList = rs[0]
    //     console.log('symbolList:', symbolList)
    //     const decimalsList = rs[1]
    //     console.log('decimalList:', decimalsList)
    //     // const symbolPromises = addrList.map((addr:string) => {
    //     //     return useErc20Contract(addr, detail.blockchain.chain_id).methods.symbol().call()
    //     // })
    //     // const decimalsPromises = addrList.map((addr:string) => {
    //     //     return useErc20Contract(addr, detail.blockchain.chain_id).methods.decimals().call()
    //     // })
    //     // const rs = await Promise.all([...symbolPromises, ...decimalsPromises])
    //     // const symbolList = [rs[0], rs[1]]
    //     // const decimalsList = [rs[2], rs[3]]
    //     // console.log('symbolList:', symbolList)
    //     // console.log('decimalList:', decimalsList)

    //     setTokenList(symbolList.map((item:string, index:number) => {
    //         return {
    //             addr: addrList[index],
    //             symbol: item,
    //             decimal: decimalsList[index]
    //         }
    //     }))
    // }

    // function getOTCAddr() {
    //     return useOTCContract(detail?.official_addr || '', detail?.blockchain.chain_id).methods.OTC_ADDRESS().call()
    // }

    async function getDecimal() {
        if (!detail) return
        console.log('otc_addr:', detail.token_addr)
        const otc_addr = await useOTCContract(detail.official_addr, detail.blockchain.chain_id).methods.OTC_ADDRESS().call()
        const data = await useErc20Contract(otc_addr, detail.blockchain.chain_id).methods.decimals().call()
        console.log('decimal:', data)
        setDecimal(data)
    }

    async function getUserAddr() {
        const accounts = await getWeb3().eth.getAccounts()
        setUserAddr(accounts[0])
    }

    async function initContractData() {
        getExpiredTime()
        // getSupports()
        getDecimal()
    }

    function getList() {
        getAggrements()
        getOffers()
    }

    // function onRefresh() {
    //     getOffers
    // }

    function initLimit() {
        if (!offers || !offers.length) return
        const list = offers.map(item => +item.costPerToken)
        setMax(Math.max(...list))
        setMin(Math.min(...list))
    }

    React.useEffect(() => {
        init()
        getUserAddr()
    }, [])

    React.useEffect(() => {
        initContractData()
    }, [detail])

    React.useEffect(() => {
        getList()
    }, [decimal])

    React.useEffect(() => {
        initLimit()
    }, [offers])

    return (
        <div className="trading-detail-page">
            <Header></Header>
            <div className="trading-detail-page__content">
                <div className="top">
                    <div className="top-wrap">
                        <InfoCard detail={detail} aggrements={aggrements} offers={offers}></InfoCard>
                        <div>
                            <Intro detail={detail}></Intro>
                            <div className="make-offer-wrap">
                                <div className="limit-wrap">
                                    <div className="line"></div>
                                    <div className="item">
                                        <div className="text1">Floor price</div>
                                        <div className="text2">{min}$</div>
                                    </div>
                                    <div className="item">
                                        <div className="text1">Highest price</div>
                                        <div className="text2">{max}$</div>
                                    </div>
                                </div>
                                <Button
                                className="make-offer-btn btn-1seed"
                                variant='contained'
                                onClick={() => loginInterceptor(handleMakeOffer)}
                                disabled={expiredTime > 0 && expiredTime * 1000 <= Date.now()}
                                >MAKE OFFER</Button>
                            </div>
                        </div>
                    </div>
                </div>
                {detail && <OfferList
                offers={offers}
                detail={detail}
                // symbol={symbol}
                userAddr={userAddr}
                onRefresh={getList}
                onRefreshData={getOffers}
                // tokenDecimal={tokenDecimal}
                ></OfferList>}
                {detail && <AggrementList
                aggrements={aggrements}
                detail={detail}
                expiredTime={expiredTime}
                // symbol={symbol}
                userAddr={userAddr}
                onRefreshData={getAggrements}
                // tokenDecimal={tokenDecimal}
                onRefresh={getList}
                ></AggrementList>}
            </div>
            <MakeOfferDialog
            open={open}
            onClose={() => setOpen(false)}
            detail={detail}
            // offers={offers}
            decimal={decimal}
            onRefresh={getOffers}
            userAddr={userAddr}
            min={min}
            max={max}
            ></MakeOfferDialog>
        </div>
    );
}
