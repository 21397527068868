import React from 'react'
import './index.scss'
import FormTextarea from 'components/common/form-textarea'
import { ProfileType } from 'api/user';
import { Button } from '@mui/material'
import {
    Comment,
    Reply,
    commentLike,
    commentUnlike,
    replyLike,
    replyUnlike,
    postReply,
    PostCommentResponse,
    getReply,
    electComment,
    electReply,
    rejectComment,
    rejectReply,
    deleteComment,
    deleteReply
} from 'api/community';
import { COMMENT_TYPE } from 'constant';
import { formatCommentTime } from 'utils/utils';
import Message from 'components/common/message';
import Loading from 'components/common/loading';
import { COMMENT_LABEL } from 'constant';
import Tips from 'components/tips';
import { USER_ROLE } from 'constant';
import { getDetail, InvestDetail } from 'api/finance';
import useLoginInterceptor from 'hooks/useLoginInterceptor';
// import { urlReg } from 'constant/form';
import { useNavigate } from 'react-router';
import { useDetectClickOutside } from 'react-detect-click-outside';


interface Props extends React.PropsWithChildren {
    profile: ProfileType|undefined
    comment?: Comment
    reply?: Reply
    isReply?: boolean
    targetText?: string
    onReply: (newReply:Reply) => void
    commentId?: string
    isProfileComment?: boolean
    noManagerOperate?: boolean
    onRefresh?: () => void
    projectId: string|undefined
    projectUserId: string|undefined
    id?: string
    isTargetHash?: boolean
}

const typeTextMap = {
    [COMMENT_TYPE.ANNOTATE]: 'annotate on',
    [COMMENT_TYPE.QUERY]: 'question on',
    [COMMENT_TYPE.SUGGEST]: 'suggest on'
}

export default function CommentBox(props:Props) {
    const { profile, isReply, comment, reply, targetText, onReply, commentId, isProfileComment, children, noManagerOperate, onRefresh, projectId, projectUserId, id = '', isTargetHash = false } = props

    const [showComment, setShowComment] = React.useState(false)
    const [data, setData] = React.useState(isReply ? reply : comment)
    const [content, setContent] = React.useState('')
    const [isManager, setIsManager] = React.useState(false)
    const [hideManagerOperate, setHideManagerOperate] = React.useState(false)

    const loginInterceptor = useLoginInterceptor()
    const navigate = useNavigate()

    const ref = useDetectClickOutside({ onTriggered: () => setShowComment(false) });

    function sliceTargetStr(str:string|undefined) {
        if (!str || !str.slice) return ''
        const len = isProfileComment ? 30 : 8
        return str.slice(0, len) + (str.length > len ? '...' : '')
    }

    async function handleClickLike() {
        if (!data) return
        try {
            if (isReply) {
                if (data.is_like) {
                    await replyUnlike({
                        reply_id: data.id || ''
                    })
                } else {
                    await replyLike({
                        reply_id: data.id || ''
                    })
                }
            } else {
                if (data?.is_like) {
                    await commentUnlike({
                        comment_id: data.id || ''
                    })
                } else {
                    await commentLike({
                        comment_id: data.id || ''
                    })
                }
            }
            setData(Object.assign({}, data, {
                is_like: !data.is_like,
                likes: data.is_like ? data.likes - 1 : data.likes + 1
            }))
        } catch (e:any) {
            console.log(e)
            Message(e.message || 'Error', {severity:'error'})
        }
    }

    async function handleClickComment() {
        if (!data) return
        Loading(true)
        try {
            const res:PostCommentResponse = await postReply({
                comment_id: isReply ? (commentId || '') : data.id,
                content: content,
                reply_to: data.user.id
            })
            setContent('')
            setShowComment(false)
            const newReply:Reply = await getReply({
                reply_id: res.id
            })
            // insert comment detail
            onReply(newReply)
        } catch (error:any) {
            console.log(error)
            Message(error.message || 'Reply error', {severity:'error'})
        } finally {
            Loading(false)
        }
    }

    async function onComment() {
        if (!showComment) {
            const _investDetail:InvestDetail = await getDetail({
                project_id: projectId || ''
            })
            const isInvested = +_investDetail.my_share > 0
            // const isInstitution = profile?.user_info.role === USER_ROLE.INSTITUTION
            // const isFamer = profile?.user_info.role === USER_ROLE.FAMER
            // const isManager = profile?.user_info.role === USER_ROLE.OFFICIAL
            const isSelfProject = projectUserId === profile?.id
            // if (!isInvested && !isInstitution && !isFamer && !isSelfProject) {
            if (!isInvested && !profile?.has_profile_nft && !isSelfProject && !isManager) {
                Message('Only investors can make a comment', {severity:'warning'})
                return
            }
        }
        setContent('')
        setShowComment(!showComment)
    }

    function refreshManagerOperate() {
        setHideManagerOperate(true)
        setTimeout(() => {
            setHideManagerOperate(false)
        })
    }

    async function handleReject() {
        refreshManagerOperate()
        try {
            if (isReply) {
                await rejectReply({
                    id: data?.id || ''
                })
            } else {
                await rejectComment({
                    id: data?.id || ''
                })
            }
            onRefresh && onRefresh()
            Message('Reject success')
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Reject failed', {severity: 'error'})
        }
    }

    async function handleHighlight() {
        refreshManagerOperate()
        try {
            if (isReply) {
                await electReply({
                    id: data?.id || ''
                })
            } else {
                await electComment({
                    id: data?.id || ''
                })
            }
            onRefresh && onRefresh()
            Message('Highlight success')
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Highlight failed', {severity: 'error'})
        }
    }

    async function handleDelete() {
        refreshManagerOperate()
        try {
            if (isReply) {
                await deleteReply({
                    id: data?.id || ''
                })
            } else {
                await deleteComment({
                    id: data?.id || ''
                })
            }
            onRefresh && onRefresh()
            Message('Delete success')
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Delete failed', {severity: 'error'})
        }
    }

    function generateCommentContent(content:string|undefined) {
        if (!content) return ''
        const reg = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/\S*)?/g
        return content.replace(reg, function(substring: string) {
            return `<a href="${substring}" target="_blank" class="comment-link">${substring}</a>`
        })
    }

    React.useEffect(() => {
        setData(isReply ? reply : comment)
    }, [comment, reply])

    React.useEffect(() => {
        setIsManager(profile?.user_info.role === USER_ROLE.OFFICIAL)
    }, [profile])

    return (
        <div className={`comment-detail-box
        ${data?.user.role === USER_ROLE.INSTITUTION ? 'institution' : ''}
        ${(data?.label === COMMENT_LABEL.ELECT) ? 'accept show-tag' : ''}
        ${(data?.label === COMMENT_LABEL.REJECTED) ? 'rejected show-tag' : ''}
        ${isTargetHash ? 'lighting' : ''}
        `}
        id={id}
        >
            <div className="tag-icon-wrap">
                {data?.label === COMMENT_LABEL.ELECT && <img src={require('assets/img/community/official-tag.png')} alt="" className="tag-icon" />}
                {data?.label === COMMENT_LABEL.REJECTED && <img src={require('assets/img/community/official-reject.png')} alt="" className="tag-icon" />}
                {/* {data?.user.role === USER_ROLE.INSTITUTION && (!(data?.label === COMMENT_LABEL.ELECT)) && (!(data?.label === COMMENT_LABEL.REJECTED)) && <img src={require('assets/img/community/institution-tag.png')} alt="" className="tag-icon" />} */}
                {/* <Tips text={(data?.label === COMMENT_LABEL.ELECT ? '1Seed Review' : '') || (data?.label === COMMENT_LABEL.REJECTED ? '1Seed Rejected' : '') || (data?.user.role === USER_ROLE.INSTITUTION ? 'Institutional' : '') || ''}></Tips> */}
                <Tips text={(data?.label === COMMENT_LABEL.ELECT ? '1Seed Review' : '') || (data?.label === COMMENT_LABEL.REJECTED ? '1Seed Rejected' : '') || ''}></Tips>
            </div>
            <div className="info">
                <img src={data?.user.profile_uri || require('assets/img/invest-detail/avatar-default.png')} onClick={() => navigate(`/profile?id=${data?.user.id}`)} alt="" className="avatar" />
                <div className="name">{data?.user.ens_name || data?.user.username}{data?.user.role === USER_ROLE.INSTITUTION && <img src={require('assets/img/identified.png')} alt="" className="identified-icon" />}</div>
                <div className="type-text">{!isReply ? (isProfileComment ? 'Comment on' : typeTextMap[comment?.type || 0]) : 'reply to'}</div>
                <div className="target-name">{sliceTargetStr(isReply ? (reply?.reply_to.ens_name || reply?.reply_to.username) : targetText)}</div>
            </div>
            <div className="desc"><span dangerouslySetInnerHTML={{
                __html: generateCommentContent(data?.content)
            }}></span>{children}</div>
            <div className="bottom">
                <div className="time">{formatCommentTime(data?.time)}</div>
                <div className='right'>
                    <div className="count" onClick={() => loginInterceptor(handleClickLike)}>
                        <img src={require(`assets/img/invest-detail/like${data?.is_like ? '-active' : ''}.png`)} alt="" className="icon" />
                        <div className="text">{data?.likes}</div>
                    </div>
                    <div className="count" onClick={onComment}>
                        <img src={require(`assets/img/invest-detail/comment${showComment ? '-active' : ''}.png`)} alt="" className="icon" />
                        {!isReply && <div className="text">{comment?.reply_cnt || 0}</div>}
                    </div>
                    {(isManager && !noManagerOperate && !hideManagerOperate) && <div className="count more-wrap">
                        <img src={require(`assets/img/community/operate-more.png`)} alt="" className="icon" />
                        <div className="operate">
                            <div className="item" onClick={handleDelete}>delete</div>
                            <div className="item" onClick={handleReject}>reject</div>
                            <div className="item" onClick={handleHighlight}>highlight</div>
                        </div>
                    </div>}
                </div>
            </div>
            {showComment && <div className="form-area" ref={ref}>
                <img src={profile?.user_info.profile_uri} alt="" className="avatar-form" />
                {isProfileComment && <span className="name-text">{profile?.user_info.ens_name || profile?.user_info.username}</span>}
                <div className="form-wrap">
                    <FormTextarea autoHeight max={300} className='form-field' placeholder='Add a comment' value={content} onChange={(v) => setContent(v)}>
                        <Button className='btn btn-1seed' variant='contained' onClick={handleClickComment}>Comment</Button>
                    </FormTextarea>
                </div>
            </div>}
        </div>
    )
}