import React from 'react';
import { socialBind } from 'api/user';
import { applySocialBind, SocialBindResponse } from 'api/apply';
import qs from 'qs'
import { useLocation } from 'react-router';
// import Message from 'components/common/message';
import { getSocialAuthBC } from 'hooks/useAuthSocialAccount';


export default function OAuth() {
    
    const location = useLocation()

    async function auth() {
        if (location.search) {
            const query = qs.parse(location.search.slice(1))
            const state = query.state as string
            if (!state) {
                return
            }
            // const idx = state.indexOf('$')
            // if (!(idx >= 1)) return
            // const social_name = state.substring(0, idx)
            // const project_id = state.substring()
            // const redirect_uri = state.substring(idx + 1, state.length)
            const [social_name, project_id] = state.split('$')
            try {
                let data:SocialBindResponse
                if (project_id) {
                    data = await applySocialBind({
                        code: query.code as string,
                        social_name: social_name,
                        project_id
                    })
                } else {
                    data = await socialBind({
                        code: query.code as string,
                        social_name: social_name,
                        // redirect_uri: `${window.location.origin + window.location.pathname}?state=${social_name}$${encodeURIComponent(redirect_uri)}`
                    })
                }
                console.log('123128731283', data)
                getSocialAuthBC().postMessage({
                    ...data,
                    success: true,
                    target: 'auth_1seed'
                })
                // window.location.replace(redirect_uri || '/')
            } catch (e:any) {
                console.log(e)
                getSocialAuthBC().postMessage({
                    e,
                    success: false,
                    target: 'auth_1seed'
                })
            } finally {
                window.close()
            }
        } else {
            getSocialAuthBC().postMessage({
                success: false,
                target: 'auth_1seed'
            })
            window.close()
            // navigate('/', { replace: true })
        }
    }

    React.useEffect(() => {
        auth()
    }, [])

  return (
    <></>
  );
}
