import React from 'react'
import { InvestInfo, InvestDetail } from 'api/finance'
import './index.scss'
// import InsightBox from './box';
import { applyConfig, ApplyConfigResponse, Insight } from 'api/apply';
import { ProfileType } from 'api/user';
import { GetTagsResponse, getTags, Tag, SliceWeight } from 'api/community';
import ExpandTextBox from 'components/expand-text-box';
import CheckText from './check-text';

interface Props {
    investInfo: InvestInfo
    profile: ProfileType|undefined
    onRefresh: () => void
    refreshFlag: boolean
    investDetail: InvestDetail
}

export default function ProjectInsights(props:Props) {
    const { investInfo, profile, onRefresh, refreshFlag, investDetail } = props
    const [insights, setInsights] = React.useState<Insight[]>([])
    const [tags, setTags] = React.useState<Tag[]>([])
    const [sliceWeights, setSliceWeights] = React.useState<SliceWeight[]>([])
    const [config, setConfig] = React.useState<ApplyConfigResponse>()

    async function getConfig() {
        const data:ApplyConfigResponse = await applyConfig({
            project_id: investInfo.project_id
        })
        // setInsights(data.insights)
        setConfig(data)
    }

    async function getTagsData() {
        const data:GetTagsResponse = await getTags({
            project_id: investInfo.project_id
        })
        setTags(data.tags || [])
        setSliceWeights(data.slice_weights || [])
    }

    // function generateOverviewStr() {
    //     return config?.overview?.map((item, index) => {
    //         return item + ' ' + (investInfo.overview[index] || '')
    //     })
    // }
    
    React.useEffect(() => {
        if (investInfo && investInfo.project_id) {
            getConfig()
        }
    }, [])

    React.useEffect(() => {
        getTagsData()
    }, [refreshFlag])
    return (
        <div className="project-insights">
            {/* <div className="overview-box">
                <div className="num">Your 10-second elevator pitch</div>
                <div className="text">{generateOverviewStr()}</div>
            </div> */}
            {/* {investInfo.insights?.map((item, index) => {
                return (
                    <InsightBox
                    insight={insights[index]}
                    answer={item}
                    index={index}
                    key={`insight_${index}`}
                    investInfo={investInfo}
                    profile={profile}
                    onRefresh={onRefresh}
                    tags={tags}
                    investDetail={investDetail}
                    ></InsightBox>
                )
            })} */}
            <div className="project-insights__title">Project insights</div>
            <ExpandTextBox title='Highlights' index={0} expand={true} className='highlights-box'>
                {investInfo.highlights?.filter(d => d).map((item, index) => {
                    return (
                        <div className="highlights-content" key={`highlights_${index}`}>
                            <div className="highlights-num">{index + 1}.</div>
                            <div className="highlights-text">{item}</div>
                        </div>
                    )
                })}
            </ExpandTextBox>
            <ExpandTextBox title='Pitch' index={1} expand={true}>
                <CheckText
                text={investInfo.pitch || ''}
                investInfo={investInfo}
                profile={profile}
                onRefresh={onRefresh}
                tags={tags}
                sliceWeights={sliceWeights}
                investDetail={investDetail}
                ></CheckText>
                {/* <div dangerouslySetInnerHTML={{__html: investInfo.pitch || ''}}></div> */}
            </ExpandTextBox>
        </div>
    )
}