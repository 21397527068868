import Login from 'components/login'
import React from 'react'
import { isLogin } from 'utils/auth'
import { createRoot } from 'react-dom/client'

type Options = {
    loginType?: string
}

export default function useLoginInterceptor() {

    function LoginInterceptor(props?:Options) {
        const [open, setOpen] = React.useState(true)
    
        return (
            <Login open={open} onClose={() => setOpen(false)} loginType={props?.loginType || ''}></Login>
        )
    
    }
    
    let root:any
    
    const id = 'login-1seed'
    
    return function loginInterceptor(cb?: any, options?:Options) {
        if (isLogin()) {
            cb && cb()
        } else {
        
            let rootDom = document.getElementById(id)
            if (!rootDom) {
                rootDom = document.createElement('div')
                rootDom.setAttribute('id', id)
                document.body.appendChild(rootDom)
            }

            if (root && root._internalRoot) {
                root.unmount()
            }

            root = createRoot(rootDom)

            const loginDom = <LoginInterceptor loginType={options?.loginType || ''}></LoginInterceptor>
            root && root._internalRoot && root.render(loginDom);
        }
    }

}