import React from 'react';
import Dialog from '@mui/material/Dialog';
import closeIcon from '../../../../assets/img/close.png'
import './index.scss'
import { Score } from '../../../../api/user';
import dayjs from 'dayjs'
import facebook from '../../../../assets/img/profile-info/facebook.png'
import twitter from '../../../../assets/img/profile-info/twitter.png'
import github from '../../../../assets/img/profile-info/github.png'
import linkedin from '../../../../assets/img/profile-info/linkedin.png'
import mirror from '../../../../assets/img/profile-info/mirror.png'
import instagram from '../../../../assets/img/profile-info/instagram.png'
import discord from '../../../../assets/img/profile-info/discord.png'
import telegram from '../../../../assets/img/profile-info/telegram.png'

type Props = {
    open: boolean,
    onClose: () => void,
    list: Array<Score>
}

export default function ScoreListDialog(props: Props) {
    const { onClose, list } = props

    function getImg(desc:string) {
        if (desc.toLocaleLowerCase().indexOf('facebook') > -1) {
            return facebook
        }
        if (desc.toLocaleLowerCase().indexOf('twitter') > -1) {
            return twitter
        }
        if (desc.toLocaleLowerCase().indexOf('github') > -1) {
            return github
        }
        if (desc.toLocaleLowerCase().indexOf('linkedin') > -1) {
            return linkedin
        }
        if (desc.toLocaleLowerCase().indexOf('mirror') > -1) {
            return mirror
        }
        if (desc.toLocaleLowerCase().indexOf('instagram') > -1) {
            return instagram
        }
        if (desc.toLocaleLowerCase().indexOf('discord') > -1) {
            return discord
        }
        if (desc.toLocaleLowerCase().indexOf('telegram') > -1) {
            return telegram
        }
    }

    return (
        <Dialog {...props}>
            <div className="score-list-dialog">
              <div className="title">My scores</div>
              <img src={closeIcon} alt="" className="close" onClick={onClose} />
              <div className="list-wrap">
                {list?.map((item, index) => {
                    return (
                        <div className="item" key={index}>
                            <img src={getImg(item.desc)} alt="" className='avatar' />
                            <div className="info">
                                <div className="name">{item.desc}</div>
                                <div className="count">{dayjs(item.create_time * 1000).format('YYYY.M.DD HH:mm:ss')}</div>
                            </div>
                            <div className="btn">
                                    +{item.score} Aseed
                            </div>
                        </div>
                    )
                })}
              </div>
            </div>
        </Dialog>
    )
}