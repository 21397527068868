import React from 'react'
import './index.scss'
import { InvestInfo, InvestDetail } from 'api/finance'
import { dealMetamaskAddress, formatProjectTitle } from 'utils/utils'
import { INVEST_STATUS } from 'constant'
import { useNavigate } from 'react-router'
import { useInvestmentContract } from 'utils/web3'
import { ProfileType } from 'api/user'
import { dealAmountDecimal } from 'utils/utils'
import { formatMoney } from 'utils/utils'
import defaultAvatar from 'assets/img/invest-detail/avatar-default.png'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Message from 'components/common/message'
import { CALL_RPC } from 'config/config'
import Tips from 'components/tips'
import ProjectTimer from 'components/project-timer'

interface Props {
    investInfo: InvestInfo,
    investDetail: InvestDetail,
    onRefresh: () => void,
    profile: ProfileType|undefined
}

const finStatusMap = {
    [INVEST_STATUS.FAILD]: {
        text: 'Failed',
        style: 'failed'
    },
    [INVEST_STATUS.INVESTOR]: {
        text: 'Success',
        style: 'success'
    },
    [INVEST_STATUS.FINANCING]: {
        text:'Token Financing',
        style: 'financing'
    },
    [INVEST_STATUS.PENDING]: {
        text: 'Pending',
        style: 'pending'
    }
}

let interval: NodeJS.Timer | undefined
let timer = 0

export default function ProjectCard(props: Props) {
    const { investInfo, investDetail, onRefresh, profile } = props
    const [endTime, setEndTime] = React.useState(-1)
    const [myShare, setMyShare] = React.useState('')
    const [amount, setAmount] = React.useState(0)

    const navigate = useNavigate()

    function getStyle() {
        return finStatusMap[investDetail.status] || {
            text: 'Token Financing',
            style: 'financing'
        }
    }

    function initTimer() {
        if (investDetail.status !== INVEST_STATUS.FINANCING) {
            return
        }
        const leftTime = investDetail.end_ts * 1000 - Date.now()
        if (leftTime <= 0) {
            return
        }
        setEndTime(leftTime)
        if (interval) {
            return
        }
        timer = leftTime
        setEndTime(leftTime)
        interval = setInterval(() => {
            if (timer - 1000 < 0) {
                onRefresh()
                clearInterval(interval)
                interval = undefined
                return
            }
            timer = timer - 1000
            setEndTime(timer)
        }, 1000)
    }

    async function initMyShare() {
        if (!profile?.user_info.wallet || !investDetail.invest_contract) return
        let data = 0
        const data1 = await useInvestmentContract(investDetail.invest_contract).methods.tokenIds(profile?.user_info.wallet).call()
        console.log('tokenIds', data1, data1.amounts)
        if (investDetail.status === INVEST_STATUS.INVESTOR || data1?.amounts?.length) {
            data1.amounts.forEach((item:string) => {
                data += +item
            })
        } else {
            const data2 = await useInvestmentContract(investDetail.invest_contract).methods.investorAmount(profile?.user_info.wallet).call()
            data = data2
            console.log('investorAmount', data2)
        }
        setMyShare(data + '')
    }

    function getDate(t:string) {
        if (investDetail.status !== INVEST_STATUS.FINANCING) {
            return '-'
        }
        if (endTime <= 0) {
            return '-'
        }
        if (t === 'd') {
            return Math.floor(endTime / (1000 * 60 * 60 * 24))
        }
        if (t === 'h') {
            return Math.floor(endTime / (1000 * 60 * 60) % 24)
        }
        if (t === 'm') {
            return Math.floor(endTime / (1000 * 60) % 60)
        }
        if (t === 's') {
            return Math.floor(endTime / (1000) % 60)
        }
    }

    async function getContractAmount() {
        // const chainId = await web3.eth.getChainId()
        const data = await useInvestmentContract(investDetail.invest_contract, undefined, CALL_RPC)
        .methods.investTotalAmount().call()
        // console.log('realTime amount:', data)
        console.log('realTime amount', data)
        if (data) {
            // setAmount(+((_.divide(data, Math.pow(10, investInfo.token_decimal))).toFixed(2)) || 0)
            setAmount(dealAmountDecimal(data, investInfo.token_decimal))
            return
        }
        setAmount(0)
    }

    function getMin() {
        return +((+investInfo.goal * (100 - investInfo.fin_rule) / 100).toFixed(2))
    }

    function getMax() {
        return +((+investInfo.goal * (100 + investInfo.fin_rule) / 100).toFixed(2))
    }

    React.useEffect(() => {
        initTimer()
        return () => {
            if (interval) {
                clearInterval(interval)
                interval = undefined
                timer = 0
            }
        }
    }, [])

    React.useEffect(() => {
        initMyShare()
    }, [profile])

    React.useEffect(() => {
        if (!investDetail.invest_contract) return
        getContractAmount()
        const _interval = setInterval(() => {
            getContractAmount()
        }, 5000)
        return () => {
            clearInterval(_interval)
        }
    }, [])

    // return (
    //     <div className={`project-card ${getStyle().style}`}>
    //         {/* <div className="project-card__bg">
    //             <div className="fin-time-wrap">
    //                 <div className="fin-time">
    //                     <div className="num">{getDate('d')}</div>
    //                     <div className="desc">DAYS</div>
    //                 </div>
    //                 <div className="fin-time">
    //                     <div className="num">{getDate('h')}</div>
    //                     <div className="desc">HRS</div>
    //                 </div>
    //                 <div className="fin-time">
    //                     <div className="num">{getDate('m')}</div>
    //                     <div className="desc">MINS</div>
    //                 </div>
    //                 <div className="fin-time">
    //                     <div className="num">{getDate('s')}</div>
    //                     <div className="desc">SECS</div>
    //                 </div>
    //             </div>
    //         </div> */}
    //         {/* <div className="project-card__status">{getStyle().text}</div> */}
    //         <div className="project-card__content">
    //             <div className="info">
    //                 <img src={investInfo.image_url} alt="" className="img" />
    //                 <div>
    //                     {investInfo.is_ido === 1 && <div className="ido-tag">IDO</div>}
    //                     <div className="text1">{formatProjectTitle(investInfo.name, investInfo.rounds)}</div>
    //                     <div className="text2">Proposed by:
    //                         <span className="sub">{investInfo.user_ens_name || investInfo.user_name}</span>
    //                         <img src={investInfo.user_avatar_url} alt="" className="avatar" onClick={() => navigate(`/profile?id=${investInfo.user_id}`)} />
                            
    //                     </div>
    //                     <div className="text2">Contract address: 
    //                         <span className="sub">{investDetail.invest_contract || 'pending'}</span>
    //                         {investDetail.invest_contract && <CopyToClipboard text={investDetail.invest_contract} onCopy={() => Message('copy success!')}>
    //                             <img src={require('assets/img/copy.png')} alt="" className="icon" />
    //                         </CopyToClipboard>}
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="info-content">
    //                 <div className="item">
    //                     <div className="text">Valuation</div>
    //                     <div className={`text2 ${!investInfo.valuation && 'small'}`}>{investInfo.valuation > 0 ? `${formatMoney(investInfo.valuation)}$` : `${investInfo.percent}% of the latest round of valuation in the future.`}</div>
    //                 </div>
    //                 <div className="item">
    //                     <div className="text">Financing goal</div>
    //                     <div className="text2">{investInfo.goal} {investInfo.token_symbol}</div>
    //                 </div>
    //                 <div className="item">
    //                     <div className="text">My shares</div>
    //                     <div className="text2">{dealAmountDecimal(myShare, investInfo.token_decimal) || investDetail.my_share || 0} {investInfo.token_symbol}</div>
    //                 </div>
    //                 {/* <div className="item">
    //                     <div className="text">Institutional investment</div>
    //                     <div className="list">
    //                         {investDetail.institutions?.map(item => {
    //                             return (
    //                             <div className="list-item" key={`institutions_${item.user_id}`}>
    //                                 <img src={item.profile_uri} alt="" className="box" />
    //                                 <div className="text">{item.name}</div>
    //                             </div>
    //                             )
    //                         })}
    //                     </div>
    //                 </div>
    //                 <div className="item">
    //                     <div className="text">Community Famer</div>
    //                     <div className="list">
    //                         {investDetail.famers?.map(item => {
    //                             return (
    //                             <div className="list-item" key={`famers_${item.user_id}`}>
    //                                 <img src={item.profile_uri} alt="" className="box" />
    //                                 <div className="text">{item.name}</div>
    //                             </div>
    //                             )
    //                         })}
    //                     </div>
    //                 </div> */}
    //             </div>
    //             <div className="info-content user-content">
    //                 <div className={`item ${investDetail.institutions?.length > 0 ? '' : 'empty'}`}>
    //                     <div className="text">Institutional</div>
    //                     <div className="list">
    //                         {investDetail.institutions?.slice(0, 5).map(item => {
    //                             return (
    //                             // <div className="list-item" key={`institutions_${item.user_id}`}>
    //                             //     <img src={item.profile_uri} alt="" className="box" />
    //                             //     {/* <div className="text">{item.name}</div> */}
    //                             // </div>
    //                             <div className="avatar-wrap" key={`institutions_${item.user_id}`}>
    //                                 <img src={item.profile_uri} alt="" className="avatar" onClick={() => navigate(`/profile?id=${item.user_id}`)} title={item.name} />
    //                             </div>
    //                             )
    //                         })}
    //                         {!investDetail.institutions?.length && <div className="avatar-wrap">
    //                             <img src={(require('assets/img/invest-detail/avatar-empty.png'))} alt="" className="avatar" />
    //                         </div>}
    //                     </div>
    //                     <div className="text3">{!investDetail.institutions?.length ? 'No institutional investment yet' : (investDetail.institutions.length === 1 ? '' : `+${investDetail.institutions.length} Institution`)}</div>
    //                 </div>
    //                 <div className={`item ${investDetail.famers?.length > 0 ? '' : 'empty'}`}>
    //                     <div className="text">KOL</div>
    //                     <div className="list">
    //                         {investDetail.famers?.slice(0, 5).map(item => {
    //                             return (
    //                             // <div className="list-item" key={`famers_${item.user_id}`}>
    //                             //     <img src={item.profile_uri} alt="" className="box" />
    //                             //     {/* <div className="text">{item.name}</div> */}
    //                             // </div>
    //                                 <div className="avatar-wrap" key={`famers_${item.user_id}`}>
    //                                     <img src={item.profile_uri} alt="" className="avatar" onClick={() => navigate(`/profile?id=${item.user_id}`)} title={item.name} />
    //                                 </div>
    //                             )
    //                         })}
    //                         {!investDetail.famers?.length && <div className="avatar-wrap">
    //                             <img src={(require('assets/img/invest-detail/avatar-empty.png'))} alt="" className="avatar" />
    //                         </div>}
    //                     </div>
    //                     <div className="text3">{!investDetail.famers?.length ? 'No KOL yet' : (investDetail.famers.length === 1 ? '' : `+${investDetail.famers.length} KOLs`)}</div>
    //                 </div>
    //                 <div className={`item ${investDetail.time_investors?.length > 0 ? '' : 'empty'}`}>
    //                     <div className="text">CONTRIBUTORS</div>
    //                     <div className="list">
    //                         {investDetail.time_investors?.slice(0, 5).map(item => {
    //                             return (
    //                                 <div className="avatar-wrap" key={`investors_${item.user_id}`}>
    //                                     <img src={item.profile_uri || defaultAvatar} alt="" className="avatar" onClick={() => navigate(`/profile?id=${item.user_id}`)} title={item.name} />
    //                                 </div>
    //                             )
    //                         })}
    //                         {!investDetail.time_investors?.length && <div className="avatar-wrap">
    //                             <img src={(require('assets/img/invest-detail/avatar-empty.png'))} alt="" className="avatar" />
    //                         </div>}
    //                     </div>
    //                     <div className="text3">{!investDetail.time_investors?.length ? 'No investors yet' : (investDetail.time_investors.length === 1 ? '' : `+${investDetail.invest_cnt} investors`)}</div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // )
    return (
        <div className={`project-card ${getStyle().style}`}>
            {getStyle().style === 'financing' && <ProjectTimer timestamp={investDetail.end_ts}></ProjectTimer>}
            <div className="img-wrap">
                <img src={investInfo.image_url} alt="" className="img" />
            </div>
            <div className="text-wrap">
                <div className="item">
                    <div className='item-wrap'>
                        <div className="text1">VALUATION:</div>
                        <div className={`text2 ${!investInfo.valuation && 'small'}`}>{investInfo.valuation > 0 ? `${formatMoney(investInfo.valuation)}$` : `${investInfo.percent}% of the latest round of valuation in the future.`}</div>
                    </div>
                </div>
                <div className="item">
                    <div className='item-wrap'>
                        <div className="text1">GOAL:</div>
                        <div className="text2">{investInfo.goal} {investInfo.token_symbol}</div>
                    </div>
                </div>
                <div className="item">
                    <div className='item-wrap'>
                        <div className="text1">REAL-TIME:</div>
                        <div className="text2">{amount} {investInfo.token_symbol}</div>
                    </div>
                </div>
            </div>
            <div className="fin-wrap">
                <div className="progress-wrap">
                    <div className="fin-progress">
                        <div className="progress"
                            style={{
                                width: `${(+amount / getMax()) * 100}%`
                            }}></div>
                        <div className="total" style={{
                            left: `${+investInfo.goal / getMax() * 100}%`
                        }}></div>
                    </div>
                    <span className="info-icon-wrap">
                        <img src={require('assets/img/invest-detail/info2.png')} alt="" className="icon" />
                        <Tips text={`Before the end of the time, the completion of no less than ${100 - investInfo.fin_rule}% of the financing target, no higher than ${100 + investInfo.fin_rule}% means that the financing is successful`}></Tips>
                    </span>
                </div>
                <div className="text">
                    Minimum raised <span className="sub">{getMin()}{investInfo.token_symbol}</span>, Hardcap is <span className="sub">{getMax()}{investInfo.token_symbol}</span>.</div>
                </div>
        </div>
    )
}