import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';

interface Props {
    onClose: () => void
    open: boolean
}

export default function GuideTips(props:Props) {
    const { open, onClose } = props

    return (
        <Dialog open={open} onClose={onClose} className="guide-tips-dialog">
            {/* <div className="guide-tips-dialog"> */}
                <div className="content">
                    <div className="title">Guide Tips</div>
                    <div className="desc">{`Congratulations on passing the platform's project financing review, please complete the financing contract deployment, and start financing after completion.`}</div>
                    <div className="btn-wrap">
                        <div className="left" onClick={onClose}>Get it!</div>
                        <div className="right" onClick={onClose}>Hide the tips</div>
                    </div>
                    <img src={require('assets/img/invest-detail/arrow-tips.png')} alt="" className="icon" />
                </div>
            {/* </div> */}
        </Dialog>
    )
}