import React, { ChangeEvent } from 'react'
import './index.scss'
import { formatMoney } from 'utils/utils'

//  extends React.PropsWithChildren<InputHTMLAttributes<HTMLInputElement>>
interface Props extends React.PropsWithChildren{
    prefix?: string,
    placeholder?: string,
    onChange?: (v:string) => void,
    className?: string,
    value?: string,
    disabled?: boolean,
    rule?: RegExp,
    textPrefix?: string,
    errorText?: string,
    error?: boolean
    errorTextPositionStatic?: boolean
    autoWidth?: boolean
    onBlur?: () => void
}
export default function FormInput(props:Props) {
    const {
        prefix = '',
        placeholder = '',
        onChange,
        className = '',
        value = '',
        disabled = false,
        rule,
        textPrefix = '',
        errorText = '',
        error = false,
        errorTextPositionStatic = false,
        autoWidth = false,
        children,
        onBlur
    } = props

    const isNumberInput = () => {
        return rule && (rule.toString() === '/^\\d*$/' || rule.toString() === '/^\\d{0,12}$/')
    }

    const handleChange = (e:ChangeEvent<HTMLInputElement>) => {
        if (!e) return
        let rs = e.target.value || ''
        // console.log(rs)
        if (isNumberInput()) {
            rs = rs.replaceAll(',', '')
            // console.log(rs)
            // rs = rs.replaceAll(/\d{3}/, function(str) {
            //     return str + ','
            // })
        }
        if (textPrefix) {
            rs = textPrefix + rs
        }
        if (rule) {
            if (!rule.test(rs)) {
                e.target.value = value || ''
                rs = value || ''
            }
        }
        onChange && onChange(rs)
        if (autoWidth) {
            // console.log(e.target.scrollWidth)
            e.target.style.width = e.target.scrollWidth + 'px'
        }
     }

     const getValue = () => {
        // props.textPrefix ? props.value?.replace(props.textPrefix, '') : props.value
        if (!value) {
            return ''
        }
        // console.log(props.rule && props.rule.toString())
        if (textPrefix) {
            return value?.replace(textPrefix, '')
        }
        if (isNumberInput()) {
            return formatMoney(value)
        }
        return value
     }

    return (
        <div className="form-input-wrap">
            {prefix && <div className='prefix-wrap'>
                <img src={prefix} alt="" className="prefix" />
            </div>}
            {/* {props.prefixnode && props.prefixnode()} */}
            {children}
            <input
            className={`form-input ${className || ''} ${error ? 'error' : ''}`}
            type='text'
            onChange={handleChange}
            value={getValue()}
            disabled={disabled}
            placeholder={placeholder}
            onBlur={() => onBlur && onBlur()}
            />
            {error && <div className={`error-text ${errorTextPositionStatic ? 'static' : ''}`}>{errorText}</div>}
        </div>
    )
}