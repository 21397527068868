import React from 'react';
import './index.scss';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/material'
import { Recommend } from 'api';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from "swiper";
// Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";
import RecommendBox from './box';
import { useNavigate, useNavigation } from 'react-router';

interface Props {
	list: Recommend[]
}

// function gaussianBlur(pixels: Uint8ClampedArray, width: number, height: number, radius: number): Uint8ClampedArray {
// 	radius = Math.floor(radius) || 1;
  
// 	const len = radius * 2 + 1;
// 	const weights = new Float32Array(len);
// 	let sum = 0;
// 	let index = 0;
  
// 	for (let i = -radius; i <= radius; i++) {
// 	  const weight = Math.exp(-(i * i) / (2 * radius * radius));
// 	  weights[index] = weight;
// 	  sum += weight;
// 	  index++;
// 	}
  
// 	for (let i = 0; i < len; i++) {
// 	  weights[i] /= sum;
// 	}
  
// 	const tempPixels = new Uint8ClampedArray(pixels.length);
  
// 	for (let i = 0; i < height; i++) {
// 	  for (let j = 0; j < width; j++) {
// 		let r = 0;
// 		let g = 0;
// 		let b = 0;
// 		let a = 0;
  
// 		for (let k = -radius; k <= radius; k++) {
// 		  const x = j + k;
  
// 		  if (x >= 0 && x < width) {
// 			const offset = (i * width + x) * 4;
// 			const weight = weights[k + radius];
  
// 			r += pixels[offset] * weight;
// 			g += pixels[offset + 1] * weight;
// 			b += pixels[offset + 2] * weight;
// 			a += pixels[offset + 3] * weight;
// 		  }
// 		}
  
// 		const offset = (i * width + j) * 4;
  
// 		tempPixels[offset] = r;
// 		tempPixels[offset + 1] = g;
// 		tempPixels[offset + 2] = b;
// 		tempPixels[offset + 3] = a;
// 	  }
// 	}
  
// 	for (let i = 0; i < height; i++) {
// 	  for (let j = 0; j < width; j++) {
// 		let r = 0;
// 		let g = 0;
// 		let b = 0;
// 		let a = 0;
  
// 		for (let k = -radius; k <= radius; k++) {
// 		  const y = i + k;
  
// 		  if (y >= 0 && y < height) {
// 			const offset = (y * width + j) * 4;
// 			const weight = weights[k + radius];
  
// 			r += tempPixels[offset] * weight;
// 			g += tempPixels[offset + 1] * weight;
// 			b += tempPixels[offset + 2] * weight;
// 			a += tempPixels[offset + 3] * weight;
// 		  }
// 		}
  
// 		const offset = (i * width + j) * 4;
  
// 		pixels[offset] = r;
// 		pixels[offset + 1] = g;
// 		pixels[offset + 2] = b;
// 		pixels[offset + 3] = a;
// 	  }
// 	}
  
// 	return pixels;
//   }

// function draw() {
// const canvas = document.getElementById("canvas") as HTMLCanvasElement;
// if (!canvas) {
// 	return
// }
// const ctx = canvas.getContext("2d");
// if (!ctx) return

// 
// const image = new Image();
// image.src = require('assets/img/index/banner.png');
// image.width = 1464
// image.height = 940
// // const div = document.createElement('div')
// // const div = new HTMLDivElement()
// // div.append(image)
// // div.style.backgroundColor = `rgba(255,255,255,0.5)`
// // div.style.width = '1464px'
// // div.style.height = '940px'
// image.onload = () => {
// 	canvas.width = 1464;
// 	canvas.height = 940;
// 	ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

// 	
// 	const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

// 	
// 	const blurredData = gaussianBlur(imageData.data, imageData.width, imageData.height, 150);

// 
// 	const blurredImage = new ImageData(blurredData, imageData.width, imageData.height);
// 	ctx.putImageData(blurredImage, 0, 0);
// };
// }

export default function TopCard(props: Props) {
	const { list = [] } = props
	const [currentIndex, setCurrentIndex] = React.useState(0)

	const navigate = useNavigate()

	function handleChange(idx:number) {
		if (idx < 0 || idx >= list.length) {
			return
		}
	}


	return <div className="card-container">
		
		<div className="card-container__left">
			<div className="title">
				<div className="sub">1SeedDAO:</div>
				On-chain fundraising <br/> for startups
			</div>
			<div className="text">1SeedDAO uses smart contracts, NFT credentials, and DAO structures to help startups achieve fully automated and secure fundraising. Raise $10,000 to over $1 million from hundreds of customers, fans, and friends, all in a single entity with DAO, recorded as a single line on your investment table.</div>
			<Button className="btn btn-1seed" variant='contained' onClick={() => navigate('/explore')}>EXPLORE NOW</Button>
		</div>
		<RecommendBox list={list}></RecommendBox>
		{/* {list?.length > 1 && <div className="card-container__pager">
			<div className={`item left ${currentIndex <= 0 ? 'disabled' : ''}`} onClick={() => handleChange(currentIndex - 1)}>
				<img src={require('assets/img/index/arrow-right.png')} alt="" className="icon" />
			</div>
			<div className={`item ${currentIndex >= list.length - 1 ? 'disabled' : ''}`} onClick={() => handleChange(currentIndex + 1)}>
				<img src={require('assets/img/index/arrow-right.png')} alt="" className="icon" />
			</div>
		</div>} */}
	</div>

	// React.useEffect(() => {
	// 	draw()
	// }, ['mount'])
	// React.useEffect(() => {
	// 	draw()
	// }, ['update'])
	// return (
	// 		<div className={'card-container'}>
	// 			{/* <canvas id='canvas' style={{
	// 				position: 'absolute',
	// 				top: '-212px',
	// 				left: 0,
	// 				right: 0,
	// 				zIndex: 0,
	// 				bottom: 0,
	// 				width: '100%',
	// 				height: '697px'
	// 			}}></canvas> */}
	// 			<Swiper
	// 			navigation={true}
	// 			modules={[Navigation]}
	// 			onSlideChange={(v:any) => setCurrentIndex(v.activeIndex)}
	// 			>
	// 				{list.map((item, index) => {
	// 					return (
	// 						<SwiperSlide key={`recommand_${index}`}>
	// 							<div className="card-container__item">
	// 								<div className="card-container__wrap" style={{
	// 									backgroundImage: `url(${require(`assets/img/index/recommend/${currentIndex}/bg.png`)})`
	// 								}}>
	// 									<RecommendBox currentIndex={currentIndex} data={item}></RecommendBox>
	// 								</div>
	// 							</div>
	// 						</SwiperSlide>
	// 					)
	// 				})}
	// 			</Swiper>
				
	// 			{/* <div className="card-container__pager">
	// 				<Button className="btn" disabled={currentIndex <= 0} onClick={() => setCurrentIndex(currentIndex - 1)}>
	// 					<ArrowBackIosIcon className='icon' />
	// 				</Button>
	// 				<Button className="btn" disabled={currentIndex >= list.length - 1} onClick={() => setCurrentIndex(currentIndex + 1)}>
	// 					<ArrowForwardIosIcon className='icon' />
	// 				</Button>
	// 			</div> */}
	// 		</div>
	// )
}