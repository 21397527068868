import React, { ChangeEvent } from 'react'
import axios from 'axios'
import Message from '../message'
import { presignUrl } from 'api/utility'
import { uploadFile } from 'utils/upload'

type Props = {
    id?:string,
    accept?:string,
    hidden?:boolean,
    onChange:(url:string, filename?:string) => void,
    // 0 - user profile, 1 project image, 2 project logo, 3 project whitepaper, 4 project other file, 5 proj recommand, 6 user cover
    uploadType:number,
    onProgress?: (progressing: boolean) => void
}

export const spliceFileName = (url:string) => {
    if (!url) return ''
    const idx = url.lastIndexOf('/')
    const str = url.substring(idx, url.length)
    const [str1, ...arr] = str.split('-')
    const name = arr.join('-')
    return name || ''
}

export default function UploadInput(props:Props) {

    const {
        id = 'upload',
        accept = '*',
        hidden = true,
        onChange,
        uploadType,
        onProgress
    } = props

    async function onUpload(e:ChangeEvent<HTMLInputElement> | undefined) {
        if (!e) return
        // onProgress && onProgress(true)
        const file = e.target.files && e.target.files[0]
        console.log(file)
        e.target.value = ''
        uploadFile(file, {
            type: uploadType
        }, {
            onChange,
            onProgress,
            onError: (e:any) => Message(e?.message || 'Upload faild', { severity: 'error' })
        })
        // if (!file) {
        //     return
        // }
        // try {
        //     const { url } = await presignUrl({
        //         type: uploadType,
        //         length: file.size,
        //         postfix: file.name.substring(file.name.lastIndexOf('.') + 1)
        //         // filename: file.name
        //     })
        //     await axios({
        //         url,
        //         method: 'put',
        //         data: file,
        //         headers: {
        //             'x-amz-acl': 'public-read'
        //         }
        //     })
        //     onChange(url.substring(0, url.indexOf('?')) + `?t=${Date.now()}`, file.name)
        // } catch (e:any) {
        //     Message(e?.message || 'Upload faild', { severity: 'error' })
        // } finally {
        //     onProgress && onProgress(false)
        // }
    }

   return (<input
     type={'file'}
     accept={accept}
     id={id}
     onChange={onUpload}
     hidden={hidden}
    />)
}
