import React from 'react';
import Header from '../../../components/header';
import ProfileInfo from '../components/info';
// import { Outlet } from 'react-router';
import { INVEST_STATUS, PROFILE_TABS } from 'constant';
import Invested from '../components/invested';
import Comment from '../components/comment';
import Watchlist from '../components/watchlist';
import Apply from '../components/apply';
import './index.scss'
// import useFixedHeader from 'hooks/useFixedHeader';
import qs from 'qs'
import { useLocation } from 'react-router';
import { ProfileType, getProfile } from 'api/user';
import { useNavigate } from 'react-router';
import { isLogin } from 'utils/auth';

export default function Profile() {

  const location = useLocation()

  // const navigation = useNavigation()

  const [tabType, setTabType] = React.useState(PROFILE_TABS.INVESTED)
  const [target, setTarget] = React.useState(getTarget())
  // to refresh page
  // const [showFlag, setShowFlag] = React.useState(true)
  const [profile, setProfile] = React.useState<ProfileType>()

  // const [isFixedHeader, setIsFixedHeader] = React.useState(false)

  const navigate = useNavigate()

  // const [isFixedHeader] = useFixedHeader()

  // function handleClickTab(t:string) {
  //   setTabType(t)
  // }

  function getTarget() {
    if (location.search) {
      const query = qs.parse(location.search.slice(1))
      return query.id as string || ''
    }
    return ''

  }

  function getTabType() {
    if (location.search) {
      const query = qs.parse(location.search.slice(1))
      return query.tab as string || PROFILE_TABS.INVESTED
    }
    return PROFILE_TABS.INVESTED
  }

  async function getProfileData() {
    const data:ProfileType = await getProfile({})
    setProfile(data)
    if(getTarget() === data.id) {
      navigate('/profile', {
        replace: true
      })
    }
  }

  React.useEffect(() => {
    if (!isLogin()) return
    getProfileData()
  }, [])

  React.useEffect(() => {
    setTarget(getTarget())
    // setShowFlag(false)
    // setTimeout(() => {
    //   setShowFlag(true)
    // })
    setTabType(getTabType())
  }, [location])

  return (
    <div className='profile'>
      <Header></Header>
      <div className={`profile-container`}>
        {/* <ProfileInfo handleClickTab={handleClickTab} target={target} /> */}
        <ProfileInfo target={target} tabType={tabType} selfProfile={profile} />
        {/* <Outlet></Outlet> */}
        {tabType === PROFILE_TABS.INVESTED && <Invested target={target} />}
        {tabType === PROFILE_TABS.COMMENTS && <Comment target={target} profile={profile} />}
        {tabType === PROFILE_TABS.WATCHLIST && <Watchlist target={target} />}
        {tabType === PROFILE_TABS.APPLY && <Apply target={target} />}
      </div>
    </div>
  );
}
