import React from 'react';
import './index.scss'
import { Trending } from 'api/explore';
import { Box, Grid } from '@mui/material';
import Tips from 'components/tips';
import Empty from 'components/empty';
import _ from 'lodash'
import { watch, unwatch } from 'api/finance';
import Message from 'components/common/message';
import { useNavigate } from 'react-router';
import useLoginInterceptor from 'hooks/useLoginInterceptor';
// import { follow } from 'api/user';

type PropsType = {
    list: Trending[],
    onRefresh: () => void
}

export default function TrendingList(props: PropsType) {

  const { list, onRefresh } = props

  const navigate = useNavigate()

  const loginInterceptor = useLoginInterceptor()

  async function handleClickStar(item:Trending, event:any) {
    event.stopPropagation()
    loginInterceptor(async () => {
        try {
            if (item.has_watched) {
                await unwatch({
                    project_id: item.project_id
                })
            } else {
                await watch({
                    project_id: item.project_id
                })
            }
            onRefresh()
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Failed', {severity:'error'})
        }
    })
  }

  function caculateProgress(amount: string, goal: string) {
    return +(_.divide(+amount, +goal) * 100).toFixed(2)
  }

  return (
    <div className="trending-list">
        <Box sx={{ flexGrow: 1 }}>
            {!!list?.length && <Grid  container spacing={0} className="card-head">
                <Grid item xs={1} className={'center'}>
                    <div className="text">NO.</div>
                </Grid>
                <Grid item xs={3}>
                    <div className="text">PROJECT</div>
                </Grid>
                <Grid item xs={4}>
                    <div className="text">PROGRESS</div>
                </Grid>
                <Grid item xs={2}>
                    <div className="text">FINANCING GOAL</div>
                </Grid>
                <Grid item xs={1}>
                    <div className="text">INVESTORS</div>
                </Grid>
                <Grid item xs={1} className={'center'}></Grid>
            </Grid>}
            {list?.map((item, index) => {
                return (
                    <div key={`trending_${index}`} onClick={() => navigate(`/invest-detail/${item.project_id}`)}>
                        <Grid  container spacing={0} className="card-body">
                            <Grid item xs={1} className={'center'}>
                                <div className="num">{index + 1}</div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="project">
                                    <img src={item.logo_url} alt="" className="logo" />
                                    <div className="project-title" title={item.project_name}>{item.project_name}</div>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div className={`fin-progress ${caculateProgress(item.fin_amount, item.goal) >= 100 ? 'full' : ''}`}>
                                    <div className="progress"
                                        style={{
                                            width: `${caculateProgress(item.fin_amount, item.goal) / 150 * 100}%`
                                        }}></div>
                                    <div className="total"></div>
                                    <div className="progress-text"
                                    style={{
                                        width: `${caculateProgress(item.fin_amount, item.goal) / 150 * 100}%`
                                    }}>{caculateProgress(item.fin_amount, item.goal)}%</div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="counts">
                                    <div className="count">{item.goal} <span className="unit">{item.token_symbol}</span></div>
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="counts">
                                    <div className="count">{item.Investors}</div>
                                </div>
                            </Grid>
                            <Grid item xs={1} className="center">
                                <div className={`star-wrap ${item.has_watched ? 'active' : ''}`} onClick={(e) => handleClickStar(item, e)}>
                                    <img src={require('assets/img/explore/star.png')} alt="" className="star default" />
                                    <img src={require('assets/img/explore/star-hover.png')} alt="" className="star hover" />
                                    <img src={require('assets/img/explore/star-active.png')} alt="" className="star active" />
                                    <Tips text={item.has_watched ? 'Remove from watchlist' : 'Add to watchlist'}></Tips>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                )
            })}
        </Box>
        {/* {!list || !list.length && <Empty onRefresh={onRefresh}></Empty>} */}
    </div>
  );
}
