import React from 'react'
import './index.scss'
import { InvestDetail, InvestInfo } from 'api/finance'
import { Button } from '@mui/material'
import { dealMetamaskAddress } from 'utils/utils'
import InvestorsDialog from './investors-dialog'
import { ProfileType } from 'api/user'
import { useNavigate } from 'react-router'
import { generateEmptyArray } from 'utils/utils'

interface Props {
    investDetail: InvestDetail,
    investInfo: InvestInfo,
    onRefresh: () => void,
    profile: ProfileType|undefined
}

export default function TopInvestors(props:Props) {
    const { investDetail, investInfo, onRefresh, profile } = props

    const [open, setOpen] = React.useState(false)
    const [hideInstitution, setHideInstitution] = React.useState(false)
    const [hideContributor, setHideContributor] = React.useState(false)

    const navigate = useNavigate()



    return (
        <div className="top-investors">
             <div className={`top-investors__title ${hideInstitution ? 'hide' : ''}`} onClick={() => setHideInstitution(!hideInstitution)}>Institution
                <img src={require('assets/img/invest-detail/arrow-down.png')} alt="" className="icon" />
            </div>
            {!hideInstitution && <div className="institutions-wrap">
                <div className={`item ${investDetail.institutions?.length > 0 ? '' : 'empty'}`}>
                    <div className="text">INSTITUTION</div>
                    <div className="list">
                        {investDetail.institutions?.slice(0, 5).map(item => {
                            return (
                            // <div className="list-item" key={`institutions_${item.user_id}`}>
                            //     <img src={item.profile_uri} alt="" className="box" />
                            //     {/* <div className="text">{item.name}</div> */}
                            // </div>
                            <div className="avatar-wrap" key={`institutions_${item.user_id}`}>
                                <img src={item.profile_uri} alt="" className="avatar" onClick={() => navigate(`/profile?id=${item.user_id}`)} title={item.name} />
                            </div>
                            )
                        })}
                        {!investDetail.institutions?.length && <div className="avatar-wrap">
                            <img src={(require('assets/img/invest-detail/avatar-empty.png'))} alt="" className="avatar" />
                        </div>}
                    </div>
                    <div className="text3">{!investDetail.institutions?.length ? 'No institutional investment yet' : (investDetail.institutions.length === 1 ? '' : `+${investDetail.institutions.length} Institution`)}</div>
                </div>
                <div className={`item ${investDetail.famers?.length > 0 ? '' : 'empty'}`}>
                    <div className="text">COMMUNITY FAMER</div>
                    <div className="list">
                        {investDetail.famers?.slice(0, 5).map(item => {
                            return (
                            // <div className="list-item" key={`famers_${item.user_id}`}>
                            //     <img src={item.profile_uri} alt="" className="box" />
                            //     {/* <div className="text">{item.name}</div> */}
                            // </div>
                                <div className="avatar-wrap" key={`famers_${item.user_id}`}>
                                    <img src={item.profile_uri} alt="" className="avatar" onClick={() => navigate(`/profile?id=${item.user_id}`)} title={item.name} />
                                </div>
                            )
                        })}
                        {!investDetail.famers?.length && <div className="avatar-wrap">
                            <img src={(require('assets/img/invest-detail/avatar-empty.png'))} alt="" className="avatar" />
                        </div>}
                    </div>
                    <div className="text3">{!investDetail.famers?.length ? 'No community famer yet' : (investDetail.famers.length === 1 ? '' : `+${investDetail.famers.length} community famer`)}</div>
                </div>
            </div>}
            <div className={`top-investors__title ${hideContributor ? 'hide' : ''}`} onClick={() => setHideContributor(!hideContributor)}>Contributors
                <img src={require('assets/img/invest-detail/arrow-down.png')} alt="" className="icon" />
            </div>
            {!hideContributor && <div className="top-investors__content">
                {investDetail.amount_investors?.slice(0, 5).map((item, index) => {
                    return (
                        <div className="item" key={`amount_investors_${index}`}>
                            <img src={item.profile_uri || require('assets/img/invest-detail/avatar-default.png')} alt="" className='avatar' onClick={() => navigate(`/profile?id=${item.user_id}`)} />
                            <div className="name">{item.name || dealMetamaskAddress(item.wallet)}</div>
                            <div className="amount">{(+item.amount).toFixed(2) || 0} {investInfo.token_symbol}</div>
                        </div>
                    )
                })}
                {(!investDetail.amount_investors || investDetail.amount_investors.length < 5) &&
                generateEmptyArray(5 - (investDetail.amount_investors?.length || 0)).map((item, index) => {
                    return (
                        <div className="item" key={`amount_investors_empty_${index}`}>
                            <img src={require('assets/img/invest-detail/avatar-empty.png')} alt="" className="avatar default" />
                            <div className="empty-text">Waiting for investors</div>
                        </div>
                    )
                })}
            </div>}
            {!hideContributor && investDetail.amount_investors?.length > 1 && <div className="top-investors__bottom">
                <div className='more' onClick={() => setOpen(true)}>View more contributors <img src={require('assets/img/invest-detail/arrow-right.png')} alt="" className="icon" /></div>
            </div>}
            <InvestorsDialog
            open={open}
            onClose={() => setOpen(false)}
            investInfo={investInfo}
            list={investDetail.amount_investors || []}
            onRefresh={onRefresh}
            profile={profile}
            ></InvestorsDialog>
        </div>
    )
}