import React from 'react';
import Header from 'components/header';
import './index.scss'
import { getLatest, LatestProjectResponse } from 'api/apply';
// import { APPLY_STATUS, INVEST_STATUS } from 'constants';
import ApplyStepper from '../components/stepper';
import ApplyBox from 'pages/profile/components/apply/box';
import { useNavigate } from 'react-router';
import Message from 'components/common/message';
import { APPLY_STATUS } from 'constant';

export default function ApplyTransfer() {

    const navigate = useNavigate()

    const [latestData, setLatestData] = React.useState<LatestProjectResponse>()

    async function init() {
        const data = await getLatest()
        // if (![APPLY_STATUS.DRAFT, APPLY_STATUS.PENDING].includes(data.apply_status)) return
        setLatestData(data)
    }

    function onDelete() {
        Message('Delete success!')
        navigate('/apply', {
            replace: true
        })
    }
    
    React.useEffect(() => {
        init()
    }, [])

    return (
        <div className='apply-transfer'>
            <Header></Header>
            <ApplyStepper step={latestData?.step || 1} isPending={latestData?.apply_status === APPLY_STATUS.PENDING}></ApplyStepper>
            <div className="transfer-card">
                <ApplyBox project={latestData} onDelete={onDelete} onRefresh={() => init()}></ApplyBox>
            </div>
        </div>
    );
}
