import React from 'react';
import './index.scss'
import { Button } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles'
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as Down } from "assets/svg/sort-arrow-down.svg";
import { ReactComponent as Up } from "assets/svg/sort-arrow-up.svg";
import Message from 'components/common/message';
import { Offer } from '../types';
import { OTCDetail } from 'api/trade';
import { dealMetamaskAddress } from 'utils/utils';
import Empty from 'components/empty';
import { useOTCContract } from 'utils/web3';
import Loading from 'components/common/loading';
import { TAB_TYPE, SORT_TYPE } from '../map';
import BuyDialog from '../buy-dialog';
import useChainInterceptor from 'hooks/useChainInterceptor';
// import { getWS, WSMessage, registerMsg, unRegisterMsg, readMsgScope } from 'utils/ws';
// import { WS_SCOPE, WS_MSG_TYPE } from 'constant/ws';
// import { WS_OTC_TRADE_STATUS } from 'constant/ws';
import useTradeWsMsg from 'hooks/useTradeWsMsg';
// import { multiply } from 'mathjs';
import useLoginInterceptor from 'hooks/useLoginInterceptor';
import CopyItem from '../copy-item';

interface Props {
    offers: Offer[],
    detail: OTCDetail
    // symbol: string,
    userAddr: string,
    onRefresh: () => void
    onRefreshData: () => void
    // tokenDecimal: string
}

export default function OfferList(props: Props) {

    const { offers, detail, userAddr, onRefresh, onRefreshData } = props

    const [tab, setTab] = React.useState(TAB_TYPE.ALL)
    const [sortType, setSortType] = React.useState(SORT_TYPE.ALL)
    const [totalSortType, setTotalSortType] = React.useState(SORT_TYPE.ALL)
    const [filterOffers, setFilterOffers] = React.useState<Offer[]>([])
    const [openBuy, setOpenBuy] = React.useState(true)
    const [offer, setOffer] = React.useState<Offer>()
    // const [msgCount, setMsgCount] = React.useState(0)

    const chainIntercepter = useChainInterceptor()
    const loginInterceptor = useLoginInterceptor()

    const {read, msgCount} = useTradeWsMsg(detail, true)

    const StyledTabs = styled(Tabs)`
        & .MuiTabs-indicator {
        background: linear-gradient(85.25deg, #001AFF -12.81%, #22C0FF 74.7%);
        height: 3px;
        }
    `

    const StyledTab = styled(Tab)`
        &.MuiTab-textColorPrimary {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #808080;
        text-transform: none;
        margin-right: 27px;
        padding: 0;
        height: 64px;
        }
        &.Mui-selected {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        background: linear-gradient(85.25deg, #001AFF -12.81%, #22C0FF 74.7%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        }
    `;

    function handleChange(v:string) {
        setTab(v)
    }

    function handleClickSortType(type:number) {
        setTotalSortType(SORT_TYPE.ALL)
        if (sortType === type) {
            setSortType(SORT_TYPE.ALL)
        } else {
            setSortType(type)
        }
    }

    function handleClickTotalSortType(type:number) {
        setSortType(SORT_TYPE.ALL)
        if (totalSortType === type) {
            setTotalSortType(SORT_TYPE.ALL)
        } else {
            setTotalSortType(type)
        }
    }

    function getFilterOffers() {
        return offers.filter(_offer => {
            if (tab === TAB_TYPE.ALL) {
                return _offer.active
            } else {
                return _offer.creator === userAddr
            }
        }).sort((a:Offer, b:Offer) => {
            if (sortType === SORT_TYPE.DOWN) {
                return +a.costPerToken - +b.costPerToken
            }
            if (sortType === SORT_TYPE.UP) {
                return +b.costPerToken - +a.costPerToken
            }
            if (totalSortType === SORT_TYPE.DOWN) {
                return +a.costPerToken * +a.tokens - +b.tokens * +b.costPerToken
            }
            if (totalSortType === SORT_TYPE.UP) {
                return +b.tokens * +b.costPerToken - +a.tokens * +a.costPerToken
            }
            return 0
        })
    }

    async function cancel(item:Offer) {
        if (!item.active) return
        chainIntercepter(() => {
            useOTCContract(detail?.official_addr || '').methods.cancelOffer(item.tradeId)
            .send({
                from: userAddr
            }).on('receipt', function(receipt:any) {
                console.log(receipt)
                Message('Cancel success')
                Loading(false)
                // item.active = false
                onRefresh()
            }).on('error', function(error:any, receipt:any) {
                console.log(error, receipt)
                Message(error.message || 'Cancel failed', {severity: 'error'})
                Loading(false)
            })
        }, undefined, detail?.blockchain.chain_id)
        
    }

    function buy(item:Offer) {
        setOffer(item)
        setOpenBuy(true)
    }
    
    // function register() {
    //     registerMsg(WS_SCOPE.TRADE)
    // }

    // function read() {
    //     readMsgScope(detail?.project_id + '-0', WS_SCOPE.TRADE)
    // }
    
    // React.useEffect(() => {
    //     const ws = getWS()
    //     if (!ws) return
    //     if (ws.OPEN === ws.readyState) {
    //         registerMsg(WS_SCOPE.TRADE)
    //     } else {
    //         ws.addEventListener('open', register)
    //     }
    //     ws.addEventListener('message', function(e:MessageEvent<any>) {
    //         try {
    //             const msgData:WSMessage = JSON.parse(e.data)
    //             console.log('watch message', e.data)
    //         if (msgData.type !== WS_MSG_TYPE.OFFER) return
    //             console.log('msgData:', msgData)
    //             const data = JSON.parse(msgData.msg)
    //             console.log('data', data)
    //             if (data.status === WS_OTC_TRADE_STATUS.OfferAccepted) {
    //                 setMsgCount(msgCount + 1)
    //             }
    //         } catch (error:any) {
    //             console.log(error)
    //         }
    //     })
    //     return () => {
    //         unRegisterMsg(WS_SCOPE.WATCHER)
    //         ws.removeEventListener('open', register)
    //     }
    // }, [])

    React.useEffect(() => {
        setFilterOffers(getFilterOffers())
    }, [offers, tab, sortType, totalSortType])

    return (
        <div className="offer-list">
            <Box paddingLeft={'20px'} borderBottom={'1px solid rgba(179, 179, 179, 0.7);'} position={'relative'}>
                    <StyledTabs
                    value={tab}
                    >
                        <StyledTab value={TAB_TYPE.ALL} label="All offer" onClick={() => handleChange(TAB_TYPE.ALL)} />
                        <StyledTab value={TAB_TYPE.MY} label="My offer" onClick={() => {
                            handleChange(TAB_TYPE.MY)
                            msgCount > 0 && read()
                        }} />
                    </StyledTabs>
                    {msgCount > 0 && <div className="msg-count">{msgCount}</div>}
            </Box>
          
            <table className='data-table'>
                <thead>
                    <tr>
                        <th>Offer ID</th>
                        <th>
                            <div className="flex left">
                                <span>Price</span>
                                <div className="sort-wrap">
                                    <Up className='icon' fill={sortType === SORT_TYPE.UP ? '#001AFF' : '#808080'} onClick={() => handleClickSortType(SORT_TYPE.UP)}></Up>
                                    <Down className='icon' fill={sortType === SORT_TYPE.DOWN ? '#001AFF' : '#808080'} onClick={() => handleClickSortType(SORT_TYPE.DOWN)}></Down>
                                </div>
                            </div>
                        </th>
                        <th>Token</th>
                        <th>Quantity</th>
                        <th>
                            <div className="flex left">
                                <span>Totals</span>
                                <div className="sort-wrap">
                                    <Up className='icon' fill={totalSortType === SORT_TYPE.UP ? '#001AFF' : '#808080'} onClick={() => handleClickTotalSortType(SORT_TYPE.UP)}></Up>
                                    <Down className='icon' fill={totalSortType === SORT_TYPE.DOWN ? '#001AFF' : '#808080'} onClick={() => handleClickTotalSortType(SORT_TYPE.DOWN)}></Down>
                                </div>
                            </div>
                        </th>
                        <th>From</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {filterOffers.length ? filterOffers.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div className="flex left">
                                        <span>{item.tradeId}</span>
                                        {/* <span className='tag'>Solded</span> */}
                                    </div>
                                </td>
                                <td>{item.costPerToken} {item.symbol}</td>
                                <td>
                                    <div className="flex">
                                        <img src={detail?.token_logo_url} alt="" className="token-logo" />
                                        ${detail?.token_name}
                                    </div>
                                </td>
                                <td>{item.tokens}</td>
                                <td>{+(+item.costPerToken * +item.tokens).toFixed(6)} {item.symbol}</td>
                                <td>
                                    {/* <div className="flex copy-wrap">
                                        {dealMetamaskAddress(item.creator)}
                                        <CopyToClipboard text={item.creator} onCopy={() => Message('copy success!')}>
                                            <img src={require('assets/img/trading/copy.png')} alt="" className="copy" />
                                        </CopyToClipboard>
                                    </div> */}
                                    <CopyItem showText={dealMetamaskAddress(item.creator)} copyText={item.creator}></CopyItem>
                                </td>
                                <td>
                                    {item.creator !== userAddr ? 
                                    <Button className="btn btn-1seed" variant='outlined' onClick={() => loginInterceptor(() => buy(item))}><span className="text-color">Buy</span></Button>
                                    :
                                    <div className={`btn btn-cancel ${item.active ? '' : 'disabled'}`} onClick={() => loginInterceptor(() => cancel(item))}>Cancel</div>
                                    }
                                </td>
                            </tr>
                        )
                    }) : <tr><td colSpan={7}><Empty text2='Try Refresh' onRefresh={onRefreshData}></Empty></td></tr>}
                </tbody>
            </table>
            {(offer && openBuy) &&
            <BuyDialog
            open={openBuy}
            onClose={() => setOpenBuy(false)}
            detail={detail}
            offer={offer}
            // symbol={symbol}
            userAddr={userAddr}
            // tokenDecimal={tokenDecimal}
            onRefresh={onRefresh}
            ></BuyDialog>}
        </div>
    );
}
