import { getWeb3 } from "utils/web3";
import { supportedChainId } from "config/connector";
import Message from "components/common/message";
import { getChainByChainId } from "@thirdweb-dev/chains";

export default function useChainInterceptor() {

	return async function chainInterceptor(onSuccess:() => void, onError?: () => void, chainId = supportedChainId) {
        let accounts:string[] = []
        const web3 = getWeb3()
        try {
            accounts = await web3.eth.getAccounts()
            console.log('accounts:', accounts)
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Unknown error', { severity: 'error' })
        }
        console.log('accounts:', accounts)
        if (!accounts?.length) {
            try {
                web3.eth.requestAccounts()
            } catch(e:any) {
                console.log(e)
                Message(e.message || 'Unknown error', { severity: 'error' })
            }
            return
        }
        const currentChainId = await web3.eth.getChainId()
        console.log('currentChainId:', currentChainId)
        if (currentChainId !== chainId) {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                await ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: web3.utils.toHex(chainId) }]
                });
                onSuccess()
            } catch (err:any) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code === 4902) {
                    try {
                        const chain = getChainByChainId(chainId)
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        await ethereum.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                            {
                                chainName: chain.name,
                                chainId: web3.utils.toHex(chainId),
                                nativeCurrency: {
                                    name: chain.nativeCurrency.name,
                                    decimals: chain.nativeCurrency.decimals,
                                    symbol: chain.nativeCurrency.symbol
                                },
                                rpcUrls: chain.rpc
                            }
                            ]
                        });
                        onSuccess()
                    } catch(e:any) {
                        console.log(e)
                        Message(e.message || 'Unknown error', { severity: 'error' })
                        onError && onError()
                    }
                } else {
                    onError && onError()
                }
            }
        } else {
            onSuccess()
        }
    }
}