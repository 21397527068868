import React from "react";
import { styled, ButtonBase } from "@mui/material"
import "./index.scss"
// import { getSocialBindUri } from "api/user";
// import Message from "components/common/message";
// import Loading from "components/common/loading";
// import { socialBind } from "api/user";
// import useAuthSocialAccount from "hooks/useAuthSocialAccount";
import { socialIconFullMapList } from "pages/apply/maps";

type Props = {
	list:ListProps,
	wallet: string
	authSocialAccount: any
}

type ListProps = Array<{
	name: string;
	value: string;
}>

type ListItemProps = {
	name: string;
	value: string;
	wallet: string
	authSocialAccount: any
}

const ImageButton = styled(ButtonBase)(() => ({
	position: 'relative',
	width: 120,
	height: 40,
	border: 'none',
	borderRadius: 10,
}));

const ImageSrc = styled('span')({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundSize: 'cover',
	backgroundPosition: 'center center',
});

function ListItem(props: ListItemProps) {
	const { name, value, wallet, authSocialAccount } = props

	return (
		<div className={'social-list__item'}>
			<div className={'social-list__item--left'}>
				<div className={'social-list__item--image'}>
					<img src={value ? socialIconFullMapList.find(item => item.key === name)?.icon : socialIconFullMapList.find(item => item.key === name)?.iconDisabled} alt="" className="logo" />
				</div>
				<div className={'social-list__item--text'}>
					<div className={'text-title'}>{name}</div>
					<div className={'text-desc'}>{value ? 'Connected' : 'Not Connected'}</div>
				</div>
			</div>
			<div className={'social-list__item--right'}>
				{value ?
				// <ImageButton focusRipple>
				// 	<ImageSrc style={{ backgroundImage: `url(${require('assets/img/setting/connected.png')})` }}/>
				// 	{/* <ImageSrc className="btn-disconnect" style={{ backgroundImage: `url(${require('assets/img/setting/disconnect.png')})` }}/> */}
				// </ImageButton>
				<div className="connected"><img src={require('assets/img/setting/check.png')} alt="" className="icon" />Connected</div>
				:
				<ImageButton focusRipple onClick={() => authSocialAccount(name, wallet)}><ImageSrc style={{ backgroundImage: `url(${require('assets/img/setting/connect.png')})` }}/></ImageButton>}
			</div>
		</div>
	)
}
export default function SocialList(props: Props) {
	const [loadMore, setLoadMore] = React.useState(false)
	return (
		<div className={`social-list-wrap ${loadMore ? 'more' : ''}`}>
			<div className={`social-list`}>
				{props.list.map((item, idx) => {
					return <ListItem name={item.name} value={item.value} key={idx} wallet={props.wallet} authSocialAccount={props.authSocialAccount} />
				})}
			</div>
			<div className="load-more" onClick={() => setLoadMore(!loadMore)}>Load {loadMore ? 'less' : 'more'}</div>
		</div>
	)
}
