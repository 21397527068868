import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { InvestInfo, InvestDetail } from 'api/finance';
import Button from '@mui/material/Button';
import { dealMetamaskAddress } from 'utils/utils';
import { ProfileType } from 'api/user';
import FormInput from 'components/common/form-input';
import { TokenIconMap } from 'pages/apply/maps';
import { getWeb3 } from 'utils/web3';
import Message from 'components/common/message';
import { walletBindPre, walletBind } from 'api/user';
import Loading from 'components/common/loading';
import { OneSeedPoolArenaConnector } from 'config/connector';
import { useErc20Contract, useOneSeedPoolArenaContract } from 'utils/web3';
import { ethers, parseUnits } from "ethers";
import _ from 'lodash'
import { dealAmountDecimal } from 'utils/utils';
import useChainInterceptor from 'hooks/useChainInterceptor';
// import { multiply } from 'mathjs';

interface Props {
    investInfo: InvestInfo
    investDetail: InvestDetail
    onClose: () => void
    open: boolean
    profile: ProfileType|undefined,
    max: number
    amount: number
    onRefresh: () => void
    onRefreshProfile: () => void
}

export default function InvestDialog(props:Props) {
    const { open, onClose, investInfo, investDetail, profile, max, amount, onRefreshProfile, onRefresh } = props
    const [inputAmount, setInputAmount] = React.useState('')
    const [needApprove, setNeedApprove] = React.useState(false)
    const [disabledInput, setDisabledInput] = React.useState(false)
    const [min, setMin] = React.useState(0)
    const [allowance, setAllowance] = React.useState('0')

    const chainInterceptor = useChainInterceptor()

    function checkInputAmount() {
        if (!inputAmount) return true
        if (isNaN(+inputAmount)) return false
        if (+inputAmount < min || +inputAmount > max - amount) return false
        return true
    }

    const handleClickMetamask = async () => {
        if (!getWeb3().givenProvider) {
            Message('Please install Metamask First', {severity:'warning'})
            window.open('https://metamask.io/download/')
            return
        }
        chainInterceptor(async () => {
            try {
              const publicAddress = await getWeb3().eth.requestAccounts();
              const data = await walletBindPre({public_address: publicAddress[0]})
              handleSignMessage(publicAddress[0], data?.nonce)
            } catch (error:any) {
                console.log(error)
                Message(error.message || 'Unknown error', { severity: 'error' })
            }
        })
    }
    
    async function handleSignMessage(publicAddress:string, nonce:string) {
        try {
            const sign = await getWeb3().eth.personal.sign(
                getWeb3().utils.toHex(nonce),
                publicAddress,
                '' // password?
            )
            await walletBind({
                signature: sign,
                public_address: publicAddress
            })
            onRefreshProfile()
        } catch (error:any) {
            console.log(error)
            Message(error.message || 'Unknown error', { severity: 'error' })
        }
    }

    async function initStatus() {
        if (investInfo.token_symbol === 'ETH') return
        const contract = useErc20Contract(investInfo.token_addr)
        const uint256Str = await contract.methods.allowance(profile?.user_info.wallet, OneSeedPoolArenaConnector.address).call()
        console.log('allowance', uint256Str)
        setAllowance(dealAmountDecimal(uint256Str, investInfo.token_decimal) + '')
        // console.log(uint256, ethers.MaxUint256.toString())
        // setNeedApprove(ethers.MaxUint256.toString() !== uint256Str)
        if (!uint256Str || uint256Str === '0') {
            setNeedApprove(true)
            setDisabledInput(true)
        } else {
            setNeedApprove(false)
            setDisabledInput(false)
        }
    }

    function confirm() {
        chainInterceptor(() => {
            if (needApprove) {
                approve()
            } else {
                invest()
            }
        })
    }

    async function approve() {
        Loading(true)
        const contract = useErc20Contract(investInfo.token_addr)
        try {
            contract.methods.approve(OneSeedPoolArenaConnector.address, ethers.MaxUint256)
            .send({
                from: profile?.user_info.wallet
            }).on('receipt', function(receipt:any) {
                console.log(receipt)
                Message('Approve success')
                Loading(false)
                // onClose()
                initStatus()
            }).on('error', function(error:any, receipt:any) {
                console.log(error, receipt)
                Message(error.message || 'Approve failed', {severity: 'error'})
                Loading(false)
            })
        } catch(error:any) {
            console.log(error)
            Message(error.message || 'Approve failed', {severity: 'error'})
            Loading(false)
        }
    }

    async function invest() {
        Loading(true)
        let sendParam
        const _amount = generateInputAmount()
        if (investInfo.token_symbol === 'ETH') {
            sendParam = {
                from: profile?.user_info.wallet,
                value: _amount.toString()
            }
        } else {
            sendParam = {
                from: profile?.user_info.wallet,
                value: 0
            }
        }
        console.log('OneSeedPoolArenaAddr:', OneSeedPoolArenaConnector.address)
        console.log('_amount:', _amount)
        console.log('ethvalue::', _amount.toString())
        console.log('investParam:', investDetail.invest_contract, _amount)
        console.log('sendParam:', sendParam)
        try {
            useOneSeedPoolArenaContract(OneSeedPoolArenaConnector.address).methods
            .invest(investDetail.invest_contract, _amount)
            .send(sendParam)
            .on('receipt', function(receipt:any) {
                console.log(receipt)
                Message('Invest success')
                Loading(false)
                onClose()
                onRefresh()
            }).on('error', function(error:any, receipt:any) {
                console.log(error, receipt)
                Message(error.message || 'Invest failed', {severity: 'error'})
                Loading(false)
            })
        } catch(error:any) {
            console.log(error)
            Message(error.message || 'Invest failed', {severity: 'error'})
            Loading(false)
        }
    }

    function generateInputAmount() {
        // console.log(inputAmount, investInfo.token_decimal, _.multiply(+inputAmount, Math.pow(10, investInfo.token_decimal)))
        if (isNaN(+inputAmount)) return ''
        // console.log(BigInt(multiply(+inputAmount, Math.pow(10, investInfo.token_decimal))).toString())
        return parseUnits(inputAmount, investInfo.token_decimal)
    }

    function handleChangeAmount(v:string) {
        setInputAmount(v)
        // console.log(v, allowance)
        // console.log(v, generateInputAmount())
        // console.log(inputAmount, investInfo.token_decimal)
        // console.log(parseUnits(inputAmount, investInfo.token_decimal))
        if (investInfo.token_symbol === 'ETH') return
        if (+v > +allowance) {
            setNeedApprove(true)
        } else {
            setNeedApprove(false)
        }
    }

    // function getTokenBalance() {
    //     if (investInfo.token_symbol === 'ETH') {

    //     } else {

    //     }
    // }

    React.useEffect(() => {
        if (open) {
            initStatus()
            setMin(dealAmountDecimal(investInfo.user_min_invest, investInfo.token_decimal))
        }
    }, [open])

    return (
        <Dialog open={open} onClose={onClose}>
            <div className="invest-dialog">
                <img className='invest-dialog__icon' src={require('assets/img/close.png')} alt="" onClick={() => onClose()} />
                <div className="invest-dialog__title">{needApprove ? 'Approve' : 'Invest'}</div>
                <div className="invest-dialog__content">
                    <div className="sub-title">Get started with wallet</div>
                    <div className="wallet-wrap">
                        <img src={require('assets/img/setting/metamask.png')} alt="" className="icon" />
                        {profile?.user_info.wallet ? (
                            <div>
                                <div className="text1">Wallet</div>
                                <div className="text2">{dealMetamaskAddress(profile.user_info.wallet)}</div>
                            </div>
                        ) : (
                            <div className="text3" onClick={handleClickMetamask}>Connect Metamask</div>
                        )}
                    </div>
                    {/* {!needApprove && <> */}
                        <div className="amount-title">Financing amount</div>
                            <FormInput
                            placeholder='Amount'
                            className='amount-input'
                            value={inputAmount}
                            onChange={handleChangeAmount}
                            errorText={'invalid amount'}
                            error={!checkInputAmount()}
                            // disabled={disabledInput}
                            >
                                <div className="token">
                                    <div className="text">{investInfo.token_symbol}</div>
                                    <img src={TokenIconMap[investInfo.token_symbol]} alt="" className="icon" />
                                </div>
                            </FormInput>
                            <div className="remind">
                                <div className="item" onClick={() => handleChangeAmount(min + '')}>
                                    <div className="item-title">Min：</div>
                                    <div className="item-text">{min} {investInfo.token_symbol}</div>
                                </div>
                                <div className="item" onClick={() => handleChangeAmount((max - amount) + '')}>
                                    <div className="item-title">Max：</div>
                                    <div className="item-text">{max - amount} {investInfo.token_symbol}</div>
                                </div>
                            </div>
                    {/* </>} */}
                    <Button className='btn btn-1seed' variant='contained' disabled={!needApprove && (!checkInputAmount() || !inputAmount)} onClick={confirm}>{needApprove ? 'APPROVE' : 'CONFIRM'}</Button>
                </div>
            </div>
        </Dialog>
    )
}