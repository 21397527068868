import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';

interface Props {
    onClose: () => void
    open: boolean
}

export default function CalendarGuideTips(props:Props) {
    const { open, onClose } = props

    return (
        <Dialog open={open} onClose={onClose} className="calendar-guide-tips-dialog">
            {/* <div className="guide-tips-dialog"> */}
                <div className="content">
                    <div className="title">Calendar Invitation</div>
                    <div className="desc">{`1Seeddao supports you to invite and communicate with investors through Cal.com. If you already have a Cal account, you need to upload your Cal.com invitation link for this project financing activity.`}</div>
                    <div className="btn-wrap">
                        <div className="left" onClick={onClose}>Get it!</div>
                        <div className="right" onClick={onClose}>Hide the tips</div>
                    </div>
                    <img src={require('assets/img/invest-detail/arrow-tips.png')} alt="" className="icon" />
                </div>
            {/* </div> */}
        </Dialog>
    )
}