import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import './index.scss'
import FormInput from 'components/common/form-input';
import FormTextarea from 'components/common/form-textarea';
import Message from 'components/common/message';
import { TeammateType } from 'pages/apply/types';
import { emailReg } from 'constant/form';
import Web3 from 'web3'

interface Props {
    teammate?: TeammateType,
    handleConfirm: (teammate:TeammateType) => void,
    onClose: React.MouseEventHandler<HTMLElement>|any,
    open: boolean,
    teammates: TeammateType[]
}

const titleList = ['CEO', 'CFO', 'COO', 'CTO', 'CIO', 'CRO']

export default function EditDialog(props: Props) {
    
    const { open, onClose, teammate, handleConfirm, teammates } = props

    const [data, setData] = React.useState<TeammateType>({
        title: '',
        name: '',
        bio: '',
        email: '',
        wallet: ''
    })

    const init = () => {
        if (teammate) {
            setData(teammate)
        } else {
            setData({
                title: '',
                name: '',
                bio: '',
                email: '',
                wallet: ''
            })
        }
    }

    React.useEffect(() => {
        init()
    }, [open])

    function handleClickTitle(v:string) {
        if (isDisabledTitle(v)) return
        data.title = v
        setData({...data})
    }

    function handleChangeName(v:string) {
        data.name = v
        setData({...data})
    }

    function handleChangeEmail(v:string) {
        data.email = v
        setData({...data})
    }

    function handleChangeWallet(v:string) {
        data.wallet = v
        setData({...data})
    }

    function handleChangeBio(v:string) {
        data.bio = v
        setData({...data})
    }

    function confirm() {
        if (!data.title) {
            Message('Please check teammate title', { severity: 'warning' })
            return
        }
        if (!data.name) {
            Message('Please set teammate name', { severity: 'warning' })
            return
        }
        if (!data.email) {
            Message('Please set teammate email', { severity: 'warning' })
            return
        }
        if (!emailReg.test(data.email)) {
            Message('Email is invalid', { severity: 'warning' })
            return
        }
        if (!data.wallet) {
            Message('Please set teammate wallet', { severity: 'warning' })
            return
        }
        if (!Web3.utils.isAddress(data.wallet)) {
            Message('Wallet address is invalid', { severity: 'warning' })
            return
        }
        if (!data.bio) {
            Message('Please set teammate bio', { severity: 'warning' })
            return
        }
        handleConfirm(data)
        onClose()
    }

    function isDisabledTitle(title:string) {
        return teammates.map(d => d.title).find(d => d === title)
    }

    return (
        <Dialog 
        open={open}
        onClose={onClose}
        className="teammate-edit-dialog">
            <div className="teammate-edit-content">
                <div className="title">Title</div>
                <div className="check-list">
                    {titleList.map((item, index) => {
                        return <div
                        className={`check-item ${data.title === item ? 'active' : ''} ${isDisabledTitle(item) ? 'disabled' : ''} ${teammate && teammate.title !== item ? 'hidden' : ''}`}
                        key={`teammate_title_${index}`}
                        onClick={() => handleClickTitle(item)}
                        >{item}</div>
                    })}
                </div>
                <div className="info-wrap wrap">
                    <div className="info-content">
                        <div className="item-text">Name</div>
                        <FormInput value={data.name} onChange={handleChangeName}></FormInput>
                    </div>
                    <div className="info-content">
                        <div className="item-text">Email</div>
                        <FormInput value={data.email} onChange={handleChangeEmail}></FormInput>
                    </div>
                </div>
                <div className="wrap">
                    <div className="item-text">Wallet address</div>
                    <FormInput value={data.wallet} onChange={handleChangeWallet}></FormInput>
                </div>
                <div className="wrap">
                    <div className="item-text">Bio</div>
                    <FormTextarea max={500} value={data.bio} onChange={handleChangeBio}></FormTextarea>
                </div>
                <div className="btn-wrap">
                    <Button variant="outlined" className='btn-1seed btn' onClick={onClose}>Back</Button>
                    <Button variant="contained" className='btn-1seed btn' onClick={confirm}>Confirm</Button>
                </div>
            </div>
        </Dialog>
    )
}