import React from 'react';
import './index.scss'
import { OTCDetail } from 'api/trade';
import Tips from 'components/tips';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface Props {
    detail: OTCDetail | undefined
}

export default function Intro(props:Props) {

    const { detail } = props

    const [isMore, setIsMore] = React.useState(false)

    const descRef = useDetectClickOutside({ onTriggered: () => setIsMore(false) });

    return (
        <div className="trading-intro">
            <div className="trading-intro__content">
                <img src={detail?.logo_url} alt="" className="logo" />
                <div className="name">
                    <span className="text">{detail?.project_name}</span>
                    <div className="track">{detail?.tracks[0]} <img src={detail?.blockchain.logo_url} alt="" className="icon" /></div>
                </div>
                <div className="desc">{(detail?.desc && detail.desc.length > 200) ? detail.desc.slice(0, 200) + '...' : detail?.desc}
                    {detail?.desc && detail.desc.length > 200 &&
                        <span className="more-wrap" ref={descRef}>
                            <span className="sub" onClick={() => setIsMore(!isMore)}>read more</span>
                            {isMore && <Tips text={detail?.desc}></Tips>}
                        </span>
                    }
                </div>
                <div className="social-list">
                    <div className="tips-wrap">
                        <img src={require('assets/img/trading/addr.png')} alt="" className="social-icon" />
                        <Tips text={`Official Smart Contract: ${detail?.official_addr || ''    }`}></Tips>
                    </div>
                    {detail?.website &&
                    <div className='tips-wrap'>
                        <img onClick={() => {window.open(detail.website)}} src={require('assets/img/trading/website.png')} alt="" className="social-icon" />
                        <Tips text={`Website:\n${detail.website}`}></Tips>
                    </div>
                    }
                    {detail?.discord && <img onClick={() => {window.open(detail.discord)}} src={require('assets/img/apply/social/discord.png')} alt="" className="social-icon" />}
                    {detail?.github && <img onClick={() => {window.open(detail.github)}} src={require('assets/img/apply/social/github.png')} alt="" className="social-icon" />}
                    {detail?.twitter && <img  onClick={() => {window.open(detail.twitter)}}src={require('assets/img/apply/social/twitter.png')} alt="" className="social-icon" />}
                </div>
            </div>
        </div>
    );
}
