import React from 'react';
import './index.scss'
import { applyPriviteGetUsers, PriviteGetUsersResp, PrivateUser } from 'api/apply';
import VisibleItem from './visible-item';

type Props = {
    projectId: string
    titleText?: string
    canDel?:boolean
}

export default function PrivateVisibleList(props: Props) {

    const { projectId, titleText = '', canDel } = props
    const [users, setUsers] = React.useState<PrivateUser[]>([])
    const [editUsers, setEditUsers] = React.useState<string[]>([])

    async function getUsers() {
        const data:PriviteGetUsersResp = await applyPriviteGetUsers({
            project_id: projectId
        })
        setUsers(data.users || [])
    }

    function handleClickAdd() {
        setEditUsers([...editUsers, ''])
        setTimeout(() => {      
            const el = document.querySelector('.private-visible-list__content')
            el?.scrollTo(0, el.scrollHeight)
        })
    }

    async function handleChangeEditUser(v:string, index: number) {
        editUsers[index] = v
        setEditUsers([...editUsers])
    }

    function handleDelVisibleUser() {
        getUsers()
    }

    function handleDelEditUser(i:number) {
        editUsers.splice(i, 1)
        setEditUsers([...editUsers])
    }

    React.useEffect(() => {
        if (!projectId) return
        getUsers()
    }, [projectId])

    return (
        <div className="private-visible-list">
            {titleText && <div className="private-visible-list__title">{titleText}</div>}
            <div className="private-visible-list__content">
                {users.map((item, index) => {
                    return (
                        <VisibleItem
                        key={`visible_user_${item.id}`}
                        index={index}
                        value={item.email || item.wallet}
                        projectId={projectId}
                        onDel={canDel ? handleDelVisibleUser : undefined}
                        data={item}
                        ></VisibleItem>
                    )
                })}
                {editUsers.map((item, index) => {
                    return (
                        <div
                            key={`edit_user_${index}`}>
                            <VisibleItem
                            index={(users?.length || 0) + index}
                            value={item}
                            onChange={(v) => handleChangeEditUser(v, index)}
                            projectId={projectId}
                            onDel={() => handleDelEditUser(index)}
                            ></VisibleItem>
                        </div>
                    )
                })}
            </div>
            <div className="add" onClick={handleClickAdd}>
                <img src={require('assets/img/apply/visible/add.png')} alt="" className="icon" />
                <span className="text">Add new</span>
            </div>
        </div>
      );
    }
    