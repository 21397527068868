import { useEffect } from "react";
import Loading from "components/common/loading";
import Message from "components/common/message";
import { getSocialBindUri, socialBind } from "api/user";
import { applySocialBind, SocialBindResponse } from "api/apply";
import { BOT_ID } from "config/config";

// let socialAuthBC: BroadcastChannel

export function getSocialAuthBC() {
	// if (!socialAuthBC) {
	// 	socialAuthBC = new BroadcastChannel('social_auth')
	// }
	return new BroadcastChannel('social_auth')
}

export default function useAuthSocialAccount(cb?: (social_name:string, social_user_name:string) => void) {

    async function auth(social_name:string, code:string, project_id?:string) {
		try {
			Loading(true)
			let data:SocialBindResponse
			if (project_id) {
				data = await applySocialBind({
					code,
					social_name,
					project_id
				})
			} else {
				data = await socialBind({
					code,
					social_name
				})
			}
			getSocialAuthBC().postMessage({
				...data,
				success: true,
				target: 'auth_1seed',
			})
		} catch(e:any) {
			console.log(e)
			Message(e.message || 'Unknown error', {severity: 'error'})
		} finally {
			Loading(false)
		}
	}

	async function bindMirror(wallet?:string) {
		if (!wallet) {
			Message('Connect wallet first', {severity: 'warning'})
			return
		}
		// console.log(socialAuthBC)
		// getSocialAuthBC().postMessage({
		// 	social_name: 'mirror',
		// 	social_user_name: 'xx',
		// 	success: true,
		// 	target: 'auth_1seed',
		// })
		window.open(`https://mirror.xyz/${wallet}`)
		auth('mirror', wallet)
	}
	
	function bindTelegram(project_id?:string) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const t = window.Telegram
		if (!t) {
			Message('Network error', {severity:'error'})
			return
		}
		t.Login.auth({ bot_id: BOT_ID, request_access: 'write', embed: 1 }, (data: any) => {
			console.log(data)
			data?.hash && auth('telegram', JSON.stringify(data), project_id)
		})
	}
    
    function onMessage(e:MessageEvent) {
        console.log('onMessage：', e.data)
        const data = e.data
        if (!data || data.target !== 'auth_1seed') {
        return
        }
        if (data.success) {
            cb && cb(data.social_name, data.social_user_name)
            Message('Connect success')
        } else {
            Message(data.e?.message || 'Connect failed', { severity: 'error' })
        }
    }

    useEffect(() => {
		// console.log(123)
        cb && getSocialAuthBC().addEventListener('message', onMessage)
        return () => {
			cb && getSocialAuthBC().removeEventListener('message', onMessage)
        }
    }, [])

    return async (social_name:string, mirror_wallet?:string, project_id?:string) => {
		if (social_name === 'mirror') {
			bindMirror(mirror_wallet)
			return
		}
		if (social_name === 'telegram') {
			bindTelegram(project_id)
			return
		}
		Loading(true)
		try {
			const data = await getSocialBindUri(social_name)
			data.uri && window.open(data.uri + `&state=${social_name}$${project_id || ''}`)
		} catch (error:any) {
			Message(error.message || 'unknow error', { severity: 'error' })
		} finally {
			Loading(false)
		}
	}
}