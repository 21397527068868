import React from 'react'
import './index.scss'
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

interface Props extends React.PropsWithChildren {
    title: string,
    index: number,
    expand: boolean,
    className?: string
}

export default function ExpandTextBox(props:Props) {

    const {title, index, expand, children, className = ''} = props

    const [open, setOpen] = React.useState(expand)

    return (
        <div className={`expand-text-box ${className}`}>
            <div className="flex">
                <div className="box-num">0{index + 1}</div>
                <div className="box-title">{title}</div>
                <SpeedDial
                    className='expand-icon'
                    open={open}
                    onClick={() => setOpen(!open)}
                    ariaLabel="SpeedDial openIcon example"
                    sx={{ zIndex: 10 }}
                    icon={<SpeedDialIcon openIcon={<HorizontalRuleIcon />} />}
                >
                </SpeedDial>
            </div>
            <div className="box-content">
                {open ? <>
                    <div className="box-text">
                        {children}
                    </div>
                </> : <></>}
            </div>
        </div>
    )
}