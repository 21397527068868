import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';

interface Props {
    onClose: () => void
    open: boolean
    handleClick: () => void
}

export default function ProfileNftMintMsgDialog(props:Props) {
    const { open, onClose, handleClick } = props

    return (
        <Dialog open={open} onClose={onClose} className="profile-nft-mint-msg-dialog">
            <div className="content">
                <img src={require(`assets/img/message/mint.png`)} alt="" className="icon" />
                <img src={require('assets/img/close-white.png')} alt="" className="close" onClick={onClose} />
                <div className="text1">Congratulations !</div>
                <div className="text2">Congratulations, you have obtained the first batch of Membership NFT airdropped by the 1Seeddao community. Please go to the platform to complete the mint.</div>
                <Button className="btn btn-1seed" variant='outlined' onClick={handleClick}><span className="text-color">Mint Now</span></Button>
            </div>
        </Dialog>
    )
}