import React from 'react';
import './index.scss';
import { Box, Grid } from '@mui/material';
import { Trending } from 'api';
import { useNavigate } from 'react-router';
import { Button } from '@mui/material'

interface Props {
	list: Trending[]
}

export default function TrendingCard(props:Props) {
    const { list = [] } = props
    const navigate = useNavigate()

    function caculateProgress(amount: string, goal: string) {
        // console.log(+(_.divide(+amount, +goal) * 100).toFixed(2))
        return +(_.divide(+amount, +goal) * 100).toFixed(2)
    }

	return (
        <div className="trending-card">
            <div className="trending-card__title">Trending</div>
            <div className="trending-card__card">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid  container spacing={0} className="card-head">
                        <Grid item xs={1} className={'center'}>
                            <div className="text">NO.</div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="text">PROJECT</div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="text">TRACK</div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="text">PROGRESS</div>
                        </Grid>
                        <Grid item xs={2} className={'left'}>
                            <div className="text">FINANCING GOAL</div>
                        </Grid>
                        <Grid item xs={1} className={'center'}>
                            <div className="text">INVESTORS</div>
                        </Grid>
                    </Grid>
                    {list.map((item, index) => {
                        return (
                            <div key={`trending_${index}`} onClick={() => navigate(`/invest-detail/${item.project_id}`)}>
                                <Grid  container spacing={0} className="card-body">
                                    <Grid item xs={1} className={'center'}>
                                        <div className="num">{index + 1}</div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="project">
                                            <img src={item.logo_url} alt="" className="logo" />
                                            <div className="project-title" title={item.project_name}>{item.project_name}</div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <div className="track">{item.tracks && item.tracks[0]}</div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className={`fin-progress ${caculateProgress(item.fin_amount, item.goal) >= 100 ? 'full' : ''}`}>
                                            <div className="progress"
                                                style={{
                                                    width: `${caculateProgress(item.fin_amount, item.goal) / 150 * 100}%`
                                                }}></div>
                                            <div className="total"></div>
                                            <div className="progress-text" style={{
                                                width: `${caculateProgress(item.fin_amount, item.goal) / 150 * 100}%`
                                            }}>{caculateProgress(item.fin_amount, item.goal)}%</div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} className={'left'}>
                                        <div className="counts">
                                            <div className="count">{item.goal}</div>
                                            <div className="unit">{item.token_symbol}</div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={1} className={'center'}>
                                        <div className="counts">
                                            <div className="count">{item.Investors}</div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    })}
                </Box>
            </div>
            <div className="trending-card__btn" onClick={() => navigate('/explore?state=1')}>
                {/* <img src={require('assets/img/index/more-btn.png')} alt="" className="btn" /> */}
                <Button className='btn btn-1seed' variant='contained'><div className="text">EXPLORE MORE</div></Button>
            </div>
        </div>
	)
}