
import discordIcon from '../../assets/img/apply/social/discord.png'
import discordDarkIcon from '../../assets/img/apply/social/discord-dark.png'
import facebookIcon from '../../assets/img/apply/social/facebook.png'
import facebookDarkIcon from '../../assets/img/apply/social/facebook-dark.png'
import githubIcon from '../../assets/img/apply/social/github.png'
import githubDarkIcon from '../../assets/img/apply/social/github-dark.png'
import instagramIcon from '../../assets/img/apply/social/instagram.png'
import instagramDarkIcon from '../../assets/img/apply/social/instagram-dark.png'
import linkedinIcon from '../../assets/img/apply/social/linkedin.png'
import linkedinDarkIcon from '../../assets/img/apply/social/linkedin-dark.png'
import twitterIcon from '../../assets/img/apply/social/twitter.png'
import twitterDarkIcon from '../../assets/img/apply/social/twitter-dark.png'
import mirrorIcon from '../../assets/img/apply/social/mirror.png'
import mirrorDarkIcon from '../../assets/img/apply/social/mirror-dark.png'
import telegramIcon from '../../assets/img/apply/social/telegram.png'
import telegramDarkIcon from '../../assets/img/apply/social/telegram-dark.png'
import ETH from '../../assets/img/project/eth.png'
import WETH from '../../assets/img/setting/WETH.png'
import USDT from '../../assets/img/setting/USDT.png'
import USDC from '../../assets/img/setting/USDC.png'
import { KV, TeammateType, InsightType } from './types'
import { urlReg } from 'constant/form'

export const socialIconMapList = [
    {
        key: 'facebook',
        tips: 'Facebook',
        icon: facebookIcon,
        iconDisabled: facebookDarkIcon
    },
    {
        key: 'discord',
        tips: 'Discord',
        icon: discordIcon,
        iconDisabled: discordDarkIcon
    },
    {
        key: 'github',
        tips: 'Github',
        icon: githubIcon,
        iconDisabled: githubDarkIcon
    },
    // {
    //     key: 'instagram',
    //     tips: 'Instagram',
    //     icon: instagramIcon,
    //     iconDisabled: instagramDarkIcon
    // },
    // {
    //     key: 'linkedin',
    //     tips: 'Linkedin',
    //     icon: linkedinIcon,
    //     iconDisabled: linkedinDarkIcon
    // },
    {
        key: 'twitter',
        tips: 'Twitter',
        icon: twitterIcon,
        iconDisabled: twitterDarkIcon
    },
    // {
    //     key: 'mirror',
    //     tips: 'Mirror',
    //     icon: mirrorIcon,
    //     iconDisabled: mirrorDarkIcon
    // },
    {
        key: 'telegram',
        tips: 'Telegram',
        icon: telegramIcon,
        iconDisabled: telegramDarkIcon
    }
]
export const socialIconFullMapList = [
    {
        key: 'facebook',
        tips: 'Facebook',
        icon: facebookIcon,
        iconDisabled: facebookDarkIcon
    },
    {
        key: 'discord',
        tips: 'Discord',
        icon: discordIcon,
        iconDisabled: discordDarkIcon
    },
    {
        key: 'github',
        tips: 'Github',
        icon: githubIcon,
        iconDisabled: githubDarkIcon
    },
    {
        key: 'instagram',
        tips: 'Instagram',
        icon: instagramIcon,
        iconDisabled: instagramDarkIcon
    },
    {
        key: 'linkedin',
        tips: 'Linkedin',
        icon: linkedinIcon,
        iconDisabled: linkedinDarkIcon
    },
    {
        key: 'twitter',
        tips: 'Twitter',
        icon: twitterIcon,
        iconDisabled: twitterDarkIcon
    },
    {
        key: 'mirror',
        tips: 'Mirror',
        icon: mirrorIcon,
        iconDisabled: mirrorDarkIcon
    },
    {
        key: 'telegram',
        tips: 'Telegram',
        icon: telegramIcon,
        iconDisabled: telegramDarkIcon
    }
]


export const stepTitleList = [
    {
        title: 'Project Details',
        desc: ''
    },
    {
        title: 'Project Insight',
        desc: ''
    },
    {
        title: 'Project Finance',
        desc: ''
    }
]

export const roundList = ['0', '1', '2', '3', '4']
export const roundDetailTitle = ['None', 'Seed Round', 'Series A Round', 'Series B Round', 'Series C Round']
export const roundDetailTitleMap = ['Seed Round', 'Series A Round', 'Series B Round', 'Series C Round', 'Series D Round']

export const TokenIconMap:KV = {
    'USDT': USDT,
    'USDC': USDC,
    'WETH': WETH,
    'ETH': ETH
}

export const durationList = ['15', '20', '30', '40', '60']

export const IDODurationList = ['1', '2', '3']

export const formCheckList = [
    [
        {
            key: 'tracks',
            rules: [
                (v:string[], obj:any) => !v.length && !obj.otherTrack && 'Please check a track'
            ]
        },
        {
            key: 'name',
            rules: [
                (v:string) => !v && "Please enter project name"
            ]
        },
        {
            key: 'short_name',
            rules: [
                (v:string) => !v && "Please enter Abbreviation",
                (v:string) => v.length > 15 && "Abbreviation no more than 15 charactors"
            ]
        },
        {
            key: 'desc',
            rules: [
                (v:string) => !v && "Please enter project description"
            ]
        },
        {
            key: 'image_url',
            rules: [
                (v:string) => !v && "Please upload project image"
            ]
        },
        {
            key: 'logo_url',
            rules: [
                (v:string) => !v && "Please upload project logo"
            ]
        },
        {
            key: 'social',
            rules: [
                (social:any) => {
                    if (!social || Object.keys(social).every(k => !social[k])) {
                        return ''
                    }
                    const invalidSocialKey = Object.keys(social).filter(k => social[k]).find(k => !urlReg.test(social[k]))
                    if (invalidSocialKey) {
                        return `${invalidSocialKey} link is invalid`
                    } else {
                        return ''
                    }
                }
            ]
        },
        {
            key: 'site',
            rules: [
                // (v:string) => !v && "Please enter your site",
                (v:string) => v && !urlReg.test(v) && "Please enter correct website url"
            ]
        },
        // {
        //     key: 'whitepaper',
        //     rules: [
        //         (v:string) => !v && "Please upload project whitepaper"
        //     ]
        // },
        // {
        //     key: 'other_files',
        //     rules: [
        //         (v:string) => !v && "Please upload project's other files"
        //     ]
        // },
        {
            key: 'teammates',
            rules: [
                (v:TeammateType[]) => !v.length && 'Please add your project teammates'
            ]
        }
    ],
    // [
    //     {
    //         key: 'overview',
    //         rules: [
    //             (v:string[]) => v.some(str => !str) && 'Please write down all overview'
    //         ]
    //     },
    //     {
    //         key: 'insights',
    //         rules: [
    //             (v:InsightType[]) => v.some(insight => insight.answer.some(answer => !answer)) && 'Please answer all questions'
    //         ]
    //     }
    // ],
    [
        {
            key: 'highlights',
            rules: [
                (v:string[]) => !v.some(str => str) && 'Please enter some highlights'
            ]
        },
        {
            key: 'pitch',
            rules: [
                (v:string) => !v && 'Please enter pitch'
            ]
        }
    ],
    [
        {
            key: 'valuation',
            rules: [
                (v:string, obj:any) => {
                    // console.log(v, +obj.formParams.percent)
                    if (!v && !obj.formParams.percent) {
                        return "Please enter valuation or valuation percent"
                    }
                    if (v && !(+v > 0)) {
                        return 'Invalid valuation'
                    }
                    if (obj.formParams.percent && !(+obj.formParams.percent >= 1 && +obj.formParams.percent < 100)) {
                        return "Invalid valuation percent"
                    }
                    return ''
                }
            ]
        },
        {
            key: 'rounds',
            rules: [
                (v:string) => !v && "Please check financing rounds"
            ]
        },
        {
            key: 'details',
            rules: [
                (v:string[]) => v.some(d => !d) && "Please write down all round details"
            ]
        },
        {
            key: 'token_symbol',
            rules: [
                (v:string) => !v && "Please select a token"
            ]
        },
        {
            key: 'goal',
            rules: [
                (v:string) => !v && "Please enter your financing goal",
                // (v:string) => !(+v > 0) && "Invalid financing goal",
                (v:string, obj:any) => {
                    if (['ETH', 'WETH'].includes(obj.formParams.token_symbol)) {
                        return +v < 10 ? `no less than ${10} ${obj.formParams.token_symbol}` : ''
                    } else {
                        return +v < 10000 ? `no less than ${10000} ${obj.formParams.token_symbol}` : ''
                    }
                }
            ]
        },
        {
            key: 'wallet',
            rules: [
                (v:string) => !v && "Please connet your wallet"
            ]
        },
        {
            key: 'duration',
            rules: [
                (v:string) => !v && "Please set a financing duration"
            ]
        }
    ]
]