import React from 'react';
import './index.scss'
import Filter from '../filters';
import ProfileCommentBox from './box';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import { getComments, GetCommentsResponse, CommentsInProjects } from 'api/user';
import { ProfileType } from 'api/user';
import Empty from 'components/empty';

interface Props {
  target:string
  profile: ProfileType|undefined
}

export default function Comment(props:Props) {

  const { target, profile } = props

  const [list, setList] = React.useState<CommentsInProjects[]>([])

  async function getData(status:number, type:number) {
    const data:GetCommentsResponse = await getComments({
      status,
      sort:type,
      target_user_id: target,
      page_no: 1,
      page_size: 20
    })
    setList(data.comments)
  }


  return (
    <div className='profile-comments'>
        <Filter onChange={getData} filterSortType target={target}></Filter>
        <div className="profile-comments__wrap">
          {(list && list.length) ? <Box sx={{ width: 1280 }}>
            <Masonry columns={2} spacing={'18px'} sx={{width: 1280}}>
              {list.map((item, index) => {
                return (
                  <ProfileCommentBox key={`profile-comments_${item.project_id}`} data={item} profile={profile}></ProfileCommentBox>
                )
              })}
            </Masonry>
          </Box> : (
            <Empty hideBtn></Empty>
            // <div className="profile-comments__empty">
            //   <img src={require('assets/img/empty.png')} alt="" className="icon" />
            //   <div className="text1">No results</div>
            //   <div className="text2">Try Refresh or reset your filters</div>
            // </div>
          )}
        </div>
    </div>
  );
}
