import React from 'react'
import './index.scss'
import editIcon from '../../../../assets/img/apply/edit.png'
import removeIcon from '../../../../assets/img/apply/remove.png'
// import { Button } from '@mui/material'
import EditDialog from './edit-dialog'
import { TeammateType } from '../../types'

interface Props {
    teammates: Teammates,
    handleChange: (v:Teammates) => void
}

type Teammates = Array<TeammateType>

export default function Teammates(props:Props) {
    const { teammates, handleChange } = props

    const [open, setOpen] = React.useState(false)

    const [list, setList] = React.useState<Teammates>([])

    const [editTeammate, setEditTeammate] = React.useState<TeammateType>()

    const init = () => {
        teammates && setList(teammates)
    }

    React.useEffect(() => {
        init()
    }, [teammates])

    function handleDataChange() {
        setList([...list])
        handleChange(list)
    }

    function handleEditConfirm(data:TeammateType) {
        const findIndex = list.findIndex(item => item.title === data.title)
        if (findIndex > -1) {
            list[findIndex] = data
        } else {
            list.push(data)
        }
        handleDataChange()
    }

    function handleEdit(data:TeammateType) {
        setEditTeammate(data)
        setOpen(true)
    }

    function handleAdd() {
        setEditTeammate(undefined)
        setOpen(true)
    }

    function handleRemove(i:number) {
        list.splice(i, 1)
        handleDataChange()
    }

    return (
        <div className="teammates">
            {list.map((item, i) => {
                return (
                    <div className="box" key={`teammate_${i}`}>
                        <div className="icon-wrap">
                            <img src={editIcon} alt="" className="icon" onClick={() => handleEdit(item)} />
                            <img src={removeIcon} alt="" className="icon" onClick={() => handleRemove(i)} />
                        </div>
                        <div className="item">
                            <div className="left">{item.title}</div>
                            <div className="right">{item.name}</div>
                        </div>
                        <div className="item">
                            <div className="left">Email</div>
                            <div className="right">{item.email}</div>
                        </div>
                        <div className="item">
                            <div className="left">wallet address</div>
                            <div className="right">{item.wallet}</div>
                        </div>
                        <div className="item">
                            <div className="left">Bio</div>
                            <div className="right">{item.bio}</div>
                        </div>
                    </div>
                )
            })}
            <div className="add-wrap">
                <div className='form-btn' onClick={handleAdd}>
                    <div className="btn-text">Add a member</div>
                </div>
            </div>
            <EditDialog
            open={open}
            onClose={() => setOpen(false)}
            handleConfirm={handleEditConfirm}
            teammate={editTeammate}
            teammates={teammates}
            ></EditDialog>
        </div>
    )
}