import React from 'react';
import './index.scss'
import img1 from 'assets/img/apply-guide/1.png'
import img2 from 'assets/img/apply-guide/2.png'
import img3 from 'assets/img/apply-guide/3.png'
import { preloadImg } from 'utils/utils';

export default function QuestionCard() {

    const [activeIndex, setActiveIndex] = React.useState(0)

    React.useEffect(() => {
        preloadImg([img1, img2, img3])
    })
    
    return (
        <div className="question-card">
            <div className="question-card__title">Why Choose 1Seed for Fundraising over Traditional VC?</div>
            {/* <div className="question-card__sub-title">1Seed Has Many Advantages</div> */}
            <div className="question-card__content">
                <img src={require(`assets/img/apply-guide/${activeIndex + 1}.png`)} alt="" className="left" />
                <div className="right">
                    <div className={`item ${activeIndex === 0 ? 'active' : ''}`} onClick={() => setActiveIndex(0)}>
                        <div className="text1">Building a Fan Community</div>
                        <div className="text2">For start-ups, the realization of creativity not only requires financial support but more importantly, finding those users who believe in their vision and are willing to support them.<br/>Therefore, for blockchain builders, being able to establish their own fan communities while receiving financial support could be the most significant help.</div>
                    </div>
                    <div className={`item ${activeIndex === 1 ? 'active' : ''}`} onClick={() => setActiveIndex(1)}>
                        <div className="text1">Enhancing Product Persuasiveness</div>
                        <div className="text2">{`When a startup gains the support of users who are willing to invest their money, it demonstrates the feasibility of the product idea from the user's perspective. This can help startups spend less time convincing traditional institutional investors. At the same time, community users, as owners of the project, become loyal users of the product and assist in promoting it.`} </div>
                    </div>
                    <div className={`item ${activeIndex === 2 ? 'active' : ''}`} onClick={() => setActiveIndex(2)}>
                        <div className="text1">Rewarding Fans</div>
                        <div className="text2">{`Community users are an integral part of Web3 projects, and community users, as owners of the project, become loyal users of the product and assist in promoting it. But what do early investing users gain? At 1Seed, we believe that "those who believe in you should get rich too".`}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
