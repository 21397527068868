import baseRequest from "../../utils/http"
const api = new baseRequest()

const PREFIX = '/api/v1/trade'

type GetOTCProjectsParams = {
  page_no: number,
  page_size: number,
  status: number
}

export type GetOTCProjectsRes = {
  projects: OTCProject[]
}

export type OTCProject = {
  project_id: string,
  tracks: string[],
  project_name: string,
  image_url: string,
  logo_url: string,
  status: number,
  expire_time: number,
  offer_count: number,
  agreement_count: number,
  member_count: number,
  official_addr: number,
  top_members: Member[] | null,
  blockchain: BlockChain
}

export type Member = {
  user_id: string,
  username: string,
  ens_name: string,
  profile_uri: string,
  wallet: string
}

type GetOTCDetailParams = {
  project_id: string
}

export type BlockChain = {
  "chain_id": number,
  "name": string,
  "logo_url": string,
  multicall: string
}

export type OTCDetail = {
  "project_id": string,
  "tracks": string[],
  "project_name": string,
  "blockchain": BlockChain,
  "desc": string,
  "image_url": string,
  "logo_url": string,
  "token_name": string,
  "token_addr": string,
  "official_addr": string,
  "website": string,
  "twitter": string,
  "discord": string,
  "github": string,
  "status": number,
  "expire_time": number,
  "member_count": number,
  "top_members": Member[] | null,
  token_logo_url: string
}

export function getOTCProjects(params: GetOTCProjectsParams):Promise<GetOTCProjectsRes | any> {
  return api.get(`${PREFIX}/otc_projects`, params)
}

export function getOTCDetail(params: GetOTCDetailParams):Promise<OTCDetail | any> {
  return api.get(`${PREFIX}/otc_detail`, params)
}