import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import confirmImg from 'assets/img/login-guide/confirm.png'
import { Button } from '@mui/material';
import './index.scss'
import { useNavigate } from 'react-router';
export default function SuccessDialog(props: DialogProps) {
    const navigate = useNavigate()

    function go() {
        navigate('/apply/transfer', {
            replace: true
        })
    }

    return (
        <Dialog {...props}>
            <div className="success-dialog">
                <img className='icon' src={confirmImg} alt="" />
                <p className="title">Congratulations !</p>
                <p className="sub-title">Congratulations on completing the financing application.The platform will complete the review within <span className="sub">48h~72h</span>, and the latest results will be notified to you by <span className="sub">Emai</span> . Please pay attention to check the email sent by 1seed.</p>
                <Button variant="contained" className='btn-1seed btn' disableElevation onClick={go}>I GOT IT</Button>
            </div>
        </Dialog>
    )
}