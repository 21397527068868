import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import './index.scss'
import closeImg from '../../../assets/img/close.png'
type Props = {
    open: boolean,
    onClose: () => void,
    onConfirm: () => void
}
export default function ConfirmCloseDialog(props: Props) {
    const { open, onClose, onConfirm } = props
    return (
        <Dialog open={open} onClose={onClose}>
            <div className="confirm-close-dialog">
                <img className='icon' src={closeImg} alt="" onClick={() => onClose()} />
                <p className="title">Confirm close page?</p>
                <p className="sub-title">your register are not finish yet</p>
                <div className="btn-wrap">
                    <Button variant="outlined" className='btn-1seed btn' disableElevation onClick={() => onClose()}>Back</Button>
                    <Button variant="contained" className='btn-1seed btn' disableElevation onClick={onConfirm}>Close</Button>
                </div>
            </div>
        </Dialog>
    )
}