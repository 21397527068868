import baseRequest from "../../utils/http"
import {FieldValues} from 'react-hook-form'
import { SectionCommentWithReplies } from "api/community"

const api = new baseRequest()

const PREFIX = '/api/v1/user'

export function googleLogin(params:any):Promise<LoginResponseType|any> {
    return api.post(`${PREFIX}/google_login`, params)
}
export type LoginResponseType = {
  access_token: string,
  at_expires: number,
  refresh_token: string,
  rt_expires: number,
  is_first_login: boolean
}

export type MetamaskLoginPreResponseType = {
  nonce: string
}

type ProfileParam = {
  target_user_id?: string
}

type AnswerType = {
  desc:string,
  option:string
}

type QuestionType = {
  desc:string,
  content:string
}

export type QasType = {
  answers: Array<AnswerType>,
  question:QuestionType,
  id:number
}

export type FirstGuideType = {
  profile_urls:Array<string>|null,
  qas: Array<QasType>
}

export type SocialType = {
  discord: string,
  facebook: string,
  github: string,
  linkedin: string,
  mirror: string,
  telegram: string,
  twitter: string,
  instagram: string,
  [k:string]: string
}

export type userinfoType = {
  bio:string,
  email:string,
  ens_name:string,
  profile_data:File|null,
  profile_name:string,
  profile_uri:string,
  role:number,
  signup_time:number,
  site:string,
  username:string,
  wallet:string,
  cover_uri: string
}

export type ProfileType = {
  followers: number,
  following:number,
  id: string,
  is_follow: boolean,
  last_seen: number,
  score: number,
  status: number,
  social: SocialType,
  user_info: userinfoType,
  has_profile_nft: boolean
}

export type PoapAssetsType = {
  desc:string,
  id:number,
  name:string,
  uri:string
}

export type PoapType = {
  poap_assets: Array<PoapAssetsType>
}

export type InvestedParams = {
  target_user_id?: string,
  sort?: number,
  status: number,
  page_no: number,
  page_size: number
}

export type ProjectInfo = {
  blockchain: string,
  fin_amount: number,
  fin_duration: number,
  fin_end: number,
  fin_goal: number,
  fin_token_symbol: string,
  image_url: string,
  name: string,
  site: string,
  slogan_url: string,
  status: number,
  track: string
}

export type ProjectType = {
  latest_fin_time: number,
  my_share: number,
  project_id: string,
  project_info: ProjectInfo
}

export type ProjectsResponseType = {
  projects: Array<ProjectType>
}

export type TopInvestor = {
  name: string,
  profile_uri: string,
  user_id: string,
  wallet: string,
  amount: string,
  is_followed: boolean
}

export type WatchlistType = {
  announce_update: boolean,
  comments_cnt: number,
  investor_count: number,
  project_id: string,
  project_info: ProjectInfo,
  top_investors: Array<TopInvestor>
}

export type WatchlistResponseType = {
  projects: Array<WatchlistType>
}

export type ApplyProjectInfoType = {
  blockchain: string,
  fin_amount: number,
  fin_duration: number,
  fin_end: number,
  fin_goal: number,
  fin_token_addr: string,
  fin_token_symbol: string,
  image_url: string,
  name: string,
  site: string,
  slogan_url: string,
  tracks: string[],
  rounds: number
}

export type ApplyProjectType = {
  audit_comment:string,
  audit: number,
  project_id: string,
  project_info: ApplyProjectInfoType,
  // status: number,
  step: number,
  apply_status: number,
  fin_status: number,
  comments_cnt: number,
  investor_count: number,
  top_investors: Array<TopInvestor> | null
}

export type ApplylistResponseType = {
  projects: Array<ApplyProjectType>
}

type FollowListParam = {
  target_user_id?: string,
  page_no: number,
  page_size: number
}

export type FollowInfo = {
  follower_cnt: number,
  id: string,
  is_followed: boolean,
  profile_url: string,
  role: number,
  status: number,
  username: string
}

export type FollowResponse = {
  users: Array<FollowInfo>
}

export type ScoreListParam = {
  page_no: number,
  page_size: number
}

export type Score = {
  desc:string,
  score:number,
  id:number,
  create_time: number
}

export type ScoreResponse = {
  current_score: number,
  score: Array<Score>
}

type FollowParam = {
  target_user_id: string
}

export type IsFirstLoginResponse = {
  result: boolean
}

type IsDuplicateParam = {
  // 0 - username
  type: number,
  value: string
}

type EmailBindParam = {
  email: string
}

type EmailBindConfirmParam = {
  code: string
}
type socialBindParams = {
  social_name: string,
  code: string,
  // redirect_uri: string

}
type socialBindUriResponse = {
  uri:string
}

type WalletBindPreParams = {
  public_address: string
}

type WalletBindParams = {
  public_address: string,
  signature: string
}

type EnsLinkResponse = {
  ens: string
}

type SocialBindResponse = {
  social_name: string,
  social_user_name: string
}

type GetCommentsParams = {
  target_user_id: string,
  status: number,
  sort: number,
  page_no: number,
  page_size: number
}

export type GetCommentsResponse = {
  comments: CommentsInProjects[],
}

export type FinOverview = {
  amount: string
  goal: string
  image_url: string
  invest_cnt: number
  logo_url: string
  my_share: string
  name: string
  project_id: string
  status: number
  token_decimal: number
  token_symbol: string
  user_id: string
}

export type CommentsInProjects = {
  project_id: string,
  comments: SectionCommentWithReplies[],
  fin_overview: FinOverview
}

type GetOtherPlatProofParams = {
  public_address: string
}

export type GetOtherPlatProofResponse = {
  proof: string[] | null,
  root_index: number
}

type IsRegisteredParam = {
  wallet?: string,
  email?: string
}

export function logout() {
  return api.post(`${PREFIX}/logout`)
}

export function metaMaskLoginPre(params:any):Promise<MetamaskLoginPreResponseType|any> {
  return api.post(`${PREFIX}/metamask_login_pre`, params)
}

export function metaMaskLogin(params:any):Promise<LoginResponseType|any> {
  return api.post(`${PREFIX}/metamask_login`, params)
}

export function walletBindPre(params:WalletBindPreParams):Promise<MetamaskLoginPreResponseType|any> {
  return api.post(`${PREFIX}/wallet_bind_pre`, params)
}

export function walletBind(params:WalletBindParams):Promise<LoginResponseType|any> {
  return api.post(`${PREFIX}/wallet_bind`, params)
}

export function getProfile(params:ProfileParam):Promise<ProfileType|any> {
  return api.get(`${PREFIX}/profile`, params)
}

export function firstGuide():Promise<FirstGuideType|any> {
  return api.get(`${PREFIX}/first_guide`)
}

export function finishGuide(params:FormData) {
  return api.postForm(`${PREFIX}/finish_guide`, params)
}

export function getPoap(params:any):Promise<PoapType|any> {
  return api.get(`${PREFIX}/poap`, params)
}

export function getInvested(params:InvestedParams):Promise<ProjectsResponseType|any> {
  return api.get(`${PREFIX}/invested`, params)
}

export function getWatchlist(params:InvestedParams):Promise<WatchlistResponseType|any> {
  return api.get(`${PREFIX}/watchlist`, params)
}

export function getApplylist(params:InvestedParams):Promise<ApplylistResponseType|any> {
  return api.get(`${PREFIX}/fin_acts`, params)
}

export function getFollowing(params:FollowListParam):Promise<FollowResponse|any> {
  return api.get(`${PREFIX}/following`, params)
}

export function getFollowers(params:FollowListParam):Promise<FollowResponse|any> {
  return api.get(`${PREFIX}/followers`, params)
}

export function getScoreList(params:ScoreListParam):Promise<ScoreResponse|any> {
  return api.get(`${PREFIX}/score_list`, params)
}

export function follow(params:FollowParam):Promise<any> {
  return api.post(`${PREFIX}/follow`, params)
}

export function unfollow(params:FollowParam):Promise<any> {
  return api.post(`${PREFIX}/unfollow`, params)
}

export function isFirstLogin():Promise<IsFirstLoginResponse|any> {
  return api.get(`${PREFIX}/is_first_login`)
}
export function isDuplicate(params:IsDuplicateParam):Promise<IsFirstLoginResponse|any> {
  return api.get(`${PREFIX}/is_duplicate`, params)
}

export function update(params: FieldValues): Promise<any> {
  return api.post(`${PREFIX}/update`, params)
}

export function emailBind(params: EmailBindParam): Promise<any> {
  return api.post(`${PREFIX}/email_bind`, params)
}

export function emailBindConfirm(params: EmailBindConfirmParam): Promise<any> {
  return api.get(`${PREFIX}/email_bind/confirm`, params)
}

export function refreshToken(refresh_token:string):Promise<LoginResponseType|any> {
  return api.post(`${PREFIX}/refresh_token`, {refresh_token})
}

export function getSocialBindUri(social_name:string):Promise<socialBindUriResponse|any> {
  return api.get(`${PREFIX}/social_bind_uri`, {social_name})
}

export function socialBind(params: socialBindParams):Promise<SocialBindResponse|any> {
  return api.post(`${PREFIX}/social_bind`, params)
}

export function ensLink():Promise<EnsLinkResponse|any> {
  return api.post(`${PREFIX}/ens_link`)
}

export function getComments(params: GetCommentsParams):Promise<GetCommentsResponse|any> {
  return api.get(`${PREFIX}/comments`, params)
}

export function getOtherPlatProof(params: GetOtherPlatProofParams):Promise<GetOtherPlatProofResponse|any> {
  return api.get(`${PREFIX}/other_plat_proof`, params)
}

export function getIsRegistered(params:IsRegisteredParam):Promise<IsFirstLoginResponse|any> {
  return api.get(`${PREFIX}/is_registered`, params)
}