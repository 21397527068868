import React from 'react';
import { Button } from '@mui/material';
import './index.scss'
import { useNavigate } from 'react-router';

export default function BindEmailResult() {
    const navigate = useNavigate()

  return (
    <div className="bind-email-result">
        <div className="top">
            <img src={require('assets/img/logo.png')} alt="" className="icon" onClick={() => navigate('/')} />
        </div>
        <div className="content">
            <div className="content-box">
                <img src={require('assets/img/email-result/confirm.png')} alt="" className="img" />
                <div className="text1">Confirmed</div>
                <div className="text2">You email has been confirmed. You may continue your work.</div>
                <Button className='btn btn-1seed' variant='contained' onClick={() => navigate('/')}>Go to the Home page</Button>
            </div>
        </div>
    </div>
  );
}
