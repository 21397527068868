import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import './index.scss'
import closeImg from 'assets/img/close.png'
type Props = {
    onClose: () => void,
    open: boolean,
    onConfirm: () => void,
    onCancel?: () => void,
    title?: string,
    subTitle?: string,
    confirmBtnText: string,
    cancelBtnText: string,
}
export default function ConfirmDialog(props: Props) {
    const { onClose, open, onConfirm, onCancel, title, subTitle, confirmBtnText, cancelBtnText } = props
    return (
        <Dialog open={open} onClose={onClose}>
            <div className="confirm-dialog">
                <img className='icon' src={closeImg} alt="" onClick={() => onClose()} />
                <p className="title">{title}</p>
                <p className="sub-title">{subTitle}</p>
                <div className="btn-wrap">
                    {onCancel && <Button variant="outlined" className='btn-1seed btn' disableElevation onClick={onCancel}>{cancelBtnText}</Button>}
                    <Button variant="contained" className='btn-1seed btn' disableElevation onClick={onConfirm}>{confirmBtnText}</Button>
                </div>
            </div>
        </Dialog>
    )
}