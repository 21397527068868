import React from 'react';
import './index.scss'
import { getApplylist, ApplylistResponseType, ApplyProjectType } from 'api/user';
import Filter from '../filters';
import ApplyBox from './box';
import Message from 'components/common/message';
import Empty from 'components/empty';

interface Props {
  target:string
}

export default function ApplyProfile(props:Props) {

  const { target } = props

  // sort type
  const [projects, setProjects] = React.useState<Array<ApplyProjectType>>([])
  const [status, setStatus] = React.useState(-1)

  async function getData(_status:number) {
    setStatus(_status)
    const data:ApplylistResponseType = await getApplylist({
      page_no: 1,
      page_size: 20,
      status: _status ,
      // sort:type,
      target_user_id: target
    })
    setProjects(data?.projects || [])
  }

  function onDelete(idx:number) {
    Message('Delete success!', { severity: 'error' })
    setProjects(projects.slice(idx, idx + 1))
  }

  return (
    <div className='apply-profile'>
        <Filter onChange={getData} filterPending target={target}></Filter>
        {projects.length > 0 ? <div className="project-box-wrap">
          {projects.map((item, idx) => {
            return (
              <ApplyBox key={`project_${item.project_id}`} project={item} onDelete={() => onDelete(idx)} onRefresh={() => getData(status)}></ApplyBox>
            )
          })}
        </div> : <Empty hideBtn></Empty>}
    </div>
  );
}
