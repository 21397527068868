import axios, { AxiosInstance } from 'axios'
import { getToken, setToken, getRefreshToken, setRefreshToken, removeToken, isLogin } from './auth'
import { refreshToken, LoginResponseType } from 'api/user'
import { BASE_URL } from 'config/config'

async function refresh(refresh_token:string) {
    if (!isLogin()) {
        window.location.href = '/'
        return
    }
    try {
        const data:LoginResponseType = await refreshToken(refresh_token)
        setToken(data.access_token)
        setRefreshToken(data.refresh_token)
        window.location.reload()
    } catch(e:any) {
        console.log(e)
        removeToken()
        window.location.reload()
    }
}

export default class baseRequest {
    $http: AxiosInstance
    constructor() {
        this.$http = axios.create({
            timeout: 30000,
            baseURL: BASE_URL
        })
        this.$http.interceptors.request.use(async (config:any) => {
            const token = getToken()
            config.headers = {
                Authorization: token ? `Bearer ${token}` : ''
            }
            
            return config
        })
        this.$http.interceptors.response.use(
            ({ data }:any) => {
                // return data
                if (data.code === 0) {
                    return data.data
                } else if (data.code === 10112) {
                    // error auth token
                    refresh(getRefreshToken())
                    return Promise.reject(data)
                } else if ([10113, 10114].includes(data.code)) {
                    // invalid token
                    setToken('')
                    window.location.href = '/'
                    return Promise.reject(data)
                } else {
                    console.log('code error')
                    return Promise.reject(data)
                }
            },
            (e:Error) => {
                console.log(e, 'error')
                return Promise.reject({
                    msg: 'error',
                    errorNetwork: true
                })
            }
        )
    }
    post(url:string, params = {}, config = {}) {
        return this.$http.post(url, params, config)
    }
    get(url:string, params = {}, config = {}) {
        return this.$http.get(url, {
            params,
            ...config
        })
    }
    postForm(url:string, params:FormData) {
        return this.$http({
            url,
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: params
        })
    }
    postMul(url:string, params:any) {
        const formData = new FormData()
        Object.keys(params).forEach(key => {
            formData.append(key, params[key])
        })
        return this.$http({
            url,
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        })
    }
}
