export const LOGIN_TYPE = {
    GOOGLE: '1',
    METAMASK: '2',
    CONNECT_WALLET: '3'
}

export const PROFILE_TABS = {
    INVESTED: '1',
    COMMENTS: '2',
    WATCHLIST: '3',
    APPLY: '4'
}

// sort types
export const INVESTED_SELECTOR_TYPES = {
    INVESTMENT_LOW_TO_HIGH: 0,
    INVESTMENT_HIGH_TO_LOW: 1,
    RECENTLY_LIST: 2
}

export const INVEST_STATUS = {
    ALL: -1,
    // success
    INVESTOR: 2,
    FINANCING: 1,
    FAILD: 3,
    PENDING: 0
}

export const APPLY_STATUS = {
    PENDING: 0,
    SUCCESS: 1,
    FAILED: 2,
    DRAFT: 3
}

export const POST_FIN_STAGE = {
    WAIT_FOR_NFT_MINT: 0,
    NFT_MINTED: 1,
    CLAIM_STARTED: 2
}

export const COMMENT_TYPE = {
    ANNOTATE: 0,
    QUERY: 1,
    SUGGEST: 2
}

export const COMMENT_SORT_TYPE = {
    LIKE_MOST: 0,
    COMMENT_MOST: 1,
    RECENTLY_REPLY: 2
}

export const COMMENT_REVIEW_TYPE = {
    ALL: 0,
    OFFICIAL: 1,
    INSTITUTIONAL: 2
}

export const COMMENT_LABEL = {
    NONE: 0,
    ELECT: 1,
    REJECTED: 2
}

export const PAGESIZE_MAX = Number.MAX_SAFE_INTEGER

// export const sectionEndSymbols = [',', '.', '?', '!']

export const USER_ROLE = {
    NORMAL: 0,
    INSTITUTION: 1,
    FAMER: 2,
    OFFICIAL: 3
}

export const OTC_STATUS = {
  ALL: -1,
  LIVING: 0,
  TRADING: 1,
  COMPLETED: 2
}