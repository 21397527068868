import dayjs from 'dayjs'
import { roundDetailTitleMap } from 'pages/apply/maps'
// import _ from 'lodash'
import { divide } from 'mathjs'


export const dealMetamaskAddress = (addr:string|undefined) => {
    if (!addr) return ''
    return addr.substring(0, 7) + '...' + addr.substring(addr.length - 4)
}

export const dealTime = (timestamp:number|undefined) => {
    if (!timestamp) return ''
    return dayjs(timestamp).format('MMM D, YYYY')
}

export const formatFinTime = (timestamp:number) => {
    return dayjs(timestamp * 1000).format('MMMM D.YYYY') + ' at ' + dayjs(timestamp * 1000).format('h:mm A')
}

export const formatCommunityTime = (timestamp:number|undefined) => {
    if (!timestamp) return ''
    return 'at ' + dayjs(timestamp * 1000).format('hh:mm A MMMM D, YYYY')
}

export const formatProjectTitle = (name:string|undefined, round:string|number|undefined) => {
    if (!name) return ''
    return `${name}'s ${roundDetailTitleMap[round ? +round : 0] || ''} Investment`
}

export const generateSocialLink = (social_type:string, social_type_name:string) => {
    if (!social_type || !social_type_name) {
        return ''
    }
    if (social_type === 'instagram') {
        return `https://www.instagram.com/${social_type_name}`
    }
    if (social_type === 'github') {
        return `https://github.com/${social_type_name}`
    }
    if (social_type === 'mirror') {
        return `https://mirror.xyz/${social_type_name}`
    }
    if (social_type === 'telegram') {
        return `https://t.me/${social_type_name}`
    }
    if (social_type === 'discord') {
        return ''
    }
    if (social_type === 'linkedin') {
        return ''
    }
    if (social_type === 'facebook') {
        return ''
    }
    if (social_type === 'twitter') {
        return ''
    }
}

export function dealAmountDecimal(amount:string, decimal:number | string, digit = 2):number {
    const rs = divide(+amount, Math.pow(10, +decimal))
    if (digit > -1) {
        return +(rs.toFixed(digit))
    }
    return +rs || 0
}

export function formatCommentTime(timestamp:number|undefined) {
    if (!timestamp) return ''
    const time = timestamp * 1000
    const now = Date.now()
    const left = now - time
    if (left <= 60 * 1000) {
        return 'just now'
    }
    if (left <= 60 * 60 * 1000) {
        return `${Math.floor(left / (60 * 1000))} minutes ago`
    }
    if (left <= 24 * 60 * 60 * 1000) {
        return `${Math.floor(left / (60 * 60 * 1000))} hours ago`
    }
    return dayjs(time).format('MMM D,YYYY') + ' at ' + dayjs(time).format('hh:mm a')
}

export function generateSectionTextList(text:string) {
    const sectionEndSymbols = [',', '.', '?', '!', '\n']
    let idx = 0
    const arr = text.split('')
    return arr.reduce((rs, char, arr_idx) => {
        rs[idx] += char
        if (sectionEndSymbols.includes(char) || arr.length - 1 === arr_idx) {
            idx = idx + 1
            rs.push('')
        }
        return rs
    }, [''] as Array<string>)
    .slice(0, idx)
}

export function generateEmptyArray(num:number) {
    return new Array(num).fill('')
}

export function formatMoney(value:string|number) {
    if (!value) return ''
    return (value + '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + ',')
}

export function preloadImg(urls:string[]) {
	urls.forEach(url => {
		const img = new Image()
		img.src = url
	})
}