import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';

interface Props {
    onClose: () => void
    open: boolean
    handleClose: () => void
}

export default function InvestNotifyDialog(props:Props) {
    const { open, onClose, handleClose } = props

    return (
        <Dialog open={open} onClose={onClose} className="invest-notify-dialog">
            <div className="content">
                <img src={require('assets/img/invest-detail/warning.png')} alt="" className="icon" />
                <div className="text1">User investment rights</div>
                <div className="text2">Community users participate in project contributions through the DAO pool and can obtain <span className="sub">NFT contribution certificates</span>. Users can claim corresponding shares according to the Token distribution rules of the project party.</div>
                <Button className="btn btn-1seed" variant='contained' onClick={() => {
                    onClose()
                    handleClose()
                }}>I GOT IT</Button>
            </div>
        </Dialog>
    )
}