import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { InvestInfo, InvestDetail } from 'api/finance';
import Button from '@mui/material/Button';
import { ProfileType } from 'api/user';
import { useInvestmentContract, useOneSeedPoolArenaContract } from 'utils/web3';
import Loading from 'components/common/loading';
import Message from 'components/common/message';
import LoadingBtn from 'components/common/loading-btn';
import { OneSeedPoolArenaConnector } from 'config/connector';
import useChainInterceptor from 'hooks/useChainInterceptor';

interface Props {
    investInfo: InvestInfo
    investDetail: InvestDetail
    onClose: () => void
    open: boolean
    onRefresh: () => void
    amount: number
    profile: ProfileType|undefined
    isShowProof?: boolean
}

export default function ClaimDialog(props:Props) {
    const { open, onClose, investInfo, investDetail, onRefresh, amount, profile, isShowProof } = props

    const [tokenIds, setTokenIds] = React.useState<string[]>([])
    // const [tokenAmounts, setTokenAmounts] = React.useState<string[]>([])
    const [tokenUris, setTokenUris] = React.useState<string[]>([])
    const [currentTokenIndex, setCurrentTokenIndex] = React.useState(0)

    const chainInterceptor = useChainInterceptor()

    async function claim(selectedTokenId?: string) {
        chainInterceptor(() => {
            Loading(true)
            try {
                const params = selectedTokenId ? [selectedTokenId] : tokenIds
                console.log('params:', params)
                console.log('wallet:', profile?.user_info.wallet)
                useInvestmentContract(investDetail.invest_contract).methods.claimBatch(params)
                .send({
                    from: profile?.user_info.wallet
                }).on('receipt', function(receipt:any) {
                    console.log(receipt)
                    Message('Claim success')
                    Loading(false)
                    onRefresh()
                    onClose()
                }).on('error', function(error:any, receipt:any) {
                    console.log(error, receipt)
                    Message(error.message || 'Claim failed', {severity: 'error'})
                    Loading(false)
                })
            } catch(error:any) {
                console.log(error)
                Message(error.message || 'Claim failed', {severity: 'error'})
                Loading(false)
            }
        })
    }

    async function getTokenIds() {
        const data = await useInvestmentContract(investDetail.invest_contract).methods.tokenIds(profile?.user_info.wallet).call()
        console.log('data', data)
        const _tokenIds = data.ids || []
        // const _tokenAmounts = data.amounts || []
        // console.log('_tokenIds:', _tokenIds)
        // console.log('_tokenAmounts:', _tokenAmounts)
        setTokenIds(_tokenIds)
        // setTokenAmounts(_tokenAmounts)
        getTokenUris(_tokenIds)
    }

    function getTokenUris(_tokenIds: string[]) {
        // const _tokenUris = new Array(_tokenIds.length).fill('')
        const promises = _tokenIds.map(tokenId => {
            // getTokenUri(tokenId, _tokenUris, index)
            return useOneSeedPoolArenaContract(OneSeedPoolArenaConnector.address).methods.tokenURI(tokenId).call()
        })
        Promise.all(promises).then(_tokenUris => {
            console.log('_tokenUris', _tokenUris)
            const imgs = _tokenUris.map(dataURI => {
                const json = atob(dataURI.substring(29));
                const result = JSON.parse(json);
                // console.log(atob(result?.image.replace('data:image/svg+xml;base64,', '')))
                return atob(result?.image.replace('data:image/svg+xml;base64,', '')) || ''
            })
            // console.log('imgs:', imgs)
            setTokenUris(imgs)
        })
    }

    // async function getTokenUri(tokenId:string, _tokenUris:string[], index:number) {
    //     const uri = await useInvestmentContract(investDetail.invest_contract).methods.tokenURI(tokenId).call()
    //     _tokenUris[index] = uri
    // }

    React.useEffect(() => {
        if (open) {
            getTokenIds()
        }
    }, [open])

    return (
        <Dialog open={open} onClose={onClose} className="claim-dialog">
            <img className='claim-dialog__icon' src={require('assets/img/close.png')} alt="" onClick={() => onClose()} />
            <div className="claim-dialog__content">
                <div className="nft-wrap">
                    <div className='nft-img' dangerouslySetInnerHTML={{
                        __html: tokenUris[currentTokenIndex]
                    }}></div>
                    {/* <img src={`data:image/svg+xml;utf8,${tokenUris[currentTokenIndex]}`} className="nft-img" /> */}
                    {!isShowProof && <img src={require('assets/img/invest-detail/claim-token-btn.png')} alt="" className="claim-btn" onClick={() => claim(tokenIds[currentTokenIndex])} />}
                </div>
                <div className="content-wrap">
                    <div>
                        <div className="title">Project Total Financing</div>
                        <div className="sub-title">you can claim all you tokens</div>
                        <div className="block">
                            <div className="count">My Financing Proof NFT’s Quantity</div>
                            <div className="text">{tokenIds.length}</div>
                            {tokenIds.length > 1 && <div className="count-list">
                                {tokenIds?.map((tokenId, index) => {
                                    return (
                                        <div className={`count-list-item ${index === currentTokenIndex ? 'active' : ''}`} key={`tokenId_${tokenId}`} onClick={() => setCurrentTokenIndex(index)}>{index + 1}</div>
                                    )
                                })}
                            </div>}
                        </div>
                        <div className="block">
                            <div className="item-wrap">
                                <div className="item">
                                    <div className="count">Total raised</div>
                                    <div className="text">{amount}{investInfo.token_symbol}</div>
                                </div>
                                <div className="item">
                                    <div className="count">My shares</div>
                                    <div className="text">{investDetail.my_share}{investInfo.token_symbol}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LoadingBtn
                    className="btn btn-1seed"
                    disabled={isShowProof}
                    variant='contained'
                    onClick={() => claim()}>
                        {tokenIds.length > 1 ? 'CLAIM All NFT TO TOKEN' : 'CLAIM TOKEN'}
                    </LoadingBtn>
                </div>
            </div>
        </Dialog>
    )
}