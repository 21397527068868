import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import confirmImg from '../../../assets/img/login-guide/confirm.png'
import { Button } from '@mui/material';
import './index.scss'
import { useNavigate } from 'react-router';
let timerObj: NodeJS.Timeout | undefined
let _timer = 3
export default function GuideDoneDialog(props: DialogProps) {
    const navigate = useNavigate()

    const [timer, setTimer] = React.useState(3)

    function go() {
        navigate('/', {
            replace: true
        })
    }

    React.useEffect(() => {
        if (timerObj) return
        timerObj = setInterval(() => {
            console.log(_timer)
            if (_timer > 1) {
                setTimer(--_timer)
            } else {
                clearInterval(timerObj)
                timerObj = undefined
                go()
            }
        }, 1000)
    }, [])

    return (
        <Dialog {...props}>
            <div className="guide-done-dialog">
                <img className='icon' src={confirmImg} alt="" />
                <p className="title">All done</p>
                <p className="sub-title">Start with 1seed</p>
                <Button variant="contained" className='btn-1seed btn' disableElevation onClick={go}>Continue ({timer}s)</Button>
            </div>
        </Dialog>
    )
}