import React, { useState, useEffect, useCallback } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import closeIcon from 'assets/img/close.png'
import googleIcon from 'assets/img/login/google.png'
import metamaskIcon from 'assets/img/login/metamask.png'
import './index.scss'
import { googleLogin, LoginResponseType, metaMaskLoginPre, metaMaskLogin, isFirstLogin, MetamaskLoginPreResponseType } from 'api/user';
import { setToken, setLoginType, setRefreshToken, removeToken, removeRefreshToken, getAddr, setAddr } from 'utils/auth';
import { getWeb3 } from 'utils/web3';
import Message from '../common/message';
// import { useNavigate } from 'react-router';
import { LOGIN_TYPE } from 'constant';
import Web3 from 'web3'
import { CircularProgress, Button } from '@mui/material';
// import backIcon from '../../assets/img/back.png'
import errorIcon from 'assets/img/login/error.png'
// import { supportedChainId } from 'config/connector';
// import useChainInterceptor from 'hooks/useChainInterceptor';
import { getProfile, ProfileType } from 'api/user';
// import AuthClient, { generateNonce } from "@walletconnect/auth-client";
// import { Web3Modal } from "@web3modal/standalone";
import { WALLET_CONNECT_METADATA, WALLET_CONNECT_PROJECT_ID } from 'config/config';
import { supportedChainId } from 'config/connector';
// import SignClient from '@walletconnect/sign-client'
// import {SessionTypes} from '@walletconnect/types/dist/types/sign-client/session'
import qs from 'qs';
import { useLocation } from 'react-router';

// const web3Modal = new Web3Modal({
//     projectId: WALLET_CONNECT_PROJECT_ID,
//     walletConnectVersion: 2,
// });


// google login response
type GoogleLoginResopnse = {
    access_token:string
}

interface Props extends DialogProps {
    loginType?: string
}

export default function Login(props: Props) {
    const { onClose, loginType }:React.MouseEventHandler<HTMLElement>|any = props
    // const navigate = useNavigate()

    const [metamaskLoading, setMetamaskLoading] = useState(false)
    const [metamaskLoginError, setMetamaskLoginError] = useState(false)
    // const [client, setClient] = useState<AuthClient | null>();
    // const [signClient, setSignClient] = useState<SignClient | null>();
    // const [hasInitialized, setHasInitialized] = useState(false);
    // const [uri, setUri] = useState<string>("");
    // const [session, setSession] = useState<SessionTypes.Struct>()
    // const [address, setAddress] = useState<string>("");

    // const chainInterceptor = useChainInterceptor()

    // const walletConnectChainId = `eip155:${supportedChainId}`
    const walletConnectChainPrefix = 'eip155'
    const walletConnectChainId = `${walletConnectChainPrefix}:${supportedChainId}`
    const relayUrl = 'wss://relay.walletconnect.com'
    const metadata = WALLET_CONNECT_METADATA

    const location = useLocation()

    // google
    async function login(token:string) {
        try {
            const data:LoginResponseType|null = await googleLogin({token})
            afterLogin(data, LOGIN_TYPE.GOOGLE)
        } catch (error:any) {
            console.log(error)
            Message(error.message || 'Unknown error', { severity: 'error' })
        }
    }

    // google third
    function loginGoogle() {
        // return
        // try {
        //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //     // @ts-ignore
        //     if(!google) {
        //         Message('browser not support google extention', {severity: 'warning'})
        //         return
        //     }
        // } catch(e:any) {
        //     console.log(e)
        // }
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const client = google.accounts.oauth2.initTokenClient({
                client_id: '950868637358-bgos0vgqtf9pn6pg08t32spaooacmr6m',
                scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
                callback: (response:GoogleLoginResopnse) => {
                    login(response.access_token)
                },
            });
            client.requestAccessToken()
        } catch (error) {
            console.log(error)
            Message('browser not support google extention', {severity: 'error'})
        }
        
        // // login('asdjdaksd')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // const client = google.accounts.oauth2.initCodeClient({
        //     client_id: '487089975864-b96k11neg5felfqb1g188o1r4ifpc5ft',
        //     scope: 'https://www.googleapis.com/auth/calendar.readonly',
        //     ux_mode: 'popup',
        //     callback: (response:any) => {
        //         console.log(response)
        //         login(response.code)
        //     },
        //   });
        //   client.requestCode()
    }

    // metamask third
    async function metamaskLogin() {
        // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // // @ts-ignore
        // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
        // console.log(accounts)
        // return 
        if (!Web3.givenProvider) {
            Message('Please install Metamask First', {severity:'warning'})
            window.open('https://metamask.io/download/')
            return
        }
        setMetamaskLoading(true)
        metamaskLoginNext()
        // chainInterceptor(() => {
        //     metamaskLoginNext()
        // }, () => {
        //     setMetamaskLoginError(true)
        // })
    }

    async function metamaskLoginNext() {
        try {
            const web3 = getWeb3()
            const publicAddress = await web3.eth.requestAccounts();
            const _addr = publicAddress[0] || ''
            
            if (location.search) {
                const query = qs.parse(location.search.slice(1))
                if (query.wallet && query.wallet !== _addr) {
                    Message('Use invited wallet to login', {severity:'warning'})
                    setMetamaskLoading(false)
                    return
                }
            }
            // const publicAddress = await web3.eth.requestAccounts();
            const data:MetamaskLoginPreResponseType = await metaMaskLoginPre({public_address: _addr})
            handleSignMessage({publicAddress: _addr, nonce: data.nonce})
        } catch (error:any) {
            console.log(error)
            Message(error.message || 'Unknown error', { severity: 'error' })
            setMetamaskLoginError(true)
        }
    }

    async function handleSignMessage(data:{publicAddress:string, nonce:string}) {
        try {
            const web3 = getWeb3()
            const sign = await web3.eth.personal.sign(
                web3.utils.toHex(data.nonce),
                data.publicAddress,
                '' // password?
            )
            let email_code = ''
            if (location.search) {
                const query = qs.parse(location.search.slice(1))
                email_code = query.email?.toString() || ''
            }
            const result = await metaMaskLogin({
                signature: sign,
                public_address: data.publicAddress,
                email_code
            })
            afterLogin(result, LOGIN_TYPE.METAMASK)
        } catch (error:any) {
            console.log(error)
            Message(error.message || 'Unknown error', { severity: 'error' })
            setMetamaskLoginError(true)
        }
    }

    function back() {
        setMetamaskLoading(false)
        setMetamaskLoginError(false)
    }

    // after third login
    async function afterLogin(data:LoginResponseType|null, loginType:string) {
        // setMetamaskLoading(false)
        setToken(data?.access_token)
        setRefreshToken(data?.refresh_token)
        if (loginType === LOGIN_TYPE.GOOGLE) {
            const profile:ProfileType = await getProfile({})
            const web3 = getWeb3()
            const addrs = await web3.eth.getAccounts()
            if (profile.user_info.wallet && addrs?.length && profile.user_info.wallet !== addrs[0]) {
                Message(`The wallet bound to the currently logged in Google account is inconsistent with Metamask's current wallet,try after it is consistent`, {severity: 'warning'})
                removeToken()
                removeRefreshToken()
                return
            }
        }
        onClose()
        setLoginType(loginType)
        try {
            const rs = await isFirstLogin()
            if (rs.result) {
                // first login
                // navigate('/login-guide')
                window.location.replace('/login-guide')
            } else {
                window.location.reload()
            }
        } catch (e) {
            console.log(e)
            window.location.reload()
        }
    }

    // async function loginWalletConnect(_session:SessionTypes.Struct) {
    //     if (!signClient) return
    //     const addr = _session.namespaces[walletConnectChainPrefix].accounts[0].replace(`${walletConnectChainId}:`, '')
    //     console.log('addr:', addr)
    //     const data:MetamaskLoginPreResponseType = await metaMaskLoginPre({public_address: addr})
    //     console.log('metaMaskLoginPre:', data)
    //     const signResult = await signClient.request({
    //         topic: _session.topic,
    //         chainId: walletConnectChainId,
    //         request: {
    //           method: "personal_sign",
    //           params: [data.nonce, addr]
    //         }
    //     })
    //     console.log('signResult:', signResult)
    //     const result = await metaMaskLogin({
    //         signature: signResult,
    //         public_address: addr
    //     })
    //     setAddr(addr)
    //     afterLogin(result, LOGIN_TYPE.CONNECT_WALLET)
    // }

    // async function initWalletConnectSignClient() {
    //     const _signClient = await SignClient.init({
    //         projectId: WALLET_CONNECT_PROJECT_ID,
    //         // optional parameters
    //         relayUrl,
    //         metadata
    //     })
    //     setSignClient(_signClient)
    //     _signClient.on('session_update', ({ topic, params }) => {
    //         console.log('session_update:', topic, params)
    //         const { namespaces } = params
    //         const _session = _signClient.session.get(topic)
    //         // Overwrite the `namespaces` of the existing session with the incoming one.
    //         const updatedSession = { ..._session, namespaces }
    //         // Integrate the updated session state into your dapp state.
    //         console.log('updatedSession:', updatedSession)
    //     })
    //     // const { uri, approval } = await _signClient.connect({
    //     //     // Optionally: pass a known prior pairing (e.g. from `signClient.core.pairing.getPairings()`) to skip the `uri` step.
    //     //     // pairingTopic: _signClient.core.pairing.getPairings()[0].topic,
    //     //     // Provide the namespaces and chains (e.g. `eip155` for EVM-based chains) we want to use in this session.
    //     //     requiredNamespaces: {
    //     //     eip155: {
    //     //         methods: [
    //     //         'eth_sendTransaction',
    //     //         'eth_signTransaction',
    //     //         'eth_sign',
    //     //         'personal_sign',
    //     //         'eth_signTypedData'
    //     //         ],
    //     //         chains: [walletConnectChainId],
    //     //         events: ['chainChanged', 'accountsChanged']
    //     //     }
    //     //     }
    //     // })
    //     // console.log("_signClient.connect", uri, approval)
    //     // if (uri) {
    //     //     // onClose()
    //     //     // await web3Modal.openModal({
    //     //     // uri,
    //     //     // standaloneChains: [walletConnectChainId],
    //     //     // });
    //     //     setUri(uri)
    //     //     const session = await approval()
    //     //     console.log('session', session)
    //     //     setSession(session)
    //     // }
    // }

    // const handleClickWalletConnect = useCallback(() => {
    //     if (!client) return;
    //     client
    //         .request({
    //         aud: window.location.href,
    //         domain: window.location.hostname.split(".").slice(-2).join("."),
    //         chainId: walletConnectChainId,
    //         type: "eip4361",
    //         nonce: generateNonce(),
    //         statement: "Sign in with wallet.",
    //         })
    //         .then(({ uri }) => {
    //         console.log('uri:', uri)
    //         if (uri) {
    //             setUri(uri);
    //         }
    //         });
    // }, [client, setUri]);

    // const handleClickWalletConnect = useCallback(async () => {
    //     if (!signClient) return;
    //     const { uri, approval } = await signClient.connect({
    //         // Optionally: pass a known prior pairing (e.g. from `signClient.core.pairing.getPairings()`) to skip the `uri` step.
    //         // pairingTopic: _signClient.core.pairing.getPairings()[0].topic,
    //         // Provide the namespaces and chains (e.g. `eip155` for EVM-based chains) we want to use in this session.
    //         requiredNamespaces: {
    //         eip155: {
    //             methods: [
    //             'eth_sendTransaction',
    //             'eth_signTransaction',
    //             'eth_sign',
    //             'personal_sign',
    //             'eth_signTypedData'
    //             ],
    //             chains: [walletConnectChainId],
    //             events: ['chainChanged', 'accountsChanged']
    //         }
    //         }
    //     })
    //     console.log("_signClient.connect", uri, approval)
    //     if (uri) {
    //         onClose()
    //         await web3Modal.openModal({
    //         uri,
    //         standaloneChains: [walletConnectChainId],
    //         });
    //         const _session = await approval()
    //         console.log('session', _session)
    //         // setSession(_session)
    //         // web3Modal.closeModal()
    //         loginWalletConnect(_session)
    //     }
    // }, [signClient]);

    useEffect(() => {
        // AuthClient.init({
        //     relayUrl,
        //     projectId: WALLET_CONNECT_PROJECT_ID,
        //     metadata
        //   })
        //     .then((authClient) => {
        //         console.log('authClient:', authClient)
        //         setClient(authClient);
        //         setHasInitialized(true);
        //     })
        //     .catch(console.error);
        // initWalletConnectSignClient()
    }, [])

    // useEffect(() => {
    //     if (!client) return;
    //     client.on("auth_response", ({ params }) => {
    //         console.log('auth_response', params)
    //         if ("code" in params) {
    //             console.error(params);
    //             return web3Modal.closeModal();
    //         }
    //         if ("error" in params) {
    //             console.error(params.error);
    //             if ("message" in params.error) {
    //             //   toast({
    //             //     status: "error",
    //             //     title: params.error.message,
    //             //   });
    //             Message(params.error.message, {severity:'error'})
    //             }
    //             return web3Modal.closeModal();
    //         }
    //         Message('Auth request successfully approved', {severity: 'success'})
    //         const address = params.result.p.iss.split(":")[4]
    //         console.log('address:', address)
    //         if (address) {
    //             loginWalletConnect(address)
    //         }
    //         // setAddress(params.result.p.iss.split(":")[4]);
    //     });
    //   }, [client]);

    //   useEffect(() => {
    //     async function handleOpenModal() {
    //         if (uri) {
    //             onClose()
    //             await web3Modal.openModal({
    //             uri,
    //             standaloneChains: [walletConnectChainId],
    //             });
    //         }
    //     }
    //     handleOpenModal();
    //   }, [uri]);

    //   useEffect(() => {
    //     if (address) {
    //         web3Modal.closeModal();
    //         // changeView("signedIn");
    //     }
    //   }, [address]);

    return (
        <Dialog {...props}>
            <div className="login-dialog-content">
                {!metamaskLoading ? (
                    <div>
                        {onClose && <img src={closeIcon} onClick={onClose} className="close-icon" alt='' ></img>}
                        <p className='title'>Connect With</p>
                        {/* <p className='title-sub'>Get started with {loginType || 'wallet or google'}</p> */}
                        <p className='title-sub'>Get started with wallet</p>
                        <div className="content">
                            <p className="name">Popular</p>
                            <div className="wrap" onClick={metamaskLogin}>
                                <img src={metamaskIcon} alt="" />
                                <span>Metamask</span>
                            </div>
                        </div>
                        {/* {(!loginType || loginType === 'wallet') && <div className="content">
                            <p className="name">Popular</p>
                            <div className="wrap" onClick={metamaskLogin}>
                                <img src={metamaskIcon} alt="" />
                                <span>Metamask</span>
                            </div>
                            <div className={`wrap`} onClick={handleClickWalletConnect}>
                                <img src={require('assets/img/login/walletconnect.png')} alt="" />
                                <span>Wallet connect</span>
                            </div>
                        </div>}
                        {(!loginType || loginType === 'email') && <div className="content">
                            <p className="name">Email</p>
                            <div className="wrap" onClick={loginGoogle}>
                                <img src={googleIcon} alt="" />
                                <span>Google</span>
                            </div>
                        </div>} */}
                    </div>
                ) : (
                    <div className='metamask-loading'>
                        {/* <img src={backIcon} alt="" className="back" onClick={() => back()} /> */}
                        <div className="title">Metamask</div>
                        {metamaskLoginError ? <img src={errorIcon} alt="" width='110' height='110' /> : <CircularProgress></CircularProgress>}
                        <div className="text">{metamaskLoginError ? 'Connecting error' : 'Connecting'}</div>
                        <div className="text2">{metamaskLoginError ? 'Please click try again and follow the steps to connect in your wallet.' : 'Open the Metamask browser extension to connect your wallet'}</div>
                        {metamaskLoginError && <Button variant="contained" className='btn-1seed btn' disableElevation onClick={() => back()}>Continue</Button>}
                    </div>
                )
            }
            </div>
        </Dialog>
    )
}