import React from 'react';
import Header from 'components/header';
import './index.scss'
import FormInput from 'components/common/form-input';
import { Button } from '@mui/material';
import { getFilterConfig, getAll, ExploreProjectType, ExploreFilterConfig, ExploreProjectParams, ExploreProjectResponse, getTrending, ExploreTrendingResponse, Trending } from 'api/explore';
import Filter from './filter';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { sortTypeList } from './map';
import Message from 'components/common/message';
import ExploreBox from './box';
import TrendingList from './trending-list';
import Loading from 'components/common/loading';
import Empty from 'components/empty';
import qs from 'qs'
import { useLocation } from 'react-router';
import useScroll from 'hooks/useScroll';

export default function Explore() {
  const [filterConfig, setFilterConfig] = React.useState<ExploreFilterConfig>()
  const [filter, setFilter] = React.useState<ExploreProjectParams>({
    keyword: '',
    financing_token_symbol: '',
    financing_amount_min: '',
    financing_amount_max: '',
    tracks: '',
    page_no: 1,
    page_size: 20,
    // 1 => all  1 => Financing 2 => succeed
    status: -1,
    // 0 => Ending soon  1 => Financing：low to high 2 => Financing：high to low 3=> Recently List 4=> Most  Comments 5=> Most Institutions
    sort: 0
  })
  const [openFilter, setOpenFilter] = React.useState(false)
  const [openSort, setOpenSort] = React.useState(false)
  const [list, setList] = React.useState<ExploreProjectType[]>()
  const [trendingList, setTrendingList] = React.useState<Trending[]>()
  const [isTrending, setIsTrending] = React.useState(false)
  const [pageNo, setPageNo] = React.useState(1)
  const [noMore, setNoMore] = React.useState(false)

  const location = useLocation()
  const filterRef = useDetectClickOutside({ onTriggered: () => setOpenFilter(false) });
  const sortRef = useDetectClickOutside({ onTriggered: () => setOpenSort(false) });
  useScroll(onMore)

  async function initConfig() {
    const data:ExploreFilterConfig = await getFilterConfig()
    setFilterConfig(data)
  }

  async function getData(_isTrending:boolean, _filter?:ExploreProjectParams, isMore?:boolean) {
    !isMore && Loading(true)
    try {
      if (_isTrending) {
        const data:ExploreTrendingResponse = await getTrending(_filter || filter)
        if (isMore) {
          if (!data.trending || !data.trending.length) {
            setNoMore(true)
            return
          }
          setTrendingList([...(trendingList || []), ...data.trending])
          return
        }
        setTrendingList(data.trending)
      } else {
        const data:ExploreProjectResponse = await getAll(_filter || filter)
        if (isMore) {
          if (!data.projects || !data.projects.length) {
            setNoMore(true)
            return
          }
          setList([...(list || []), ...data.projects])
          return
        }
        setList(data.projects)
      }
    } catch (e:any) {
      console.log(e)
      Message(e.message || 'Fetch Data Failed', {severity: 'error'})
    } finally {
      !isMore && Loading(false)
    }
  }

  function onMore() {
    if (noMore) return
    setPageNo(pageNo + 1)
    getData(isTrending, {...filter, page_no: pageNo + 1}, true)
  }

  async function onFilterChange(_filter:ExploreProjectParams) {
    setFilter(_filter)
    getData(isTrending, _filter)
  }

  function handleClickTrending(_isTrending:boolean) {
    setIsTrending(_isTrending)
    setPageNo(1)
    setNoMore(false)
    getData(_isTrending, {...filter, page_no: 1})
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
    initConfig()
    if (location.search) {
        const query = qs.parse(location.search.slice(1))
        const state = query.state as string
        if (state === '1') {
          handleClickTrending(true)
          return
        }
    }
    // Loading unmount caused a warning
    setTimeout(() => {
      getData(isTrending)
    })
  }, [])

  return (
    <div className="explore-page">
      <Header></Header>
      <div className="explore-container">
        <span className="explore-container__title">EXPLORE PROJECTS</span>
        <FormInput className='explore-container__search' placeholder='Search Projects' value={filter.keyword} onChange={(v) => setFilter({...filter, keyword: v})}>
          <Button className="icon-wrap" onClick={() => getData(isTrending)}>
            <img src={require('assets/img/explore/search.png')} alt="" className="icon" />
          </Button>
        </FormInput>
        <div className="explore-container__btns">
          <Button className={`btn ${isTrending ? '' : 'active'}`} variant='contained' onClick={() => handleClickTrending(false)}>All</Button>
          <Button className={`btn ${isTrending ? 'active' : ''}`} variant='contained' onClick={() => handleClickTrending(true)}>Trending</Button>
        </div>
        <div className="explore-container__filters">
          <div className="filter-wrap">
            <Button className='filter-btn' onClick={() => setOpenFilter(!openFilter)} ref={filterRef}>
              <img src={require('assets/img/explore/filter.png')} alt="" className="icon" />
              Filters
            </Button>
            {openFilter &&
            <Filter
            filter={filter}
            config={filterConfig}
            onClose={() => setOpenFilter(false)}
            onChange={(filter) => onFilterChange(filter)}
            ></Filter>}
          </div>
          <div className="selector-wrap">
            <div className="selector" ref={sortRef} onClick={() => setOpenSort(!openSort)}>
              <div className="text">{sortTypeList.find(item => item.v === filter.sort)?.k}</div>
              <img src={require('assets/img/explore/arrow-down.png')} alt="" className="icon" />
              {openSort && <div className="sort-list">
                {sortTypeList.map((item, index) => {
                  return <div className='sort' key={`sort_${index}`} onClick={() => onFilterChange({...filter, sort: item.v})}>{item.k}</div>
                })}
              </div>}
            </div>
            <Button className='btn-1seed btn' variant='contained' onClick={() => getData(isTrending)}>
              <img src={require('assets/img/explore/refresh.png')} alt="" className="icon" />
              Refresh
            </Button>
          </div>
        </div>
      </div>
      <div className={`explore-list-container ${openFilter ? 'filter-style' : ''}`}>
        {!isTrending && list && list.length && list.map((item, index) => {
          return (
            <ExploreBox project={item} key={`project_${index}`}></ExploreBox>
          )
        })}
        {((!isTrending && (!list?.length)) || (isTrending && !trendingList?.length)) && <Empty onRefresh={() => getData(isTrending)}></Empty>}
        {isTrending && <TrendingList list={trendingList || []} onRefresh={() => getData(isTrending)}></TrendingList>}
      </div>
    </div>
  );
}
