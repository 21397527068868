import React from 'react';
import Dialog from '@mui/material/Dialog';
import closeIcon from 'assets/img/close.png'
import './index.scss'
import { FollowInfo, ProfileType, follow, unfollow } from 'api/user';
import addIcon from 'assets/img/add.png'
import useLoginInterceptor from 'hooks/useLoginInterceptor';
import { useNavigate } from 'react-router';
// import InfiniteScroll from 'react-infinite-scroll-component'

type Props = {
    open: boolean,
    onClose: () => void,
    isFollowers: boolean,
    list: Array<FollowInfo>,
    handleRefleshBack: () => Promise<Array<FollowInfo>>,
    selfProfile: ProfileType | undefined,
    target: string
}

export default function FollowListDialog(props: Props) {
    const { onClose, isFollowers, list, handleRefleshBack, selfProfile, target } = props
    const loginInterceptor = useLoginInterceptor()
    const navigate = useNavigate()

    async function handleFollow(id:string) {
        await follow({
            target_user_id: id
        })
        handleRefleshBack()
    }

    async function handleUnfollow(id:string) {
        await unfollow({
            target_user_id: id
        })
        handleRefleshBack()
    }
    return (
        <Dialog {...props}>
            <div className="follow-list-dialog">
              <div className="title">{isFollowers ? 'Followers' : 'Following'}</div>
              <img src={closeIcon} alt="" className="close" onClick={onClose} />
              {/* <InfiniteScroll
                dataLength={list?.length}
                next={fetchData}
                hasMore={true}
              > */}
                <div className="list-wrap">
                    {list?.map((item, index) => {
                        return (
                            <div className="item" key={index}>
                                <img src={item.profile_url} alt="" className='avatar' onClick={() => {navigate(`/profile?id=${item.id}`);onClose()}} />
                                <div className="info">
                                    <div className="name">{item.username}</div>
                                    <div className="count">{item.follower_cnt} follower</div>
                                </div>
                                {
                                    (selfProfile?.id && target && selfProfile?.id !== target) && (!item.is_followed ? (
                                        <div className="btn add" onClick={() => loginInterceptor(handleFollow(item.id))}>
                                            <img src={addIcon} alt="" className='add-icon' />
                                                Follow
                                        </div>
                                    ) : (
                                        <div className="btn followed" onClick={() => loginInterceptor(handleUnfollow(item.id))}>
                                            <span className='following text'>Following</span>
                                            <span className='unfollow text'>Unfollow</span>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                    })}
                </div>
              {/* </InfiniteScroll> */}
              
            </div>
        </Dialog>
    )
}