import React from 'react'
import './index.scss'
import Button from '@mui/material/Button'
import { getProfile, ProfileType } from 'api/user'
import { useDetectClickOutside } from 'react-detect-click-outside';
import { isLogin } from 'utils/auth';
import SettingDialog from '../setting-dialog';
import Login from 'components/login';

interface Props {
    className?: string
    hideLogin?: boolean
}

export default function HeaderProfile(props: Props) {

    const { className = '', hideLogin = false } = props

    const [profile, setProfile] = React.useState<ProfileType>()
    const [open, setOpen] = React.useState(false)
    const [openSetting, setOpenSetting] = React.useState(false)
    
    const ref = useDetectClickOutside({ onTriggered: () => setOpenSetting(false) });

    async function initProfile() {
        if (!isLogin()) return
        const data = await getProfile({})
        setProfile(data)
    }

    function handleCloseSeeting() {
        setOpenSetting(false)
    }

    React.useEffect(() => {
        initProfile()
    }, [])

    return (
        <div className={`header-profile ${className}`} ref={ref}>
            {
                !isLogin() ? 
                (!hideLogin && <Button variant='contained' className="btn-right btn-1seed" onClick={() => setOpen(true)}>Connect</Button>)
                : 
                (<div className={`avatar-wrap ${profile?.user_info.profile_uri ? 'bg' : ''}`} 
                    onClick={() => setOpenSetting(!openSetting)}>
                    <img src={profile?.user_info.profile_uri} alt="" className='avatar'></img>
                </div>)
            }
            {openSetting && <SettingDialog open={openSetting} onClose={handleCloseSeeting}></SettingDialog>}
            <Login onClose={() => setOpen(false)} open={open}></Login>
        </div>
    )
}