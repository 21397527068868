import Web3 from "web3";
import {AbiItem} from 'web3-utils';
import Erc20ABI from '../config/abi/Erc20.json'
import OneSeedPoolArenaABI from 'config/abi/OneSeedPoolArena.json'
import InvestmentABI from 'config/abi/Investment.json'
import OTC from 'config/abi/OTC.json'
import { getUrlProvider } from "./providers";
import ProfileABI from 'config/abi/Profile.json'
import { CALL_RPC, WALLET_CONNECT_METADATA } from "config/config";
import { getLoginType, isLogin } from "./auth";
import { LOGIN_TYPE } from "constant";
// import Provider, { EthereumProvider } from "@walletconnect/ethereum-provider";
import { supportedChainId } from "config/connector";
import { WALLET_CONNECT_PROJECT_ID } from "config/config";
// import { provider } from "web3-core";
// import { EthereumProviderOptions } from "@walletconnect/ethereum-provider/dist/types/EthereumProvider";

// let ethereumProvider: Provider

const isLoginWalletConnect = isLogin() && getLoginType() === LOGIN_TYPE.CONNECT_WALLET

// const getEthernumProvider = async (options?: EthereumProviderOptions) => {
//     if (!isLoginWalletConnect) return
//     if (!ethereumProvider) {
//         ethereumProvider = await EthereumProvider.init({
//             projectId: WALLET_CONNECT_PROJECT_ID, // REQUIRED your projectId
//             chains: [supportedChainId], // REQUIRED chain ids
//             showQrModal: true, // REQUIRED set to "true" to use @walletconnect/modal,
//             methods: ['eth_getBalance'], // OPTIONAL ethereum methods
//             // events, // OPTIONAL ethereum events
//             rpcMap: {
//                 [supportedChainId]: CALL_RPC
//             }, // OPTIONAL rpc urls for each chain
//             metadata: WALLET_CONNECT_METADATA, // OPTIONAL metadata of your app
//             // qrModalOptions, // OPTIONAL - `undefined` by default
//             ...options
//           });
//     }
//     console.log('ethereumProvider:', ethereumProvider)
//     return ethereumProvider
// }

// getEthernumProvider()


// export const web3 = new Web3(Web3.givenProvider);

export const getWeb3 = (chainId?: number) => {
    // if (isLoginWalletConnect) {
    //     // const ethereumProvider = await getEthernumProvider()
    //     // if (ethereumProvider) {
    //     //     return new Web3(ethereumProvider)
    //     // }
    //     if (!ethereumProvider) {
    //         throw new Error('ethereumProvider init is not completed')
    //     }
    //     return new Web3(ethereumProvider)
    // }
    return chainId ? (new Web3(getUrlProvider(chainId))) : new Web3(Web3.givenProvider)
}

export function getWeb3ByRpc(rpc?:string) {
    // if (isLoginWalletConnect) {
    //     // const ethereumProvider = await getEthernumProvider(
    //     //     // {
    //     //     //     rpcMap: {
    //     //     //         [supportedChainId]: rpc || CALL_RPC
    //     //     //     }
    //     //     // }
    //     // )
    //     // if (ethereumProvider) {
    //     //     return new Web3(ethereumProvider)
    //     // }
    //     if (!ethereumProvider) {
    //         throw new Error('ethereumProvider init is not completed')
    //     }
    //     return new Web3(ethereumProvider)
    // }
    return new Web3(new Web3.providers.HttpProvider(rpc || CALL_RPC))
}

const useContract = (abi:AbiItem[], address:string, chainId?:number, providerUrl?: string) => {
    let _web3
    if (chainId) {
        _web3 = getWeb3(chainId)
    } else if (providerUrl) {
        _web3 = getWeb3ByRpc(providerUrl)
    } else {
        _web3 = getWeb3()
    }
    return new _web3.eth.Contract(abi, address)
}

export const useErc20Contract = (address: string, chainId?: number, providerUrl?: string) => {
    // const chainId = await web3.eth.getChainId()
    return useContract(Erc20ABI.abi as AbiItem[], address, chainId, providerUrl)
}
export const useOneSeedPoolArenaContract = (address: string, chainId?: number) => {
    return useContract(OneSeedPoolArenaABI.abi as AbiItem[], address, chainId)
}

export const useInvestmentContract = (address: string, chainId?: number, providerUrl?: string) => {
    return useContract(InvestmentABI.abi as AbiItem[], address, chainId, providerUrl)
}

export const useOTCContract = (address: string, chainId?: number) => {
    return useContract(OTC.abi as AbiItem[], address, chainId)
}

export const useProfileContract = (address: string, chainId?: number, providerUrl?: string) => {
    return useContract(ProfileABI.abi as AbiItem[], address, chainId, providerUrl)
}