import { useEffect } from 'react'
export default function useScroll(cb: () => void) {

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    })


    function onScroll() {
        const scrollTop = document.documentElement.scrollTop
        const scrollHeight = document.documentElement.scrollHeight
        const clientHeight = document.documentElement.clientHeight
        // console.log(document.documentElement.scrollTop, document.documentElement.scrollHeight, document.documentElement.clientHeight,)
        if (scrollTop + clientHeight === scrollHeight) {
            // console.log('on bottom')
            cb()
        }
    }
}