import React from 'react'
import './index.scss'

export default function PrivateTag() {
    
    return (
        <div className="private-tag">
            <img src={require('assets/img/invest-detail/private.png')} alt="" className="icon" />
            <span className="text">Private Financing Invitation</span>
        </div>
    )
}