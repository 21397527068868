import { isLogin, getToken } from "./auth"
import { WS_SCOPE, WS_REGISTER_TYPE } from "constant/ws"
import { WSS_URL } from "config/config"

let ws: WebSocket

export type WSMessage = {
    msg_id: string,
    msg: string,
    type: number
}

export function getWS() {
    if (!ws && isLogin()) {
        ws = new WebSocket(`${WSS_URL}?token=${getToken()}`)
        ws.onopen = function() {
            console.log('ws connected')
            registerMsg(WS_SCOPE.DEFAULT)
        }
        ws.onclose = function() {
            console.log('ws closed')
        }
        ws.onerror = function() {
            console.log('ws error')
        }
    }
    return ws
}

export function registerMsg(scope:string) {
    ws.send(JSON.stringify({
        scope,
        register_type: WS_REGISTER_TYPE.REGISTER,
        token: getToken()
    }))
}

export function unRegisterMsg(scope:string) {
    if (!ws || ws.readyState !== ws.OPEN) return
    getWS().send(JSON.stringify({
        scope,
        register_type: WS_REGISTER_TYPE.UNREGISTER,
        token: getToken()
    }))
}

export function readMsg(msgData:WSMessage, scope = WS_SCOPE.DEFAULT) {
    if (!ws || ws.readyState !== ws.OPEN) return
    getWS().send(JSON.stringify({
        scope,
        message_id: msgData.msg_id,
        read_type: '1',
        token: getToken()
    }))
}

export function readMsgScope(sub_scope:string, scope = WS_SCOPE.WATCHER) {
    if (!ws || ws.readyState !== ws.OPEN) return
    console.log({
        scope,
        sub_scope,
        read_type: '2',
        token: getToken()
      })
    getWS().send(JSON.stringify({
        scope,
        sub_scope,
        read_type: '2',
        token: getToken()
      }))
}