import React from 'react'
import './index.scss'
import { InvestInfo, InvestDetail, getDetail, watch, unwatch } from 'api/finance'
import { Button } from '@mui/material'
import { useInvestmentContract, useOneSeedPoolArenaContract } from 'utils/web3';
import { OneSeedPoolArenaConnector } from 'config/connector';
import { getParam, GetParamResp } from 'api/finance';
import { parseUnits } from 'ethers';
import Loading from 'components/common/loading';
import Message from 'components/common/message';
import { PROJECT_STATUS } from 'pages/invest-detail/map';
// import _ from 'lodash'
import InvestDialog from './invest-dialog';
import { ProfileType } from 'api/user';
import ConfirmDialog from 'components/common/confirm-dialog';
// import { useNavigate } from 'react-router';
import { dealAmountDecimal } from 'utils/utils';
import { INVEST_STATUS, POST_FIN_STAGE, USER_ROLE } from 'constant';
import CreateNFTDialog from './create-NFT-dialog';
import CalendarGuideTips from './calendar-guide-tips';
import GuideTips from './guide-tips';
import ClaimDialog from './claim-dialog';
import Tips from 'components/tips';
import { isLogin } from 'utils/auth';
import useLoginInterceptor from 'hooks/useLoginInterceptor';
import InvestNotifyDialog from './invest-notify-dialog';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useChainInterceptor from 'hooks/useChainInterceptor';
import RemindDialog from 'components/common/remind-dialog';
import CalendarInviteDialog from './calendar-invite-dialog';
import { CALL_RPC } from 'config/config';
import DiscussInvitationCalendarDialog from './discuss-invitation-calendar-dialog';
import SwitchPublicDialog from './switch-public-dialog';
import VisibleListDialog from './visible-list-dialog';

interface Props {
    investInfo: InvestInfo
    investDetail: InvestDetail
    isSelfProject: boolean
    projectStatus: number
    onRefresh: () => void
    profile: ProfileType|undefined
    onRefreshProfile: () => void
    onWatch: (iswatch: boolean) => void,
    isPrivate: boolean
}

export default function InvestCard(props: Props) {
    const { investInfo, investDetail, isSelfProject, projectStatus, onRefresh, profile, onRefreshProfile, onWatch, isPrivate } = props

    // const navigate = useNavigate()

    const loginInterceptor = useLoginInterceptor()

    const chainInterceptor = useChainInterceptor()

    const [amount, setAmount] = React.useState(0)
    const [open, setOpen] = React.useState(false)
    const [openConfirmClaim, setOpenConfirmClaim] = React.useState(false)
    const [openConfirmRefund, setOpenConfirmRefund] = React.useState(false)
    // const [isCreateNFTDone, setIsCreateNFTDone] = React.useState(false)
    // for user
    const [isinvested, setIsInvested] = React.useState(false)
    const [openCreateNFT, setOpenCreateNFT] = React.useState(false)
    const [openGuideTips, setOpenGuideTips] = React.useState(false)
    const [openCalendarGuideTips, setOpenCalendarGuideTips] = React.useState(false)
    const [openCalendarInviteDialog, setOpenCalendarInviteDialog] = React.useState(false)
    const [openCalendarInviteSuccessDialog, setOpenCalendarInviteSuccessDialog] = React.useState(false)
    const [openDiscussCalendarInviteDialog, setOpenDiscussCalendarInviteDialog] = React.useState(false)
    const [openInvestNotifyDialog, setOpenInvestNotifyDialog] = React.useState(false)
    const [openSwitchPublicDialog, setOpenSwitchPublicDialog] = React.useState(false)
    const [openVisibleListDialog, setOpenVisibleListDialog] = React.useState(false)

    const [amountBlink, setAmountBlink] = React.useState(false)

    const [isShowProof, setIsShowProof] = React.useState(false)
    const [isInvestFailed, setIsInvestFailed] = React.useState(false)
    const [myShare, setMyShare] = React.useState('')

    function getMin() {
        return +((+investInfo.goal * (100 - investInfo.fin_rule) / 100).toFixed(2))
    }

    function getMax() {
        return +((+investInfo.goal * (100 + investInfo.fin_rule) / 100).toFixed(2))
    }

    async function getContractAmount() {
        // const chainId = await web3.eth.getChainId()
        const data = await useInvestmentContract(investDetail.invest_contract, undefined, CALL_RPC)
        .methods.investTotalAmount().call()
        // console.log('realTime amount:', data)
        console.log('realTime amount', data)
        if (data) {
            // setAmount(+((_.divide(data, Math.pow(10, investInfo.token_decimal))).toFixed(2)) || 0)
            setAmount(dealAmountDecimal(data, investInfo.token_decimal))
            return
        }
        setAmount(0)
    }

    async function createFundingPool() {
        setOpenGuideTips(false)
        chainInterceptor(async () => {
            try {
                Loading(true)
                const params:GetParamResp = await getParam({project_id: investInfo.project_id})
                // const accounts = await web3.eth.getAccounts()
                useOneSeedPoolArenaContract(OneSeedPoolArenaConnector.address).methods.createInvestmentInstance([
                    params.param.name,
                    params.param.symbol,
                    [
                    params.param.investment_key.collateral_token,
                    parseUnits(
                        params.param.investment_key.min_financing_amount,
                        0
                    ),
                    parseUnits(
                        params.param.investment_key.max_financing_amount,
                        0
                    ),
                    parseUnits(
                        params.param.investment_key.user_min_invest_amount,
                        0
                    ),
                    params.param.investment_key.financing_wallet,
                    params.param.investment_key.duration,
                    ],
                ], params.signature)
                .send({
                    from: investInfo.wallet
                }).on('receipt', function(receipt:any) {
                    console.log(receipt)
                    // Message('Create funding pool success')
                    // Loading(false)
                    // onRefresh()
                    refreshInvestInfoInterval(8)
                }).on('error', function(error:any, receipt:any) {
                    console.log(error, receipt)
                    Message(error.message || 'Create funding pool failed', {severity: 'error'})
                    Loading(false)
                })
            } catch (e:any) {
                console.log(e)
                Message(e.message || 'Create funding pool failed', {severity:"error"})
                Loading(false)
            }
        })
    }

    function onCreatePoolSuccess() {
        Message('Create funding pool success')
        Loading(false)
        onRefresh()
    }

    async function refreshInvestInfoInterval(times:number) {
        if (times <= 0) {
            onCreatePoolSuccess()
            return
        }
        try {
            const data:InvestDetail = await getDetail({project_id: investInfo.project_id})
            if (data.status === INVEST_STATUS.FINANCING) {
                onCreatePoolSuccess()
            } else {
                setTimeout(() => {
                    refreshInvestInfoInterval(times - 1)
                }, 4000)
            }
        } catch(e:any) {
            console.log(e)
            onCreatePoolSuccess()
        }
    }

    // async function claim() {
    //     Loading(true)
    //     try {
    //         console.log('wallet:', profile?.user_info.wallet)
    //         const investmentContract = useInvestmentContract(investDetail.invest_contract)
    //         const tokenIds = await investmentContract.methods.tokenIds(profile?.user_info.wallet).call()
    //         console.log('tokenIds:', tokenIds)
    //         investmentContract.methods.claimBatch(tokenIds)
    //         .send({
    //             from: profile?.user_info.wallet
    //         }).on('receipt', function(receipt:any) {
    //             console.log(receipt)
    //             Message('Claim success')
    //             Loading(false)
    //             onRefresh()
    //             setOpenConfirmClaim(false)
    //         }).on('error', function(error:any, receipt:any) {
    //             console.log(error, receipt)
    //             Message(error.message || 'Claim failed', {severity: 'error'})
    //             Loading(false)
    //         })
    //     } catch(error:any) {
    //         console.log(error)
    //         Message(error.message || 'Claim failed', {severity: 'error'})
    //         Loading(false)
    //     }
    // }

    async function refund() {
        chainInterceptor(() => {
            try {
                Loading(true)
                useInvestmentContract(investDetail.invest_contract).methods.refund()
                .send({
                    from: profile?.user_info.wallet
                }).on('receipt', function(receipt:any) {
                    console.log(receipt)
                    Message('Refund success')
                    Loading(false)
                    onRefresh()
                    setOpenConfirmRefund(false)
                }).on('error', function(error:any, receipt:any) {
                    console.log(error, receipt)
                    Message(error.message || 'Refund failed', {severity: 'error'})
                    Loading(false)
                })
            } catch(error:any) {
                console.log(error)
                Message(error.message || 'Refund failed', {severity: 'error'})
                Loading(false)
            }
        })
        
    }

    // async function initCreateNFTStatus() {
    //     const investmentContract = useInvestmentContract(investDetail.invest_contract)
    //     const investorCount = await investmentContract.methods.investorCount().call()
    //     const tokenCount = await investmentContract.methods.tokenCount().call()
    //     console.log(investorCount, tokenCount)
    //     setIsCreateNFTDone(investorCount === tokenCount)
    // }

    async function initInvestorStatus() {
        if (!profile?.user_info.wallet || !investDetail.invest_contract) return
        try {
            // console.log(investDetail.invest_contract, profile?.user_info.wallet)
            // const investorAmount = await useInvestmentContract(investDetail.invest_contract).methods.investorAmount(profile?.user_info.wallet).call()
            // console.log("investorAmount:", investorAmount)
            // setIsInvested(investorAmount && investorAmount > 0)
            const data1 = await useInvestmentContract(investDetail.invest_contract).methods.tokenIds(profile?.user_info.wallet).call()
            if (investDetail.status === INVEST_STATUS.INVESTOR || data1?.amounts?.length) {
                setIsInvested(true)
            } else {
                const data2 = await useInvestmentContract(investDetail.invest_contract).methods.investorAmount(profile?.user_info.wallet).call()
                setIsInvested(data2 && +data2 > 0)
            }
        } catch(e:any) {
            console.log(e)
            setIsInvested(false)
        }
    }

    // function initStatus() {
    //     // initCreateNFTStatus()
    //     initInvestorStatus()
    // }

    async function createNFT() {
        setOpenCreateNFT(true)
    }

    function goCalendar() {
        // window.open(`https://calendly.com/leichengsanc`)
        // window.open(investDetail.google_calendar_url)
        setOpenCalendarGuideTips(false)
        if (investDetail.google_calendar_url) {
            window.open(investDetail.google_calendar_url)
            return
        }
        setOpenCalendarInviteDialog(true)
    }

    function handleClickClaim() {
        setIsShowProof(false)
        setOpenConfirmClaim(true)
    }

    function handleClickProof() {
        setIsShowProof(true)
        setOpenConfirmClaim(true)
    }

    async function getIsInvestFailed() {
        if (!investDetail) return
        const data = await useInvestmentContract(investDetail.invest_contract).methods.isInvestFailed().call()
        console.log('isInvestFailed：', data)
        setIsInvestFailed(!!data)
    }

    function handleCalendarInviteSuccess() {
        setOpenCalendarInviteDialog(false)
        setOpenCalendarInviteSuccessDialog(true)
    }

    async function initMyShare() {
        if (!profile?.user_info.wallet || !investDetail.invest_contract) return
        let data = 0
        const data1 = await useInvestmentContract(investDetail.invest_contract).methods.tokenIds(profile?.user_info.wallet).call()
        console.log('tokenIds', data1, data1.amounts)
        if (investDetail.status === INVEST_STATUS.INVESTOR || data1?.amounts?.length) {
            data1.amounts.forEach((item:string) => {
                data += +item
            })
        } else {
            const data2 = await useInvestmentContract(investDetail.invest_contract).methods.investorAmount(profile?.user_info.wallet).call()
            data = data2
            console.log('investorAmount', data2)
        }
        setMyShare(data + '')
    }

    async function watchProject() {
        try {
            await watch({
                project_id: investInfo.project_id
            })
            onWatch(true)
            Message('Add to watchlist success')
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Add to watchlist failed', {severity: 'error'})
        }
    }

    async function unwatchProject() {
        try {
            await unwatch({
                project_id: investInfo.project_id
            })
            onWatch(false)
            Message('Remove from watchlist success')
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Remove from watchlist failed', {severity: 'error'})
        }
    }

    function handleSwitchPublic() {
        onRefresh()
    }

    function handleClickSwitchPublic() {
        if (investInfo.allow_public !== 1) {
            Message('Not allowed', {severity:'warning'})
            return
        }
        setOpenSwitchPublicDialog(true)
    }

    React.useEffect(() => {
        if (!investDetail.invest_contract) return
        window.scrollTo(0, 0)
        getContractAmount()
        const interval = setInterval(() => {
            getContractAmount()
        }, 5000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    React.useEffect(() => {
        initInvestorStatus()
        initMyShare()
    }, [profile])

    React.useEffect(() => {
        if (isSelfProject && !investDetail.google_calendar_url) {
            const isFirst = window.sessionStorage.getItem(`first_calendar_guide_${investInfo.project_id}`)
            if (isFirst) return
            setOpenCalendarGuideTips(true)
            window.sessionStorage.setItem(`first_calendar_guide_${investInfo.project_id}`, '1')
            window.scrollTo(0, 0)
        }
        // setOpenCalendarGuideTips(true)
    }, [isSelfProject, investDetail])

    React.useEffect(() => {
        if (isSelfProject && projectStatus === PROJECT_STATUS.PENDING_CREATE_FUNDING_POOL) {
            setOpenGuideTips(true)
        }
        // setOpenGuideTips(true)
    }, [isSelfProject, projectStatus])

    React.useEffect(() => {
        if (amount > 0) {
            setAmountBlink(true)
            setTimeout(() => {
                setAmountBlink(false)
            }, 1000)
        }
    }, [amount])

    React.useEffect(() => {
        getIsInvestFailed()
    }, [investDetail])

    return (
        <div className="invest-card">
            <div className="content">
                <div className="text-wrap">
                    <div className="title">MY SHARES</div>
                    <div className={`amount`}>{dealAmountDecimal(myShare, investInfo.token_decimal) || 0} {investInfo.token_symbol}</div>
                </div>
                {(isSelfProject ||
                    (investDetail.google_calendar_url &&
                    [USER_ROLE.FAMER, USER_ROLE.INSTITUTION, USER_ROLE.OFFICIAL].includes(profile?.user_info.role || 0))) &&
                    <div className="calendar-wrap">
                        <img src={require('assets/img/invest-detail/info.png')} alt="" className="icon" onClick={() => setOpenDiscussCalendarInviteDialog(true)} />
                        <span className="text" onClick={() => setOpenDiscussCalendarInviteDialog(true)}>discuss invitation</span>
                        <img
                        src={require('assets/img/invest-detail/calendar.png')}
                        alt=""
                        className={`calendar-icon ${openCalendarGuideTips?'guide':''}`}
                        onClick={goCalendar}
                        />
                    </div>
                }
            </div>
            {/* <div className="title">Real-time Amount</div>
            <div className={`amount ${amountBlink ? 'blink' : ''}`}>{amount || 0} {investInfo.token_symbol}</div> */}
            {/* <div className="fin-progress">
                <div className="progress"
                    style={{
                        width: `${(+amount / getMax()) * 100}%`
                    }}></div>
                <div className="total" style={{
                    left: `${+investInfo.goal / getMax() * 100}%`
                }}></div>
            </div> */}
            {/* <div className="text"><p className="sub">Total raised {investInfo.goal} {investInfo.token_symbol}.</p>Minimum raised {getMin()}{investInfo.token_symbol}, Hardcap is {getMax()}{investInfo.token_symbol}.</div> */}
            <div className="btn-wrap">
                {isLogin() ? (<>
                {/* project self btn */}
                {isSelfProject && <>
                    {PROJECT_STATUS.PENDING_CREATE_FUNDING_POOL === projectStatus &&
                        <Button
                        className={`btn btn-1seed ${openGuideTips ? 'top' : ''}`}
                        variant='contained'
                        onClick={() => createFundingPool()}
                        >Create funding pool</Button>
                    }
                    {PROJECT_STATUS.PENDING_CREATE_FUNDING_POOL !== projectStatus &&
                        <div className="tips-wrap">
                            <Button
                            className="btn btn-1seed"
                            variant='contained'
                            // disabled={!(PROJECT_STATUS.SUCCESS === projectStatus && investDetail.post_fin_stage === POST_FIN_STAGE.WAIT_FOR_NFT_MINT)}
                            disabled={
                                amount < +investInfo.goal ||
                                [POST_FIN_STAGE.CLAIM_STARTED, POST_FIN_STAGE.NFT_MINTED].includes(investDetail.post_fin_stage)
                            }
                            onClick={createNFT}
                            >Create Financing NFT</Button>
                            {
                                PROJECT_STATUS.FINANCING === projectStatus &&
                                !(amount < +investInfo.goal ||
                                [POST_FIN_STAGE.CLAIM_STARTED, POST_FIN_STAGE.NFT_MINTED].includes(investDetail.post_fin_stage)) &&
                                <Tips text='When 100% of the financing amount is reached, you can trigger Create Finaning NFT to end this round of financing early'></Tips>
                            }
                        </div>
                    }
                </>}
                {/* {PROJECT_STATUS.PENDING_AUDIT === projectStatus &&
                <Button className="btn btn-1seed" variant='contained' disabled>Waiting for audit</Button>} */}
                {/* investor btn */}
                {!isSelfProject && <>
                    {PROJECT_STATUS.FINANCING === projectStatus &&
                        <Button
                        className="btn btn-1seed"
                        variant='contained'
                        onClick={() => setOpen(true)}
                        ><NotificationsIcon className='btn-icon' onClick={e => {
                            e.stopPropagation();
                            setOpenInvestNotifyDialog(true)
                        }}></NotificationsIcon> Invest</Button>
                    }
                    {PROJECT_STATUS.SUCCESS === projectStatus && isinvested
                    && investDetail.post_fin_stage !== POST_FIN_STAGE.CLAIM_STARTED &&
                        <div className="tips-wrap">
                            <Button
                            className="btn btn-1seed"
                            variant='contained'
                            disabled={investDetail.post_fin_stage === POST_FIN_STAGE.WAIT_FOR_NFT_MINT}
                            onClick={handleClickProof}
                            >My Financing Proof</Button>
                            {investDetail.post_fin_stage === POST_FIN_STAGE.WAIT_FOR_NFT_MINT && <Tips text='Mint NFT in progress!'></Tips>}
                        </div>
                    }
                    {PROJECT_STATUS.SUCCESS === projectStatus && isinvested
                    && investDetail.post_fin_stage === POST_FIN_STAGE.CLAIM_STARTED &&
                        <Button
                        className="btn btn-1seed"
                        variant='contained'
                        disabled={!isinvested}
                        onClick={handleClickClaim}
                        >My Financing Proof</Button>
                    }
                    {PROJECT_STATUS.SUCCESS === projectStatus && !isinvested &&
                        <Button
                        className="btn btn-1seed"
                        variant='contained'
                        disabled
                        >Invest</Button>
                    }
                    {PROJECT_STATUS.FAILED === projectStatus &&
                        <Button
                        className="btn btn-1seed"
                        variant='contained'
                        disabled={!isinvested || !isInvestFailed}
                        onClick={() => setOpenConfirmRefund(true)}
                        >Refund</Button>
                    }
                </>}
                {/* {(isSelfProject ||
                (investDetail.google_calendar_url &&
                [USER_ROLE.FAMER, USER_ROLE.INSTITUTION, USER_ROLE.OFFICIAL].includes(profile?.user_info.role || 0))) &&
                <img
                src={require('assets/img/invest-detail/calendar.png')}
                alt=""
                className={`icon ${openCalendarGuideTips?'guide':''}`}
                onClick={goCalendar}
                />} */}
                </>) : <Button
                    className={`btn btn-1seed`}
                    variant='contained'
                    onClick={() => loginInterceptor()}
                    >login</Button>
                }
                {!isSelfProject && (!investDetail.watched ?
                    (<Button className="btn btn-1seed outline-btn" variant='outlined' onClick={() => loginInterceptor(watchProject)}>
                        {/* <img src={require('assets/img/invest-detail/add.png')} alt="" className="icon" /> */}
                        Add to watchlist
                    </Button>) :
                    (<Button className="btn btn-1seed at-watch" variant='outlined' onClick={() => loginInterceptor(unwatchProject)}>
                        <div className="text text-default">At watchlist</div>
                        <div className="text text-hover">Remove</div>
                    </Button>))
                }
                {isSelfProject && isPrivate && 
                    <Button
                    className="btn btn-1seed outline-btn private-btn"
                    variant='outlined'
                    onClick={handleClickSwitchPublic}
                    >
                        <span className="text">Switch to public</span>
                        <div className="private-icon-wrap"
                            onClick={e => {
                                e.stopPropagation()
                                setOpenVisibleListDialog(true)
                            }}>
                            <img
                            src={require('assets/img/invest-detail/person.png')}
                            alt=""
                            className="icon" />
                        </div>
                    </Button>
                }
            </div>

            <InvestDialog
            open={open}
            onClose={() => setOpen(false)}
            investInfo={investInfo}
            investDetail={investDetail}
            profile={profile}
            max={getMax() || 0}
            amount={amount}
            onRefreshProfile={onRefreshProfile}
            onRefresh={onRefresh}
            ></InvestDialog>

            <CreateNFTDialog
            open={openCreateNFT}
            onClose={() => setOpenCreateNFT(false)}
            investInfo={investInfo}
            investDetail={investDetail}
            onRefresh={onRefresh}
            amount={amount}
            profile={profile}
            ></CreateNFTDialog>

            {!openGuideTips && <CalendarGuideTips
            onClose={() => setOpenCalendarGuideTips(false)}
            open={openCalendarGuideTips}
            ></CalendarGuideTips>}

            <GuideTips
            onClose={() => setOpenGuideTips(false)}
            open={openGuideTips}
            ></GuideTips>

            {/* <RemindDialog
                open={openGuideTips}
                onClose={() => setOpenGuideTips(false)}
                handleClickBtn={() => setOpenGuideTips(false)}
                title={`Guide Tips`}
                content={`Congratulations on passing the platform's project financing review, please complete the financing contract deployment, and start financing after completion.`}
            ></RemindDialog> */}

            <ClaimDialog
            open={openConfirmClaim}
            onClose={() => setOpenConfirmClaim(false)}
            investInfo={investInfo}
            investDetail={investDetail}
            onRefresh={onRefresh}
            amount={amount}
            profile={profile}
            isShowProof={isShowProof}
            ></ClaimDialog>

            <ConfirmDialog
            open={openConfirmRefund}
            title={`Confirm to refund?`}
            confirmBtnText='Confirm'
            cancelBtnText='Cancel'
            onClose={() => setOpenConfirmRefund(false)}
            onCancel={() => setOpenConfirmRefund(false)}
            onConfirm={() => refund()}
            ></ConfirmDialog>

            <InvestNotifyDialog
            open={openInvestNotifyDialog}
            onClose={() => setOpenInvestNotifyDialog(false)}
            handleClose={() => setOpen(true)}
            ></InvestNotifyDialog>

            <DiscussInvitationCalendarDialog
            open={openDiscussCalendarInviteDialog}
            onClose={() => setOpenDiscussCalendarInviteDialog(false)}
            onClickGuide={() => {setOpenDiscussCalendarInviteDialog(false);setOpenCalendarInviteDialog(true)}}
            ></DiscussInvitationCalendarDialog>

            <SwitchPublicDialog
            open={openSwitchPublicDialog}
            onClose={() => setOpenSwitchPublicDialog(false)}
            onConfirm={handleSwitchPublic}
            investInfo={investInfo}
            ></SwitchPublicDialog>

            <VisibleListDialog
            open={openVisibleListDialog}
            onClose={() => setOpenVisibleListDialog(false)}
            investInfo={investInfo}
            ></VisibleListDialog>

            <CalendarInviteDialog
            open={openCalendarInviteDialog}
            onClose={() => setOpenCalendarInviteDialog(false)}
            projectId={investInfo.project_id}
            onSuccess={handleCalendarInviteSuccess}
            ></CalendarInviteDialog>

            <RemindDialog
                open={openCalendarInviteSuccessDialog}
                onClose={() => setOpenCalendarInviteSuccessDialog(false)}
                handleClickBtn={() => setOpenCalendarInviteSuccessDialog(false)}
                title={`Success`}
                content={`Congratulations on opening the project financing Invitation Calendar. Please pay attention to the invitation notices from you and investors`}
            ></RemindDialog>

        </div>
    )
}