import { presignUrl } from "api/utility";
import axios from "axios";

export async function uploadFile(
    file:File|null,
    params: {
        type: number,
        index?: number
    },
    options?: {
        onChange?: (url:string, postfix?: string) => void,
        onProgress?: (flag:boolean) => void,
        onError?: (e:any) => void
    }
    )
    {
        if (!file) {
            return
        }
        const { onChange, onProgress, onError } = options || {}
        try {
            onProgress && onProgress(true)
            const { url } = await presignUrl({
                length: file.size,
                postfix: file.name.substring(file.name.lastIndexOf('.') + 1),
                ...params
            })
            await axios({
                url,
                method: 'put',
                data: file,
                headers: {
                    'x-amz-acl': 'public-read'
                }
            })
            const _url = url.substring(0, url.indexOf('?')) + `?t=${Date.now()}`
            onChange && onChange(_url, file.name)
            return _url
        } catch (e:any) {
            onError && onError(e)
            return ''
        } finally {
            onProgress && onProgress(false)
        }
}