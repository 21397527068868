import React from 'react';
import './index.scss'
import { CommentsInProjects } from 'api/user';
import { INVEST_STATUS } from 'constant';
import CommentBox from 'components/comment-detail/comment-box';
import { ProfileType } from 'api/user';
import { Reply, SectionCommentWithReplies, GetRepliesResponse, getReplies, Comment } from 'api/community';
import CommentDetail from 'components/comment-detail';
import { InvestInfo, getInfo } from 'api/finance';

interface Props {
    data: CommentsInProjects
    profile: ProfileType|undefined
}

export default function ProfileCommentBox(props: Props) {

    const { data, profile } = props

    const [tmpData, setTmpData] = React.useState<CommentsInProjects>()
    const [showMoreComments, setShowMoreComments] = React.useState(false)
    const [openComment, setOpenComment] = React.useState(false)
    const [section, setSection] = React.useState(-1)
    const [investInfo, setInvestInfo] = React.useState<InvestInfo>()

    const statusMap = {
        [INVEST_STATUS.FAILD]: {
        text: 'Failed',
        style: 'failed'
        },
        [INVEST_STATUS.INVESTOR]: {
        text: 'Investor',
        style: 'success'
        },
        [INVEST_STATUS.FINANCING]: {
        text:'Financing',
        style: 'financing'
        }
    }

    function onReply(newReply:Reply, item:SectionCommentWithReplies, replyIndex?: number) {
        item.comment.reply_cnt += 1
        const isReplyComment = replyIndex === undefined
        if (isReplyComment) {
            if (item.replies && item.replies.length > 0) {
                item.replies.unshift(newReply)
            } else {
                item.replies = [newReply]
            }
        } else {
            if (item.replies && item.replies.length > 0) {
                item.replies = [...item.replies.slice(0, replyIndex + 1), newReply, ...item.replies.slice(replyIndex + 1, item.replies.length)]
            } else {
                item.replies = [newReply]
            }
        }
        setTmpData(Object.assign({}, tmpData))
    }

    async function getMoreReplies(comments:SectionCommentWithReplies) {
        if (!comments || !comments.replies || !comments.replies.length) return
        const data:GetRepliesResponse = await getReplies({
            comment_id: comments.comment.id,
            // from last index
            from: comments.replies.length,
            size: 5
        })
        comments.replies = [...comments.replies, ...data.replies].reduce((rs:Reply[], item:Reply) => {
            // drop repeat item
            if (!rs.find(d => d.id === item.id)) {
                rs.push(item)
            }
            return rs
        }, [])
        setTmpData(Object.assign({}, tmpData))
    }

    function handleClickMoreComments() {
        setShowMoreComments(true)
    }

    async function handleShowCommentDetail(comment: Comment) {
        const investInfo = await getInfo({
            project_id: tmpData?.project_id || ''
        })
        setInvestInfo(investInfo)
        setSection(comment.section)
        setOpenComment(true)
    }

    React.useEffect(() => {
        setTmpData(data)
    }, [data])

    return (
        <div className={`profile-comment-box ${statusMap[tmpData?.fin_overview.status || 0]?.style}`}>
            <div className="status-wrap"></div>
            <div className="status">{statusMap[tmpData?.fin_overview.status || 0]?.text}</div>
            <div className="content-box">
                <div className="box-info">
                    <div className="left">
                        <img src={tmpData?.fin_overview.logo_url} alt="" className="logo" />
                        <div className="name">{tmpData?.fin_overview.name}</div>
                    </div>
                    <div className="right">
                        <div className="item">
                            <div className="item-title">Total Financing：</div>
                            <div className="item-text">{tmpData?.fin_overview.goal}{tmpData?.fin_overview.token_symbol}</div>
                        </div>
                        <div className="item">
                            <div className="item-title">My shares：</div>
                            <div className="item-text">{tmpData?.fin_overview.my_share || 0}{tmpData?.fin_overview.token_symbol}</div>
                        </div>
                        <div className="item">
                            <div className="item-title">Investors：</div>
                            <div className="item-text">{tmpData?.fin_overview.invest_cnt || 0}</div>
                        </div>
                    </div>
                </div>
                <div className="comments-wrap">
                    {tmpData?.comments.slice(0, showMoreComments ? tmpData.comments.length : 2).map((item, index) => {
                        return (
                            <div className="comment-wrap" key={`comment-wrap_${item.comment.id}`}>
                                <CommentBox
                                profile={profile}
                                comment={item.comment}
                                targetText={`${tmpData?.fin_overview.name}'s financing`}
                                onReply={(_reply) => onReply(_reply, item)}
                                isProfileComment
                                noManagerOperate
                                projectId={tmpData.fin_overview.project_id}
                                projectUserId={tmpData.fin_overview.user_id}
                                >
                                    <img src={require('assets/img/community/arrow-right.png')} alt="" className="arrow-icon" onClick={() => handleShowCommentDetail(item.comment)} />
                                </CommentBox>
                                <div className="replies-wrap">
                                    {item.replies?.map((reply, replyIndex) => {
                                        return (
                                            <CommentBox
                                            key={`reply_${reply.id}`}
                                            profile={profile}
                                            isReply
                                            reply={reply}
                                            commentId={item.comment.id}
                                            onReply={(_reply) => onReply(_reply, item, replyIndex)}
                                            isProfileComment
                                            noManagerOperate
                                            projectId={tmpData.fin_overview.project_id}
                                            projectUserId={tmpData.fin_overview.user_id}
                                            >
                                                {/* <img src={require('assets/img/community/arrow-right.png')} alt="" className="arrow-icon" onClick={() => handleShowCommentDetail()} /> */}
                                            </CommentBox>
                                        )
                                    })}
                                    {(item.comment.reply_cnt > 2 && item.comment.reply_cnt >(item.replies?.length || 0)) &&
                                    <div className="more-replies"
                                    onClick={() => getMoreReplies(item)}>View {item.comment.reply_cnt - (item.replies?.length || 0)} Comments</div>}
                                </div>
                            </div>
                        )
                    })}
                    {(!showMoreComments && (tmpData?.comments?.length || 0) > 2) && <div className="more-comments" onClick={handleClickMoreComments}>Load More</div>}

                </div>

            </div>
            
            <CommentDetail
            profile={profile}
            open={openComment}
            onClose={() => setOpenComment(false)}
            section={section}
            investInfo={investInfo}
            ></CommentDetail>

        </div>
    )
}
