export const sortTypeList = [
    {
        k: 'Ending soon',
        v: 0
    },
    {
        k: 'Financing：low to high',
        v: 1
    },
    {
        k: 'Financing：high to low',
        v: 2
    },
    {
        k: 'Recently List',
        v: 3
    },
    {
        k: 'Most  Comments',
        v: 4
    },
    {
        k: 'Most Institutions',
        v: 5
    }
]

export const FIN_STATUS = {
    ALL: -1,
    FINANCING: 1,
    SUCCESS: 2
}