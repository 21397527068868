import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import { setPublic } from 'api/apply';
import { InvestInfo } from 'api/finance';
import Message from 'components/common/message';

interface Props {
    onClose: () => void
    open: boolean,
    onConfirm: () => void
    investInfo: InvestInfo
}

export default function SwitchPublicDialog(props:Props) {
    const { open, onClose, onConfirm, investInfo } = props

    async function handleSwitchPublic() {
        try {
            await setPublic({
                project_id: investInfo.project_id
            })
            Message('Switch to publicd successfully ！')
            onConfirm()
            onClose()
        } catch (e:any) {
            Message(e.message || 'Error', {severity:'error'})
        }
    }

    return (
        <Dialog open={open} onClose={onClose} className="switch-public-dialog">
            <div className="content">
                <img src={require('assets/img/message/warning.png')} alt="" className="icon" />
                <div className="text1">Switch to public</div>
                <div className="text2">Please pay attention to confirm that your project financing model is to be public to the community ！ <span className="sub">Once confirmed ，it cannot be changed to private mode</span></div>
                <Button className="btn btn-1seed" variant='contained' onClick={handleSwitchPublic}>CONFIRM</Button>
            </div>
        </Dialog>
    )
}