import React from 'react';
import Header from 'components/header';
import Footer from 'components/footer'
// import useFixedHeader from "hooks/useFixedHeader";
import './index.scss'
import TopCard from './top-card';
import TrendingCard from './trending-card';
import CommunityFamer from './community-famer';
import HotComments from './hot-comments';
import { getIndexData, IndexResponse } from 'api';
import QuestionCard from 'pages/apply-guide/components/question-card';
import Advantages from 'pages/apply-guide/components/advantages';
import Raises from 'pages/apply-guide/components/raises';
export default function Index() {
  // const [isFixedHeader] = useFixedHeader()

  const [indexData, setIndexData] = React.useState<IndexResponse>()

  async function initData() {
    const data:IndexResponse = await getIndexData()
    setIndexData(data)
  }

  function onRefresh() {
    setIndexData(Object.assign({}, indexData))
  }

  React.useEffect(() => {
    initData()
  }, [])

  return (
    <div className='index-page'>
      <Header hideShadow></Header>
      {/* <div className="index-page-title-wrap">
        <div className="index-page-title">
          <div className="text1">Investment and financing with&nbsp;</div><div className="text2">Web3-Native DAO</div>
        </div>
      </div> */}
      {<TopCard list={indexData?.recommend || []}></TopCard>}
      <div className={'page-container'}>
        {<TrendingCard list={indexData?.trending || []}></TrendingCard>}
        {/* {<CommunityFamer list={indexData?.community_farmer || []} onRefresh={onRefresh}></CommunityFamer>}
        {<HotComments list={indexData?.hot_comment || []} onRefresh={onRefresh}></HotComments>} */}
        <QuestionCard></QuestionCard>
        <Advantages></Advantages>
        <Raises></Raises>
      </div>
      <Footer />
    </div>
  );
}
