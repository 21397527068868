import React from 'react';
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import './index.scss'
import closeImg from 'assets/img/close.png'
type Props = {
    onClose: () => void,
    open: boolean,
    onConfirm: () => void,
    onCancel: () => void
}
export default function ApplyConfirmDialog(props: Props) {
    const { onClose, open, onConfirm, onCancel } = props
    return (
        <Dialog open={open} onClose={onClose}>
            <div className="apply-confirm-dialog">
                <img className='icon' src={closeImg} alt="" onClick={() => onClose()} />
                <p className="title">Confirm close page?</p>
                <p className="sub-title">your application are not finish yet</p>
                <div className="btn-wrap">
                    <Button variant="outlined" className='btn-1seed btn' disableElevation onClick={onConfirm}>save as a draft</Button>
                    <Button variant="contained" className='btn-1seed btn' disableElevation onClick={onCancel}>Close</Button>
                </div>
            </div>
        </Dialog>
    )
}