import baseRequest from "../../utils/http"

const api = new baseRequest()

const PREFIX = '/api/v1/community'


export type Annoucement = {
  "announcement_id": string,
  "content": string,
  "time": number,
  "likes": number,
  is_like: boolean
}

export type GetAnnoucementsResponse = {
  announcements: Annoucement[]
}

type GetAnnoucementsParam = {
  project_id: string,
  page_no: number,
  page_size: number
}

type PostAnnoucementParam = {
  project_id: string,
  content: string
}

type GetCommentsParam = {
  project_id: string,
  page_no: number,
  page_size: number,
  sort: number,
  review: number
}

export type Comment = {
  "id": string,
  // "user_id": string,
  "content": string,
  "time": number,
  "likes": number,
  "reply_cnt": number,
  "type": number,
  "label": number,
  "is_like": boolean,
  user: EarlyContributor,
  review: number[] | null,
  section: number,
  project_id: string,
  project_name: string,
  index: number,
  length: number,
  text: string
}

export type Reply = {
  "id": string,
  // "user_id": string,
  "content": string,
  "time": number,
  "likes": number,
  "reply_to": EarlyContributor,
  "parent_id": string,
  "label": number,
  "is_like": boolean,
  user: EarlyContributor
}

export type GetCommentsResponse = {
  comments: Comment[],
  total: number
}

type GetCommentParam = {
  project_id: string
}

type PostCommentParam = {
  "project_id": string,
  // "start": number,
  // "end": number,
  "content": string,
  "type": number,
  section: number,
  text: string,
  index: number,
  length: number
}

type AnnouncementLikeParam = {
  announcement_id: string
}

type CommentLikeParam = {
  comment_id: string
}

type GetQuestionsParam = {
  project_id: string,
  page_no: number,
  page_size: number
}

type GetTagsParam = {
  project_id: string
}

export type SliceWeight = {
  // "slice": number,
  "weight": number,
  "commented": boolean,
  index: number,
  length: number
}

export type Contributor = {
  comments: number,
  ens_name: "",
  likes: number,
  profile_uri: string,
  role: number,
  user_id: string,
  username: string,
}

export type EarlyContributor = {
  "id": string,
  "email": string,
  "wallet": string,
  "username": string,
  "ens_name": string,
  "profile_uri": string,
  "role": number,
  "is_followed": boolean
}

export type Tag = {
  section: number,
  comment_cnt: number,
  // slice_weights: SliceWeight[],
  early_contributors: EarlyContributor[]
}

// export type TagSlice = {
//   tags: Tag[] | null,
//   slice_weights: SliceWeight[] | null
// }

export type GetTagsResponse = {
  tags: Tag[] | null,
  slice_weights: SliceWeight[] | null
}

type GetContributorsParam = {
  project_id: string,
  page_no: number,
  page_size: number
}

export type GetContributorsResponse = {
  contributors: Contributor[]
}

type GetSectionCommentsParam = {
  project_id: string,
  section: number
}

export type SectionCommentWithReplies = {
  comment: Comment,
  replies: Reply[]|null
}

export type SectionComment = {
  index: number,
  length: number,
  comments: SectionCommentWithReplies[]
}

export type GetSectionCommentsResponse = {
  section_comments: SectionComment[]
}

type ReplyLikeParam = {
  reply_id: string
}

type PostReplyParam = {
  comment_id: string,
  content: string,
  reply_to: string
}

export type PostCommentResponse = {
  id: string
}

type GetAllQuestionsParam = {
  project_id: string
}

export type CommentsOfSection = {
  section: number,
  section_comments: SectionComment[]
}

export type GetAllQuestionsResponse = {
  project_id: string,
  comments: CommentsOfSection[]
}

type GetReplyParam = {
  reply_id: string
}

type GetRepliesParam = {
  comment_id: string,
  from: number,
  size: number
}

export type GetRepliesResponse = {
  replies: Reply[]
}

type operateParams = {
  id: string
}

type postQuestionParams = {
  project_id: string,
  content: string
}

export function getAnnouncements(params:GetAnnoucementsParam):Promise<GetAnnoucementsResponse|any> {
  return api.get(`${PREFIX}/announcements`, params)
}

export function postAnnouncement(params:PostAnnoucementParam):Promise<any> {
  return api.post(`${PREFIX}/announcement`, params)
}

export function announcementLike(params:AnnouncementLikeParam):Promise<any> {
  return api.post(`${PREFIX}/announcement/like`, params)
}

export function announcementUnlike(params:AnnouncementLikeParam):Promise<any> {
  return api.post(`${PREFIX}/announcement/unlike`, params)
}

export function getComments(params:GetCommentsParam):Promise<GetCommentsResponse|any> {
  return api.get(`${PREFIX}/comments`, params)
}

export function getComment(params:GetCommentParam):Promise<Comment|any> {
  return api.get(`${PREFIX}/comment`, params)
}

export function postComment(params:PostCommentParam):Promise<PostCommentResponse|any> {
  return api.post(`${PREFIX}/comment`, params)
}

export function commentLike(params:CommentLikeParam):Promise<any> {
  return api.post(`${PREFIX}/comment/like`, params)
}

export function commentUnlike(params:CommentLikeParam):Promise<any> {
  return api.post(`${PREFIX}/comment/unlike`, params)
}

export function getQuestions(params:GetQuestionsParam):Promise<GetCommentsResponse|any> {
  return api.get(`${PREFIX}/questions`, params)
}

export function getTags(params:GetTagsParam):Promise<GetTagsResponse|any> {
  return api.get(`${PREFIX}/tags`, params)
}

export function getContributors(params:GetContributorsParam):Promise<GetContributorsResponse|any> {
  return api.get(`${PREFIX}/contributors`, params)
}

export function getSectionComments(params:GetSectionCommentsParam):Promise<GetSectionCommentsResponse|any> {
  return api.get(`${PREFIX}/section_comments`, params)
}

export function replyLike(params:ReplyLikeParam):Promise<any> {
  return api.post(`${PREFIX}/reply/like`, params)
}

export function replyUnlike(params:ReplyLikeParam):Promise<any> {
  return api.post(`${PREFIX}/reply/unlike`, params)
}

export function postReply(params:PostReplyParam):Promise<PostCommentResponse|any> {
  return api.post(`${PREFIX}/reply`, params)
}

export function getAllQuestions(params:GetAllQuestionsParam):Promise<GetAllQuestionsResponse|any> {
  return api.get(`${PREFIX}/all_questions`, params)
}

export function getReply(params:GetReplyParam):Promise<Reply|any> {
  return api.get(`${PREFIX}/reply`, params)
}

export function getReplies(params:GetRepliesParam):Promise<GetRepliesResponse|any> {
  return api.get(`${PREFIX}/replies`, params)
}

export function rejectComment(params:operateParams):Promise<any> {
  return api.post(`${PREFIX}/reject_comment`, params)
}

export function electComment(params:operateParams):Promise<any> {
  return api.post(`${PREFIX}/elect_comment`, params)
}

export function deleteComment(params:operateParams):Promise<any> {
  return api.post(`${PREFIX}/delete_comment`, params)
}

export function rejectReply(params:operateParams):Promise<any> {
  return api.post(`${PREFIX}/reject_reply`, params)
}

export function electReply(params:operateParams):Promise<any> {
  return api.post(`${PREFIX}/elect_reply`, params)
}

export function deleteReply(params:operateParams):Promise<any> {
  return api.post(`${PREFIX}/delete_reply`, params)
}

export function postQuestion(params:postQuestionParams):Promise<any> {
  return api.post(`${PREFIX}/question`, params)
}