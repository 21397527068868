import baseRequest from "../utils/http"
const api = new baseRequest()

const PREFIX = '/api/v1'

import { Comment } from "./community"
import { TeammateType } from "pages/apply/types"
import { TopInvestor } from "./user"

export type Recommend = {
    "id": number,
    "seq": string,
    "project_id": string,
    "name": string,
    "image_url": string,
    "founder": string,
    "desc": string,
    "banner_url": string,
    "participants": number,
    "community_comments": number,
    goal: string,
    token_symbol: string,
    fin_amount: string,
    fin_token_decimal: number,
    status: number,
    teammates: TeammateType[],
    end_ts: number,
    is_ido: number,
    kol: TopInvestor | null,
    institution: TopInvestor | null
}

export type Trending = {
    "project_id": string,
    "image_url": string,
    logo_url: string,
    "project_name": string,
    "tracks": string[],
    "goal": string,
    "token_symbol": string,
    "token_addr": string,
    "progress": string,
    "Investors": number,
    "fin_amount": string
}

export type CommutinyFarmar = {
    "user_id": string,
    "name": string,
    "ens_name": string,
    "image_url": string,
    "description": string,
    "followers": number,
    has_followed: boolean,
    role: number
}

export type HotComment = Comment

export type IndexResponse = {
    recommend: Recommend[],
    trending: Trending[],
    community_farmer: CommutinyFarmar[],
    hot_comment: HotComment[]
}
export function getIndexData():Promise<IndexResponse|any> {
  return api.get(`${PREFIX}/index/`)
}