import React from 'react'
import CommentBox from './comment-box'
import { InvestInfo, InvestDetail } from 'api/finance'
import { getComments, Comment, GetCommentsResponse } from 'api/community'
import { Button } from '@mui/material'
import { Box, Grid } from '@mui/material';
import './index.scss'
import { COMMENT_REVIEW_TYPE, COMMENT_SORT_TYPE } from 'constant';
import { useDetectClickOutside } from 'react-detect-click-outside';
import CommentDetail from 'components/comment-detail';
import { ProfileType } from 'api/user'
import Empty from 'components/empty'
import Masonry from '@mui/lab/Masonry';


interface Props {
    investInfo: InvestInfo
    refreshFlag: boolean
    profile: ProfileType|undefined
    investDetail: InvestDetail
}

export default function CommunityComments(props:Props) {
    const { investInfo, refreshFlag, profile, investDetail } = props

    const [comments, setComments] = React.useState<Comment[]>([])
    // const [showMore, setShowMore] = React.useState(false)
    const [hideSelectorItems, setHideSelectorItems] = React.useState<boolean>(true)
    const [sortType, setSortType] = React.useState(COMMENT_SORT_TYPE.RECENTLY_REPLY)
    const [reviewType, setReviewType] = React.useState(COMMENT_REVIEW_TYPE.ALL)
    const [openComment, setOpenComment] = React.useState(false)
    const [section, setSection] = React.useState(-1)
    const [count, setCount] = React.useState(0)
    const [pageNo, setPageNo] = React.useState(1)

    const ref = useDetectClickOutside({ onTriggered: () => setHideSelectorItems(true) });

    const labelMap = {
        [COMMENT_SORT_TYPE.COMMENT_MOST]: 'Comment Most',
        [COMMENT_SORT_TYPE.LIKE_MOST]: 'Like Most',
        [COMMENT_SORT_TYPE.RECENTLY_REPLY]: 'Recently Reply'
    }

    async function getData() {
        const data:GetCommentsResponse = await getComments({
            project_id: investInfo.project_id,
            page_no: 1,
            page_size: 10,
            sort: sortType,
            review: reviewType
        })
        setComments(data.comments || [])
        setCount(data.total || 0)
    }

    async function getMore() {
        setPageNo(pageNo + 1)
        const data:GetCommentsResponse = await getComments({
            project_id: investInfo.project_id,
            page_no: pageNo + 1,
            page_size: 10,
            sort: sortType,
            review: reviewType
        })
        setComments([...comments, ...data.comments])
    }

    function handleChangeSortType(_sortType:number) {
        setSortType(_sortType)
        setHideSelectorItems(true)
    }

    function handleChangeReviewType(_reviewType:number) {
        if (_reviewType === reviewType) {
            setReviewType(COMMENT_REVIEW_TYPE.ALL)
        } else {
            setReviewType(_reviewType)
        }
        setHideSelectorItems(true)
    }

    function onOpenComment(_section:number) {
        setSection(_section)
        setOpenComment(true)
    }

    React.useEffect(() => {
        getData()
    }, [refreshFlag, sortType, reviewType])

    return (
        <div className="community-comments">
            <div className="community-comments__title">Community comments</div>
            <div className="community-comments__container">
                <div className="filter-wrap">
                    <div className="left">
                        {/* <div className={`review-btn ${COMMENT_REVIEW_TYPE.ALL === reviewType ? 'active' : ''}`}
                        onClick={() => handleChangeReviewType(COMMENT_REVIEW_TYPE.ALL)}>All</div> */}
                        <div className={`review-btn ${COMMENT_REVIEW_TYPE.OFFICIAL === reviewType ? 'active' : ''}`}
                        onClick={() => handleChangeReviewType(COMMENT_REVIEW_TYPE.OFFICIAL)}>KOL View</div>
                        <div className={`review-btn ${COMMENT_REVIEW_TYPE.INSTITUTIONAL === reviewType ? 'active' : ''}`}
                        onClick={() => handleChangeReviewType(COMMENT_REVIEW_TYPE.INSTITUTIONAL)}>Institutional View</div>
                    </div>
                    <div className="right">
                        {/* <div className="count">All {count} comments</div> */}
                        <div className='selector' ref={ref} onClick={() => setHideSelectorItems(!hideSelectorItems)}>
                            <span className="text">{labelMap[sortType]}</span>
                            <div className="selector-items" hidden={hideSelectorItems}>
                            <div className={`item ${sortType === COMMENT_SORT_TYPE.COMMENT_MOST && 'active'}`}
                                onClick={() => handleChangeSortType(COMMENT_SORT_TYPE.COMMENT_MOST)}
                            >Comment Most</div>
                            <div className={`item ${sortType === COMMENT_SORT_TYPE.LIKE_MOST && 'active'}`}
                                onClick={() => handleChangeSortType(COMMENT_SORT_TYPE.LIKE_MOST)}
                            >Like Most</div>
                            <div className={`item ${sortType === COMMENT_SORT_TYPE.RECENTLY_REPLY && 'active'}`}
                                onClick={() => handleChangeSortType(COMMENT_SORT_TYPE.RECENTLY_REPLY)}
                            >Recently Reply</div>
                            </div>
                        </div>
                        <Button variant='contained' className='btn-1seed filter-btn' onClick={getData}>
                            {/* Refresh */}
                            <img className='icon' src={require('assets/img/reflesh.png')} alt="" />
                        </Button>
                    </div>
                </div>
                <Box sx={{ flexGrow: 1 }}>
                    <Masonry columns={3} spacing={'18px'} sx={{width: 1280}}>
                    {comments.map((item, index) => {
                        return (
                            <CommentBox
                            key={`comment_${index}`}
                            investInfo={investInfo}
                            data={item}
                            onRefresh={getData}
                            onOpenComment={onOpenComment} />
                        )
                    })}
                    </Masonry>
                    {/* <Grid  container spacing={2}>
                        {comments.map((item, index) => {
                            return (
                                <Grid item xs={4} key={`comment_${index}`}>
                                    <CommentBox investInfo={investInfo} data={item} onRefresh={getData} onOpenComment={onOpenComment} />
                                </Grid>
                            )
                        })}
                    </Grid> */}
                    {/* {comments.map((item, index) => {
                        return (
                            <CommentBox key={`comment_${index}`} investInfo={investInfo} data={item} onRefresh={getData} onOpenComment={onOpenComment} />
                        )
                    })} */}
                </Box>
                {(count > comments.length && comments?.length > 0) && <Button className="btn-more btn-1seed" variant='outlined' onClick={getMore}><span className="text">LOAD MORE</span></Button>}
                {!comments.length && <Empty onRefresh={getData}></Empty>}
                {/* {hasMore && <CircularProgress className='comments-more'></CircularProgress>}s */}
            </div>
            <CommentDetail
            profile={profile}
            open={openComment}
            onClose={() => setOpenComment(false)}
            section={section}
            investInfo={investInfo}
            ></CommentDetail>
        </div>
    )
}