import React from 'react';
import ReactDOM from "react-dom/client";
import './index.scss'
import { Dialog, CircularProgress, Backdrop } from '@mui/material';
import { ReactComponent as LoadingIcon } from "assets/svg/loading.svg";

let root:any

export default function Loading(loading:boolean) {

    const id = 'loading-1seed'
    
    let loadingDom = document.getElementById(id)
    if (!loadingDom) {
        loadingDom = document.createElement('div')
        loadingDom.setAttribute('id', id)
        document.body.appendChild(loadingDom)
    }
    
    if (root && root._internalRoot) {
        root.unmount()
    }
    
    root = ReactDOM.createRoot(loadingDom)

    // const snakeBar = <Dialog className='loading-1seed'
    //                     open={loading}
    //                 >
    //                     <CircularProgress></CircularProgress>
    //                 </Dialog>
    const snakeBar = <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
                        open={loading}
                        // onClick={root.unmount()}
                    >
                        {/* <CircularProgress color="inherit" /> */}
                        <LoadingIcon></LoadingIcon>
                    </Backdrop>
    root && root._internalRoot && root.render(snakeBar);
}
