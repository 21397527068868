export const TAB_TYPE = {
    ALL: '1',
    MY: '2'
}

export const SORT_TYPE = {
    ALL: -1,
    UP: 1,
    DOWN: 2
}
    
export const AGGREMENT_STATUS = {
    PENDING: '1',
    FAILED: '2',
    COMPLETED: '3'
}

export const statusMap = {
    [AGGREMENT_STATUS.COMPLETED]: {
        text: 'Completed',
        style: 'completed'
    },
    [AGGREMENT_STATUS.PENDING]: {
        text: 'Pending',
        style: 'pending'
    },
    [AGGREMENT_STATUS.FAILED]: {
        text: 'Failed',
        style: 'failed'
    }
}

export const AGGREMENT_TYPE = {
    CLAIM: '1',
    FULFILL: '2'
}