import React from 'react'
import { getWS, WSMessage, registerMsg, unRegisterMsg, readMsgScope } from 'utils/ws';
import { WS_SCOPE, WS_MSG_TYPE } from 'constant/ws';
import { WS_OTC_TRADE_STATUS } from 'constant/ws';
import { OTCDetail } from 'api/trade';

export default function useTradeWsMsg(data: OTCDetail, isOffer: boolean) {

    const [msgCount, setMsgCount] = React.useState(0)

    function register() {
        registerMsg(WS_SCOPE.TRADE)
    }
    
    function read() {
        readMsgScope(data.project_id + (isOffer ? '-0' : '-1'), WS_SCOPE.TRADE)
        setMsgCount(0)
    }
    
    React.useEffect(() => {
        const ws = getWS()
        if (!ws) return
        if (ws.OPEN === ws.readyState) {
            registerMsg(WS_SCOPE.TRADE)
        } else {
            ws.addEventListener('open', register)
        }
        ws.addEventListener('message', function(e:MessageEvent<any>) {
            try {
                const msgData:WSMessage = JSON.parse(e.data)
                console.log('watch message', e.data)
            if (msgData.type !== WS_MSG_TYPE.OFFER) return
                console.log('msgData:', msgData)
                const data = JSON.parse(msgData.msg)
                console.log('data', data)
                if (isOffer && data.status === WS_OTC_TRADE_STATUS.OfferAccepted) {
                    setMsgCount(msgCount + 1)
                }
                if (!isOffer && [WS_OTC_TRADE_STATUS.AgreementCompleted, WS_OTC_TRADE_STATUS.AgreementFailedAndClaimed, WS_OTC_TRADE_STATUS.AgreementFailedNotClaimed].includes(data.status)) {
                    setMsgCount(msgCount + 1)
                }
            } catch (error:any) {
                console.log(error)
            }
        })
        return () => {
            unRegisterMsg(WS_SCOPE.TRADE)
            ws.removeEventListener('open', register)
        }
    }, [])

    return {
        read,
        msgCount
    }
}