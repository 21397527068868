import React from 'react'
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton'
import './index.scss'
import SaveIcon from '@mui/icons-material/Save';

interface Props extends React.PropsWithChildren, LoadingButtonProps {
    
}

export default function LoadingBtn(props: Props) {
    const { children } = props
    return (
        <LoadingButton
        {...props}
        className={`loading-btn ${props.className} ${props.loading ? 'loading' : ''}`}
        loadingPosition="end"
        endIcon={<></>}
        >{children}</LoadingButton>
    )
}