import baseRequest from "../../utils/http"
import { ApplyProjectInfoType, TopInvestor } from "api/user"
const api = new baseRequest()

const PREFIX = '/api/v1/explore'

type Track = {
  id: number,
  track: string
}

type Token = {
  address: string,
  blockchain: string,
  name: string,
  symbol: string
}

export type ExploreFilterConfig = {
  apply_support_token: Token[],
  apply_track: Track[]
}

export type ExploreProjectParams = {
  keyword?: string,
  financing_token_symbol?: string,
  financing_amount_min?: string,
  financing_amount_max?: string,
  tracks?: string,
  page_no: number,
  page_size: number,
  // 1 => all  1 => Financing 2 => succeed
  status: number,
  // 0 => Ending soon  1 => Financing：low to high 2 => Financing：high to low 3=> Recently List 4=> Most  Comments 5=> Most Institutions
  sort: number
}

export type ExploreProjectType = {
  project_id: string,
  announce_update: boolean,
  top_investors: Array<TopInvestor> | null,
  investor_count: number,
  comments_cnt: number,
  status: number,
  project_info: ApplyProjectInfoType
}

export type ExploreProjectResponse = {
  projects: ExploreProjectType[]
}

export type Trending = {
  project_id: string,
  image_url: string,
  logo_url: string,
  project_name: string,
  tracks: string[],
  goal: string,
  token_symbol: string,
  token_addr: string,
  progress: string,
  Investors: number,
  has_watched: boolean,
  fin_amount: string
}

export type ExploreTrendingResponse = {
  trending: Trending[]
}

export function getFilterConfig():Promise<ExploreFilterConfig|any> {
  return api.get(`${PREFIX}/projects/filters_config`)
}

export function getAll(params: ExploreProjectParams):Promise<ExploreProjectResponse | any> {
  return api.get(`${PREFIX}/projects/all`, params)
}

export function getTrending(params: ExploreProjectParams):Promise<ExploreTrendingResponse | any> {
  return api.get(`${PREFIX}/projects/trending`, params)
}