import React from 'react';
import './index.scss'
import { OTC_STATUS } from 'constant';
import TradingCard from './card';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import { getOTCProjects, OTCProject, GetOTCProjectsRes } from 'api/trade';
import useScroll from 'hooks/useScroll';

export default function TradingList() {
    const filterList = [
        {
            value: OTC_STATUS.ALL,
            text: 'All'
        },
        
        {
            value: OTC_STATUS.LIVING,
            text: 'Living Now'
        },
        
        {
            value: OTC_STATUS.TRADING,
            text: 'Trading Now'
        },
        
        {
            value: OTC_STATUS.COMPLETED,
            text: 'Completed'
        }
    ]

    const [status, setStatus] = React.useState(OTC_STATUS.ALL)
    const [pageNo, setPageNo] = React.useState(1)
    const [list, setList] = React.useState<OTCProject[]>([])
    const [noMore, setNoMore] = React.useState(false)

    useScroll(onMore)

    async function getList(isMore = false) {
        const data:GetOTCProjectsRes = await getOTCProjects({
            page_no: pageNo,
            page_size: 10,
            status
        })
        if (!data.projects?.length) {
            setNoMore(true)
        }
        if (isMore) {
            if (!data.projects?.length) return
            setList([...list, ...(data.projects || [])])
        } else {
            setList(data.projects || [])
        }
    }

    function onMore() {
        if (noMore) return
        setPageNo(pageNo + 1)
      }

    React.useEffect(() => {
        getList(false)
    }, [status])

    React.useEffect(() => {
        if (pageNo > 1) {
            getList(true)
        }
    }, [pageNo])

    return (
        <div className="trading-list">
            <div className="filter">
                {filterList.map(item => 
                    <div
                    key={item.value}
                    className={`btn ${status === item.value ? 'active' : ''}`}
                    onClick={() => setStatus(item.value)}
                    >{item.text}</div>
                )}
            </div>
            <div className="list">
                <Box>
                    <Masonry columns={2} spacing={'18px'}>
                        {list.map(item => {
                            return (
                                <TradingCard key={item.project_id} data={item}></TradingCard>
                            )
                        })}
                    </Masonry>
                </Box>
            </div>
        </div>
    );
}
