import React from 'react'
import './index.scss'
import { useNavigate } from 'react-router'
import ApplyText from 'components/apply-text'

export default function Footer() {

	const navigate = useNavigate()

	function goAbout() {
        window.open('https://jolly-pyjama-340.notion.site/1Seed-2dbd2bc2422f4ad7b5ea8098fa0c0d0a')
    }

	return (
		<div className='footer'>
			<div className="footer__wrap">
				<div className="left">
					<img src={require('assets/img/logo.png')} alt="" className="logo" />
					<div className="text">Revolutionizing Web3 Startup Success, One Community at a Time.</div>
					<div className="icon-wrap">
						<img src={require('assets/img/footer/twitter.png')} alt="" className="icon" onClick={() => window.open('https://twitter.com/1seedDAO')} />
						{/* <img src={require('assets/img/footer/facebook.png')} alt="" className="icon" /> */}
						<img src={require('assets/img/footer/github.png')} alt="" className="icon" onClick={() => window.open('https://github.com/1SeedDao')} />
					</div>
				</div>
				<div className="right">
					<div className="item">
						<div className="text1 text" onClick={() =>{goAbout()}}>About</div>
						<div className="text">Privacy Policy and Terms of Service</div>
					</div>
					<div className="item">
						<div className="text1 text" onClick={() => navigate('/explore')}>Explore</div>
						<div className="text" onClick={() => open('https://docs.1seeddao.com/what-is-1seed/privacy-policy')}>Privacy Policy</div>
					</div>
					<div className="item">
						<ApplyText className="text1 text" isGuideApply></ApplyText>
						<div className="text">Terms of Service</div>
					</div>
				</div>
			</div>
		</div>
	)
}