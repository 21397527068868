import React from 'react'
import './index.scss'
// import Button from '@mui/material/Button'
import { dealMetamaskAddress } from 'utils/utils'
import RemindDialog from 'components/common/remind-dialog'
import { getOtherPlatProof, GetOtherPlatProofResponse } from 'api/user'
import { getWeb3 } from 'utils/web3'
import Message from 'components/common/message'
// import Loading from 'components/common/loading'
import { useProfileContract } from 'utils/web3'
import { useNavigate } from 'react-router'
import { profileNFTAddr } from 'config/connector'
import { getProfile, ProfileType } from 'api/user'
import useChainInterceptor from 'hooks/useChainInterceptor'
import LoadingBtn from 'components/common/loading-btn'
import useLoginInterceptor from 'hooks/useLoginInterceptor'
import { isLogin } from 'utils/auth'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CALL_RPC } from 'config/config'

interface Props {
    onSuccess?: () => void
    onClose?: () => void
}

export default function MintProfileNFT(props: Props) {

    const {onSuccess, onClose} = props

    const desc = 'Holding the 1seed official NFT can enjoy the community review rights of platform financing projects, and can participate in the 1seed Reward points competition. 50% of the agreement revenue will be distributed to contest users, of which 25% will be distributed to the top ten contributors; 25% will be used as a reward pool Distributed to other contributing participants.'

    // const [addr, setAddr] = React.useState('')
    const [openMore, setOpenMore] = React.useState(false)
    const [notEligibility, setNotEligibility] = React.useState(false)
    const [btnLoading, setBtnLoading] = React.useState(false)
    const [isMinted, setIsMinted] = React.useState(false)
    const [proof, setProof] = React.useState<string[]>([])

    const navigate = useNavigate()

    const chainInterceptor = useChainInterceptor()

    const loginInterceptor = useLoginInterceptor()

    // async function getData() {
    //     try {
    //         const data = await web3.eth.getAccounts()
    //         if (data && data[0]) {
    //             setAddr(data[0])
    //         } else {
    //             getProfileData()
    //         }
    //     } catch(e:any) {
    //         console.log(e)
    //         getProfileData()
    //     }
    // }

    // async function getProfileData() {
    //     const data:ProfileType = await getProfile({})
    //     setAddr(data.user_info.wallet || '')
    // }

    async function getStatus(addr: string) {
        const data = await useProfileContract(profileNFTAddr, undefined, CALL_RPC).methods.balanceOf(addr).call()
        console.log('balanceOf:', data)
        if (+data > 0) {
            setIsMinted(true)
        }

    }

    async function check(addr: string) {
        // if (!addr) {
        //     Message('Please bind wallet', {severity: 'warning'})
        //     return
        // }
        try {
            // Loading(true)
            setBtnLoading(true)
            const data:GetOtherPlatProofResponse = await getOtherPlatProof({
                public_address: addr
            })
            if (!data.proof || !data.proof.length) {
                Message('No eligibility')
                // Loading(false)
                setBtnLoading(false)
                setNotEligibility(true)
                return
            }
            Message(isMinted ? 'You have minted it！' : 'You can mint')
            setProof(data.proof)
            // mint(data.proof)
        } catch (error:any) {
            Message(error.message || 'Error', {severity: 'error'})
            // Loading(false)
        } finally {
            setBtnLoading(false)
        }
    }

    function mint(addr: string) {
        chainInterceptor(
            () => {
                setBtnLoading(true)
                useProfileContract(profileNFTAddr).methods
                .wlMint(proof)
                .send({
                    from: addr
                })
                .on('receipt', function(receipt:any) {
                    console.log(receipt)
                    Message('Success')
                    // Loading(false)
                    setBtnLoading(false)
                    getStatus(addr)
                    if (onSuccess) {
                        onSuccess()
                    } else {
                        navigate('/profile?showProfileNFT=1')
                    }
                }).on('error', function(error:any, receipt:any) {
                    console.log(error, receipt)
                    Message(error.message || 'Failed', {severity: 'error'})
                    // Loading(false)
                    setBtnLoading(false)
                })
            },
            () => {
                // Loading(false)
                setBtnLoading(false)
            }
        )
    }

    async function handleClickBtn() {
        if (notEligibility) {
            return
        }
        
        let addr = ''

        const web3 = getWeb3()
        const datas = await web3.eth.requestAccounts()
        console.log(datas)
        addr = datas[0]
        await getStatus(addr)
        if (proof.length) {
            loginInterceptor(() => {
                mint(addr)
            })
        } else {
            setTimeout(() => {
                check(addr)
            })
        }
    }

    // React.useEffect(() => {
    //     getData()
    // }, [])

    async function initMintedStatus() {
        const web3 = getWeb3()
        const data = await web3.eth.getAccounts()
        if (data && data[0]) {
            getStatus(data[0])
        }
    }

    React.useEffect(() => {
        initMintedStatus()
    }, [])

    return (
        <div className="mint-profile-nft">
            <div className="card-title">Mint Membership NFT</div>
            {onClose && <img src={require('assets/img/close.png')} alt="" className="close" onClick={onClose} />}
            <div className="card-content">
                <img src={require('assets/img/check-eligibility/card.png')} alt="" className="left" />
                <div className="right">
                    <div className="text1">DESCRIPTION</div>
                    <div className="text2">{desc.slice(0, 120)}... <span className="more" onClick={() => setOpenMore(true)}>View more</span></div>
                </div>
            </div>
            <div className="text-item">
                <div className="text1">Contract address:</div>
                <div className="text2">{dealMetamaskAddress(profileNFTAddr)}
                    <CopyToClipboard text={profileNFTAddr} onCopy={() => Message('Copy success!')}>
                        <img src={require('assets/img/copy.png')} alt="" className="icon" />
                    </CopyToClipboard>
                </div>
            </div>
            <div className="text-item">
                <div className="text1">Type:</div>
                <div className="text2">ERC721</div>
            </div>
            <div className="text-item price">
                <div className="text1">Price</div>
                <div className="text2"><span className="sub">Limited Free</span></div>
            </div>
            <LoadingBtn
            className={`btn btn-1seed ${notEligibility ? 'not-eligibility' : ''}`}
            variant='contained'
            onClick={handleClickBtn}
            loading={btnLoading}
            disabled={isMinted}
            >{
            (notEligibility && 'No eligibility') ||
            (isMinted && 'Minted') ||
            (!isLogin() && 'Go 1SeedDao to mint') ||
            (proof.length && 'Mint') ||
            `Check eligibility`}</LoadingBtn>
            
            <RemindDialog
                open={openMore}
                onClose={() => setOpenMore(false)}
                hideStatusImg
                title='Description'
                content={desc}
            ></RemindDialog>
        </div>
    )
}