import React from 'react'
import { InvestInfo } from 'api/finance'
import './index.scss'
import arrowRight from 'assets/img/invest-detail/arrow-right.png'
import { roundDetailTitleMap } from 'pages/apply/maps'

interface Props {
    investInfo: InvestInfo
}

const styleArr = [
    {
        bg: require('assets/img/invest-detail/rounds/bg1.png'),
        color: '#66B6FF'
    },
    {
        bg: require('assets/img/invest-detail/rounds/bg2.png'),
        color: '#EAC544'
    },
    {
        bg: require('assets/img/invest-detail/rounds/bg3.png'),
        color: '#B5EA44'
    },
    {
        bg: require('assets/img/invest-detail/rounds/bg4.png'),
        color: '#FF6666'
    }
]

export default function FinRounds(props:Props) {
    const { investInfo } = props
    const [ isMove, setIsMove ] = React.useState(false)
    const [index, setIndex] = React.useState(0)
    
    function handleIndex(index:number) {
        if (!investInfo.details || !investInfo.details[index]) return
        setIndex(index)
    }

    return (
        investInfo.details ? <div className="fin-rounds">
            <div className="fin-rounds__title">Financing round ： {investInfo.rounds}</div>
            {investInfo.details[index] && <div className="fin-rounds__content">
                <div className={`item`} style={{
                    backgroundImage: `url(${styleArr[index].bg})`
                }}>
                    <div className="left">
                        <div className="text" style={{
                            color: styleArr[index].color
                        }}>0{index + 1}</div>
                        <div className={`arrow-wrap ${index <= 0 ? 'disabled' : ''}`} onClick={() => handleIndex(index - 1)}>
                            <img src={require('assets/img/invest-detail/arrow-right-round.png')} alt="" className="icon icon-left" />
                        </div>
                        <div className={`arrow-wrap ${index >= investInfo.details.length - 1 ? 'disabled' : ''}`} onClick={() => handleIndex(index + 1)}>
                            <img src={require('assets/img/invest-detail/arrow-right-round.png')} alt="" className="icon" />
                        </div>
                    </div>
                    <div className="right">
                        <div className="text1">{roundDetailTitleMap[index]}</div>
                        <div className="text2">{investInfo.details[index]}</div>
                    </div>
                </div>
            </div>}
        </div> : <></>
    )
}