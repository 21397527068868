export const WS_REGISTER_TYPE = {
    REGISTER: '1',
    UNREGISTER: '2'
}

export const WS_SCOPE = {
    DEFAULT: 'default',
    WATCHER: 'watcher',
    COMMUNITY: 'community',
    TRADE: 'trade',
    NFT_MINT: 'NFTMint'
}

export const WS_MSG_TYPE = {
    COMMENT: 0,
    REPLY: 1,
    ANNOUCEMENT: 2,
    PROJ_REMINDER: 3,
    PROJ_APPLY_RECORD: 4,
    OFFER: 5,
    TRADE_EMERGENCY_WITHDRAW: 6,
    TRADE_AIRDROP_START: 7,
    TRADE_CLAIM: 8,
    PROFILE_NFT_MINT: 9,
    PRIVATE_INVITION: 10
}

export const WS_OTC_TRADE_STATUS = {
    OfferCreated: 0,
    OfferAccepted: 1,
    OfferCanceled: 2,
    AgreementCompleted: 3,
    AgreementFailedNotClaimed: 4,
    AgreementFailedAndClaimed: 5

}