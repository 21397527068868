import React from 'react';
import Dialog from '@mui/material/Dialog';
import './index.scss'
import closeImg from 'assets/img/close.png'
import { Button } from '@mui/material';
import { FormParams } from '../../types';
import { ApplyConfigResponse } from 'api/apply';
import trackIcon from 'assets/img/apply/track-icon.png'
import { getProfile, ProfileType } from 'api/user';
import { socialIconFullMapList } from 'pages/apply/maps'
import Message from 'components/common/message'
import CopyToClipboard from 'react-copy-to-clipboard'
import { formatMoney } from 'utils/utils';
import Tips from 'components/tips'
import { roundDetailTitleMap } from 'pages/apply/maps';
import ExpandTextBox from 'components/expand-text-box';
import { PrivateTag } from 'pages/invest-detail/components';
import 'pages/invest-detail/components/info-card/index.scss'
import 'pages/invest-detail/components/project-card/index.scss'
import 'pages/invest-detail/components/project-insights/index.scss'
import 'pages/invest-detail/components/project-teammates/index.scss'
import 'pages/invest-detail/components/fin-rounds/index.scss'

interface Props {
    previewData: FormParams,
    previewConf: ApplyConfigResponse | undefined
    onClose: React.MouseEventHandler<HTMLElement>|any,
    open: boolean,
    hideBtn?: boolean,
    otherTrack?: string,
    onSubmit?: () => void
}

const styleArr = [
    {
        bg: require('assets/img/invest-detail/rounds/bg1.png'),
        color: '#66B6FF'
    },
    {
        bg: require('assets/img/invest-detail/rounds/bg2.png'),
        color: '#EAC544'
    },
    {
        bg: require('assets/img/invest-detail/rounds/bg3.png'),
        color: '#B5EA44'
    },
    {
        bg: require('assets/img/invest-detail/rounds/bg4.png'),
        color: '#FF6666'
    }
]

export default function PreviewDialog(props: Props) {
    const { onClose, previewData, previewConf, open, hideBtn, otherTrack, onSubmit } = props

    // const [profile, setProfile] = React.useState<ProfileType>()
    const [showMore, setShowMore] = React.useState(false)
    const [roundsIndex, setRoundsIndex] = React.useState(0)
    
    function handleIndex(index:number) {
        if (!previewData.details || !previewData.details[index]) return
        setRoundsIndex(index)
    }

    // async function initProfile() {
    //     const data = await getProfile({})
    //     setProfile(data)
    // }
    
    function handleClickWhitepaper() {
        if (!previewData.whitepaper_url) return
        window.open(`/pdf-view?url=${encodeURIComponent(previewData.whitepaper_url)}`)
    }

    function goSocial(social_type:string) {
        const link = previewData.social[social_type]
        if (!link) {
            return
        }
        window.open(link)
    }
    
    function getMin() {
        return +((+previewData.goal * (100 - previewData.fin_rule) / 100).toFixed(2))
    }

    function getMax() {
        return +((+previewData.goal * (100 + previewData.fin_rule) / 100).toFixed(2))
    }

    // React.useEffect(() => {
    //     open && initProfile()
    // }, [open])

    return (
        <Dialog open={open} onClose={onClose} className="preview-dialog">
            <div className="preview-dialog-content">
                <img src={closeImg} alt="" className="close" onClick={onClose} />
                <div className="invest-detail__container">
                    <div className={`info-card`}>
                        <div className="info-card__content">
                            <div className="img-wrap">
                            <img src={previewData?.logo_url} alt="" className="img" />
                            </div>
                            <div className="top-wrap">
                                <div className="title-wrap">
                                    <div className="text item">{previewData?.name}</div>
                                    {previewData?.is_ido === 1 && <div className="ido-tag item">IDO</div>}
                                    <div className="track item">
                                        {previewData?.tracks ? previewData?.tracks[0] || '' : ''}
                                        <img src={trackIcon} alt="" className="icon" />
                                    </div>
                                </div>
                                <div className="link-wrap">
                                    {socialIconFullMapList.map((item, index) => {
                                        return previewData?.social[item.key] && (
                                            <img key={`social_${index}`} src={item.icon} alt="" className="icon" onClick={() => goSocial(item.key)} />
                                        )
                                    })}
                                    {(Object.keys(previewData?.social).find(item => previewData?.social[item]) && (previewData?.whitepaper_url || previewData.site)) && <img src={require('assets/img/invest-detail/social-divide.png')} alt="" className="icon default" />}
                                    {previewData?.whitepaper_url && <img src={require('assets/img/invest-detail/file.png')} alt="" className="icon" onClick={handleClickWhitepaper} />}
                                    {previewData?.site && <img src={require('assets/img/invest-detail/site.png')} alt="" className="icon" onClick={() => window.open(previewData.site)} />}
                                    {/* <div className='share'>
                                        <img src={require('assets/img/invest-detail/share.png')} alt="" className="icon" />
                                        <CopyToClipboard text={window.location.href} onCopy={() => Message('copy success!')}>
                                            <div className="share-wrap">
                                                <img src={require('assets/img/copy.png')} alt="" className="share-icon" />
                                                <span className="text">Copy link</span>
                                            </div>
                                        </CopyToClipboard>
                                    </div> */}
                                </div>
                            </div>
                            <div className="info-wrap">
                                <div className="item">
                                    <span className="text1">Proposed by: </span>
                                    <span className="text2">{previewData.user_ens_name || previewData.user_name}</span>
                                    {/* <img src={investInfo.user_avatar_url} alt="" className="avatar" onClick={() => navigate(`/profile?id=${investInfo.user_id}`)} /> */}
                                </div>
                                <div className="item">
                                    <img src={require('assets/img/invest-detail/watch.png')} alt="" className="icon" />
                                    <div className="text2">Watchlist {previewData.watch_cnt}</div>
                                </div>
                                <div className="item">
                                    <span className="text2">Contract address: {previewData.invest_contract || 'pending'}</span>
                                    {previewData.invest_contract && <CopyToClipboard text={previewData.invest_contract} onCopy={() => Message('copy success!')}>
                                        <img src={require('assets/img/copy.png')} alt="" className="copy-icon" />
                                    </CopyToClipboard>}
                                </div>
                            </div>
                            <div className="desc-wrap">{previewData.desc.length > 200 && !showMore ? previewData.desc.slice(0, 200) + '...' : previewData.desc}
                                {previewData.desc.length > 200 ? <span className="sub" onClick={() => setShowMore(!showMore)}> {showMore ? 'read less' : 'read more'}</span> : ''}
                            </div>
                        </div>
                    </div>
                    <div className="invest-detail__content">
                        <div className="invest-detail__content_left">
                            {previewData.stealth === 1 && <PrivateTag></PrivateTag>}
                            <div className={`project-card`}>
                                <div className="img-wrap">
                                    <img src={previewData.image_url} alt="" className="img" />
                                </div>
                                <div className="text-wrap">
                                    <div className="item">
                                        <div className='item-wrap'>
                                            <div className="text1">VALUATION:</div>
                                            <div className={`text2 ${!previewData.valuation && 'small'}`}>{+previewData.valuation > 0 ? `${formatMoney(previewData.valuation)}$` : `${previewData.percent}% of the latest round of valuation in the future.`}</div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='item-wrap'>
                                            <div className="text1">GOAL:</div>
                                            <div className="text2">{previewData.goal} {previewData.token_symbol}</div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className='item-wrap'>
                                            <div className="text1">REAL-TIME:</div>
                                            <div className="text2">- {previewData.token_symbol}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="fin-wrap">
                                    <div className="progress-wrap">
                                        <div className="fin-progress">
                                            <div className="progress"
                                                style={{
                                                    width: `0%`
                                                }}></div>
                                            <div className="total" style={{
                                                left: `${+previewData.goal / getMax() * 100}%`
                                            }}></div>
                                        </div>
                                        <span className="info-icon-wrap">
                                            <img src={require('assets/img/invest-detail/info2.png')} alt="" className="icon" />
                                            <Tips text={`Before the end of the time, the completion of no less than ${100 - previewData.fin_rule}% of the financing target, no higher than ${100 + previewData.fin_rule}% means that the financing is successful`}></Tips>
                                        </span>
                                    </div>
                                    <div className="text">
                                        Minimum raised <span className="sub">{getMin()}{previewData.token_symbol}</span>, Hardcap is <span className="sub">{getMax()}{previewData.token_symbol}</span>.</div>
                                    </div>
                            </div>
                            
                            <div className="project-teammates">
                                <div className="project-teammates__title">Team member</div>
                                <div className="project-teammates__wrap">
                                    {previewData.teammates?.map((item, index) => {
                                        return (
                                            <div className="project-teammate" key={`teammate_${index}`}>
                                                <div className="left">{item.name.slice(0, 1)}</div>
                                                <div className="content">
                                                    <div className="text1">{item.name}<div className="tag">{item.title}</div></div>
                                                    <div className="text2" title={item.bio}>{item.bio.length > 120 ? item.bio.slice(0, 120) + '...' : item.bio}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                
                            </div>

                            <div className="fin-rounds">
                                <div className="fin-rounds__title">Financing round ： {previewData.rounds}</div>
                                {+previewData.rounds > 0 && <div className="fin-rounds__content">
                                    <div className={`item`} style={{
                                        backgroundImage: `url(${styleArr[roundsIndex].bg})`
                                    }}>
                                        <div className="left">
                                            <div className="text" style={{
                                                color: styleArr[roundsIndex].color
                                            }}>0{roundsIndex + 1}</div>
                                            <div className={`arrow-wrap ${roundsIndex <= 0 ? 'disabled' : ''}`} onClick={() => handleIndex(roundsIndex - 1)}>
                                                <img src={require('assets/img/invest-detail/arrow-right-round.png')} alt="" className="icon icon-left" />
                                            </div>
                                            <div className={`arrow-wrap ${roundsIndex >= previewData.details.length - 1 ? 'disabled' : ''}`} onClick={() => handleIndex(roundsIndex + 1)}>
                                                <img src={require('assets/img/invest-detail/arrow-right-round.png')} alt="" className="icon" />
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="text1">{roundDetailTitleMap[roundsIndex]}</div>
                                            <div className="text2">{previewData.details[roundsIndex]}</div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div className="project-insights">
                                <div className="project-insights__title">Project insights</div>
                                <ExpandTextBox title='Highlights' index={0} expand={true} className='highlights-box'>
                                    {previewData.highlights?.filter(d => d).map((item, index) => {
                                        return (
                                            <div className="highlights-content" key={`highlights_${index}`}>
                                                <div className="highlights-num">{index + 1}.</div>
                                                <div className="highlights-text">{item}</div>
                                            </div>
                                        )
                                    })}
                                </ExpandTextBox>
                                <ExpandTextBox title='Pitch' index={1} expand={true}>
                                    <div dangerouslySetInnerHTML={{__html: previewData.pitch || ''}}></div>
                                </ExpandTextBox>
                            </div>
                        </div>
                        {/* <div className="invest-detail__content_right">
                            {previewData.stealth === 1 && <PrivateTag></PrivateTag>}
                        </div> */}
                    </div>
                </div>
                {!hideBtn &&
                <div className="btn-wrap">
                    <Button variant="outlined" className='btn-1seed btn' onClick={onClose}>Back</Button>
                    <Button variant="contained" className='btn-1seed btn' onClick={() => onSubmit && onSubmit()}>Submit</Button>
                </div>}
            </div>
        </Dialog>
    )

    // return (
    //     <Dialog open={open} onClose={onClose} className="preview-dialog">
    //         <div className="preview-dialog-content">
    //             <img src={closeImg} alt="" className="close" onClick={onClose} />
    //             <div className="title">{formatProjectTitle(previewData?.name, previewData?.rounds)}</div>
    //             <div className="content">
    //                 <div className="left">
    //                     <div className="left-content">
    //                         <img src={previewData?.logo_url} alt="" className="logo" />
    //                         {/* <img src={defiIcon} alt="" className="tag" /> */}
    //                         <div className="tag">
    //                             <div className="track">
    //                                 {(previewData?.tracks && previewData?.tracks[0]) || otherTrack || ''}
    //                                 <img src={trackIcon} alt="" className="icon" />
    //                             </div>
    //                         </div>
    //                         <p className="name">{previewData?.name}</p>
    //                         <div className="desc">{previewData?.desc}
    //                             {/* <span className="more">read more</span> */}
    //                         </div>
    //                         <div className="whitepaper" onClick={openWhitepaper}>
    //                             <img src={whitepaperIcon} alt="" className="whitepaper-icon" />
    //                             <div className="whitepaper-text">Whitepaper</div>
    //                         </div>
    //                         <div className="social-list">
    //                             {socialIconMapList.map((item, index) => {
    //                                 return (
    //                                     <img
    //                                     src={previewData?.social[item.key] ? item.icon : item.iconDisabled}
    //                                     key={`social_${index}`}
    //                                     alt=""
    //                                     className="social-icon"
    //                                     onClick={() => handleClickSocial(item.key)}
    //                                     />
    //                                 )
    //                             })}
    //                         </div>
    //                         <div className="divider"></div>
    //                         <div className="round-title">Financing round ： {previewData?.rounds}</div>
    //                         {previewData?.details?.map((item, index) => (
    //                             <div className="round" key={`round_${index}`}>
    //                                 <span className="text">{roundDetailTitle[index + 1]}</span>
    //                                 <div className="desc">{item}</div>
    //                             </div>
    //                         ))}
    //                     </div>
    //                 </div>
    //                 <div className="right">
    //                     <div className="right-title">Financing Detials</div>
    //                     <div className="financing-wrap">
    //                         <div className="wrap-bg"></div>
    //                         <div className="wrap-content">
    //                             <div className="duration">Financing Duration ： {previewData?.duration} DAYS</div>
    //                             <div className="fin-title">Token Financing</div>
    //                             <div className="info-content">
    //                                 <img src={previewData?.image_url} alt="" className="img" />
    //                                 <div className="info">
    //                                     <div className="name">{formatProjectTitle(previewData?.name, previewData?.rounds)}</div>
    //                                     <div className="propose">
    //                                         <div className="text1">Proposed by:</div>
    //                                         <div className="text2">{profile?.user_info.ens_name || profile?.user_info.username || ''}</div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                             <div className="fin-content">
    //                                 <div className="wrap">
    //                                     <div className="text1">Financing goal：</div>
    //                                     <div className="text2">{previewData?.goal} {previewData?.token_symbol}</div>
    //                                 </div>
    //                                 <div className="wrap">
    //                                     <div className="text1">Valuation：</div>
    //                                     <div className="text2">{previewData?.valuation ? `$${previewData?.valuation}` : `${previewData?.percent}%`}</div>
    //                                 </div>
    //                             </div>
    //                             {/* <div className="name">Uniswaps angel round investment</div>
    //                             <div className="info-wrap">
    //                                 <div className="goal">
    //                                     <div className="info-title">Financing goal：</div>
    //                                     <div className="goal-text">{previewData?.goal} {previewData?.token_symbol}</div>
    //                                 </div>
                    
    //                             </div>
    //                             <div className="valuation">
    //                                 <div className="text1">Valuation：</div>
    //                                 <div className="text2">${previewData?.valuation}</div>
    //                             </div>
    //                             <div className="propose">
    //                                 <div className="text1">Proposed by:</div>
    //                                 <div className="text2">{profile?.user_info.ens_name || profile?.user_info.username || ''}</div>
    //                             </div> */}
    //                         </div>
                            
    //                     </div>
    //                     <div className="right-title">Team Member</div>
    //                     <div className="teammate-list">
    //                         {previewData?.teammates?.map((item, index) => (
    //                             <div className="box" key={`teammate_${index}`}>
    //                                 <div className="box-title">
    //                                     <span className="job-title">{item.title}</span>
    //                                     <div className="job-name">{item.name}</div>
    //                                 </div>
    //                                 <div className="item">
    //                                     <img src={emailIcon} alt="" className="icon" />
    //                                     <div className="text" title={item.email}>{item.email}</div>
    //                                 </div>
    //                                 <div className="item">
    //                                     <img src={addressIcon} alt="" className="icon" />
    //                                     <div className="text" title={item.wallet}>{item.wallet}</div>
    //                                 </div>
    //                                 <div className="item">
    //                                     <img src={bioIcon} alt="" className="icon" />
    //                                     <div className="text" title={item.bio}>{item.bio}</div>
    //                                 </div>
    //                             </div>
    //                         ))}
    //                     </div>
    //                     <div className="right-title">Project Insights</div>
    //                     <div className="insights">
    //                         <ExpandTextBox title='Highlights' index={0} expand={true}>
    //                         {previewData?.highlights.filter(d => d).map((item, index) => {
    //                             return (
    //                                 <div className="highlights-content" key={`highlights_${index}`}>
    //                                     <div className="highlights-num">{index + 1}.</div>
    //                                     <div className="highlights-text">{item}</div>
    //                                 </div>
    //                             )
    //                         })}
    //                         </ExpandTextBox>
    //                         <ExpandTextBox title='Pitch' index={1} expand={true}>
    //                             {/* <div dangerouslySetInnerHTML={{__html: previewData?.pitch || ''}}></div> */}
    //                             <QuillEditor
    //                             value={previewData?.pitch || ''}
    //                             readOnly
    //                             ></QuillEditor>
    //                         </ExpandTextBox>
    //                         {/* {previewConf?.insights.map((insight, index) => {
    //                             return (
    //                                 <div className="insight-box" key={`insight_${index}`}>
    //                                     <div className="insight-title">{insight.label}</div>
    //                                     {insight.question.map((ques, ques_index) => {
    //                                         return (
    //                                             <div className="item" key={`ques_${ques_index}`}>
    //                                                 <div className="ques">{ques}</div>
    //                                                 <div className="ans">{`${previewData?.insights[index]?.answer[ques_index]}`}</div>
    //                                             </div>
    //                                         )
    //                                     })}
    //                                 </div>
    //                             )
    //                         })} */}
                            
    //                     </div>
    //                     <div className="right-title">Attchment</div>
    //                     {previewData?.other_files_url ? <div className="files">
    //                         <div className="file" onClick={handleClickOtherFiles}>
    //                             <img src={fileIcon} alt="" className="icon" />
    //                             <div className="text">{previewData.other_files_name}</div>
    //                         </div>
    //                     </div> : <div className="files">
    //                         <div className="file empty-file">
    //                             <img src={fileEmpty} alt="" className="icon" />
    //                             <div className="text">No attchments yet</div>
    //                         </div>
    //                     </div>}
    //                 </div>
    //             </div>
    //             {!hideBtn &&
    //             <div className="btn-wrap">
    //                 <Button variant="outlined" className='btn-1seed btn' onClick={onClose}>Back</Button>
    //                 <Button variant="contained" className='btn-1seed btn' onClick={() => onSubmit && onSubmit()}>Submit</Button>
    //             </div>}
    //         </div>
    //     </Dialog>
    // )
    
}