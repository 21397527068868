import React from 'react'
import './index.scss'
import { Button } from '@mui/material'
import { getAnnouncements, GetAnnoucementsResponse, Annoucement, postAnnouncement, announcementLike, announcementUnlike } from 'api/community'
import { InvestInfo, InvestDetail } from 'api/finance'
import { formatCommunityTime } from 'utils/utils'
// import "react-quill/dist/quill.snow.css";
// import "react-quill/dist/quill.bubble.css";
// import ReactQuill, { Quill } from "react-quill";
import Message from 'components/common/message'
import Loading from 'components/common/loading'
// import sanitize from 'sanitize-html'
import Empty from 'components/empty'
import useLoginInterceptor from 'hooks/useLoginInterceptor'
import QuillEditor from 'components/quill-editor'
import Dialog from '@mui/material/Dialog';
// import * as  quillEmoji from 'quill-emoji'
// import 'quill-emoji/dist/quill-emoji.css'

// const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;
    // Quill.register("modules/emoji", quillEmoji);
// Quill.register({
//     'formats/emoji': EmojiBlot,
//     'modules/emoji-shortname': ShortNameEmoji,
//     'modules/emoji-toolbar': ToolbarEmoji,
//     'modules/emoji-textarea': TextAreaEmoji
//   }, true);

    const modules = {
        toolbar: [
			// ["bold", "italic", "underline", "strike", "blockquote"],
            ["bold", "italic"],
			[{ header: [1, 2, false] }],
			[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
			["link"],
			// [{ size: ["small", false, "large", "huge"] }]
            ['emoji']
		],
        'emoji-toolbar': true,
        // "emoji-textarea": true,
        "emoji-shortname": true,
    }

interface Props {
    investInfo: InvestInfo
    isSelfProject: boolean
    onGetData: (count:number) => void,
    investDetail: InvestDetail
}

export default function AnnouncementBox(props:Props) {

    const { investInfo, isSelfProject, onGetData, investDetail } = props

    const [annoucements, setAnnoucements] = React.useState<Annoucement[]>()
    const [showMore, setShowMore] = React.useState(false)
    const [content, setContent] = React.useState('')
    const [open, setOpen] = React.useState(false)

    const loginInterceptor = useLoginInterceptor()

    async function getData() {
        const data:GetAnnoucementsResponse = await getAnnouncements({
            project_id: investInfo.project_id,
            page_no: 1,
            page_size: 20
        })
        setAnnoucements(data?.announcements || [])
        onGetData(data?.announcements?.length || 0)
    }

    function handleChangeContent(v:string) {
        setContent(v)
    }

    async function handleClickPost() {
        if (!content) return
        try {
            Loading(true)
            await postAnnouncement({
                project_id: investInfo.project_id,
                content: content
            })
            setContent('')
            getData()
            setOpen(false)
        } catch (error:any) {
            Message(error.message || 'Post annoucement failed', {severity:'error'})
        } finally {
            Loading(false)
        }
    }

    async function handleClickLike(annoucement: Annoucement) {
        try {
            if (annoucement.is_like) {
                await announcementUnlike({
                    announcement_id: annoucement.announcement_id
                })
            } else {
                await announcementLike({
                    announcement_id: annoucement.announcement_id
                })
            }
            getData()
        } catch (error:any) {
            Message(error.message || 'Unknown error', {severity:'error'})
        }
    }

    React.useEffect(() => {
        getData()
    }, [])

    return (
        <div className="announcement">
            <div className="info-wrap">
                <img src={investInfo.logo_url} alt="" className="img" />
                <div className="text-wrap">
                    <span className="text1">{investInfo.name}</span>
                    <div className="text2">{investDetail.watch_cnt} follower</div>
                </div>
            </div>
            <div className="line"><img src={require('assets/img/invest-detail/announce.png')} alt="" className="icon" />Project announcements</div>
            {annoucements?.map((annoucement, index) => {
                return (showMore || index < 2) && (
                    <div className="announcement__content" key={`annoucement_${annoucement.announcement_id}`}>
                        {/* <div className="title"><img src={investInfo.logo_url} alt="" className="img" />{investInfo.name}</div> */}
                        <div className="desc" dangerouslySetInnerHTML={{
                            __html: annoucement?.content || ''
                        }}></div>
                        <div className="info">
                            <div className="time">{formatCommunityTime(annoucement?.time)}</div>
                            <div className="like">
                                <img
                                src={require(`assets/img/invest-detail/like${annoucement.is_like ? '-active' : ''}.png`)}
                                alt=""
                                className="icon"
                                onClick={() => loginInterceptor(() => handleClickLike(annoucement))}
                                />
                                {annoucement?.likes || 0} likes</div>
                        </div>
                        {/* {(!showMore && annoucements.length > 1) && <div className="more" onClick={() => setShowMore(true)}>View more annoucement</div>}
                        {(showMore && index === annoucements.length - 1) && <div className="more" onClick={() => setShowMore(false)}>View less annoucement</div>} */}
                    </div>
                )
            })}
            {(annoucements?.length && annoucements?.length > 3) &&
            <div className="more" onClick={() => setShowMore(!showMore)}>{showMore ? 'View less' : 'View more'}</div>
            }
            {(isSelfProject && (!annoucements || !annoucements.length)) && <Empty text1='No Announcement' text2='Post an announcement know' hideBtn></Empty>}
            {isSelfProject && <>
                {/* <ReactQuill
                theme='snow'
                value={content}
                onChange={handleChangeContent}
                modules={modules}
                ></ReactQuill> */}
                {/* <QuillEditor
                value={content}
                onChange={handleChangeContent}
                modules={modules}
                ></QuillEditor> */}
                <Button className="btn-post btn-1seed" variant='contained' onClick={() => setOpen(true)}><span className="text">Post an announcement</span></Button>
            </>}
            <Dialog open={open} onClose={() => setOpen(false)} className="confirm-annoucement-dialog">
                <div className="content">
                    <div className="content-title">Post Annoucement</div>
                    <img src={require('assets/img/close.png')} alt="" className="close" onClick={() => setOpen(false)} />
                    <QuillEditor
                    value={content}
                    onChange={handleChangeContent}
                    modules={modules}
                    ></QuillEditor>
                    <Button className="btn btn-1seed" variant='contained' onClick={handleClickPost}><span className="text">CONFIRM</span></Button>
                </div>
            </Dialog>
        </div>
    )
}