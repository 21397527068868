import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { TopInvestor } from 'api/user';
import { InvestInfo } from 'api/finance';
import Button from '@mui/material/Button';
import { dealMetamaskAddress } from 'utils/utils';
import { follow, unfollow } from 'api/user';
import Message from 'components/common/message';
import { ProfileType } from 'api/user';
import useLoginInterceptor from 'hooks/useLoginInterceptor';
import { useNavigate } from 'react-router';

interface Props {
    investInfo: InvestInfo
    list: TopInvestor[]
    onClose: () => void
    open: boolean
    onRefresh: () => void
    profile: ProfileType|undefined
}

export default function InvestorsDialog(props:Props) {
    const { list, open, onClose, investInfo, onRefresh, profile } = props

    const navigate = useNavigate()
    const loginInterceptor = useLoginInterceptor()

    async function followUser(user_id:string) {
        try {
            await follow({target_user_id: user_id})
            Message('Follow success')
            onRefresh()
        } catch(error:any) {
            Message(error.message || 'Follow failed', {severity:'error'})
        }
    }

    async function unFollowUser(user_id:string) {
        try {
            await unfollow({target_user_id: user_id})
            Message('Unfollow success')
            onRefresh()
        } catch(error:any) {
            Message(error.message || 'Unfollow failed', {severity:'error'})
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <div className="investors-dialog">
                <img className='investors-dialog__icon' src={require('assets/img/close.png')} alt="" onClick={() => onClose()} />
                <div className="investors-dialog__title">Contributors</div>
                <div className="investors-dialog__content">
                    {list?.map((item, index) => {
                        return (
                            <div className="item" key={`investors_${index}`}>
                                <img src={item.profile_uri || require('assets/img/invest-detail/avatar-default.png')} alt="" className="avatar" onClick={() => item.user_id && navigate(`/profile?id=${item.user_id}`)} />
                                <div className='text-wrap'>
                                    <div className="text1">{item.name || dealMetamaskAddress(item.wallet)}</div>
                                    <div className="text2">Invest {item.amount} {investInfo.token_symbol} for {investInfo.name}</div>
                                </div>
                                {profile?.id !== item.user_id && <div className={`btn-wrap ${item.is_followed ? 'unfollow' : ''}`}>
                                    <Button className="btn btn-1seed" variant='contained' onClick={() => loginInterceptor(() => followUser(item.user_id))}>
                                        <img src={require('assets/img/invest-detail/add.png')} alt="" className="icon" />
                                        Follow
                                    </Button>
                                    <Button className="btn btn-1seed btn-unfollow" variant='outlined' onClick={() => loginInterceptor(() => unFollowUser(item.user_id))}>
                                        <span className="text">Following</span>
                                        <span className="text-unfollow">Unfollow</span>
                                    </Button>
                                </div>}
                            </div>
                        )
                    })}
                    
                </div>
            </div>
        </Dialog>
    )
}