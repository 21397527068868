import React, { useEffect, useState } from 'react'
import './index.scss'
import Header from 'components/header'
// import useFixedHeader from 'hooks/useFixedHeader'
import { getDetail, getInfo, InvestInfo, InvestDetail } from 'api/finance'
import { useParams } from 'react-router'
import {
    Announcement,
    CommunityComments,
    FinRounds,
    InfoCard,
    InvestCard,
    ProjectCard,
    ProjectInsights,
    Questions,
    ProjectTeammates,
    TopContributors,
    TopInvestors,
    PrivateTag
} from './components'
import { getProfile, ProfileType } from 'api/user'
import { PROJECT_STATUS } from './map'
import { INVEST_STATUS } from 'constant'
import { isLogin, setLoginType } from 'utils/auth'
import { roundDetailTitle } from 'pages/apply/maps'
import qs from 'qs'
import { useLocation } from 'react-router';
import { readMsgScope } from 'utils/ws'
import RemindDialog from 'components/common/remind-dialog'
import useLoginInterceptor from 'hooks/useLoginInterceptor'

export default function InvestDetailPage() {

    const [investInfo, setInvestInfo] = useState<InvestInfo>()
    const [investDetail, setInvestDetail] = useState<InvestDetail>()
    const [isSelfProject, setIsSelfProject] = useState(false)
    const [status, setStatus] = useState(PROJECT_STATUS.FINANCING)
    const [profile, setProfile] = useState<ProfileType>()
    const [refreshCommunityFlag, setRefreshCommunityFlag] = useState(false)
    const [showAnnoucement, setShowAnnoucement] = useState(true)
    const [isPrivate, setIsPrivate] = useState(false)
    const [openPrivateRemindLoginDialog, setOpenPrivateRemindLoginDialog] = useState(false)
    const [openPrivateRemindPermissionDialog, setOpenPrivateRemindPermissionDialog] = useState(false)
    const [forceLoginType, setForceLoginType] = useState('')

    // const [isFixedHeader] = useFixedHeader()
    const projectId = useParams().id

    const location = useLocation()

    async function init() {
        getInvestInfo()
        getInvestDetail()
    }

    async function getInvestInfo() {
        if (!projectId) return
        try {
            const data:InvestInfo = await getInfo({
                project_id: projectId
            })
            setInvestInfo(data)
            initProfile(data)
        } catch (error:any) {
            console.log(error)
            if (error.code === 11025 || error.code === 11025) {
                // private
                if (!isLogin()) {
                    if (location.search) {
                        const query = qs.parse(location.search.slice(1))
                        if (query.loginType === 'email') {
                            setForceLoginType('email')
                        }
                    }
                    setOpenPrivateRemindLoginDialog(true)
                } else {
                    setOpenPrivateRemindPermissionDialog(true)
                }
            }
        }
    }

    async function getInvestDetail() {
        if (!projectId) return
        const data:InvestDetail = await getDetail({
            project_id: projectId
        })
        setInvestDetail(data)
        initStatus(data)
    }

    async function initProfile(_investInfo:InvestInfo) {
        if (!isLogin()) return
        const data:ProfileType = await getProfile({})
        setProfile(data)
        setIsSelfProject(data.id === _investInfo.user_id)
    }

    function initStatus(_investDetail:InvestDetail) {
        if (_investDetail.audit && _investDetail.status === INVEST_STATUS.PENDING) {
            setStatus(PROJECT_STATUS.PENDING_CREATE_FUNDING_POOL)
            return
        }
        if (!_investDetail.audit && _investDetail.status === INVEST_STATUS.PENDING) {
            // pre state, maby not effect
            setStatus(PROJECT_STATUS.PENDING_AUDIT)
            return
        }
        setStatus(_investDetail.status)
    }

    function onWatch(iswatch:boolean) {
        setInvestDetail({...investDetail as InvestDetail, watched: iswatch, watch_cnt: (investDetail?.watch_cnt || 0) + (iswatch ? 1 : -1)})
    }

    function refreshCommunity() {
        setRefreshCommunityFlag(!refreshCommunityFlag)
    }

    function onGetAnnoucements(count:number) {
        setShowAnnoucement(count > 0)
    }

    function dealAnnoucementMsg() {
        if (location.search) {
            const query = qs.parse(location.search.slice(1))
            if (query.read as string === '1' && projectId) {
                // console.log(query.read, projectId)
                readMsgScope(projectId)
            }
        }
    }

    useEffect(() => {
        init()
        window.scrollTo(0, 0)
        dealAnnoucementMsg()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location])

    useEffect(() => {
        setIsPrivate(investInfo?.stealth === 1)
    }, [investInfo])

    return (
        <div className="invest-detail">
            <Header></Header>
            {investInfo && investDetail &&
            <div className="invest-detail__container">
                <InfoCard
                investInfo={investInfo}
                investDetail={investDetail}
                onWatch={onWatch}
                isSelfProject={isSelfProject}
                ></InfoCard>
                {/* <div className="invest-detail__title">Financing Detials</div> */}
                <div className="invest-detail__content">
                    <div className="invest-detail__content_left">
                        <ProjectCard
                        investInfo={investInfo}
                        investDetail={investDetail}
                        onRefresh={init}
                        profile={profile}
                        ></ProjectCard>
                        <ProjectTeammates investInfo={investInfo}></ProjectTeammates>
                        <FinRounds investInfo={investInfo}></FinRounds>
                        <ProjectInsights
                        investInfo={investInfo}
                        investDetail={investDetail}
                        profile={profile}
                        onRefresh={refreshCommunity}
                        refreshFlag={refreshCommunityFlag}
                        ></ProjectInsights>
                    </div>
                    <div className="invest-detail__content_right">
                        {isPrivate && <PrivateTag></PrivateTag>}
                        <InvestCard
                        investInfo={investInfo}
                        investDetail={investDetail}
                        isSelfProject={isSelfProject}
                        projectStatus={status}
                        onRefresh={init}
                        profile={profile}
                        onRefreshProfile={() => initProfile(investInfo)}
                        onWatch={onWatch}
                        isPrivate={isPrivate}
                        ></InvestCard>
                        <TopInvestors
                        investInfo={investInfo}
                        investDetail={investDetail}
                        onRefresh={getInvestDetail}
                        profile={profile}
                        ></TopInvestors>
                        <Announcement
                        investInfo={investInfo}
                        isSelfProject={isSelfProject}
                        onGetData={onGetAnnoucements}
                        investDetail={investDetail}
                        ></Announcement>
                        <Questions
                        investInfo={investInfo}
                        investDetail={investDetail}
                        refreshFlag={refreshCommunityFlag}
                        profile={profile}
                        ></Questions>
                    </div>
                    {/* <div className="box-wrap">
                        <ProjectCard
                        investInfo={investInfo}
                        investDetail={investDetail}
                        onRefresh={init}
                        profile={profile}
                        ></ProjectCard>
                        <div className="right">
                            <InvestCard
                            investInfo={investInfo}
                            investDetail={investDetail}
                            isSelfProject={isSelfProject}
                            projectStatus={status}
                            onRefresh={init}
                            profile={profile}
                            onRefreshProfile={() => initProfile(investInfo)}
                            ></InvestCard>
                            <TopInvestors
                            investInfo={investInfo}
                            investDetail={investDetail}
                            onRefresh={getInvestDetail}
                            profile={profile}
                            ></TopInvestors>
                        </div>
                    </div>
                    <ProjectTeammates investInfo={investInfo}></ProjectTeammates> */}
                </div>
                
                <CommunityComments
                investInfo={investInfo}
                investDetail={investDetail}
                refreshFlag={refreshCommunityFlag}
                profile={profile}
                ></CommunityComments>
                {/* {investInfo.rounds > 0 && <div className="invest-detail__title">Financing round ： {roundDetailTitle[investInfo.rounds]}</div>}
                <FinRounds investInfo={investInfo}></FinRounds>
                {(showAnnoucement || isSelfProject) && <div className="invest-detail__title">Announcement</div>} */}
                {/* <div className="invest-detail__title">Announcement</div> */}
                {/* <Announcement investInfo={investInfo} isSelfProject={isSelfProject} onGetData={onGetAnnoucements}></Announcement> */}
                {/* <div className="light">
                    <div className="invest-detail__title">Project Insights</div>
                    <div className="invest-detail__content">
                        <div className="box-wrap">
                            <ProjectInsights investInfo={investInfo} investDetail={investDetail} profile={profile} onRefresh={refreshCommunity} refreshFlag={refreshCommunityFlag}></ProjectInsights>
                            <div className="right">
                                <TopContributors investInfo={investInfo} refreshFlag={refreshCommunityFlag}></TopContributors>
                                <Questions investInfo={investInfo} investDetail={investDetail} refreshFlag={refreshCommunityFlag} profile={profile}></Questions>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="invest-detail__title">Community Comments</div> */}
                {/* <CommunityComments investInfo={investInfo} investDetail={investDetail} refreshFlag={refreshCommunityFlag} profile={profile} ></CommunityComments> */}
            </div>
            }
            <RemindDialog
            open={openPrivateRemindLoginDialog}
            onClose={() => setOpenPrivateRemindLoginDialog(false)}
            btnText='I got it'
            handleClickBtn={() => useLoginInterceptor()(undefined, {loginType: forceLoginType})}
            title='Private Financing Eligible Check'
            content='This is an exclusive link for the private financing , please log in to 1seeddao to check whether you have permission to view it'
            img={require('assets/img/invest-detail/private-dialog-icon/login.png')}
            ></RemindDialog>

            <RemindDialog
            open={openPrivateRemindPermissionDialog}
            onClose={() => setOpenPrivateRemindPermissionDialog(false)}
            btnText='I got it'
            handleClickBtn={() => setOpenPrivateRemindPermissionDialog(false)}
            title='Private Financing Uneligible'
            content='This is an exclusive link for private financing of a project. I am sorry to inform you that you do not have permission to view this project.'
            img={require('assets/img/invest-detail/private-dialog-icon/permission.png')}
            ></RemindDialog>
        </div>
    )
}