import React from 'react'
import { useLocation } from 'react-router'
import qs from 'qs'
import { Document, pdfjs } from 'react-pdf';
import './index.scss'
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     'pdfjs-dist/build/pdf.worker.min.js',
//     import.meta.url,
//   ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
export default function PDFView() {

    const location = useLocation()

    const [url, setUrl] = React.useState('')

    function getUrl() {
        if (location.search) {
            const query = qs.parse(location.search.slice(1))
            return decodeURIComponent(query.url as string || '')
        }
        return ''
    }

    React.useEffect(() => {
        setUrl(getUrl())
        console.log(getUrl())
    }, [])

    return (
        // <Document file={url}></Document>
        <div className="pdf-wrap">
            <iframe className='pdf-frame' src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${url}`}></iframe>
        </div>
    )
}