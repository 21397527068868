import React, { useState } from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Header from "components/header";
import "./index.scss"
import { ReactComponent as Vector } from "assets/svg/Vector.svg";
import { SvgIcon } from "@mui/material";
// import useFixedHeader from "hooks/useFixedHeader";
import { update, getProfile, ProfileType, walletBindPre, walletBind, emailBind, ensLink } from "api/user"
import { styled } from '@mui/material/styles'
import SocialList from './components/list';
import UploadInput from 'components/common/upload';
import {Button} from '@mui/material';
import FormInput from 'components/common/form-input';
import FormTextarea from 'components/common/form-textarea';
import Loading from 'components/common/loading';
import { KV } from 'pages/apply/types';
import { emailReg, urlReg } from 'constant/form';
import Message from 'components/common/message';
import { getWeb3 } from 'utils/web3';
import useChainInterceptor from 'hooks/useChainInterceptor';
import useAuthSocialAccount, { getSocialAuthBC } from 'hooks/useAuthSocialAccount';

export interface FormValueProps extends KV {
  username: string;
  email: string;
  wallet: string;
  ens_name: string;
  site: string;
  bio: string;
  profile_url: string;
  twitter: string;
  discord: string;
  facebook: string;
  telegram: string;
  linkedin: string;
  github: string;
  mirror: string;
  instagram: string
  cover_url: string
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// 'discord','facebook','telegram','linkedin','github','mirror','instagram', 'twitter'
const social_list = ['discord','telegram','linkedin','github','mirror', 'twitter']

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
      <div
        hidden={value !== index}
        {...other}
      >
        {value === index && (
            <div>{children}</div>
        )}
      </div>
  )
}

let timerObj: NodeJS.Timer | undefined
let _timer = 60
let _formData:FormValueProps

export default function Setting() {
  // const [isFixedHeader] = useFixedHeader()
  const [tabsValue, setTabsValue] = useState(0)
  // const [profile, setProfile] = useState<ProfileType>()
  const [formData, setFormData] = useState<FormValueProps>({
    username: '',
    email: '',
    wallet: '',
    ens_name: '',
    site: '',
    bio: '',
    profile_url: '',
    twitter: '',
    discord: '',
    facebook: '',
    telegram: '',
    linkedin: '',
    github: '',
    mirror: '',
    instagram: '',
    cover_url: ''
  })
  const [emailPrefix, setEmailPrefix] = useState('')
  const [timer, setTimer] = useState(60)

  const chainInterceptor = useChainInterceptor()

	const authSocialAccount = useAuthSocialAccount((social_name:string, social_user_name: string) => {
    setFormData({..._formData, [social_name]: social_user_name})
  })
  
  async function initProfile() {
    const profileData:ProfileType = await getProfile({})
    // setProfile(profileData)
    setFormData({...Object.assign(formData, {
      ...profileData.social,
      email: profileData.user_info.email,
      wallet: profileData.user_info.wallet,
      ens_name: profileData.user_info.ens_name,
      profile_url: profileData.user_info.profile_uri,
      site: profileData.user_info.site,
      bio: profileData.user_info.bio,
      username: profileData.user_info.username,
      cover_url: profileData.user_info.cover_uri
    })})
  }

  React.useEffect(() => {
    _formData = formData
  }, [formData])

  React.useEffect(() => {
    initProfile()
    // getSocialAuthBC().addEventListener('message', onMessage)
    // return () => {
    //   getSocialAuthBC().removeEventListener('message', onMessage)
    // }
  }, [])

  // function onMessage(e:MessageEvent) {
  //   console.log('onMessage：', e.data)
  //   const data = e.data
  //   if (!data || data.target !== 'auth_1seed') {
  //     return
  //   }
  //   if (data.success) {
  //     // handleFormChange(data.social_name, data.social_user_name)
  //     setFormData({..._formData, [data.social_name]: data.social_user_name})
  //     Message('+10 Aseed')
  //   } else {
  //     Message(data.e?.message || 'Connect failed', { severity: 'error' })
  //   }
  // }

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue)
  }
  const StyledTab = styled(Tab)`
  &.MuiTab-textColorPrimary {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.005em;
    color: #1A1A1A;
    text-transform: none;
  }
`;

  const handleFormChange = (k:string, v:string) => {
    setFormData({...formData, [k]: v})
  }

  const submit = async (noCheck?:boolean) => {
    if (!noCheck) {
      if (!formData.username) {
        Message('Username is required', { severity: 'warning' })
        return
      }
      if (formData.username.length < 3 || formData.username.length > 32) {
        Message('Username should be 3 - 32 characters', { severity: 'warning' })
        return
      }
      if (formData.site && !urlReg.test(formData.site)) {
        Message('Site address is invalid')
        return
      }
    }
    const param = new FormData()
    Object.keys(formData).forEach(k => {
      param.append(k, formData[k])
    })
    Loading(true)
    try {
      await update(param)
      Message('save success')
      initProfile()
    } catch(e:any) {
      console.log(e)
      Message(e.message || 'Unknown error', {severity: 'error'})
    } finally {
      Loading(false)
    }
  }

  const authWallet = async () => {
    if (formData.wallet) return
    const web3 = getWeb3()
    if (!web3.givenProvider) {
        Message('Please install Metamask First', {severity:'warning'})
        window.open('https://metamask.io/download/')
        return
    }
    chainInterceptor(async () => {
      try {
        const publicAddress = await web3.eth.requestAccounts();
        const data = await walletBindPre({public_address: publicAddress[0]})
        handleSignMessage(publicAddress[0], data?.nonce)
      } catch (error:any) {
          console.log(error)
          Message(error.message || 'Unknown error', { severity: 'error' })
      }
    })
  }

  async function handleSignMessage(publicAddress:string, nonce:string) {
      try {
        const web3 = getWeb3()
          const sign = await web3.eth.personal.sign(
              web3.utils.toHex(nonce),
              publicAddress,
              '' // password?
          )
          await walletBind({
              signature: sign,
              public_address: publicAddress
          })
          Message('Connect wallet success')
          handleFormChange('wallet', publicAddress)
      } catch (error:any) {
          console.log(error)
          Message(error.message || 'Unknown error', { severity: 'error' })
      }
  }

  const confirmEmail = async () => {
    if (!emailPrefix) {
      Message('Enter email first', {severity: 'warning'})
      return
    }
    if (!emailReg.test(emailPrefix)) {
      Message('Please enter correct email', {severity: 'warning'})
      return
    }
    try {
      await emailBind({
        email: emailPrefix
      })
      _timer = 60
      timerObj = setInterval(() => {
        _timer = _timer - 1
        if (timer <= 0) {
          clearInterval(timerObj)
          return
        }
        setTimer(_timer)
      }, 1000)
    } catch (e:any) {
      console.log(e)
      Message(e.message || 'Unknown error', {severity: 'error'})
    }
  }

  const isEmailTimerRunning = () => timer > 0 && timer < 60

  const handleClickEns = async () => {
    try {
      const data = await ensLink()
      if (data && data.ens) {
        handleFormChange('ens_name', data.ens)
      } else {
        Message('empty ens name', {severity:'warning'})
      }
    } catch (error:any) {
      Message(error.message || 'Unknown error', {severity: "warning"})
    }
  }


  return (
    <div className={'setting-page__container'}>
      <Header></Header>
      <div className={'setting-page__content'}>
        <div className={'setting-page__content--tab'}>
          <div className={'setting-page__content--title'}>SETTINGS</div>
          <Tabs
            orientation={'vertical'}
            value={tabsValue}
            onChange={handleTabsChange}
          >
            <StyledTab iconPosition="start" icon={<SvgIcon component={Vector} sx={{ width: 20, height: 20, fontSIze: 20, display: 'inline', textTransform: 'none' }} />} label={'My profile'} />
            <StyledTab iconPosition="start" icon={<SvgIcon component={Vector} sx={{ width: 20, height: 20, fontSIze: 20, display: 'inline' }} />} label={'My wallet'} />
          </Tabs>
        </div>
        <div className={'setting-page__content--form'}>
          <TabPanel value={tabsValue} index={0}>
              <div className="img-field" style={{
                backgroundImage: formData.cover_url ? `url(${formData.cover_url})` : `url(${require('assets/img/profile-info/bg.png')})`
              }}>
                <div className="profile-img">
                  <div className="profile-img__box">
                    <label htmlFor="profile-img__upload">
                      <UploadInput id='profile-img__upload' uploadType={0} onChange={v => handleFormChange('profile_url', v)} onProgress={Loading}></UploadInput>
                      {formData.profile_url && <img className='profile-img__preview' src={formData.profile_url} alt="" />}
                      <div className="profile-img__icon">
                        <img src={require('assets/img/setting/upload.png')} alt="" className="icon" />
                      </div>
                    </label>
                  </div>
                </div>
                {/* <label htmlFor="profile-cover__upload"> */}
                  {/* <UploadInput id='profile-cover__upload' uploadType={6} onChange={v => handleFormChange('cover_url', v)} onProgress={Loading}></UploadInput> */}
                  {/* <img src={require('assets/img/setting/cover-btn.png')} alt="" className='profile-cover' /> */}
                  <Button className="profile-cover btn-1seed" variant='outlined' component="label">
                    <div className="text">Edit cover</div>
                    <UploadInput id='profile-cover__upload' uploadType={6} onChange={v => handleFormChange('cover_url', v)} onProgress={Loading}></UploadInput>
                  </Button>
                {/* </label> */}
              </div>
              <div className="flex">
                <div>
                  <div className="form-wrap">
                    <div className="form-title">Username</div>
                    <FormInput placeholder='Edit your username' value={formData.username} onChange={v => handleFormChange('username', v)}></FormInput>
                  </div>
                  <div className="form-wrap">
                    <div className="form-title">Bio</div>
                    <FormTextarea value={formData.bio} onChange={v => handleFormChange('bio', v)} placeholder='Tell about yourself in a few words.'></FormTextarea>
                  </div>
                  <div className="form-wrap">
                    <div className="form-title">Email
                      <div className="sub">Add your email and complete the verification to sign in the platform and get the latest notifications.</div>
                    </div>
                    <div className="email-input-wrap">
                      <FormInput
                      disabled={!!formData.email}
                      value={formData.email ? formData.email : emailPrefix}
                      onChange={v => setEmailPrefix(v)}
                      placeholder='Enter your Email'
                      className='email-input'>
                        {/* {!formData.email && <div className="email-subfix">@gmail.com</div>} */}
                      </FormInput>
                      {!formData.email &&
                      <Button
                      className="email-confirm-btn btn-1seed"
                      variant='contained'
                      disabled={!emailPrefix || isEmailTimerRunning()}
                      onClick={confirmEmail}>Confirm
                      </Button>}
                    </div>
                    {isEmailTimerRunning() && <div className='email-remind'>Please check {emailPrefix} and verify your email address.Still no email? You can resend email in 0:{timer}</div>}
                  </div>
                  <div className="form-wrap">
                    <div className="form-title">Social Links
                      <div className="sub">Add your social links to build a stronger community reputation.</div>
                    </div>
                    <SocialList list={Object.keys(formData).filter(k => social_list.includes(k)).map(k => {
                      return {
                        name: k,
                        value: formData[k]
                      }
                    })}
                    wallet={formData.wallet}
                    authSocialAccount={authSocialAccount}
                    ></SocialList>
                  </div>
                  <div className="form-wrap">
                    <div className="form-title">Yoursite</div>
                    <FormInput placeholder='https://' value={formData.site} onChange={v => handleFormChange('site', v)}></FormInput>
                  </div>
                </div>
                <div className="verify-box">
                  <img src={require('assets/img/setting/verify.png')} alt="" className="verify-box__img" />
                  <div className="verify-box__text1">Verify your account</div>
                  <div className="verify-box__text2">Investment institutions and KOL can conduct official certification to enhance your community reputation in 1Seed</div>
                  <div className="verify-box__btn" onClick={() => Message('Coming soon!')}>
                    <div className="verify-box__btn-text">Verify</div>
                  </div>
            </div>
              </div>
              
            
            <Button className="btn btn-1seed" variant='contained' onClick={() => submit(false)}>Save</Button>

          </TabPanel>
          <TabPanel value={tabsValue} index={1}>
            <div className="form-wrap">
              <div className="form-title">Wallet
                  <div className="sub">Connect your wallet address for login and community activities</div>
              </div>
              <div className="wallet-wrap" onClick={authWallet}>
                <img src={require('assets/img/setting/metamask.png')} alt="" className="wallet-wrap__icon" />
                <div className="wallet-wrap__text">
                  {formData.wallet && <div className='sub'>Wallet</div>}
                  {formData.wallet || 'Metamask'}
                </div>
              </div>
              
            </div>
            <div className="form-wrap">
              <div className="form-title">ENS
                  <div className="sub">Setting your primary ENS name will replace your username with your ENS name in all events you participate in on 1seed.</div>
              </div>
              <FormInput placeholder='Show your ENS name' disabled value={formData.ens_name}>
                <Button className='btn-1seed ens-btn' disabled={!formData.wallet} variant='contained' onClick={handleClickEns}>Link</Button>
              </FormInput>
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  )
}