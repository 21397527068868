import React from 'react';
import './index.scss'
import FormInput from 'components/common/form-input';
import { Button } from '@mui/material';
import { ExploreProjectParams, ExploreFilterConfig } from 'api/explore';
import { FIN_STATUS } from '../map';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { TokenIconMap } from 'pages/apply/maps';
import chainLogo from 'assets/svg/chain-logo.svg'

interface Props {
    filter: ExploreProjectParams,
    config: ExploreFilterConfig|undefined,
    onClose: () => void,
    onChange: (filter:ExploreProjectParams) => void
}

const finList = [
    {
        k: 'All',
        v: FIN_STATUS.ALL
    },
    {
        k: 'Financing',
        v: FIN_STATUS.FINANCING
    },
    {
        k: 'Success',
        v: FIN_STATUS.SUCCESS
    }
]

export default function Filter(props: Props) {
    const { config, filter, onClose, onChange } = props
    const [type, setType] = React.useState(1)
    const [filterData, setFilterData] = React.useState(filter)
    const [openTokenList, setOpenTokenList] = React.useState(false)

    const ref = useDetectClickOutside({ onTriggered: () => setOpenTokenList(false) });

    function onChangeFilter(k:string, v:string|number) {
        setFilterData({...filterData, [k]: v})
    }
    function handleClickApply() {
        onChange({...filterData})
        // onClose()
    }

    function handleChangeTrack(_track: string) {
        console.log(_track, filterData)
        if (_track === filterData.tracks) {
            onChangeFilter('tracks', '')
            return
        }
        onChangeFilter('tracks', _track)
    }

    return (
      <div className="explore-filter" onClick={(e) => e.stopPropagation()}>
        <div className="explore-filter__chain">
            <img src={chainLogo} alt="" className="icon" />
            <div className='chain-title'>
                <div className="text1">Explore</div>
                <div className="text2">Optimism</div>
            </div>
        </div>
        <div className="explore-filter__content">
            <div className="explore-filter__item">
                <div className="item-title">Status</div>
                <div className="item-content btn-list">
                    {finList.map((item, index) => {
                        return (
                            <Button
                            key={`fin_${index}`}
                            className={`btn ${item.v === filterData.status ? 'active' : ''}`}
                            onClick={() => onChangeFilter('status', item.v)}
                            >{item.k}</Button>
                        )
                    })}
                </div>
            </div>
            <div className="explore-filter__item">
                <div className="item-title">Tracks</div>
                <div className="item-content btn-list">
                    {config?.apply_track.map((item, index) => {
                        return (
                            <Button
                            key={`track_${index}`}
                            className={`btn ${item.track === filterData.tracks ? 'active' : ''}`}
                            onClick={() => handleChangeTrack(item.track)}
                            >{item.track}</Button>
                        )
                    })}
                </div>
            </div>
            <div className="explore-filter__item">
                <div className="item-title">Type</div>
                <div className="item-content btn-list">
                    <Button className={`btn ${type === 1 ? 'active' : ''}`} onClick={() => setType(1)}>All</Button>
                    <Button className={`btn ${type === 2 ? 'active' : ''}`} onClick={() => setType(2)}>Token</Button>
                    <Button className={`btn ${type === 3 ? 'active' : ''}`} onClick={() => setType(3)}>IDO</Button>
                    <Button className="btn" disabled>Equity</Button>
                </div>
            </div>
            <div className="explore-filter__item">
                <div className="item-title">Financing amount</div>
                <div className="item-content amount">
                    <FormInput
                    placeholder='Min'
                    className='amount-input'
                    rule={/^\d*$/}
                    value={filterData.financing_amount_min}
                    onChange={(v) => onChangeFilter('financing_amount_min', v)}
                    ></FormInput>
                    <span className="to">To</span>
                    <FormInput
                    placeholder='Max'
                    className='amount-input'
                    rule={/^\d*$/}
                    value={filterData.financing_amount_max}
                    onChange={(v) => onChangeFilter('financing_amount_max', v)}
                    ></FormInput>
                    <div className="token-selector" onClick={() => setOpenTokenList(!openTokenList)} ref={ref}>
                        <div className="text">{filterData.financing_token_symbol}</div>
                        <img src={require('assets/img/explore/arrow-down-token.png')} alt="" className="icon" />
                        {openTokenList && <div className="token-list">
                            {config?.apply_support_token.map((item, index) => {
                                return (
                                    <div className="token" key={`token_${index}`} onClick={() => onChangeFilter('financing_token_symbol', item.symbol)}>
                                        <img src={TokenIconMap[item.symbol]} alt="" className="icon" />
                                        {item.symbol}
                                    </div>
                                )
                            })}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <Button className='btn-1seed btn-apply' variant='contained' onClick={handleClickApply}>Apply</Button>
      </div>
    );
  }
