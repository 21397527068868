import React from "react";
import {
    createBrowserRouter,
  } from "react-router-dom";
import Index from "pages/index/index";
import Profile from "pages/profile/index";
import LoginGuide from "pages/login-guide";
import Setting from "pages/setting/index"
import Apply from "pages/apply";
import ApplyTransfer from "pages/apply/transfer";
import BindEmailConfirm from "pages/bind-email-confirm";
import OAuth from "pages/oauth";
import BindEmailResult from "pages/bind-email-result";
import Explore from "pages/explore";
import InvestDetailPage from "pages/invest-detail";
import ApplyGuide from "pages/apply-guide";
import Container from "components/container";
import TradingPage from "pages/trading";
import TradingListPage from "pages/trading-list";
import TradingDetailPage from "pages/trading-detail";
import CheckEligibility from "pages/check-eligibility";
import PDFView from "pages/pdf-view";

const router = createBrowserRouter([
  {
    // when the URL matches this segment
    path: "/",

    // it renders this element
    element: <Container />,

    // // with this data loaded before rendering
    // loader: async ({ request, params }) => {
    //   return 
    // },

    // // performing this mutation when data is submitted to it
    // action: async ({ request }) => {
    //   return 
    // },

    // // and renders this element in case something went wrong
    // errorElement: null,
    children: [
      {
        // when the URL matches this segment
        path: "/",
    
        // it renders this element
        element: <Index />,
    
        // // with this data loaded before rendering
        // loader: async ({ request, params }) => {
        //   return 
        // },
    
        // // performing this mutation when data is submitted to it
        // action: async ({ request }) => {
        //   return 
        // },
    
        // // and renders this element in case something went wrong
        // errorElement: null,
      },
      {
        path: "/profile",
    
        element: <Profile />,
    
        // element: <Navigate to='/profile/invested' replace={true}></Navigate>,
        // children: [
        //   {
          
        //     path: "invested",
    
        //     element: <Invested />
        //   }
        // ],
      },
      {
          path: "/setting",
    
          element: <Setting />,
      },
      {
        path: "/login-guide",
    
        element: <LoginGuide />,
      },
      {
        path: "/apply",
    
        element: <Apply />,
      },
      {
        path: "/apply/transfer",
    
        element: <ApplyTransfer />,
      },
      {
        path: "/bind-email-confirm",
    
        element: <BindEmailConfirm />,
      },
      {
        path: "/oauth",
    
        element: <OAuth />,
      },
      {
        path: "/bind-email-result",
    
        element: <BindEmailResult />,
      },
      {
        path: "/explore",
    
        element: <Explore />,
      },
      {
        path: "/invest-detail/:id",
    
        element: <InvestDetailPage />
      },
      {
        path: "/apply-guide",
        
        element: <ApplyGuide></ApplyGuide>
      },
      {
        path: "/trading",

        element: <TradingPage></TradingPage>
      },
      {
        path: "/trading-list",

        element: <TradingListPage></TradingListPage>
      },
      {
        path: "/trading-detail/:id",
    
        element: <TradingDetailPage />
      },
      {
        path: '/check-eligibility',
        element: <CheckEligibility></CheckEligibility>
      }
    ]
  },
  {
    path: '/pdf-view',
    element: <PDFView></PDFView>
  }
]);

export default router
