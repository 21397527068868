import React from 'react';
import Login from 'components/login';
import { isLogin } from 'utils/auth';
import { useNavigate } from 'react-router-dom';
import { getLatest } from 'api/apply';
import { APPLY_STATUS } from 'constant';
import Message from 'components/common/message';
import { ProfileType, getProfile } from 'api/user';
import BindEmailDialog from 'components/bind-email-dialog';

interface Props extends React.PropsWithChildren {
    className?: string
    text?: string
    isGuideApply?: boolean
}

export default function ApplyText(props:Props) {

    const { className = '', text = 'Apply', children, isGuideApply = false } = props

    const [open, setOpen] = React.useState(false)
    const [openBindEmail, setOpenBindEmail] = React.useState(false)

    const navigate = useNavigate()

    async function guideToApply() {
        if (isLogin()) {
            const { project_id, apply_status } = await getLatest()
            if (project_id && [APPLY_STATUS.DRAFT, APPLY_STATUS.PENDING].includes(apply_status)) {
                navigate('/apply/transfer')
            } else {
                navigate(`/apply-guide`)
            }
        } else {
            navigate(`/apply-guide`)
        }
    }

    async function goApply() {
        if (isGuideApply) {
            guideToApply()
            return
        }
        if (!isLogin()) {
            setOpen(true)
            return
        }
        try {
            const { project_id, apply_status } = await getLatest()
            const profile:ProfileType = await getProfile({})
            if (profile.user_info.email) {
                if (project_id && [APPLY_STATUS.DRAFT, APPLY_STATUS.PENDING].includes(apply_status)) {
                    navigate('/apply/transfer')
                } else {
                    navigate(`/apply`)
                }
            } else {
                setOpenBindEmail(true)
            }
        } catch(e:any) {
            console.log(e)
            Message(e.message || 'Unknown error', {severity: 'error'})
        }
    }

    return (
        <div>
            <div className={className} onClick={goApply}>
                {children ? <>{children}</> : <span>{text}</span>}
            </div>
            <Login onClose={() => setOpen(false)} open={open}></Login>
            <BindEmailDialog onClose={() => setOpenBindEmail(false)} open={openBindEmail}></BindEmailDialog>
        </div>
  );
}
