import baseRequest from "../../utils/http"
import { TeammateType, SocialType } from "pages/apply/types"
import { TopInvestor } from "api/user"

const api = new baseRequest()

const PREFIX = '/api/v1/finance'

type GetParamParams = {
  project_id: string
}

export type GetParamResp = {
    param:any,
    signature: string
}

type GetProjectParams = {
  project_id: string
}

export type Answer = {
  answer: string[]
}

export interface InvestInfo {
  blockchain: string
  desc: string
  details: string[]
  duration: number
  fin_rule: number
  goal: string
  image_url: string
  // insights: Answer[]
  logo_url: string
  name: string
  short_name: string
  other_files_name: string
  other_files_url: string
  project_id: string
  rounds: number
  site: string
  social: SocialType
  step: number
  teammates: TeammateType[]
  token_addr: string
  token_decimal: number
  token_symbol: string
  tracks: string[]
  type: number
  user_ens_name: string
  user_id: string
  user_min_invest: string
  user_name: string
  valuation: number
  wallet: string
  whitepaper_name: string
  whitepaper_url: string
  user_avatar_url: string
  percent: string,
  highlights: string[],
  pitch: string,
  is_ido: number,
  token_issuance: number,
  stealth: number,
  allow_public: number
  // overview: string[]
}

export interface InvestDetail {
  amount_investors: TopInvestor[]
  famers: TopInvestor[]
  fin_amount: string
  institutions: TopInvestor[]
  invest_cnt: number
  project_id: string
  time_investors: TopInvestor[]
  watch_cnt: number
  watched: boolean,
  end_ts: number,
  status: number,
  invest_contract: string,
  // claim_start: boolean,
  audit: number,
  post_fin_stage: number,
  // google_calendar_permissions: boolean,
  my_share: string,
  google_calendar_url: string
}

type GetInvestorsParam = {
  project_id: string,
  page_no: number,
  page_size: number
}

type GetInvestorsResponse = {
  investors: Array<TopInvestor>
}

type PostCalParam = {
  project_id: string,
  cal: string
}

export function getParam(params:GetParamParams):Promise<GetParamResp|any> {
  return api.get(`${PREFIX}/get_param`, params)
}

export function getInfo(params:GetProjectParams):Promise<InvestInfo|any> {
  return api.get(`${PREFIX}/info`, params)
}

export function getDetail(params:GetProjectParams):Promise<InvestDetail|any> {
  return api.get(`${PREFIX}/detail`, params)
}

export function watch(params:GetProjectParams):Promise<any> {
  return api.post(`${PREFIX}/watch`, params)
}

export function unwatch(params:GetProjectParams):Promise<any> {
  return api.post(`${PREFIX}/unwatch`, params)
}

export function getInvestors(params:GetInvestorsParam):Promise<any|GetInvestorsResponse> {
  return api.get(`${PREFIX}/investors`, params)
}

export function postCal(params:PostCalParam):Promise<any> {
  return api.post(`${PREFIX}/cal`, params)
}