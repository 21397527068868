import React from 'react'
import './index.scss'
import CopyToClipboard from 'react-copy-to-clipboard'
import Tips from 'components/tips'

type Props = {
    showText: string,
    copyText: string
}

export default function CopyItem(props:Props) {
    const { showText, copyText } = props

    const [showTip, setShowTip] = React.useState(false)

    function handleCopy() {
        setShowTip(true)
        setTimeout(() => {
            setShowTip(false)
        }, 2000)
    }

    return <div className="copy-item">
        {showText}
        <CopyToClipboard text={copyText} onCopy={handleCopy}>
            <span className="copy-wrap">
                <img src={require('assets/img/trading/copy.png')} alt="" className="copy" />
                {showTip && <Tips text='Copied'></Tips>}
            </span>
        </CopyToClipboard>
    </div>
}
