import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import closeIcon from 'assets/img/close.png'
import googleIcon from 'assets/img/login/google.png'
import google3x from 'assets/img/login/google@3x.png'
import backIcon from 'assets/img/back.png'
import './index.scss'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { emailBind } from 'api/user';
import Message from '../common/message';
import { emailReg } from 'constant/form';

let timerObj: NodeJS.Timer | undefined
let _timer = 60
let profileInterval: NodeJS.Timer | undefined

// interface Props{
//     open: boolean,
//     onClose: () => void
// }

export default function BindEmailDialog(props: DialogProps) {
    const { onClose, open }:React.MouseEventHandler<HTMLElement>|any = props
    const [step, setStep] = React.useState(0)
    const [emailPrefix, setEmailPrefix] = React.useState('')
    const [timer, setTimer] = React.useState(60)
    const [isResend, setIsResend] = React.useState(false)

    const navigate = useNavigate()

    function back() {
        setStep(step - 1)
    }

    React.useEffect(() => {
        return () => {
            if (profileInterval) {
                clearInterval(profileInterval)
            }
        }
    }, [])

    React.useEffect(() => {
        if (open) {
            setEmailPrefix('')
            setIsResend(false)
            setTimer(60)
            setStep(0)
        }
    }, [open])

    async function confirmEmail() {
        if (!emailPrefix) {
            Message('Please enter gmail prefix first', { severity: 'warning' })
            return
        }
        if (!emailReg.test(emailPrefix)) {
            Message('Please enter correct email address', { severity: 'warning' })
            return
        }
        // if (emailPrefix.includes('@')) {
        //     Message('Please enter correct email address', { severity: 'warning' })
        //     return
        // }
        try {
            await emailBind({
                email: `${emailPrefix}`
            })
            timerObj = setInterval(() => {
                setTimer(--_timer)
                if (_timer === 0) {
                    clearInterval(timerObj)
                    timerObj = undefined
                    setIsResend(true)
                    _timer = 60
                }
            }, 1000)
            // if (!profileInterval) {
            //     profileInterval = setInterval(async () => {
            //         const data:ProfileType = await getProfile({})
            //         if (data.user_info.email) {
            //             profileInterval = undefined
            //             clearInterval(profileInterval)
            //             Message('Binding email success ')
            //             onClose()
            //             navigate('/apply')
            //         }
            //     }, 1000)
            // }
        } catch (error:any) {
            Message(error.message || 'Unknown error', { severity: 'error' })
        }
    }

    function onChange(e:any) {
        setEmailPrefix(e.target.value || '')
    }

    function isDisabled() {
        return timer < 60 && timer > 0
    }

    return (
        <Dialog {...props}>
            <div className='bind-email-dialog'>
                {step === 0 && (
                    <div className='step0'>
                        <img src={closeIcon} alt="" className="close" onClick={onClose} />
                        <div className="title">Reminder</div>
                        <div className="title-sub">Before applying for financing, please complete personal 【Email Verification】</div>
                        <div className="email">Email</div>
                        <div className="email-content" onClick={() => setStep(step + 1)}>
                            <img src={require('assets/img/email.png')} alt="" className="icon" />
                            <span className="text">Email</span>
                        </div>
                        <div className="line">
                            <div className="divide"></div>
                            <span className="text">Or</span>
                            <div className="divide"></div>
                        </div>
                        <Button variant='contained' className='btn-1seed btn' onClick={() => navigate('/setting')}>Go to Profile Settings</Button>
                    </div>
                )}
                {/* {step === 1 && (
                    <div className='step1 form-step'>
                        <img src={backIcon} alt="" className="back" onClick={back} />
                        <div className="title">Google Email Verification</div>
                        <div className="title2">Email</div>
                        <div className="desc">Add your Google email and complete the verification to sign in the platform and get the latest notifications.</div>
                        <div className="form-wrap">
                            <input type="text" className='input' placeholder='Enter your Google Email' />
                            <div className="subfix">@gmail.com</div>
                        </div>
                        <Button variant='contained' className='btn-1seed btn'>Confirm</Button>
                    </div>
                )} */}
                {step === 1 && (
                    <div className='step2 form-step'>
                        <img src={backIcon} alt="" className="back" onClick={back} />
                        <div className="title">Email Verification</div>
                        <img src={require('assets/img/email.png')} className='google-img' alt="" />
                        <div className="text1">Confirm Email</div>
                        {isDisabled() ? (
                            <div className="text2">Please check <span className="sub">{emailPrefix}</span> and verify your email address. Still no email ? You can resend email in <span className="sub">0:{timer}</span></div>
                        ) : (
                            <div className="text2">Add your email and complete the verification to sign in the platform and get the latest notifications.</div>
                        )}
                        <div className="form-wrap">
                            <input
                            type="text"
                            className='input'
                            placeholder='Enter your Email'
                            value={emailPrefix}
                            onChange={onChange}
                            />
                            {/* <div className="subfix">@gmail.com</div> */}
                        </div>
                        <Button
                        variant='contained'
                        className='btn-1seed btn'
                        onClick={confirmEmail}
                        disabled={isDisabled()}
                        >{isDisabled() ? `Resend email ${timer}s` : isResend ? 'Resend email' : 'Confirm'}
                        </Button>
                    </div>
                )}
            </div>
        </Dialog>
    )
}