import React from 'react';
import { emailBindConfirm } from 'api/user';
import qs from 'qs'
import { useLocation, useNavigate } from 'react-router';
// import Message from 'components/common/message';


export default function BindEmailConfirm() {
    
    const location = useLocation()

    const navigate = useNavigate()

    async function confirm() {
        if (location.search) {
            const query = qs.parse(location.search.slice(1))
            try {
                await emailBindConfirm({ code: query.code as string })
            } catch (e:any) {
                console.log(e)
            } finally {
                navigate('/bind-email-result', { replace: true })
            }
        } else {
            navigate('/bind-email-result', { replace: true })
        }
    }

    React.useEffect(() => {
        confirm()
    }, [])

  return (
    <></>
  );
}
