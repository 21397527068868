import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';

interface Props {
    onClose: () => void
    open: boolean,
    onClickGuide: () => void
}

export default function DiscussInvitationCalendarDialog(props:Props) {
    const { open, onClose, onClickGuide } = props

    return (
        <Dialog open={open} onClose={onClose} className="discuss-invitation-calendar-dialog">
            <div className="content">
                <div className="text1">{'Discuss Invitation'}</div>
                <div className="text2">Here you can make an investment calendar invitation with the project party, you need to share your Google calendar to the 1seeddao calendar.<br/>Please refer to
                <span className="sub" onClick={onClickGuide}> Operating Guide</span>.</div>
                <Button className="btn btn-1seed" variant='contained' onClick={() => {
                    onClose()
                }}>I GOT IT</Button>
            </div>
        </Dialog>
    )
}