import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import file from './card.js'

interface Props {
    onClose: () => void
    open: boolean
}

export default function ProfileNftCardDialog(props:Props) {
    const { open, onClose } = props

    function onload(e:any) {
        const iframe = e.target
        const body = iframe.contentWindow.document.querySelector('body')
        // body.style.display = 'flex'
        // body.style['align-items'] = 'center'
        body.addEventListener('click', function() {
            onClose()
        })
    }

    return (
        <Dialog open={open} onClose={onClose} className="profile-nft-card-dialog">
            <div className="content">
                <iframe
                id='iframe'
                // src='https://1seeddaoprod.s3.ap-east-1.amazonaws.com/assets/card.html'
                srcDoc={file}
                frameBorder="0"
                onClick={onClose}
                onLoad={onload}></iframe>
            </div>
        </Dialog>
    )
}