import React from 'react';
import './index.scss'
import { INVEST_STATUS, APPLY_STATUS } from 'constant';
import { ApplyProjectType } from 'api/user';
// import defiIcon from 'assets/img/apply/defi.png'
import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { applyView, applyConfig, ApplyConfigResponse, applyDelete } from 'api/apply';
import PreviewDialog from 'pages/apply/components/preview-dialog';
import { FormParams } from 'pages/apply/types';
import { useNavigate } from 'react-router';
import AuditReasonDialog from './audit-reason';
// import trackIcon from 'assets/img/apply/track-icon.png'
import trackIcon from 'assets/svg/chain-logo.svg'
import Message from 'components/common/message';
import applyFailedImg from 'assets/img/apply/apply-failed.png'
import applyRejectedImg from 'assets/img/apply/apply-rejected.png'
import applySuccessImg from 'assets/img/apply/apply-success.png'
import ConfirmDialog from 'components/common/confirm-dialog';
import { formatFinTime, formatProjectTitle } from 'utils/utils';
// import { roundDetailTitle } from 'pages/apply/maps';
// import { useOneSeedPoolArenaContract, web3 } from 'utils/web3';
// import { OneSeedPoolArenaConnector } from 'config/connector';
// import { getParam, GetParamResp } from 'api/finance';
// import { parseUnits } from 'ethers';
// import Loading from 'components/common/loading';
import Tips from 'components/tips';
import { formatMoney } from 'utils/utils';

const finStatusMap = {
    [INVEST_STATUS.FAILD]: {
        text: 'Failed',
        style: 'failed',
        img: applyFailedImg
    },
    [INVEST_STATUS.INVESTOR]: {
        text: 'Success',
        style: 'success',
        img: applySuccessImg
    },
    [INVEST_STATUS.FINANCING]: {
        text:'Financing',
        style: 'financing',
        img: ''
    }
}

const applyStatusMap = {
    [APPLY_STATUS.DRAFT]: {
        text: 'Draft',
        style: 'draft',
        img: ''
    },
    [APPLY_STATUS.PENDING]: {
        text: 'Pending',
        style: 'pending',
        img: ''
    },
    [APPLY_STATUS.FAILED]: {
        text: 'Failed',
        style: 'failed',
        img: applyRejectedImg
    }
}

type PropsType = {
    project: ApplyProjectType | undefined,
    onDelete?: () => void,
    onRefresh?: () => void
}

export default function ApplyBox(props: PropsType) {

  const { project, onDelete, onRefresh } = props

  const navigate = useNavigate()

  const [openPreview, setOpenPreview] = React.useState(false)
  const [openReason, setOpenReason] = React.useState(false)
  const [previewData, setPreviewData] = React.useState<FormParams>()
  const [previewConf, setPreviewConf] = React.useState<ApplyConfigResponse>()
  const [openDeleteConfirm, setOpenDeleteConfirm] = React.useState(false)

  function continueApply() {
      navigate(`/apply?projectId=${project?.project_id}&step=${project?.step}`)
  }

  async function preview() {
      const data = await applyView({
          project_id: project?.project_id || '',
          step: project?.step || 1
      })
      const conf = await applyConfig({
          project_id: project?.project_id || '',
      })
      setPreviewData(data)
      setPreviewConf(conf)
      setOpenPreview(true)
  }

  async function deleteApply() {
    if (!project) return
    try {
        await applyDelete({
            project_id: project.project_id
        })
        onDelete && onDelete()
        setOpenDeleteConfirm(false)
    } catch (error:any) {
        console.log(error)
        Message(error.message || 'Network error', { severity: 'error' })
    }
  }

  function getStyleObj() {
    if (!project) return {
        text: 'Failed',
        style: 'failed',
        img: ''
    }
    if (project.apply_status === APPLY_STATUS.SUCCESS) {
        return finStatusMap[project.fin_status]
    } else {
        return applyStatusMap[project.apply_status]
    }
  }

  async function createFundingPool() {
    navigate(`/invest-detail/${project?.project_id}`)
    // if (!project) return
    // try {
    //     Loading(true)
    //     const params:GetParamResp = await getParam({project_id: project.project_id})
    //     const accounts = await web3.eth.getAccounts()
    //     console.log(accounts)
    //     const data = await useOneSeedPoolArenaContract(OneSeedPoolArenaConnector.address).methods.createInvestmentInstance([
    //         params.param.name,
    //         params.param.symbol,
    //         params.param.base_token_uri,
    //         [
    //         params.param.investment_key.collateral_token,
    //         parseUnits(
    //             params.param.investment_key.min_financing_amount,
    //             0
    //         ),
    //         parseUnits(
    //             params.param.investment_key.max_financing_amount,
    //             0
    //         ),
    //         parseUnits(
    //             params.param.investment_key.user_min_invest_amount,
    //             0
    //         ),
    //         params.param.investment_key.financing_wallet,
    //         params.param.investment_key.duration,
    //         ],
    //     ], params.signature)
    //     // .call.request()
    //     .send({
    //         from: accounts[0]
    //     })
    //     console.log(data)
    //     Message('Create funding pool success')
    //     onRefresh && onRefresh()
    // } catch (e:any) {
    //     console.log(e)
    //     Message(e.message || 'Create funding pool failed', {severity:"error"})
    // } finally {
    //     Loading(false)
    // }
  }

  function goInvestDetail() {
    if (!project || project.apply_status !== APPLY_STATUS.SUCCESS) return
    navigate(`/invest-detail/${project.project_id}`)
  }

  function getViewTitle() {
    if (project && [APPLY_STATUS.DRAFT, APPLY_STATUS.PENDING].includes(project.apply_status)) {
        return 'Application Status'
    } else {
        return `${formatProjectTitle(project?.project_info.name, project?.project_info.rounds)}`
    }
  }

  function goUserProfile(id:string, e:any) {
    e.stopPropagation()
    navigate(`/profile?id=${id}`)
  }

  return project && project.project_info ? (
    <div className={`apply-box ${getStyleObj().style}`} onClick={() => goInvestDetail()}>
        <div className="card-bg">
            {project?.apply_status === APPLY_STATUS.FAILED && <div className="status-text" onClick={() => setOpenReason(true)}>
                Reasons For Rejection
                <ChevronRightIcon></ChevronRightIcon>
            </div>}
        </div>
        <div className="status">{getStyleObj().text}</div>
        {getStyleObj().img && <img src={getStyleObj().img} alt="" className="status-img" />}
        <div className="content">
            <div className="card-content">
                <div className="card-left">
                    <div className="title" title={getViewTitle()}>{getViewTitle()}</div>
                    {project && [APPLY_STATUS.PENDING, APPLY_STATUS.FAILED].includes(project.apply_status) && 
                    (
                    <div className="goal-wrap">
                        <div className="goal-content">
                            <div className="goal-title">Financing Goal：</div>
                            <div className="goal-text">{formatMoney(project?.project_info.fin_goal) || '-'} {project?.project_info.fin_token_symbol}</div>
                        </div>
                        <div className="goal-content">
                            <div className="goal-title">Financing Duration：</div>
                            <div className="goal-text">{project?.project_info.fin_duration} DAYS</div>
                        </div>
                    </div>
                    )}
                    {APPLY_STATUS.SUCCESS === project?.apply_status && (
                        <div className="goal-wrap fin-wrap">
                            <div className="goal-content">
                                <div className="goal-title">Financing goal：</div>
                                <div className="goal-text">{project?.project_info.fin_goal || '-'} {project?.project_info.fin_token_symbol}</div>
                            </div>
                            <div className="fin-total">
                                <div className="fin-title">Total Financing</div>
                                <div className="fin-progress">
                                    <div className="progress"
                                        style={{
                                            width: `${project.project_info.fin_amount / (project.project_info.fin_goal * 1.5) * 100}%`
                                        }}></div>
                                    <div className="total"></div>
                                    <Tips text={`${project?.project_info.fin_amount} ${project?.project_info.fin_token_symbol}`}></Tips>
                                </div>
                                <div className="fin-time">{formatFinTime(project.project_info.fin_end)}</div>
                            </div>
                        </div>
                    )}
                    {APPLY_STATUS.DRAFT === project?.apply_status && <div className='step-wrap'>
                        <div className={`step ${project?.step === 1 ? 'active' : ''}`}>
                            <div className="item">1</div>
                            <div className="text">Project Detail</div>
                        </div>
                        <div className={`step ${project?.step === 2 ? 'active' : ''}`}>
                            <div className="item">2</div>
                            <div className="text">Project Insights</div>
                        </div>
                        <div className={`step ${project?.step === 3 ? 'active' : ''}`}>
                            <div className="item">3</div>
                            <div className="text">Project Finance</div>
                        </div>
                        <div className="step">
                            <div className="item">4</div>
                        </div>
                    </div>}
                    {APPLY_STATUS.DRAFT === project?.apply_status && <div className="btn-wrap">
                        <Button className="btn btn-1seed" variant='contained' onClick={continueApply}>Continue</Button>
                        <Button className="btn btn-1seed delete" variant='outlined' onClick={() => setOpenDeleteConfirm(true)}>Delete</Button>
                    </div>}
                    {APPLY_STATUS.PENDING === project?.apply_status && !(INVEST_STATUS.PENDING === project?.fin_status && project?.audit === 1) && 
                        <div className="btn-wrap">
                            <Button className="btn btn-1seed" variant='contained' onClick={preview}>Application View</Button>
                        </div>
                    }
                    {INVEST_STATUS.PENDING === project?.fin_status && project?.audit === 1 &&
                        <div className="btn-wrap">
                            <Button className="btn btn-1seed" variant='contained' onClick={createFundingPool}>Create funding pool</Button>
                        </div>
                    }
                    {APPLY_STATUS.SUCCESS === project?.apply_status && INVEST_STATUS.PENDING !== project.fin_status && (
                        <div className="investors">
                            {project.top_investors?.map((item, idx) => (
                                <div className="investor" key={`investor_${idx}`}>
                                    <img src={item.profile_uri} alt="" className="avatar" onClick={(e) => goUserProfile(item.user_id, e)} />
                                </div>
                            ))
                            }
                            {!project.top_investors?.length && (
                                <>
                                    <div className="investor">
                                        <img src={require('assets/img/avatar-empty.png')} alt="" className="avatar" />
                                    </div>
                                    <div className="empty-text">No investors yet</div>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <img src={project?.project_info.image_url} alt="" className="img" />
            </div>
            <div className="card-bottom">
                <img src={project?.project_info.slogan_url} alt="" className="logo" />
                <div className="text">{project?.project_info.name}</div>
                <div className="tag">
                    {APPLY_STATUS.SUCCESS === project?.apply_status && [INVEST_STATUS.FAILD, INVEST_STATUS.FINANCING].includes(project.fin_status) ? (
                        <div className="counts">
                            <div className="count">{project.investor_count || '0'} <span className="sub">Investors</span></div>
                            <div className="count">{project.comments_cnt || '0'} <span className="sub">Comments</span></div>
                        </div>
                    ) : (
                        <div className="track">
                            {project?.project_info.tracks && project?.project_info.tracks[0]}
                            <img src={trackIcon} alt="" className="icon" />
                        </div>
                    )
                    }
                </div>
            </div>
        </div>
        {previewData && <PreviewDialog
            open={openPreview}
            onClose={() => setOpenPreview(false)}
            previewData={previewData}
            previewConf={previewConf}
            hideBtn
        ></PreviewDialog>}
        <AuditReasonDialog open={openReason} onClose={() => setOpenReason(false)} reason={project?.audit_comment || ''}></AuditReasonDialog>
        <ConfirmDialog
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
        title={'Confirm delete draft?'}
        subTitle='This operation cannot be undone'
        confirmBtnText='Confirm'
        cancelBtnText='Cancel'
        onConfirm={deleteApply}
        onCancel={() => setOpenDeleteConfirm(false)}
        ></ConfirmDialog>
    </div>
  ) : (<></>)
}
