import React from 'react';
import './index.css'
import { logout } from 'api/user';
import { removeToken, getLoginType, isLogin } from 'utils/auth';
import { LOGIN_TYPE } from 'constant';
import { getWS, readMsg, readMsgScope } from 'utils/ws';
import { Outlet } from 'react-router';
import RemindDialog from 'components/common/remind-dialog';
// import { createProfileContext } from 'hooks/useContext';
// import { refreshToken } from '../../api/user';
import { useNavigate } from 'react-router';
import { WS_MSG_TYPE, WS_SCOPE } from 'constant/ws';
import { WSMessage } from 'utils/ws';
// import Header from '../header';
import ProfileNftMintMsgDialog from './profile-nft-mint-msg-dialog';
import Dialog from '@mui/material/Dialog'
import MintProfileNFT from 'components/mint-profile-nft';
// import { getWeb3 } from 'utils/web3';

export default function Container() {

  const [openRemindDialog, setOpenRemindDialog] = React.useState(false)
  const [openProfileNftMintMsgDialog, setOpenProfileNftMintMsgDialog] = React.useState(false)
  const [openMintDialog, setOpenMintDialog] = React.useState(false)
  // const [projectId, setProjectId] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [content, setContent] = React.useState('')
  const [msgCb, setMsgCb] = React.useState<() => void>()

  const [openPrivateRemindInviteDialog, setOpenPrivateRemindInviteDialog] = React.useState(false)

  const navigate = useNavigate()

  async function doLogout() {
      try {
          await logout()
          removeToken()
          window.location.href = '/'
      } catch (e:any) {
          console.log(e)
      }
  }


  function init() {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const e = window.ethereum || ethereum
      if (!e) return
      e.on('accountsChanged', (accounts:string[]) => {
        dealChange()
        console.log("accounts",accounts)
      });
      e.on('error', (e:any) => {
        // dealChange()
        console.log("metamask error",e)
      });
      // e.on('chainChanged', (chainId:number) => {
      //   dealChange()
      //   console.log("chainId",chainId)
      // });
    } catch(e:any) {
      console.log(e)
    }
  }

  function dealChange() {
    if (!isLogin()) return
    // if (LOGIN_TYPE.METAMASK !== getLoginType()) return
    doLogout()
  }

  function onAuditMessage(msgData:WSMessage) {
    try {
      const auditMsg = JSON.parse(msgData.msg)
      console.log('auditMsg:', auditMsg)
      // 0 => unfinished，1 => accept，2 => reject
      if (+auditMsg.audit !== 1 || !auditMsg.project_id) return
      setTitle('Congratulations !')
      setContent(`Congratulations on passing the platform's project financing review, please go to the financing details page to complete the financing contract deployment, and start financing after completion.`)
      // setProjectId(auditMsg.project_id)
      setMsgCb(() => navigate(`/invest-detail/${auditMsg.project_id}`))
      setOpenRemindDialog(true)
      readMsg(msgData)
    } catch (e:any) {
      console.log(e)
    }
  }

  function onProjRemindMessage(msgData:WSMessage) {
    const remindStr = msgData.msg
    setContent(remindStr)
    setTitle('Reminder')
    setMsgCb(undefined)
    setOpenRemindDialog(true)
    readMsg(msgData)
  }

  

  React.useEffect(() => {
    if (!isLogin()) return
    init()
    const ws = getWS()
    ws.addEventListener('message', function(e:MessageEvent<any>) {
      console.log('wsmessage:', e.data)
      try {
        const msgData:WSMessage = JSON.parse(e.data)
        console.log('msgData:', msgData)
        if (+msgData.type === WS_MSG_TYPE.PROJ_APPLY_RECORD) {
          onAuditMessage(msgData)
        } else if (+msgData.type === WS_MSG_TYPE.PROJ_REMINDER) {
          onProjRemindMessage(msgData)
        } else if (+msgData.type === WS_MSG_TYPE.TRADE_AIRDROP_START) {
          setTitle('Aggrement News')
          const auditMsg = JSON.parse(msgData.msg)
          setMsgCb(() => navigate(`/trading-detail/${auditMsg.uuid}`))
          setContent(`The Airdrop of the project party has already started. Please complete the confirmation in my transaction within 10 hours. If it is not processed within the timeout, the system will confiscate your pledged share.`)
          setOpenRemindDialog(true)
          readMsg(msgData, WS_SCOPE.TRADE)
        } else if (+msgData.type === WS_MSG_TYPE.TRADE_CLAIM) {
          setTitle('Aggrement News')
          const auditMsg = JSON.parse(msgData.msg)
          setMsgCb(() => navigate(`/trading-detail/${auditMsg.uuid}`))
          setContent(`Since the seller failed to complete the Airdrop confirmation within the specified time, you can go to 【My Aggrements】 to complete the token Claim operation .`)
          setOpenRemindDialog(true)
          readMsg(msgData, WS_SCOPE.TRADE)
        } else if (+msgData.type === WS_MSG_TYPE.TRADE_EMERGENCY_WITHDRAW) {
          setTitle('Aggrement News')
          const auditMsg = JSON.parse(msgData.msg)
          setMsgCb(() => navigate(`/trading-detail/${auditMsg.uuid}`))
          setContent(`Due to the project party's reasons, the Airdrop is abnormal. After confirmation, all Agreements of the project will be automatically released, and buyers and sellers can claim by themselves.`)
          setOpenRemindDialog(true)
          readMsg(msgData, WS_SCOPE.TRADE)
        } else if (+msgData.type === WS_MSG_TYPE.PROFILE_NFT_MINT) {
          // const msg = JSON.parse(msgData.msg)
          setOpenProfileNftMintMsgDialog(true)
          readMsgScope('NFTMint', WS_SCOPE.DEFAULT)
        } else if (+msgData.type === WS_MSG_TYPE.PRIVATE_INVITION) {
          const msg = JSON.parse(msgData.msg)
          console.log(msg)
          setMsgCb(() => {
            navigate(`/invest-detail/${msg.uuid}`)
            setOpenPrivateRemindInviteDialog(false)
          })
          setOpenPrivateRemindInviteDialog(true)
          readMsg(msgData, WS_SCOPE.DEFAULT)
        }
      } catch(e:any) {
        console.log(e)
      }
    })
  }, [])

  return (
    <div className='container'>
      {/* <Header></Header> */}
      {/* {props.children} */}
      <Outlet></Outlet>
      <RemindDialog
        open={openRemindDialog}
        onClose={() => setOpenRemindDialog(false)}
        handleClickBtn={() => msgCb && msgCb()}
        title={title}
        content={content}
      ></RemindDialog>
      <ProfileNftMintMsgDialog
        open={openProfileNftMintMsgDialog}
        onClose={() => setOpenProfileNftMintMsgDialog(false)}
        handleClick={() => {setOpenProfileNftMintMsgDialog(false);setOpenMintDialog(true)}}
      ></ProfileNftMintMsgDialog>
      <Dialog open={openMintDialog} onClose={() => setOpenMintDialog(false)}>
        <MintProfileNFT
        onClose={() => setOpenMintDialog(false)}
        // onSuccess={handleSuccessMint}
        ></MintProfileNFT>  
      </Dialog>
      
      <RemindDialog
      open={openPrivateRemindInviteDialog}
      onClose={() => setOpenPrivateRemindInviteDialog(false)}
      btnText='Go to view'
      handleClickBtn={() => msgCb && msgCb()}
      title='Private Financing Invitation'
      content='Congratulations, you have received an invitation for private financing of a project, please check it at watchlist in time'
      img={require('assets/img/invest-detail/private-dialog-icon/invite.png')}
      ></RemindDialog>
    </div>
  );
}
