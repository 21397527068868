import React from 'react';
import Header from 'components/header';
import Footer from 'components/footer'
// import useFixedHeader from "hooks/useFixedHeader";
import './index.scss'
import { Button } from '@mui/material';
import ApplyText from 'components/apply-text';
import { ReactComponent as StepIcon1 } from "assets/img/apply-guide/step1.svg";
import { ReactComponent as StepIcon2 } from "assets/img/apply-guide/step2.svg";
import { ReactComponent as StepIcon3 } from "assets/img/apply-guide/step3.svg";
import { ReactComponent as StepIcon4 } from "assets/img/apply-guide/step4.svg";
import { ReactComponent as StepIcon5 } from "assets/img/apply-guide/step5.svg";
import QuestionCard from './components/question-card';
import Advantages from './components/advantages';
import Raises from './components/raises';
export default function ApplyGuide() {

    // const [isFixedHeader] = useFixedHeader()

    const [activeIndex, setActiveIndex] = React.useState(0)
    const [activeRaiseIndex, setActiveRaiseIndex] = React.useState(0)

    const videoList = [
        'https://1seeduserprofile.s3.ap-east-1.amazonaws.com/1seeddao/1.mp4',
        'https://1seeduserprofile.s3.ap-east-1.amazonaws.com/1seeddao/2.mp4',
        'https://1seeduserprofile.s3.ap-east-1.amazonaws.com/1seeddao/3.mp4',
        'https://1seeduserprofile.s3.ap-east-1.amazonaws.com/1seeddao/4.mp4'
    ]

    const raisesData = [
        {
            title: 'Upload project information and pitch.',
            text: `The fundraiser uploads the project's basic information and pitch.`,
            icon: <StepIcon1 className='icon'></StepIcon1>,
            activeIcon: <StepIcon1 className='icofn' fill='#fff'></StepIcon1>
        },
        {
            title: `Set fundraising goals and timeframe.`,
            text: `Set fundraising goal: 1Seed's fundraising model adopts the all-or-nothing approach. Startup projects can choose a funding goal and set the number of days to reach this target.`,
            icon: <StepIcon2 className='icon'></StepIcon2>,
            activeIcon: <StepIcon2 className='icon' fill='#fff'></StepIcon2>
        },
        {
            title: `Choose private or public fundraising mode.`,
            text: `Choose the fundraising method: Private fundraising (public fundraising after confirming lead investors) or direct public fundraising. When you start in private mode, your project page is already online but only visible to people with the direct link. When you switch to public mode, your company will be eligible to appear on the explore page.`,
            icon: <StepIcon3 className='icon'></StepIcon3>,
            activeIcon: <StepIcon3 className='icon' fill='#fff'></StepIcon3>
        },
        {
            title: `Initiate fundraising by creating a smart contract.`,
            text: `Forge the fundraising contract. Investor funds are managed through smart contracts, which guarantee full transparency and security. `,
            icon: <StepIcon4 className='icon'></StepIcon4>,
            activeIcon: <StepIcon4 className='icon' fill='#fff'></StepIcon4>
        },
        {
            title: `Receive funds if crowdfunding goals are met.`,
            text: `Funds are automatically transferred to fundraisers only after reaching the crowdfunding goals.`,
            icon: <StepIcon5 className='icon'></StepIcon5>,
            activeIcon: <StepIcon5 className='icon' fill='#fff'></StepIcon5>
        }
    ]

    return (
        <div className='apply-guide'>
        <Header></Header>
        <div className="apply-guide__content">
            <div className="content-top">
                <div className="text1">{`Succeed with 1Seed`}</div>
                <div className="text2">1SeedDAO uses smart contracts, NFT credentials, and DAO structures to help startups achieve fully automated and secure fundraising. Raise $10,000 to over $1 million from hundreds of customers, fans, and friends, all in a single entity with DAO, recorded as a single line on your investment table.</div>
                <ApplyText><Button className="btn-1seed btn" variant='contained'>Applying for financing</Button></ApplyText>
                <img src={require('assets/img/apply-guide/top-1.png')} alt="" className="icon1" />
                <img src={require('assets/img/apply-guide/top-2.png')} alt="" className="icon2" />
                <img src={require('assets/img/apply-guide/top-3.png')} alt="" className="icon3" />
            </div>
            <QuestionCard></QuestionCard>
            <Advantages></Advantages>
            <Raises></Raises>
        </div>
        <Footer />
      </div>
    );
}
