export function getToken():string {
    return localStorage.getItem('token') || ''
}

export function setToken(token:string|undefined) {
    localStorage.setItem('token', token || '')
}

export const isLogin = ():boolean => !!getToken()

export const removeToken = () => {
    localStorage.removeItem('token')
}

export function getLoginType():string {
    return localStorage.getItem('loginType') || ''
}

export function setLoginType(loginType:string|undefined) {
    localStorage.setItem('loginType', loginType || '')
}

export function setRefreshToken(t:string|undefined) {
    localStorage.setItem('refreshToken', t || '')
}

export function getRefreshToken() {
    return localStorage.getItem('refreshToken') || ''
}

export const removeRefreshToken = () => {
    localStorage.removeItem('refreshToken')
}

export function getAddr():string {
    return localStorage.getItem('addr') || ''
}

export function setAddr(addr:string|undefined) {
    localStorage.setItem('addr', addr || '')
}
