import React from 'react';
import Header from 'components/header';
import './index.scss'
// import { useNavigate } from 'react-router';
import TradingQA from './q-a';
import TradingList from './list';

export default function TradingListPage() {

    // const navigate = useNavigate()

    const [activeQA, setActiveQA] = React.useState(false)
    const [showQA, setShowQA] = React.useState(false)

    function handleClickDetail() {
        setShowQA(true)
        setActiveQA(true)
    }

    return (
        <div className="trading-list-page">
            <Header></Header>
            <div className="trading-list-page__content">
                <div className="card">
                    <div className="btn-wrap" onClick={handleClickDetail}>
                        <div className="btn">Trading Details</div>
                    </div>
                    <div className="title">Airdrop OTC trading</div>
                    <div className="option">
                        <span className={`item ${activeQA ? '' : 'active'}`} onClick={() => setActiveQA(false)}>Airdrop OTC trading list</span>
                        {showQA && <span className={`item ${activeQA ? 'active' : ''}`} onClick={() => setActiveQA(true)}>/Airdrop Trading Q&A</span>}
                    </div>
                </div>
                {!activeQA && <TradingList></TradingList>}
                {activeQA && <TradingQA></TradingQA>}
            </div>
        </div>
    );
}
