import baseRequest from "../../utils/http"
import { ApplyProjectType } from "../user"
import { MetamaskLoginPreResponseType } from "../user"
const api = new baseRequest()

const PREFIX = '/api/v1/apply'

export type LatestProjectResponse = ApplyProjectType

type ViewParam = {
  project_id: string,
  step: number
}

type ConfigParam = {
  project_id: string
}

export type Insight = {
  label: string,
  question: string[]
}

export type Token = {
  chain_id:string,
  name:string,
  address:string,
  symbol:string,
  decimal:number,
}

export type ApplyConfigResponse = {
  project_id: string,
  tracks: string[],
  // insights: Insight[],
  tokens: Token[],
  fin_rule: number,
  user_min_invest: number,
  // overview: string[]
}

type DeleteParam = {
  project_id: string
}

type ApplySocialBindParams = {
  social_name: string,
  code: string,
  project_id: string
}

export type SocialBindResponse = {
  social_name: string,
  social_user_name: string
}
type WalletBindPreParams = {
  public_address: string,
  project_id: string
}
type WalletBindParams = {
  public_address: string,
  signature: string,
  project_id: string
}

type PriviteAddUsersParams = {
  project_id: string,
  wallets: string[],
  emails: string[]
}

type PriviteDelUsersParams = {
  project_id: string,
  wallet?: string,
  email?: string
}

type PriviteGetUsersParams = {
  project_id: string
}

type PriviteSetPublicParams = {
  project_id: string
}

export type PrivateUser = {
  id: string,
  email: string,
  wallet: string,
  username: string,
  ens_name: string,
  profile_uri: string,
  role: number,
  is_followed: boolean,
  has_profile_nft: boolean,
  status: number
}

export type PriviteGetUsersResp = {
  users: PrivateUser[]
}

type setPublicParam = {
  project_id: string
}

export function getLatest():Promise<LatestProjectResponse|any> {
  return api.get(`${PREFIX}/latest`)
}

export function applySubmit(params:FormData):Promise<any> {
  return api.postForm(`${PREFIX}/submit`, params)
}

export function applyView(params:ViewParam):Promise<any> {
  return api.get(`${PREFIX}/view`, params)
}
export function applySave(params:FormData):Promise<any> {
  return api.postForm(`${PREFIX}/save`, params)
}
export function applyConfig(params:ConfigParam):Promise<ApplyConfigResponse|any> {
  return api.get(`${PREFIX}/config`, params)
}
export function applyNew():Promise<ApplyConfigResponse|any> {
  return api.post(`${PREFIX}/new`)
}
export function applyDelete(params: DeleteParam):Promise<any> {
  return api.post(`${PREFIX}/delete`, params)
}

export function applySocialBind(params: ApplySocialBindParams):Promise<SocialBindResponse|any> {
  return api.post(`${PREFIX}/social_bind`, params)
}

export function applyWalletBindPre(params:WalletBindPreParams):Promise<MetamaskLoginPreResponseType|any> {
  return api.post(`${PREFIX}/wallet_bind_pre`, params)
}

export function applyWalletBind(params:WalletBindParams):Promise<any> {
  return api.post(`${PREFIX}/wallet_bind`, params)
}

export function applyPriviteAddUsers(params:PriviteAddUsersParams):Promise<any> {
  return api.post(`${PREFIX}/private/add_users`, params)
}
export function applyPriviteDelUsers(params:PriviteDelUsersParams):Promise<any> {
  return api.post(`${PREFIX}/private/del_user`, params)
}
export function applyPriviteGetUsers(params:PriviteGetUsersParams):Promise<any|PriviteGetUsersResp> {
  return api.get(`${PREFIX}/private/get_users`, params)
}
export function applyPriviteSetPublic(params:PriviteSetPublicParams):Promise<any> {
  return api.post(`${PREFIX}/private/set_public`, params)
}

export function setPublic(params:setPublicParam):Promise<any> {
  return api.post(`${PREFIX}/private/set_public`, params)
}