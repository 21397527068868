import React from 'react';
import { stepTitleList } from '../../maps';
import './index.scss'

type Props = {
    step: number,
    isPending?: boolean
}

export default function ApplyStepper(props:Props) {
    const { step, isPending } = props

    return (
        <div className="apply-stepper">
            <div className="stepper-content">
                <div className="title">Apply Project Financing</div>
                <div className="stepper-wrap">
                    <div className={`step ${(!isPending && step === 1) && 'active'} ${(isPending || step > 1) && 'done'}`}>
                        <div className="num">1</div>
                        <div className="desc">{stepTitleList[0].title}</div>
                    </div>
                    <div className={`step ${(!isPending && step === 2) && 'active'} ${(isPending || step > 2) && 'done'}`}>
                        <div className="num">2</div>
                        <div className="desc">{stepTitleList[1].title}</div>
                    </div>
                    <div className={`step ${(!isPending && step === 3) && 'active'} ${(isPending || step > 3) && 'done'}`}>
                        <div className="num">3</div>
                        <div className="desc">{stepTitleList[2].title}</div>
                        <div className={`review ${isPending ? 'active' : ''}`}>
                            <div className="text">Platform review</div>
                            <div className="icon-wrap"><img src={require(`assets/img/apply/review${isPending ? '-active' : ''}.png`)} alt="" className="icon" /></div>
                        </div>
                    </div>
                    <div className="step">
                        <div className="num">4</div>
                        <div className="desc">Contract deploy</div>
                    </div>
                </div>
            </div>
        </div>
      );
    }
    