import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import { OTCDetail } from 'api/trade';
import Tips from 'components/tips';
import { Aggrement } from '../types';
import { dealMetamaskAddress, dealAmountDecimal } from 'utils/utils';
import { useOTCContract, useErc20Contract } from 'utils/web3';
import Message from 'components/common/message';
import Loading from 'components/common/loading';
import { AGGREMENT_STATUS, statusMap, AGGREMENT_TYPE } from '../map';
import { ethers } from 'ethers';
import useChainInterceptor from 'hooks/useChainInterceptor';

interface Props {
    onClose: () => void,
    open: boolean,
    detail: OTCDetail
    aggrement: Aggrement
    // symbol: string
    userAddr: string
    expiredTime: number
    type: string
    // tokenDecimal: string
    onRefresh: () => void
}

export default function AggrementDialog(props:Props) {
    const { open, onClose, detail, aggrement, userAddr, expiredTime, type, onRefresh } = props

    const [status, setStatus] = React.useState('')
    const [allowance, setAllowance] = React.useState('0')
    const [needApprove, setNeedApprove] = React.useState(true)

    const chainIntercepter = useChainInterceptor()

    function submit() {
        chainIntercepter(
        () => {
            if (type === AGGREMENT_TYPE.CLAIM) {
                claim()
            } else {
                if (needApprove) {
                    approve()
                } else {
                    fulfill()
                }
            }
        },
        () => {
            console.log('err')
        },
        detail?.blockchain.chain_id)
    }
    
    async function approve() {
        if (!detail) return
        Loading(true)
        try {
            useErc20Contract(detail.token_addr).methods.approve(detail.official_addr, ethers.MaxUint256)
            .send({
                from: userAddr
            }).on('receipt', function(receipt:any) {
                console.log(receipt)
                Message('Approve success')
                Loading(false)
                getAllowance()
            }).on('error', function(error:any, receipt:any) {
                console.log(error, receipt)
                Message(error.message || 'Approve failed', {severity: 'error'})
                Loading(false)
            })
        } catch(error:any) {
            console.log(error)
            Message(error.message || 'Approve failed', {severity: 'error'})
            Loading(false)
        }
    }

    async function claim() {
        useOTCContract(detail?.official_addr || '').methods
        .claimCollateral(aggrement.tradeId)
        .send({
            from: userAddr
        }).on('receipt', function(receipt:any) {
            console.log(receipt)
            onRefresh()
            Message('Claim success')
            Loading(false)
            onClose()
        }).on('error', function(error:any, receipt:any) {
            console.log(error, receipt)
            Message(error.message || 'Claim failed', {severity: 'error'})
            Loading(false)
        })
    }

    async function fulfill() {
        useOTCContract(detail?.official_addr || '').methods
        .fulfilOffer(aggrement.tradeId)
        .send({
            from: userAddr
        }).on('receipt', function(receipt:any) {
            console.log(receipt)
            onRefresh()
            Message('Fulfill success')
            Loading(false)
            onClose()
        }).on('error', function(error:any, receipt:any) {
            console.log(error, receipt)
            Message(error.message || 'Fulfill failed', {severity: 'error'})
            Loading(false)
        })
    }

    function generateStatus() {
        if (!aggrement) return
        let status = ''
        if (aggrement.active) {
            if (expiredTime * 1000 < Date.now()) {
                status = AGGREMENT_STATUS.FAILED
            } else {
                status = AGGREMENT_STATUS.PENDING
            }
        } else {
            status = AGGREMENT_STATUS.COMPLETED
        }
        setStatus(status)
    }

    async function getAllowance() {
        const _allowance = await useErc20Contract(detail.token_addr, detail.blockchain.chain_id).methods.allowance(userAddr, detail?.official_addr).call()
        console.log('allowance', _allowance)
        setAllowance(dealAmountDecimal(_allowance, +aggrement.decimal) + '')
        getNeedApprove(_allowance)
    }

    function getNeedApprove(_allowance:string) {
        setNeedApprove(!_allowance || _allowance === '0')
    }

    function getFee() {
        return +((+aggrement.costPerToken * +aggrement.tokens * 0.25 * 0.8).toFixed(6))
    }

    React.useEffect(() => {
        getAllowance()
    }, [])

    React.useEffect(() => {
        generateStatus()
    }, [aggrement])

    return (
        <Dialog open={open} onClose={onClose} className="aggrement-dialog">
            <div className="content">
                <img src={require(`assets/img/close.png`)} alt="" className="close" onClick={() => onClose()} />
                <div className="text1">Offer Trading</div>
                <div className="text2">5% of the total amount as trading Fee</div>
                <div className="info">
                    <img src={detail?.logo_url} alt="" className="logo" />
                    <span className="name">{detail?.project_name}</span>
                    <img src={require('assets/img/trading/identified.png')} alt="" className="icon" />
                </div>
                <div className="data-wrap">
                    <div className="data-title">#{aggrement.tradeId}</div>
                    <div className="data-item flex">
                        <div className="item-text1">Price:</div>
                        <div className="item-text2">{aggrement.costPerToken} $</div>
                    </div>
                    <div className="data-item flex">
                        <div className="item-text1">Amount:</div>
                        <div className="item-text2">{aggrement.tokens} {detail?.token_name}</div>
                    </div>
                    <div className="data-item flex">
                        <div className="item-text1">Total:</div>
                        <div className="item-text2">{+((+aggrement.costPerToken * +aggrement.tokens).toFixed(6))} {aggrement.symbol}</div>
                    </div>
                    <div className="data-item flex">
                        <div className="item-text1">From:</div>
                        <div className="item-text2"><span className="lighting">{dealMetamaskAddress(aggrement.seller)}</span></div>
                    </div>
                    <div className="data-item flex">
                        <div className="item-text1">To:</div>
                        <div className="item-text2"><span className="lighting">{dealMetamaskAddress(aggrement.buyer)}</span></div>
                    </div>
                    <div className="data-item flex">
                        <div className="item-text1">Status:</div>
                        <div className="item-text2">
                            <span className={`status ${statusMap[status]?.style}`}>{statusMap[status]?.text}</span>
                        </div>
                    </div>
                    {type === AGGREMENT_TYPE.CLAIM && <div className="data-item flex">
                        <div className="item-text1">Seller Penalty:</div>
                        <div className="item-text2 flex">{getFee()} {aggrement.symbol}
                            <div className='notify-wrap'>
                                <img src={require('assets/img/trading/notify.png')} alt="" className="notify" />
                                <Tips text={`${aggrement.costPerToken} $ * ${aggrement.tokens} * 25% * 80%`}></Tips>
                            </div>
                        </div>
                    </div>}
                    {type === AGGREMENT_TYPE.FULFILL && <div className="data-item flex">
                        <div className="item-text1">Deposit:</div>
                        <div className="item-text2 flex">{+((+aggrement.costPerToken * +aggrement.tokens * 0.25).toFixed(6))} {aggrement.symbol}
                            <div className='notify-wrap'>
                                <img src={require('assets/img/trading/notify.png')} alt="" className="notify" />
                                <Tips text={`${aggrement.costPerToken} $ * ${aggrement.tokens} * 25%`}></Tips>
                            </div>
                        </div>
                    </div>}
                </div>
                <Button
                className="btn btn-1seed"
                variant='contained'
                onClick={submit}
                >
                    {type === AGGREMENT_TYPE.CLAIM && <div>
                        <span>Claim</span>
                        {<div className="sub">{getFee() + +(+aggrement.costPerToken * +aggrement.tokens).toFixed(6)}  USDT</div>}
                    </div>}
                    {type === AGGREMENT_TYPE.FULFILL && (needApprove ? 'Approve' : <div>Fulfill</div>)}
                </Button>
            </div>
        </Dialog>
    )
}