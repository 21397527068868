// import WETH from '../../assets/img/setting/WETH.png'
import USDT from '../../assets/img/setting/USDT.png'
import USDC from '../../assets/img/setting/USDC.png'
import { SEED_ADDR, USDC_ADDR, USDT_ADDR, PROFILE_NFT_ADDR, CHAIN_ID } from 'config/config'

export const USDTConnectors = {
    symbol: 'USDT',
    address: USDT_ADDR,
    decimal: 6,
    icon: USDT
}

// export const WETHConnectors = {
//     symbol: 'WETH',
//     address: '0x287e9a38CfD2C5b6E98830d47d2f8ADB38E921F7',
//     decimal: 18,
//     icon: WETH
// }

export const USDCConnectors = {
    symbol: 'USDC',
    address: USDC_ADDR,
    decimal: 6,
    icon: USDC
}

export const ConstractConnectors = [
    USDTConnectors,
    // WETHConnectors,
    USDCConnectors
]

export const TokenConnectorsMap = {
    'USDT': USDTConnectors,
    'USDC': USDCConnectors,
    // 'WETH': WETHConnectors
}

export const OneSeedPoolArenaConnector = {
    address: SEED_ADDR
}

export const supportedChainId = CHAIN_ID

//op_goerli
export const profileNFTAddr = PROFILE_NFT_ADDR