import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import { OTCDetail } from 'api/trade';
import FormInput from 'components/common/form-input';
import { Token } from '../types';
import { TokenIconMap } from 'pages/apply/maps';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useOTCContract, useErc20Contract } from 'utils/web3';
// import { Offer } from '../types';
import { parseUnits } from 'ethers';
import Message from 'components/common/message';
import Loading from 'components/common/loading';
// import { OneSeedPoolArenaConnector } from 'config/connector';
import { dealAmountDecimal } from 'utils/utils';
import { ethers } from 'ethers';
import Multicall from '@dopex-io/web3-multicall';
import { getUrlProvider } from 'utils/providers';
import { Decimal } from 'decimal.js';

interface Props {
    onClose: () => void,
    open: boolean,
    detail: OTCDetail | undefined,
    // offers: Offer[],
    decimal: string,
    onRefresh: () => void,
    userAddr: string,
    min: number,
    max: number
}

export default function MakeOfferDialog(props:Props) {
    const { open, onClose, detail, decimal, onRefresh, userAddr, min, max } = props

    const [currentToken, setCurrentToken] = React.useState<Token>()
    const [openTokenSelector, setOpenTokenSelector] = React.useState(false)
    const [price, setPrice] = React.useState('')
    const [bal, setBal] = React.useState(0)
    const [amount, setAmount] = React.useState('')
    // const [max, setMax] = React.useState(0)
    // const [min, setMin] = React.useState(0)
    const [allowance, setAllowance] = React.useState('0')
    const [needApprove, setNeedApprove] = React.useState(true)
    const [maxInput, setMaxInput] = React.useState(0)
    const [minInput, setMinInput] = React.useState(0)
    const [tokenList, setTokenList] = React.useState<Token[]>([])
    const [isErr, setIsErr] = React.useState(false)

    const tokenSelectorRef = useDetectClickOutside({ onTriggered: () => setOpenTokenSelector(false) });

    // function init() {
    //     const list = offers.map(item => +item.costPerToken)
    //     setMax(Math.max(...list))
    //     setMin(Math.min(...list))
    // }

    async function initMinInput(token:Token) {
        if (!detail) return
        const _min = await useOTCContract(detail.official_addr, detail.blockchain.chain_id).methods.MIN_COST().call()
        console.log('MinInput', _min)
        setMinInput(dealAmountDecimal(_min, +token.decimal))
    }



    async function initMaxInput(token:Token) {
        if (!detail) return
        const _max = await useOTCContract(detail.official_addr, detail.blockchain.chain_id).methods.MAX_COST().call()
        console.log('MaxInput', _max)
        setMaxInput(dealAmountDecimal(_max, +token.decimal))
    }

    async function approve() {
        if (!detail) return
        console.log('userAddr', userAddr)
        Loading(true)
        try {
            useErc20Contract(currentToken?.addr || '').methods.approve(detail.official_addr, ethers.MaxUint256)
            .send({
                from: userAddr
            }).on('receipt', function(receipt:any) {
                console.log(receipt)
                Message('Approve success')
                Loading(false)
                getAllowance(currentToken)
            }).on('error', function(error:any, receipt:any) {
                console.log(error, receipt)
                Message(error.message || 'Approve failed', {severity: 'error'})
                Loading(false)
            })
        } catch(error:any) {
            console.log(error)
            Message(error.message || 'Approve failed', {severity: 'error'})
            Loading(false)
        }
    }


    async function makeOffer() {
        if (!detail || !currentToken) return
        console.log(currentToken?.decimal, price)
        console.log(parseUnits(price, +currentToken.decimal))
        console.log(parseUnits(amount, +decimal))
        console.log('bal', bal)
        const offerAmount = new Decimal(price).mul(new Decimal(amount)).mul(new Decimal(0.25))
        if (offerAmount.gt(new Decimal(bal))) {
            Message(bal + currentToken?.symbol + ' insufficient balance', {severity: 'error'})
            return 
        }
        Loading(true)
        try {
            useOTCContract(detail.official_addr).methods.createOffer(
                parseUnits(price, +currentToken.decimal),
                parseUnits(amount, +decimal),
                currentToken?.addr
            ).send({
                from: userAddr
            }).on('receipt', function(receipt:any) {
                console.log(receipt)
                Message('Make Offer success')
                onRefresh()
                Loading(false)
                onClose()
            }).on('error', function(error:any, receipt:any) {
                console.log(error, receipt)
                Message(error.message || 'Make Offer failed', {severity: 'error'})
                Loading(false)
            })
        } catch (e:any) {
            console.log(e)
            Message(e.message || 'Make Offer failed', {severity: 'error'})
            Loading(false)
        }
    }

    async function handleSubmit() {
        if (needApprove) {
            approve()
        } else {
            await makeOffer()
        }
    }

    const handleChangeToken = (e:any) => {
        e.stopPropagation();
        setOpenTokenSelector(!openTokenSelector)
    }

    function handleSelectToken(item:Token) {
        setCurrentToken(item)
        setOpenTokenSelector(false)
        getAllowance(item)
        initMinInput(item)
        initMaxInput(item)
        setUserBal(item)
    }

    async function getAllowance(_currentToken?: Token) {
        if (!_currentToken) return
        const _allowance = await useErc20Contract(_currentToken.addr || '', detail?.blockchain.chain_id).methods.allowance(userAddr, detail?.official_addr).call()
        console.log('allowance', _allowance)
        setAllowance(dealAmountDecimal(_allowance, +(_currentToken.decimal || 0)) + '')
        getNeedApprove(_allowance, price)
    }

    async function setUserBal(_currentToken?: Token){
        if (!_currentToken) return 0
        const bal = await useErc20Contract(_currentToken.addr || '', detail?.blockchain.chain_id).methods.balanceOf(userAddr).call()
        console.log('balance', bal)
        setBal(dealAmountDecimal(bal, +(_currentToken.decimal || 0)))
    }

    function handleChangePrice(v:string) {
        setIsErr(!!v && (+v < minInput || +v > maxInput))
        setPrice(v)
        getNeedApprove(allowance, v)
    }

    function getNeedApprove(_allowance:string, _price:string) {
        setNeedApprove(!_allowance || _allowance === '0' || +_allowance < +_price)
    }

    async function getSupports() {
        if (!detail) return
        const addrList = await useOTCContract(detail.official_addr, detail.blockchain.chain_id).methods.getSupports().call()
        console.log('supports' ,addrList)
        // const chainId = await web3.eth.getChainId()
        const multicall = new Multicall({
            // chainId: detail.blockchain.chain_id,
            multicallAddress: detail.blockchain.multicall,
            // multicallAddress: '0x9d94f4137a69390f0669fd6cb9be50ebeaf8d2d4',
            provider: getUrlProvider(detail.blockchain.chain_id),
        })
        const aggregates = addrList.map((addr:string) => {
            return useErc20Contract(addr, detail.blockchain.chain_id).methods.symbol()
        })
        const decimalsAggregates = addrList.map((addr:string) => {
            return useErc20Contract(addr, detail.blockchain.chain_id).methods.decimals()
        })
        // const symbolList = await multicall.aggregate(aggregates)
        // console.log('symbolList:', symbolList)
        // const decimalsList = await multicall.aggregate(decimalsAggregates)
        // console.log('decimalList:', decimalsList)
        const rs = await Promise.all([multicall.aggregate(aggregates), multicall.aggregate(decimalsAggregates)])
        const symbolList = rs[0]
        console.log('symbolList:', symbolList)
        const decimalsList = rs[1]
        console.log('decimalList:', decimalsList)
        // const symbolPromises = addrList.map((addr:string) => {
        //     return useErc20Contract(addr, detail.blockchain.chain_id).methods.symbol().call()
        // })
        // const decimalsPromises = addrList.map((addr:string) => {
        //     return useErc20Contract(addr, detail.blockchain.chain_id).methods.decimals().call()
        // })
        // const rs = await Promise.all([...symbolPromises, ...decimalsPromises])
        // const symbolList = [rs[0], rs[1]]
        // const decimalsList = [rs[2], rs[3]]
        // console.log('symbolList:', symbolList)
        // console.log('decimalList:', decimalsList)

        const list = symbolList.map((item:string, index:number) => {
            return {
                addr: addrList[index],
                symbol: item,
                decimal: decimalsList[index]
            }
        })

        setTokenList(list)
        handleSelectToken(list[0])
    }

    // React.useEffect(() => {
    //     init()
    // }, [offers])

    React.useEffect(() => {
        if (!open) return
        getSupports()
    }, [open])

    return (
        <Dialog open={open} onClose={onClose} className="make-offer-dialog">
            <div className="content">
                <img src={require(`assets/img/close.png`)} alt="" className="close" onClick={() => onClose()} />
                <div className="text1">Make an offer</div>
                <div className="text2">25% of the total amount as security deposit</div>
                <div className="info">
                    <img src={detail?.logo_url} alt="" className="logo" />
                    <span className="name">{detail?.project_name}</span>
                    <img src={require('assets/img/trading/identified.png')} alt="" className="icon" />
                </div>
                <div className="price-wrap">
                    <div className="item">
                        <div className="item-text1">Floor price:</div>
                        <div className="item-text2">{min} $</div>
                    </div>
                    <div className="item">
                        <div className="item-text1">Highest price:</div>
                        <div className="item-text2">{max} $</div>
                    </div>
                </div>

                <FormInput
                rule={/^([0-9]+\.?([0-9]{1,2})?)?$/}
                placeholder={currentToken ? `$${minInput}-$${maxInput}` : 'Price'}
                value={price}
                onChange={handleChangePrice}
                error={isErr}
                errorText='Invalid Input'
                >
                    <div className="token-wrap">
                        <div className="show" onClick={handleChangeToken}>
                            <span className="text">{currentToken?.symbol}</span>
                            {currentToken && <img src={TokenIconMap[currentToken.symbol]} alt="" className="icon" />}
                            <img src={require('assets/img/trading/arrow-down.png')} alt="" className="icon" />
                        </div>
                        {openTokenSelector && <div className="token-selector" ref={tokenSelectorRef}>
                            {tokenList.map((item, index) => {
                                return (
                                    <div className="item" key={index} onClick={() => handleSelectToken(item)}>
                                        <img src={TokenIconMap[item.symbol]} alt="" className="icon" />
                                        <span className="text">{item.symbol}</span>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>
                </FormInput>

                <FormInput
                rule={/^([0-9]+\.?([0-9]{1,2})?)?$/}
                placeholder={`Amount`}
                value={amount}
                onChange={(v) => setAmount(v)}
                >
                    <div className="token-wrap amount-wrap">
                        <div className="show">
                            <span className="text">{detail?.token_name}</span>
                            <img src={detail?.token_logo_url} alt="" className="icon" />
                        </div>
                    </div>
                </FormInput>

                <Button
                className="btn btn-1seed"
                variant='contained'
                disabled={!(price && amount && currentToken) || isErr}
                onClick={handleSubmit}
                >
                    <div>
                        <span>{needApprove ? 'APPROVE' : 'MAKE OFFER'}</span>
                        <div className="sub">25%{price && amount && currentToken ? `(${new Decimal(price).mul(new Decimal(amount)).mul(new Decimal(0.25)).toString()} ${currentToken.symbol})` : ''} of the total amount as security deposit</div>
                    </div>
                </Button>
            </div>
        </Dialog>
    )
}