import React, { useEffect } from 'react';
// import avatar from '../../../../assets/img/profile-info/avatar.jpg'
import identified from 'assets/img/profile-info/identified.png'
import copy from 'assets/img/profile-info/copy.png'
import poapImg from 'assets/svg/poap.svg'
// import badge from 'assets/img/profile-info/badge.jpg'
// import facebook from 'assets/img/profile-info/facebook.png'
// import twitter from 'assets/img/profile-info/twitter.png'
// import discord from 'assets/img/profile-info/discord.png'
// import linkedin from 'assets/img/profile-info/linkedin.png'
// import instagram from 'assets/img/profile-info/instagram.png'
// import github from 'assets/img/profile-info/github.png'
// import mirror from 'assets/img/profile-info/mirror.png'
// import telegram from 'assets/img/profile-info/telegram.png'
import more from 'assets/img/profile-info/more.png'
import settingIcon from 'assets/img/setting.png'
import poapDefault from 'assets/img/profile-info/poap-default.png'
import './index.scss'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles'
import CopyToClipboard from 'react-copy-to-clipboard';
import Message from 'components/common/message';
import { getProfile, ProfileType, getPoap, PoapType,  getFollowing, getFollowers, FollowResponse, FollowInfo, getScoreList, Score, PoapAssetsType, getApplylist, follow, unfollow } from 'api/user';
import { dealMetamaskAddress } from 'utils/utils';
import aseedIcon from 'assets/img/profile-info/aseed.png'
import { dealTime } from 'utils/utils';
import { Link, useNavigate } from 'react-router-dom';
import { PROFILE_TABS, USER_ROLE } from 'constant';
import FollowListDialog from '../follow-list-dialog';
import ScoreListDialog from '../score-list-dialog';
import PoapDialog from './poapDialog';
// import axios from 'axios';
import { generateSocialLink } from 'utils/utils';
import { Button } from '@mui/material';
import Tips from 'components/tips';
import useLoginInterceptor from 'hooks/useLoginInterceptor';
import { useProfileContract, getWeb3 } from 'utils/web3';
import MintGuide from './mint-guide';
import { profileNFTAddr } from 'config/connector';
import ProfileNftCardDialog from 'components/profile-nft-card-dialog';
import MintProfileNFT from 'components/mint-profile-nft';
import { Dialog } from '@mui/material';
import { getOtherPlatProof, GetOtherPlatProofResponse } from 'api/user';
import { useOneSeedPoolArenaContract } from 'utils/web3';
import { OneSeedPoolArenaConnector } from 'config/connector';
// import { web3 } from 'utils/web3';
import Multicall from '@dopex-io/web3-multicall';
// import { useProfileContract } from 'utils/web3';
// import { getLatest } from '../../../../api/apply';
import qs from 'qs'
import { CALL_RPC } from 'config/config';

type InfoProps = {
  // handleClickTab: (tabType:string) => void,
  target: string
  tabType: string
  selfProfile: ProfileType|undefined
}

const socialList = ['facebook', 'twitter', 'discord', 'linkedin', 'instagram', 'github', 'mirror', 'telegram']

export default function ProfileInfo(props:InfoProps) {

  const {
    target,
    // handleClickTab
    tabType,
    selfProfile
  } = props

  // const [value, setValue] = React.useState(PROFILE_TABS.INVESTED);
  const [profile, setProfile] = React.useState<ProfileType|undefined>(undefined)
  // const [poapData, setPoapData] = React.useState<PoapType|undefined>(undefined)
  const [openFollow, setOpenFollow] = React.useState(false);
  const [isFollowers, setIsFollowers] = React.useState(false);
  const [followList, setFollowList] = React.useState<Array<FollowInfo>>([])
  const [openScoreList, setOpenScoreList] = React.useState(false)
  const [scoreList, setScoreList] = React.useState<Array<Score>>([])
  const [openPoap, setOpenPoap] =  React.useState(false)
  // const [poapDialogData, setPoapDialogData] =  React.useState<any>({})
  const [showApply, setShowApply] = React.useState(false)
  const [tokenList, setTokenList] = React.useState<string[]>([])
  const [currentTokenIndex, setCurrentTokenIndex] = React.useState(0)
  // showProfileNFT
  const [hasNFT, setHasNFT] = React.useState(false)
  const [showMintGuide, setShowMintGuide] = React.useState(false)
  const [showProfileNftCardDialog, setShowProfileNftCardDialog] = React.useState(false)
  const [openMintDialog, setOpenMintDialog] = React.useState(false)
  const [canMintNFT, setCanMintNFT] = React.useState(false)

  const loginInterceptor = useLoginInterceptor()
  const navigate = useNavigate()

  async function initProfile() {
    const data = await getProfile({target_user_id: target})
    setProfile(data)
  }

  async function initApplyTab() {
    // const data = await getLatest()
    const data = await getApplylist({
      target_user_id: target,
      status: -1,
      page_no: 1,
      page_size: 5
    })
    if (data?.projects && data.projects[0] && data.projects[0].project_id) {
      setShowApply(true)
    }
  }

  // async function initPoap() {
  //   const data = await getPoap({
  //     "target_user_id": target ||   '',
  //     "page_no": 1,
  //     "page_size": 10
  //   })
  //   setPoapData(data)
  // }

  const handleChange = (newValue: string) => {
    // setValue(newValue);
    // handleClickTab(newValue)
    navigate(`/profile?id=${target}&tab=${newValue}`, {
      replace: true
    })
  };

  const StyledTabs = styled(Tabs)`
    & .MuiTabs-indicator {
      background: linear-gradient(85.25deg, #001AFF -12.81%, #22C0FF 74.7%);
      height: 3px;
    }
  `

  const StyledTab = styled(Tab)`
    &.MuiTab-textColorPrimary {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #1A1A1A;
      text-transform: none;
      margin-right: 60px;
      padding: 0;
    }
    &.Mui-selected {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      background: linear-gradient(85.25deg, #001AFF -12.81%, #22C0FF 74.7%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  `;
  
  useEffect(() => {
    initProfile()
    // initPoap()
    initApplyTab()
  }, [target])

  async function handleClickFollow(flag:boolean) {
    await getFollowingData(flag)
    setIsFollowers(flag)
    setOpenFollow(true)
  }

    
  async function getFollowingData(flag: boolean) {
      let data:FollowResponse
      if (flag) {
          data = await getFollowers({
              page_no: 1,
              page_size: 20,
              target_user_id: target
          })
      } else {
          data = await getFollowing({
              page_no: 1,
              page_size: 20,
              target_user_id: target
          })
      }
      setFollowList(data.users)
      return data.users
    }

    const handleRefleshFollowList = async () => {
      return getFollowingData(isFollowers)
    }

    async function handleClickAseed() {
      const data = await getScoreList({
        page_no: 1,
        page_size: 10
      })
      setScoreList(data.score)
      setOpenScoreList(true)
    }

    // async function handleClickPoap(d: PoapAssetsType) {
    //   let {data} = await axios.get(d.uri)
    //   if (typeof data === 'string') {
    //     try {
    //       data = JSON.parse(data)
    //     } catch (e) {
    //       console.log(e)
    //       Message('data parse error', { severity: 'error' })
    //       return 
    //     }
    //   }
    //   setPoapDialogData(data)
    //   setOpenPoap(true)
    // }

    function handleClickSocial(social_type:string) {
      console.log(social_type, profile?.social[social_type])
      const link = generateSocialLink(social_type, profile?.social[social_type] || '')
      if (!link) return
      window.open(link)
    }

    async function handleFollow() {
        await follow({
            target_user_id: profile?.id || ''
        })
        initProfile()
    }

    async function handleUnfollow() {
        await unfollow({
            target_user_id: profile?.id || ''
        })
        initProfile()
    }

    async function getArenaNFTData() {
      if (!profile?.user_info.wallet) return
      const contract = useOneSeedPoolArenaContract(OneSeedPoolArenaConnector.address)
      const tokenCount = await contract.methods.balanceOf(profile.user_info.wallet).call()
      console.log('tokenCount', tokenCount)
      if (tokenCount > 0) {
        
        const chainId = await getWeb3().eth.getChainId()
        
        // const multicall = new Multicall({
        //   // chainId,
        //   multicallAddress: profile.user_info.wallet,
        //   provider: web3.givenProvider,
        //   // defaultBlock: 1000 /* Optional */
        // });
        // const batch = new web3.eth.BatchRequest()
        const multicall = new Multicall({
          chainId,
          multicallAddress: '0xe03A31c59BcDB152C43D42cb94F58571937FC2AC',
          provider: getWeb3().givenProvider,
        })
        const aggregates = []
        for (let i = 0; i < tokenCount; i++) {
          aggregates.push(contract.methods.tokenOfOwnerByIndex(profile.user_info.wallet, i))
        }
        const tokenIds = await multicall.aggregate(aggregates)
        console.log('tokenIds', tokenIds)

        const tokenAggregates = []
        for (let i = 0; i < tokenIds.length; i++) {
          tokenAggregates.push(contract.methods.tokenURI(tokenIds[i]))
        }

        const tokens = await multicall.aggregate(tokenAggregates)
        console.log('tokens', tokens)
        setTokenList(tokens.map((d:string) => dealTokenData(d)))
        console.log('tokenList', tokens.map((d:string) => dealTokenData(d)))
      }
    }

    function dealTokenData(tokenData:string) {
      const json = atob(tokenData.substring(29));
      const result = JSON.parse(json);
      return atob(result?.image.replace('data:image/svg+xml;base64,', '')) || ''
    }

    // getProfileNFT
    async function getProfileNFTData() {
      if (!profile) return
      if (target) {
        const data = await useProfileContract(profileNFTAddr, undefined, CALL_RPC).methods.balanceOf(profile.user_info.wallet).call()
        if (+data > 0) {
          setHasNFT(true)
        }
        return
      }
      const _data:GetOtherPlatProofResponse = await getOtherPlatProof({
          public_address: profile.user_info.wallet
      })
      if (!_data.proof || !_data.proof.length) {
          return
      }
      const addrs = await getWeb3().eth.getAccounts()
      const addr = addrs[0] || ''
      const data = await useProfileContract(profileNFTAddr, undefined, CALL_RPC).methods.balanceOf(addr).call()
      console.log('balanceOf:', data)
      setHasNFT(true)
      if (+data > 0 || target) {
        setCanMintNFT(false)
        return
      }
      setCanMintNFT(true)
      if (!selfProfile) return
      if (!localStorage.getItem(`profileFirstMintGuide_${selfProfile.id}`)) {
        setShowMintGuide(true)
        localStorage.setItem(`profileFirstMintGuide_${selfProfile.id}`, '1')
      }
    }

    function handleSuccessMint() {
      setOpenMintDialog(false)
      setShowProfileNftCardDialog(true)
      getProfileNFTData()
    }

    function handleClickProfileNFT() {
      if (!canMintNFT) {
        setShowProfileNftCardDialog(true)
        return
      }
      setOpenMintDialog(true)
    }

    function handleClickToken(index:number) {
      setCurrentTokenIndex(index)
      setOpenPoap(true)
    }


    function getshowProfileNFT() {
      if (location.search) {
        const query = qs.parse(location.search.slice(1))
        return query.showProfileNFT as string || ''
      }
      return ''
    }

    React.useEffect(() => {
      if (getshowProfileNFT()) {
        setShowProfileNftCardDialog(true)
        navigate('/profile', {
          replace: true
        })
      }
    }, [])
    

    React.useEffect(() => {
      getProfileNFTData()
      getArenaNFTData()
    }, [profile])
  

  return (
    <div className='profile-info'>
        <div className="content" style={{
          backgroundImage: profile?.user_info.cover_uri ? `url(${profile?.user_info.cover_uri})` : `url(${require('assets/img/profile-info/bg.png')})`
        }}>
          <div className="content-cover">
            <div className="left">
              <div className="avatar-wrap">
                <img src={profile?.user_info.profile_uri} className='avatar' alt="" />
              </div>
              <div className="info-wrap">
                <div className="info-wrap-base name-wrap">
                  <span className='name'>{profile?.user_info.ens_name || profile?.user_info.username || ''}</span>
                  {[USER_ROLE.FAMER, USER_ROLE.INSTITUTION].includes(profile?.user_info.role || 0) && <img src={identified} className="name-icon" alt="" />}
                  {target && (
                    !profile?.is_follow ? (
                      <Button
                      onClick={() => loginInterceptor(handleFollow)}
                      className='btn-1seed follow-btn'
                      variant='contained'><img src={require('assets/img/add.png')} alt="" className="icon" /> Follow</Button>
                    ) : (
                      <div className="unfollow-btn" onClick={() => loginInterceptor(handleUnfollow)}>
                        <div className="text following">Following</div>
                        <div className="text unfollow">Unfollow</div>
                      </div>
                    )
                  )
                  }
                </div>
                {profile?.user_info.wallet && <div className="info-wrap-base chain-wrap">
                  <span className="chain-name">{dealMetamaskAddress(profile?.user_info.wallet || '')}</span>
                  <CopyToClipboard text={profile?.user_info.wallet || ''} onCopy={() => Message('copy success!')}>
                    <img src={copy} className="chain-icon" alt="" />
                  </CopyToClipboard>
                </div>}
                <p className="self-desc">
                  {profile?.user_info.bio}
                </p>
                <div className="info-wrap-base state-wrap">
                  {/* <div className='state-content aseed' onClick={handleClickAseed}>
                    <img src={aseedIcon} alt="" className="icon" />
                    <span className="desc">Aseeds</span>
                    <span className="count">{profile?.score || 0}</span>
                  </div> */}
                  <div className='state-content' onClick={() => handleClickFollow(true)}>
                    <span className="count">{profile?.followers || 0}</span>
                    <span className="desc">Followers</span>
                  </div>
                  <div className='state-content' onClick={() => handleClickFollow(false)}>
                    <span className="count">{profile?.following || 0}</span>
                    <span className="desc">Following</span>
                  </div>
                </div>
                <div className="info-wrap-base badge-wrap">
                  {
                    hasNFT &&
                    <div className="badge-item active">
                      <img src={poapImg} alt="" className="badge" onClick={handleClickProfileNFT} />
                      {canMintNFT && <Tips text='Mint Now'></Tips>}
                      {showMintGuide && <MintGuide onClose={() => setShowMintGuide(false)}></MintGuide>}
                    </div>
                  }
                  {
                    tokenList.map((item, index) => {
                      return (
                        <div className="badge-item active" key={`badge_${index}`}>
                          <img src={require('assets/img/profile-info/financing-icon.png')} alt="" className="badge" onClick={() => handleClickToken(index)} />
                        </div>
                      )
                    })
                  }
                  {!hasNFT && !tokenList.length && <>
                    <div className="badge-item">
                      <img src={poapImg} alt="" className="badge" />
                      <Tips text='not yet owned'></Tips>
                    </div>
                    <div className="badge-item">
                      <img src={poapImg} alt="" className="badge" />
                      <Tips text='not yet owned'></Tips>
                    </div>
                    <div className="badge-item">
                      <img src={poapImg} alt="" className="badge" />
                      <Tips text='not yet owned'></Tips>
                    </div>
                  </>}
                </div>
              </div>
            </div>
            <div className={`share-wrap ${socialList.every(item => !profile?.social[item]) ? 'no-bg' : ''}`}>
              {socialList.map((item, index) => {
                return profile?.social[item] && (
                  <img key={`social_${index}`} onClick={() => handleClickSocial(item)} src={require(`assets/img/profile-info/${item}.png`)} alt="" className="share-icon" />
                )
              })}
              {/* {profile?.social.facebook && <img src={facebook} alt="" className="share-icon" />}
              {profile?.social.twitter && <img src={twitter} alt="" className="share-icon" />}
              {profile?.social.linkedin && <img src={linkedin} alt="" className="share-icon" />}
              {profile?.social.instagram && <img src={instagram} alt="" className="share-icon" />}
              {profile?.social.discord && <img src={discord} alt="" className="share-icon" />}
              {profile?.social.telegram && <img src={telegram} alt="" className="share-icon" />}
              {profile?.social.mirror && <img src={mirror} alt="" className="share-icon" />}
              {profile?.social.github && <img src={github} alt="" className="share-icon" />} */}
              {!target && <div className="more-wrap">
                <img src={more} alt="" className="share-icon" />
                <Link to='/setting'>
                  <div className="setting-pop">
                    <img src={settingIcon} alt="" className="setting-icon" />
                    <span className="setting-text">Setting</span>
                  </div>
                </Link>
              </div>}
            </div>
          </div>
          
        </div>
        <div className="bottom">
          <Box>
            <StyledTabs
              value={tabType}
              // onChange={handleChange}
            >
              <StyledTab value={PROFILE_TABS.INVESTED} label="Invested" onClick={() => handleChange(PROFILE_TABS.INVESTED)} />
              <StyledTab value={PROFILE_TABS.COMMENTS} label="Community Comments" onClick={() => handleChange(PROFILE_TABS.COMMENTS)} />
              <StyledTab value={PROFILE_TABS.WATCHLIST} label="Watchlist" onClick={() => handleChange(PROFILE_TABS.WATCHLIST)} />
              {showApply && <StyledTab value={PROFILE_TABS.APPLY} label="Financing Activities" onClick={() => handleChange(PROFILE_TABS.APPLY)} />}
            </StyledTabs>
          </Box>
          {!target && <p className="login-record">Since: {dealTime(profile?.user_info.signup_time)}
            <span className="last">Last Seen: {dealTime(profile?.last_seen)}</span>
          </p>}
        </div>

        <FollowListDialog
        open={openFollow}
        onClose={() => setOpenFollow(false)}
        isFollowers={isFollowers}
        list={followList}
        handleRefleshBack={handleRefleshFollowList}
        selfProfile={selfProfile}
        target={target}
        ></FollowListDialog>

        <ScoreListDialog
        open={openScoreList}
        onClose={() => setOpenScoreList(false)}
        list={scoreList}
        ></ScoreListDialog>

        <Dialog open={openMintDialog} onClose={() => setOpenMintDialog(false)}>
          <MintProfileNFT
          onClose={() => setOpenMintDialog(false)}
          onSuccess={handleSuccessMint}
          ></MintProfileNFT>  
        </Dialog>

        <ProfileNftCardDialog
        open={showProfileNftCardDialog}
        onClose={() => setShowProfileNftCardDialog(false)}
        ></ProfileNftCardDialog>

        {openPoap && <PoapDialog open={openPoap} onClose={() => setOpenPoap(false)} data={tokenList[currentTokenIndex]}></PoapDialog>}
    </div>
  );
}
