import {
  CHAIN_ARBITRUM_TESTNET,
  CHAIN_AURORA_TESTNET,
  CHAIN_BSC,
  CHAIN_BSC_TESTNET,
  CHAIN_ETHER,
  CHAIN_ETHER_KOVAN,
  CHAIN_FANTOM,
  CHAIN_HARMONY_0,
  CHAIN_HARMONY_0_TESTNET,
  CHAIN_OK,
  CHAIN_OK_TESTNET,
  CHAIN_POLYGON,
  CHAIN_POLYGON_MUMBAI,
  Chains,
} from '@w3u/chains'
import Web3 from 'web3'
import { getChainByChainId } from '@thirdweb-dev/chains'

export const getUrl = (chainID?: number) => {
  // const id = '12eb5516d58a46a3a0e4522fa1811797'
  const id = 'f05d1f443cdc4cd089c073969678e26a'
  if (!chainID) {
    return `https://mainnet.infura.io/v3/${id}`
  }

  let url
  switch (chainID) {
    case CHAIN_ETHER_KOVAN:
      url = `https://kovan.infura.io/v3/${id}`
      break
    case CHAIN_ETHER:
      // url = 'https://eth-mainnet.alchemyapi.io/v2/xt19QLfMwjVcrvqiqfbHWDJV7_Yx1vn0'
      url = `https://mainnet.infura.io/v3/${id}`
      break
    case CHAIN_OK_TESTNET:
      url = 'https://exchaintestrpc.okex.org'
      break
    case CHAIN_OK:
      url = 'https://exchainrpc.okex.org'
      break
    case CHAIN_HARMONY_0:
      url = 'https://api.harmony.one'
      break
    case CHAIN_HARMONY_0_TESTNET:
      url = 'https://api.s0.pops.one'
      break
    case CHAIN_BSC_TESTNET:
      url = 'https://data-seed-prebsc-1-s1.binance.org:8545'
      break
    case CHAIN_BSC:
      url = 'https://bsc-dataseed.binance.org/'
      // url = 'https://bsc-dataseed2.defibit.io/'
      break
    case CHAIN_POLYGON_MUMBAI:
      // url = 'https://rpc-mumbai.matic.today'
      // url = 'https://rpc-mumbai.maticvigil.com/'
      // url = 'https://polygon-mumbai.infura.io/v3/f064dd62c6b646a788786d0dfb59623a'
      url = 'https://polygon-mumbai.infura.io/v3/f05d1f443cdc4cd089c073969678e26a'
      break
    case CHAIN_POLYGON:
      url = 'https://polygon-rpc.com/'
      // url = 'https://polygon-mainnet.g.alchemy.com/v2/rPqtNyA8W6Auhe9Yv2jpE5JeczyfnTr8'
      // url = 'https://rpc-mainnet.matic.quiknode.pro'
      break
    case CHAIN_FANTOM:
      url = 'https://rpc.ftm.tools/'
      break
    case 421613:
      url = 'https://goerli-rollup.arbitrum.io/rpc/'
      break
    case 97:
      url = 'https://bsc-testnet.publicnode.com'
      break
    case 5:
      url = 'https://ethereum-goerli.publicnode.com'
      break
    default:
      url = Chains[chainID]?.rpc || ''
  }

  // console.log('providerUrl:', getChainByChainId(chainID))
  return url || getChainByChainId(chainID).rpc[0] || ''
}

export const getUrlProvider = (chainID?: number) => {
  // console.log('providerUrl:', getUrl(chainID))
  return new Web3.providers.HttpProvider(getUrl(chainID))
}

export const isTestnet = (chainID: number | undefined): boolean => {
  return (
    chainID === CHAIN_ETHER_KOVAN ||
    chainID === CHAIN_BSC_TESTNET ||
    chainID === CHAIN_POLYGON_MUMBAI ||
    chainID === CHAIN_OK_TESTNET ||
    chainID === CHAIN_AURORA_TESTNET ||
    chainID === CHAIN_ARBITRUM_TESTNET
  )
}
