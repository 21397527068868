import React from 'react';
import './index.scss'
import { WatchlistResponseType, WatchlistType, getWatchlist } from 'api/user';
import ProjectBox from '../project-box';
import Filter from '../filters';
import { getWS, WSMessage, registerMsg, unRegisterMsg } from 'utils/ws';
import { WS_SCOPE, WS_MSG_TYPE } from 'constant/ws';
import Empty from 'components/empty';

interface Props {
  target:string
}

export default function WatchList(props:Props) {

  const { target } = props

  const [projects, setProjects] = React.useState<Array<WatchlistType>>([])
  const [annoucementProjectIds, setAnnoucementProjectIds] = React.useState<string[]>([])

  async function getData(status:number) {
    const data:WatchlistResponseType = await getWatchlist({
      page_no: 1,
      page_size: 20,
      status: status ,
      // sort:type,
      target_user_id: target
    })
    setProjects(data?.projects || [])
  }

  function register() {
    registerMsg(WS_SCOPE.WATCHER)
  }

  React.useEffect(() => {
    const ws = getWS()
    if (!ws) return
    // console.log(ws.readyState)
    if (ws.OPEN === ws.readyState) {
      // console.log(2)
      registerMsg(WS_SCOPE.WATCHER)
    } else {
      ws.addEventListener('open', register)
    }
    ws.addEventListener('message', function(e:MessageEvent<any>) {
      try {
        const msgData:WSMessage = JSON.parse(e.data)
        console.log('watch message', e.data)
        if (msgData.type !== WS_MSG_TYPE.ANNOUCEMENT) return
        console.log('msgData:', msgData)
        const annoucementData = JSON.parse(msgData.msg)
        console.log('annoucementData', annoucementData)
        if (!annoucementData.project_id) return
        setAnnoucementProjectIds([...annoucementProjectIds, annoucementData.project_id as string])
      } catch (error:any) {
        console.log(error)
      }
      // readMsg(msgData, WS_SCOPE.WATCHER)
    })
    return () => {
      unRegisterMsg(WS_SCOPE.WATCHER)
      ws.removeEventListener('open', register)
    }
  }, [])

  return (
    <div className='watchlist'>
        <Filter onChange={getData} target={target} showSuccessText></Filter>
        {/* {JSON.stringify(annoucementProjectIds)} */}
        {projects.length > 0 ? <div className="project-box-wrap">
          {projects.map((item) => {
            return (
              <ProjectBox key={`project_${item.project_id}`} watchlistProject={item} annouceUpdated={annoucementProjectIds.includes(item.project_id)}></ProjectBox>
            )
          })}
        </div> : <Empty hideBtn></Empty>}
    </div>
  );
}
