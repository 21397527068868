import React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import metamaskIcon from 'assets/img/login/metamask.png'
import googleIcon from 'assets/img/login/google.png'
import walletconnectIcon from 'assets/img/login/walletconnect.png'
import personIcon from 'assets/img/person.png'
import logoutIcon from 'assets/img/logout.png'
import copyIcon from 'assets/img/setting/copy.png'
import closeIcon from 'assets/img/setting/close.png'
import balanceIcon from 'assets/img/balance.png'
// import chainLogo from 'assets/svg/chain-logo.svg'
import settingIcon from 'assets/img/setting.png'
import './index.scss'
import { removeToken, getLoginType, getAddr, isLogin } from 'utils/auth';
import { logout, getProfile, ProfileType } from 'api/user';
import { LOGIN_TYPE } from 'constant';
import Message from 'components/common/message';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getWeb3ByRpc, useErc20Contract, getWeb3, useOTCContract } from 'utils/web3';
import { dealMetamaskAddress } from 'utils/utils';
import { Link } from 'react-router-dom';
import { ConstractConnectors, supportedChainId, TokenConnectorsMap } from 'config/connector';
import _ from 'lodash';
// import ethers from 'ethers'
// import { Contract, Provider } from 'ethers-multicall';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getOTCDetail, OTCDetail } from 'api/trade';
import Multicall from '@dopex-io/web3-multicall';
import { getUrlProvider } from 'utils/providers';
import { TokenIconMap } from 'pages/apply/maps';
import { CALL_RPC } from 'config/config';
import { getChainByChainId } from '@thirdweb-dev/chains';

type Balance = {
    symbol: string,
    balance: number,
    icon: string
}

export default function SettingDialog(props: DialogProps) {

    const projectId = useParams().id || ''

    const { open, onClose }:React.MouseEventHandler<HTMLElement>|any = props

    const [account, setAccount] = React.useState('')
    const [balance, setBalance] = React.useState(0)
    const [email, setEmail] = React.useState('')
    const [balanceList, setBalanceList] = React.useState<Array<Balance>>([])
    const [chainName, setChainName] = React.useState('')
    const [profile, setProfile] = React.useState<ProfileType>()

    const location = useLocation()
    const loginType = getLoginType()

    async function doLogout() {
        try {
            await logout()
            removeToken()
            window.location.href = '/'
            onClose && onClose()
        } catch (e:any) {
            console.log(e)
            Message(e?.message || 'Network error', { severity: 'error' })
        }
    }

    async function initProfile() {
        if (!isLogin()) return
        const data = await getProfile({})
        setProfile(data)
    }

    // const isGoogleLogin = getLoginType() === LOGIN_TYPE.GOOGLE

    const formatBalanceNumber = (b: number, decimal: number):number => {
        return +_.divide(b, Math.pow(10, decimal)).toFixed(4)
    }

    const getMetamaskInfo = async () => {
        try {
            const web3 = getWeb3()
            const isTradingDetailPage = location.pathname.startsWith('/trading-detail')
            
            const list:Array<Balance> = ConstractConnectors.filter(item => !(isTradingDetailPage && item.symbol === 'WETH')).map(d => {
                return {
                    symbol: d.symbol,
                    balance: 0,
                    icon: d.icon
                }
            })
            setBalanceList(list)
            const accounts = await web3.eth.getAccounts()
            console.log(accounts)
            if (!accounts || !accounts[0]) return
            setAccount(accounts[0])
            
            const web3ByRpc = getWeb3ByRpc()
            const chainId = await web3.eth.getChainId()
            // const 
            const chain = getChainByChainId(chainId)
            console.log('chain:', chain)
            setChainName(chain.name)
            // const _web3 = getWeb3(chainId)
            const _balance = await web3ByRpc.eth.getBalance(accounts[0])
            setBalance(formatBalanceNumber(+_balance, 18))
            console.log('balance:', _balance)

            if (isTradingDetailPage) {
                getTradeTokenList(accounts[0])
            } else {

                const batch = new web3ByRpc.eth.BatchRequest()
    
                ConstractConnectors.map(d => {
                    batch.add(useErc20Contract(d.address, chainId, CALL_RPC).methods.balanceOf(accounts[0]).call.request({}, function(_:any, v:any) {
                        const find = list.find(item => item.symbol === d.symbol)
                        console.log(v, find)
                        if (!find) return
                        find.balance = formatBalanceNumber(+v || 0, d.decimal)
                        setBalanceList([...list])
                    }))
                })
                batch.execute()

            }
        } catch (e) {
            // address invalid auth
            console.log(e)
        }
    }

    async function getTradeTokenList(account: string) {
        const detail:OTCDetail = await getOTCDetail({
            project_id: projectId
        })
        const addrList = await useOTCContract(detail.official_addr, detail.blockchain.chain_id).methods.getSupports().call()
        const multicall = new Multicall({
            multicallAddress: detail.blockchain.multicall,
            provider: getUrlProvider(detail.blockchain.chain_id),
        })
        const symbolAggregates: any[] = []
        const decimalsAggregates: any[] = []
        const balanceAggregates: any[] = []
        addrList.forEach((addr:string) => {
            symbolAggregates.push(useErc20Contract(addr, detail.blockchain.chain_id).methods.symbol())
            decimalsAggregates.push(useErc20Contract(addr, detail.blockchain.chain_id).methods.decimals())
            balanceAggregates.push(useErc20Contract(addr, detail.blockchain.chain_id).methods.balanceOf(account))
        })
        // const symbolAggregates = addrList.map((addr:string) => {
        //     return useErc20Contract(addr, detail.blockchain.chain_id).methods.symbol()
        // })
        // const decimalsAggregates = addrList.map((addr:string) => {
        //     return useErc20Contract(addr, detail.blockchain.chain_id).methods.decimals()
        // })
        // const balanceAggregates = addrList.map((addr:string) => {
        //     return useErc20Contract(addr, detail.blockchain.chain_id).methods.balanceOf(account)
        // })
        const rs = await Promise.all([
            multicall.aggregate(symbolAggregates),
            multicall.aggregate(decimalsAggregates),
            multicall.aggregate(balanceAggregates)
        ])
        const symbolList = rs[0]
        const decimalsList = rs[1]
        const balanceList = rs[2]

        setBalanceList(symbolList.map((item:string, index:number) => {
            return {
                symbol: item,
                balance: formatBalanceNumber(balanceList[index], +decimalsList[index]),
                icon: TokenIconMap[item]
            }
        }))
    }
    
    const getGoogleInfo = async () => {
        const data:ProfileType = await getProfile({})
        setEmail(data.user_info.email)
    }

    async function getConnectWalletInfo() {
        getMetamaskInfo()
        // setAccount(getAddr())
        // const chain = getChainByChainId(supportedChainId)
        // console.log('chain:', chain)
        // setChainName(chain.name)
    }

    React.useEffect(() => {
        initProfile()
        if (loginType === LOGIN_TYPE.GOOGLE) {
            getGoogleInfo()
        } else if (loginType === LOGIN_TYPE.METAMASK) {
            getMetamaskInfo()
        } else if (loginType === LOGIN_TYPE.CONNECT_WALLET) {
            getConnectWalletInfo()
        }
    }, [])

    // React.useEffect(() => {
    //     console.log(location)
    //     initProfile()
    // }, [])

    // React.useEffect(() => {
    //     getMetamaskInfo()
    // }, ['updated'])
    
    return (
        <div className="setting-dialog">
        {/* <Dialog {...props}> */}
            <div className="setting-dialog-content">
                {
                    loginType === LOGIN_TYPE.GOOGLE?(
                        <div className="box">
                            <div className="left">
                                <img src={googleIcon} alt="" className='icon' />
                                <div>
                                    <p className='sub-title'>Google</p>
                                    <p className="title">{email}</p>
                                </div>
                            </div>
                        </div>
                    ):(
                    <div>
                        <div className="box">
                            <div className="left">
                                <img src={loginType === LOGIN_TYPE.CONNECT_WALLET ? walletconnectIcon : metamaskIcon} alt="" className='icon' />
                                <div>
                                    <p className='sub-title'>{chainName || ''}</p>
                                    <p className="title">{dealMetamaskAddress(account || profile?.user_info.wallet || '')}</p>
                                    
                                </div>
                            </div>
                            <div className="right">
                                {account && <CopyToClipboard text={account} onCopy={() => Message('copy success!')}>
                                    <img src={copyIcon} alt="" className="icon" />
                                </CopyToClipboard>}
                                <img src={closeIcon} alt="" className="icon" onClick={() => doLogout()} />
                                {/* <div className="btn">Connected</div>
                                <div className="btn-sub" onClick={() => doLogout()}>Disconnect</div> */}
                            </div>
                        </div>
                        <div className="balance-box">
                            <div className="balance-item">
                                <img src={balanceIcon} alt="" className="icon" />
                                <div className="text">ETH</div>
                                <div className="transfer">{balance}</div>
                            </div>
                            {balanceList.map((d, i) => {
                                return (
                                    <div className="balance-item" key={`balance-item_${i}`}>
                                        <img src={d.icon} alt="" className="icon" />
                                        <div className="text">{d.symbol}</div>
                                        <div className="transfer">{d.balance}</div>
                                    </div>
                                )
                            })}
                            {/* <div className="transfer">$ 1.32</div> */}
                        </div>
                    </div>
                    )
                }
                
                <div className="box-operate">
                    <Link to='/profile'>
                        <img src={personIcon} alt="" className="icon" />
                        <span className="text">Profile</span>
                    </Link>
                </div>
                <div className="box-operate">
                    <Link to='/setting'>
                        <img src={settingIcon} alt="" className="icon" />
                        <span className="text">Setting</span>
                    </Link>
                </div>
                {/* <div className="divider"></div> */}
                {loginType === LOGIN_TYPE.GOOGLE ? (
                    <div className="box-operate" onClick={doLogout}>
                        <img src={logoutIcon} alt="" className="icon" />
                        <span className="text">Logout</span>
                    </div>
                ) : (<></>)}
            </div>
        {/* </Dialog> */}
        </div>
    )
}