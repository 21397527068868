import React from 'react';
// import { Provider } from 'react-redux';
// import { store } from './store';
// import reportWebVitals from './reportWebVitals';
import './index.scss';
import './reset.css'
import {
  RouterProvider,
} from "react-router-dom";
import router from './router/index'
import ReactDOM from "react-dom/client";
// import Container from './components/container';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  //   {/* <Provider store={store}> */}
  //     <Container>
  //       <RouterProvider router={router} ></RouterProvider>
  //     </Container>
  //   {/* </Provider> */}
  // </React.StrictMode>
  // <Container>
  //   <RouterProvider router={router} ></RouterProvider>
  // </Container>
  <RouterProvider router={router} ></RouterProvider>
);

// const container = document.getElementById('root')!;
// const root = createRoot(container);

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <RouterProvider router={router} />
//     </Provider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
