import React from 'react';
import './index.scss'
import { getInvested, ProjectsResponseType, ProjectType } from 'api/user';
import ProjectBox from '../project-box';
import Filter from '../filters';
import Empty from 'components/empty';

interface Props {
  target:string
}

export default function Invested(props:Props) {

  const { target } = props

  // sort type
  const [projects, setProjects] = React.useState<Array<ProjectType>>([])

  async function getData(status:number, type:number) {
    const data:ProjectsResponseType = await getInvested({
      page_no: 1,
      page_size: 20,
      status: status ,
      sort:type,
      target_user_id: target
    })
    setProjects(data?.projects || [])
  }

  return (
    <div className='invested'>
        <Filter onChange={getData} filterSortType target={target}></Filter>
        {projects.length > 0 ? <div className="project-box-wrap">
          {projects.map((item) => {
            return (
              <ProjectBox key={`project_${item.project_id}`} investedProject={item}></ProjectBox>
            )
          })}
        </div> : <Empty hideBtn></Empty>}
    </div>
  );
}
