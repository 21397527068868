import React from 'react'
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import * as  quillEmoji from 'quill-emoji'
import 'quill-emoji/dist/quill-emoji.css'
import './index.scss'

Quill.register("modules/emoji", quillEmoji)


interface Props extends ReactQuill.ReactQuillProps {
    onRef?: (el:ReactQuill) => void
}

const QuillEditor = (props:Props) => {
    const { onRef } = props

    return (
        // <div id="quill"></div>
        <ReactQuill
            theme='snow'
            modules={{
                toolbar: [
                    // ["bold", "italic", "underline", "strike", "blockquote"],
                    // [{ header: [1, 2, false] }],
                    [{size: ['small', 'large', 'huge']}],
                    ["bold", "underline"],
                    [{ 'color': [] }], 
                    ['clean'],
                    // ['code-block'],
                    // [{ 'font': [] }],
                    // [{ 'align': [] }],
                    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                    ["link", 'video', 'image'],
                    // [{ size: ["small", false, "large", "huge"] }]
                    ['emoji']
                ],
                'emoji-toolbar': true,
                // "emoji-textarea": true,
                "emoji-shortname": true
            }}
            {...props}
            ref={(el:ReactQuill) => {
                onRef && onRef(el)
                // el?.editor?.focus()
            }}
        ></ReactQuill>
    )
}

export default QuillEditor