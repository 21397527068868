import React from 'react'
import { Button } from '@mui/material';
import './index.scss'

interface Props {
    onRefresh?: () => void
    btnText?: string
    text2?: string
    text1?: string
    hideBtn?: boolean
}

export default function Empty(props: Props) {
    const { onRefresh, btnText = 'Refresh', text1 = 'No results', text2 = 'Try Refresh or reset your filters', hideBtn = false } = props
    return (
        <div className='empty-component'>
            <img src={require('assets/img/empty.png')} alt="" className="icon" />
            <div className="empty-text1">{text1}</div>
            <div className="empty-text2">{text2}</div>
            {!hideBtn && <Button className='btn-1seed empty-btn' variant='contained' onClick={() => onRefresh && onRefresh()}>{btnText}</Button>}
        </div>
    )
}