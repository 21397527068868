import React, { useEffect } from 'react';
import './index.scss'
import { ProjectType, WatchlistType } from 'api/user';
import { INVEST_STATUS } from 'constant';
import arrowRightIcon from 'assets/img/arrow-right.png'
// import identifiedImg from 'assets/img/profile-info/identified.png'
// import ethImg from 'assets/img/project/eth.png'
import chainLogo from 'assets/svg/chain-logo.svg'
import { formatFinTime } from 'utils/utils';
import { formatProjectTitle } from 'utils/utils';
import { useNavigate } from 'react-router';
import { dealAmountDecimal } from 'utils/utils';
import { formatMoney } from 'utils/utils';

type propsType = {
  investedProject?: ProjectType,
  watchlistProject?: WatchlistType,
  annouceUpdated?: boolean
}

let interval: NodeJS.Timer | undefined
let timer = 0

const statusMap = {
  [INVEST_STATUS.FAILD]: {
    text: 'Failed',
    style: 'faild'
  },
  [INVEST_STATUS.INVESTOR]: {
    text: 'Success',
    style: 'success'
  },
  [INVEST_STATUS.FINANCING]: {
    text:'Financing',
    style: 'financing'
  },
  [INVEST_STATUS.PENDING]: {
    text: 'Pending',
    style: 'pending'
  }
}

export default function ProjectBox(props: propsType) {
  const { investedProject, watchlistProject, annouceUpdated } = props
  // console.log(annouceUpdated)
  const navigate = useNavigate()

  const project:any = investedProject || watchlistProject || []

  const [endTime, setEndTime] = React.useState(-1)

  function initTimer() {
    if (!investedProject || project.status !== INVEST_STATUS.FINANCING) {
      return
    }
    const leftTime = project.project_info.fin_end - Date.now()
    if (leftTime <= 0) {
      return
    }
    setEndTime(leftTime)
    if (interval) {
      return
    }
    timer = leftTime
    setEndTime(leftTime)
    interval = setInterval(() => {
      if (timer - 1000 < 0) {
        clearInterval(interval)
        return
      }
      timer = timer - 1000
      setEndTime(timer)
    }, 1000)
  }

  useEffect(() => {
    initTimer()
  }, [])

  function getDate(t:string) {
    if (project.status !== INVEST_STATUS.FINANCING || !investedProject) {
      return '-'
    }
    if (endTime <= 0) {
      return '-'
    }
    if (t === 'd') {
      return Math.floor(endTime / (1000 * 60 * 60 * 24))
    }
    if (t === 'h') {
      return Math.floor(endTime / (1000 * 60 * 60) % 24)
    }
    if (t === 'm') {
      return Math.floor(endTime / (1000 * 60) % 60)
    }
    if (t === 's') {
      return Math.floor(endTime / (1000) % 60)
    }
  }

  function goUserProfile(id:string, e:any) {
    e.stopPropagation()
    navigate(`/profile?id=${id}`)
  }

  return (
    <div className={`project-box ${statusMap[project.status]?.style || ''}`} onClick={() => navigate(`/invest-detail/${project.project_id}${annouceUpdated ? '?read=1' : ''}`)}>
        <div className="status-wrap">
          <div className="status-content">
            {project.status === INVEST_STATUS.INVESTOR && 'Invest success'}
            {project.status === INVEST_STATUS.FAILD && (
              <span>Invest failed, Please unlock your investment
                <img src={arrowRightIcon} alt="" className='icon' />
              </span>
            )}
            {project.status === INVEST_STATUS.FINANCING && investedProject && (
              <div className="fin-time-wrap">
                <div className="fin-time">
                  <div className="num">{getDate('d')}</div>
                  <div className="desc">DAYS</div>
                </div>
                <div className="fin-time">
                  <div className="num">{getDate('h')}</div>
                  <div className="desc">HRS</div>
                </div>
                <div className="fin-time">
                  <div className="num">{getDate('m')}</div>
                  <div className="desc">MINS</div>
                </div>
                <div className="fin-time">
                  <div className="num">{getDate('s')}</div>
                  <div className="desc">SECS</div>
                </div>
              </div>
            )}
            {project.status === INVEST_STATUS.FINANCING && watchlistProject && annouceUpdated && (
              <div className="watch-updated">
                <div className="new">New</div>
                <span className='text'>Official Announcement Update
                  <img src={require('assets/img/profile-info/annoucement-right.png')} alt="" className='icon' />
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="status">{statusMap[project.status]?.text || ''}</div>
        <div className="content">
          <div className="info">
            <div className="left">
              <div className="name" title={formatProjectTitle(project.project_info.name, project.project_info.rounds)}>{formatProjectTitle(project.project_info.name, project.project_info.rounds)}</div>
              <div className="fin">
                <div className="fin-left">
                  <div className="fin-title">Financing Goal：</div>
                  <div className="fin-num">{formatMoney(project.project_info.fin_goal)} {project.project_info.fin_token_symbol}</div>
                </div>
                <div className="fin-right">
                  <div className="fin-title">Total Financing：</div>
                  <div className="fin-progress" title={`${project.project_info.fin_amount} ${project.project_info.fin_token_symbol}`}>
                    <div className="progress"    
                      style={{
                        width: `${project.project_info.fin_amount / (project.project_info.fin_goal * 1.5) * 100}%`
                      }}></div>
                    <div className="total"></div>
                  </div>
                  <div className="fin-time">{formatFinTime(project.project_info.fin_end || 0)}</div>
                </div>
              </div>
              {investedProject ? (
                <div className="shares">
                <span className="label">My shares：</span>
                <span className="share">{dealAmountDecimal(project.my_share, project.project_info.fin_token_decimal)} {project.project_info.fin_token_symbol}</span>
              </div>
              ) : (
                <div className="shares">
                  {project.top_investors?.map((item:any, index:number) => {
                    return (
                      <img src={item.profile_uri} alt="" key={index} className='top' onClick={(e) => goUserProfile(item.user_id, e)} />
                    )
                  })}
                  {!project.top_investors?.length && (
                    <>
                        <img src={require('assets/img/avatar-empty.png')} alt="" className="top" />
                        <div className="empty-text">No investors yet</div>
                    </>
                  )}
                </div>
              )
            }
            </div>
            <img src={project.project_info.image_url} alt="" className="right" />
          </div>
          <div className="bottom">
            <div className="left">
              <img src={project.project_info.slogan_url} alt="" className="logo" />
              <div className="name">{project.project_info.name}</div>
              {/* <img src={identifiedImg} alt="" className="identified" /> */}
            </div>
            <div className={`right ${watchlistProject && 'watch'}`}>
              {project.project_info.tracks && project.project_info.tracks[0] || ''}
              <img src={chainLogo} alt="" className='icon' />
            </div>
            {watchlistProject && (
              <div className="watch-info">
                <span className="item">{project.investor_count}
                  <span className="sub">Investors</span>
                </span>
                <span className="item">{project.comments_cnt}
                  <span className="sub">Comments</span>
                </span>
              </div>
            )}
          </div>
        </div>
    </div>
  );
}
