import React from 'react'
import './index.scss'
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';

interface Props {
    onClose: () => void
    open: boolean
    handleClickBtn?: () => void
    variant?: 'warning' | 'error' | 'success'
    title: string
    content: string
    // subContent?: string
    btnText?: string
    hideStatusImg?: boolean
    img?: string
}

export default function RemindDialog(props:Props) {
    const { open, onClose, handleClickBtn, variant = 'success', title, content, btnText = 'I GOT IT', hideStatusImg = false, img } = props

    return (
        <Dialog open={open} onClose={onClose} className="remind-dialog">
            <div className="content">
                {(!hideStatusImg && !img) && <img src={require(`assets/img/message/${variant}.png`)} alt="" className="icon" />}
                {img && <img src={img} alt="" className="icon" />}
                <div className="text1">{title}</div>
                <div className="text2">{content}</div>
                <Button className="btn btn-1seed" variant='contained' onClick={() => {
                    onClose()
                    handleClickBtn && handleClickBtn()
                }}>{btnText}</Button>
            </div>
        </Dialog>
    )
}